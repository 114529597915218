/* eslint-disable react/prop-types */
import React from 'react';
import Block from './block';
/**
 * @param place - content block place name
 * @param props - all page props
 * @param otherProps - props, that will be additionally add to content block
 */
export default function factory(config, place, props, otherProps = {}) {
  return (
    <Block
      key={place}
      config={config}
      place={place}
      params={props.$blocksParams}
      places={props.$blocksPlaces}
      content={props.$blocksContent}
      {...otherProps}
    />
  );
}
