import React, { Children, PureComponent } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import { geolocationConnect, GeolocationProp } from 'geolocation';
import { connect } from 'react-redux';
import * as actions from './redux';
import linksModifier from './modifier';

let requesting = false;

@geolocationConnect()
@connect(state => ({
  geolandings: state.geolandings,
}), { ...actions }, null, { forwardRef: true })
export default class GeoLandings extends PureComponent {
  static propTypes = {
    geolocation: GeolocationProp,
    geolandings: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool
    }),
    // Base (non geolocated) menu from data preloader
    base: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    children: PropTypes.node,
    injectPropName: PropTypes.string.isRequired,
    actionLoad: PropTypes.func,
    project: PropTypes.string.isRequired,
  };

  static defaultProps = {
    injectPropName: 'geoLinks',
    project: __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.mountLocated = props.geolocation.status === 'located' ? true : false;
  }

  componentDidMount() {
    if (this.mountLocated) {
      this.mountLocated = false;
      this.forceUpdate();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      geolocation: newGeolocation,
      geolandings: newGeolandings,
      actionLoad, project
    } = this.props;
    const { location, confirmed } = newGeolocation;
    const { loading, loaded, failure } = newGeolandings;

    if (confirmed && location &&
      location.id &&
      newGeolandings.location !== location.id &&
      !requesting && !loading
    ) {
      requesting = true;
      actionLoad(project, location.id);
    }

    if (loaded || failure) {
      requesting = false;
    }
  }

  render() {
    const { base, injectPropName, project, geolocation, geolandings, } = this.props;
    const { location } = geolocation;
    const element =
      isArray(this.props.children) ?
        Children.only(this.props.children[0]) :
        Children.only(this.props.children);

    const query = {
      project
    };

    // This needed for fix bug with preloaded data and mounting with already located
    const injectLocation = this.mountLocated ? { status: 'unlocated' } : geolocation;

    if (geolocation.status === 'located' && location) {
      query.location = location.id;
    }

    return React.cloneElement(element, {
      ...element.props,
      geolocation: injectLocation,
      [injectPropName]: geolandings.loaded ? geolandings.data : linksModifier(base.data)
    });
  }
}
