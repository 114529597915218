import Leaflet from 'leaflet';

/**
 * Add a method, that return a GeoJSON polygon representation of LatLngBounds
 */
Leaflet.LatLngBounds.prototype.toGeoJSON = function toGeoJSON() {
  const nw = this.getNorthWest();
  const se = this.getSouthEast();
  return {
    type: 'Polygon',
    coordinates: [[
      [nw.lng, nw.lat],
      [se.lng, nw.lat],
      [se.lng, se.lat],
      [nw.lng, se.lat],
      [nw.lng, nw.lat]
    ]]
  };
};

/**
 * Add a method, that return a GeoJSON point representation of LatLng
 */
Leaflet.LatLng.prototype.toGeoJSON = function toGeoJSON() {
  return {
    type: 'Point',
    coordinates: [this.lng, this.lat]
  };
};

Leaflet.latLngFromGeoJSON = function fromGeoJSON(val = {}) {
  const c = val.coordinates;
  if (!val.type) {
    return null;
  }
  if (val.type !== 'Point') {
    // eslint-disable-next-line
    console.error('Invalid GeoJSON type for LatLng generation. Must be point.');
  }
  return Leaflet.latLng(c[1], c[0]);
};

/**
 * Override a Circle toGeoJSON for adding radius to result JSON
 */
const circleToGeoJSON = Leaflet.Circle.prototype.toGeoJSON;
Leaflet.Circle.prototype.toGeoJSON = function toGeoJSON() {
  const json = circleToGeoJSON.call(this);
  json.geometry.radius = this.getRadius();
  return json;
};

/**
 * Patch editable markers
 */
Leaflet.Editable.prototype.createVertexIcon = function createVertexIcon(options) {
  if (options && options.className && options.className.indexOf('middle-icon') > -1) {
    return Leaflet.divIcon({
      iconSize: Leaflet.point(8, 8),
      iconAnchor: Leaflet.point(3, 3)
    });
  }
  return Leaflet.divIcon({
    iconSize: Leaflet.point(10, 10),
    iconAnchor: Leaflet.point(4, 4)
  });
};

