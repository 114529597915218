import loadable from '@loadable/component';
import module from './form';
import AbstractInput from './abstractInput';
import Input from './input';
import Checkbox from './input-checkbox';
// import InputArray from './input-inputarray';
// import MaskedInput from './input-masked';
// import PhoneInput from './input-phone';
// import ImageInput from './input-image';
// import ComboboxInput from './input-combobox';
// import ComboboxInputWrapper from './input-combobox-wrapper';
// import InputListBox from './input-listbox';
// import ComboListboxWrapper from './combo-listbox-wrapper';
// import AutocompleteInput from './input-autocomplete';
import RecaptchaButton from './input-recaptcha-button';
import Text from './input-text';
// import InputLocality from './input-locality';
import InputHide from './input-hide';
import InputShowSelected from './input-showselected';
import InputClearField from './input-clearfield';
import InputSubform from './input-subform';
import InputLabel from './input-label';
// import InputDatePicker from './input-date-picker';
import HelperInput from './input-helper';
import VisFunc from './vis-func';
import DependentFields from './dependent-fields';

const InputArray = loadable(() => import('./input-inputarray'));
const MaskedInput = loadable(() => import('./input-masked'));
const PhoneInput = loadable(() => import('./input-phone'));
const ImageInput = loadable(() => import('./input-image'));
const ComboboxInput = loadable(() => import('./input-combobox'));
const ComboboxInputWrapper = loadable(() => import('./input-combobox-wrapper'));
const InputListBox = loadable(() => import('./input-listbox'));
const ComboListboxWrapper = loadable(() => import('./combo-listbox-wrapper'));
const AutocompleteInput = loadable(() => import('./input-autocomplete'));
const InputLocality = loadable(() => import('./input-locality'));
const InputDatePicker = loadable(() => import('./input-date-picker'));
const VisualizeSelectedItems = loadable(() => import('./visualize-selected-items'));

export {
  module as default,
  AbstractInput,
  Input,
  Checkbox,
  InputArray,
  MaskedInput,
  PhoneInput,
  ImageInput,
  ComboboxInput,
  ComboboxInputWrapper,
  InputListBox,
  ComboListboxWrapper,
  AutocompleteInput,
  RecaptchaButton,
  Text,
  InputLocality,
  InputHide,
  InputShowSelected,
  InputClearField,
  InputSubform,
  InputLabel,
  InputDatePicker,
  HelperInput,
  VisFunc,
  DependentFields,
  VisualizeSelectedItems
};
