import searchTranslates from '../section-projects/search-i18n';

export default function () {
  return {
    ...searchTranslates(),

    developerTopic: {
      ru: 'Застройщик',
      ua: 'Забудовник'
    },
    developerYear: {
      ru: ' с\u00a0{{placeholder}}\u00a0года',
      ua: ' з\u00a0{{placeholder}}\u00a0року'
    },
    developerHouse: {
      ru: 'дом',
      ua: 'будинок'
    },
    developerHouseDeclination: {
      ru: 'дома',
      ua: 'будинки'
    },
    developerHouseMultiple: {
      ru: 'домов',
      ua: 'будинків'
    },
    developerInCount: {
      ru: 'в\u00a0{{placeholder}}\u00a0ЖК',
      ua: 'в\u00a0{{placeholder}}\u00a0ЖК'
    },
    developerBuild: {
      ru: 'строят',
      ua: 'будують'
    },
    developerHouseReady: {
      ru: 'готовый дом',
      ua: 'готовий будинок'
    },
    developerHouseReadyDeclination: {
      ru: 'готовых дома',
      ua: 'готових будинки'
    },
    developerHouseReadyMultiple: {
      ru: 'готовых домов',
      ua: 'готових будинків'
    },
    developerHouseReadyCT: {
      ru: 'готовый КП',
      ua: 'готове КМ'
    },
    developerHouseReadyCTDeclination: {
      ru: 'готовых КП',
      ua: 'готових КМ'
    },
    developerHouseReadyCTMultiple: {
      ru: 'готовых КП',
      ua: 'готових КМ'
    },
    developerLinkText: {
      ru: 'Сайт компании',
      ua: 'Сайт компанії'
    },

    moreDetails: {
      ru: 'Подробнее',
      ua: 'Детальніше'
    },
    hide: {
      ru: 'Скрыть',
      ua: 'Сховати'
    },

    developerH2: {
      ru: 'Проекты',
      ua: 'Проєкти'
    },
    developerLand: {
      ru: 'Вся Украина',
      ua: 'Вся Україна'
    },
    developerFilterAllProjects: {
      ru: 'Все проекты',
      ua: 'Всі проєкти'
    },
    developerImgAlt: {
      ru: 'Жилые комплексы {{placeholder}}',
      ua: 'Житлові комплекси {{placeholder}}'
    },
    developerLinkToSiteTitle: {
      ru: 'Сайт застройщика',
      ua: 'Сайт забудовника'
    },
    developerPhoneTitle: {
      ru: 'Телефон застройщика',
      ua: 'Телефон забудовника'
    },
    developerFoundedYearText: {
      ru: 'Год основания',
      ua: 'Рік заснування'
    },
    developerHouseBuilt: {
      ru: 'Дом строится',
      ua: 'Будинок будується'
    },
    developerHouseBuiltDeclination: {
      ru: 'Дома строятся',
      ua: 'Будинки будуються'
    },
    developerHouseBuiltMultiple: {
      ru: 'Домов строятся',
      ua: 'Будинків будуються'
    },
    call: {
      ru: 'Позвонить',
      ua: 'Дзвонити'
    },
    developerForum: {
      ru: 'Отзывы {{placeholder}}',
      ua: 'Відгуки {{placeholder}}'
    },
    middleReliabilityIndex: {
      ru: 'Средний Индекс надёжности',
      ua: 'Середній Індекс надійності'
    },
    middleReliabilityIndexDescription: {
      ru: 'Средний Индекс надёжности проектов застройщика рассчитывается как среднее значение индексов всех его ЖК, которые не сданы в эксплуатацию на момент расчета показателя. Средний индекс рассчитывается, если застройщик возводит 2 и более ЖК на момент расчёта',
      ua: 'Середній Індекс надійності проєктів забудовника розраховується як середнє значення індексів усіх його ЖК, які не здані в експлуатацію на момент розрахунку показника. Середній індекс розраховується, якщо забудовник зводить 2 і більше ЖК на момент розрахунку'
    },
    from: {
      ru: 'из',
      ua: 'з'
    }
  };
}
