/**
 * Created by Kotkin on 14.06.2017.
 */
import React, { PureComponent, Children } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import forEach from 'lodash/forEach';
import Button from 'components/button';

import 'styles/base/components/modal/_modal-footer.scss';

const classes = new Bem('modalFooter');

/**
 * === FooterModal Component ===
 * Uses for rendering modal footer (optional).
 *
 * = Note =
 * Button or any element which are supposed to close modal window on click
 * should have *close* property
 *
 */

export default class FooterModal extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,

    // Footer content aligned to center
    center: PropTypes.bool,

    // Footer content aligned to left
    left: PropTypes.bool
  };

  static contextTypes = {
    close: PropTypes.func.isRequired
  };

  createButton(button, key) {
    const { close, children, label = null, onClick, ...other } = button.props;
    const handleClick = () => {
      if (onClick) onClick();
      return this.context.close();
    };

    return (
      <Button
        key={key}
        {...classes('btn', { close })}
        {...other}
        onClick={close ? handleClick : onClick && onClick}
        label={children || label}
      />
    );
  }

  createElement(element, key) {
    const { close, onClick } = element.props;
    const handleClick = () => {
      if (onClick) onClick();
      return this.context.close();
    };

    return (
      React.cloneElement(element, {
        onClick: close ? handleClick : onClick && onClick,
        key
      })
    );
  }

  render() {
    const elements = [];
    const { className, center, left } = this.props;
    const children = Children.toArray(this.props.children);

    forEach(children, (child, index) => {
      if (child.type !== 'button') {
        elements.push(this.createElement(child, index));
      } else {
        elements.push(this.createButton(child, index));
      }
    });

    return (
      <div
        {...classes({
          extra: className
        })}
      >
        <div {...classes('contentContainer', { center, left })} data-test="showButtonModal">
          <div {...classes('content')} children={elements} />
        </div>
      </div>
    );
  }
}
