/**
 * Created by Kotkin on 28.05.2017.
 */
import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import simpleStore from 'helpers/simpleStore';

import 'styles/base/components/interactive-fly-component/_interactive-fly-component.scss';

import Icon from 'component/icon';

const classes = new Bem('interactive');

/**
 *
 * === Interactive Component ===
 *
 * = Example =
 * <InteractiveComponent
 *   iconType={icon type}
 *   clicked={bool}
 *   newIconType={icon type}
 *   destinationPoint={simpleStore key}
 * >
 *   <Button onClick={func}>
 *     <span>
 *       <span>
 *         <Icon type={icon type} />
 *       </span>
 *     </span>
 *   </Button>
 * </InteractiveComponent>
 *
 */

export default class InteractiveComponent extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,

    // Type of an icon with initial coordinates
    iconType: PropTypes.string.isRequired,

    // Type of an icon which will fly after button is clicked
    newIconType: PropTypes.string.isRequired,

    // Key in simpleStore of an object with final coordinates
    destinationPoint: PropTypes.string.isRequired,

    // Defines whether element is clicked or not
    active: PropTypes.bool
  };

  static defaultProps = {
    active: false
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.onClickHandle = this.onClickHandle.bind(this);
    this.clicked = props.active;
  }

  /* TODO: this is unneeded
  componentWillReceiveProps({ active }) {
    this.clicked = !!active;
  }
  */

  onClickHandle() {
    const { children, destinationPoint, iconType } = this.props;
    if (children.props.onClick) children.props.onClick();
    if (this.clicked) {
      this.clicked = false;
      return;
    }

    // get final coordinates
    const destObj = simpleStore.get(destinationPoint, true);
    if (!destObj) return false;
    const top = (destObj.top + (destObj.height / 2)) / 10;
    const left = (destObj.left + (destObj.width / 2)) / 10;

    // find needed DOM elements and set initial position
    let startIcon = this.node.querySelector(`.icon_${iconType}`);
    if (!startIcon) return;
    startIcon = startIcon.getBoundingClientRect();
    const posTop = startIcon.top / 10;
    const posLeft = startIcon.left / 10;
    this.icon.classList.add('visible');
    this.icon.style.top = `${posTop}rem`;
    this.icon.style.left = `${posLeft}rem`;

    setTimeout(() => {
      this.icon.classList.add('scaled');
      this.icon.style.top = `${top}rem`;
      this.icon.style.left = `${left}rem`;
    }, 20);
    setTimeout(() => {
      this.icon.classList.remove('visible');
      this.icon.classList.remove('scaled');
    }, 580);
    this.clicked = true;
  }

  render() {
    const { className, children, newIconType } = this.props;

    return (
      <div
        {...classes({
          extra: className
        })}
        ref={ref => this.node = ref}
      >
        {cloneElement(children, { onClick: this.onClickHandle })}
        <div {...classes('element', { [newIconType]: true })} ref={ref => this.icon = ref}>
          <Icon {...classes('icon')} type={newIconType} />
        </div>
      </div>
    );
  }
}
