import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'leaflet-textpath';
import DataProvider from 'api/data-provider';

/**
 * @abstract
 * Wrap gis/info layer for dynamically request data, limited by map bbox
 */
export default class BboxWrapper extends Component {
  static propTypes = {
    query: PropTypes.shape({}),
    provider: PropTypes.string,
    bboxCriteria: PropTypes.string,
    updateEvent: PropTypes.string,
    updateMatch: PropTypes.string
  };

  static defaultProps = {
    provider: 'admin/location/gis-layer',
    bboxCriteria: 'geoIntersects'

  };

  constructor(props, ctx) {
    super(props, ctx);
    this.update = this.update.bind(this);
    this.component = null; // Must be redec
    this.state = {
      bbox: null
    };
  }

  update(bbox) {
    this.setState({
      bbox
    });
  }

  reload() {
    // TODO fix it this.provider.reload();
  }

  render() {
    const {
      provider,
      query,
      bboxCriteria,
      updateEvent,
      updateMatch,
      ...otherProps
    } = this.props;
    const newQuery = {
      [bboxCriteria]: this.state.bbox,
      ...query
    };

    const MapComponent = this.component;

    if (!this.state.bbox) {
      return (
        <MapComponent //eslint-disable-line
          onUpdate={this.update}
          {...otherProps}
        />
      );
    }

    return (
      <DataProvider
        url={provider}
        queryJson
        ref={el => this.provider = el}
        query={newQuery}
        updateEvent={updateEvent}
        updateMatch={updateMatch}
        updateMatchDataField="properties"
      >
        <MapComponent
          onUpdate={this.update}
          updateEvent={updateEvent}
          {...otherProps}
        />
      </DataProvider>
    );
  }
}
