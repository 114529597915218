/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InfoLayer } from 'containers/maps';

/**
 * Read-only visualizer for geometry data
 */
export default class InfoLayerInputWrapper extends Component {
  static propTypes = {
    value: PropTypes.shape({})
  };

  render() {
    const val = this.props.value ? [this.props.value] : null;
    return (
      <InfoLayer
        {...this.props}
        data={this.props.value}
      />
    );
  }
}
