import lastIndexOf from 'lodash/lastIndexOf';
import { init } from 'components/link/builder';

// Identifier for pages in pagination
export const PAGE_ID = 'page-';

const linksDomik = {
  project: 'domik',

  // ***
  // *** Menu top links
  // ***
  /*
  sectionTopGoods: props => ({
    href: '/goods/services/',
    title: props.title || props.name
  }),
  sectionTopCities: props => ({
    href: '/cities/',
    title: props.title || props.name
  }),
  sectionTopCalculator: props => ({
    href: '/calculator/',
    title: props.title || props.name
  }),
  sectionTopUkrWeather: props => ({
    href: '/ukr-weather/',
    title: props.title || props.name
  }),
  */

  // ***
  // *** Main sections
  // ***
  // Home
  sectionDomik: props => ({
    href: `/${props.langPrefix.replace('/', '')}`,
    title: props.title || props.name
  }),
  // Sitemap
  sectionSitemap: props => ({
    href: '/sitemap.xml',
    title: props.title || props.name
  }),
  // Nedvizhimost
  sectionProjects: props => ({
    href: '/projects/',
    title: props.title || props.name
  }),
  /*
  // Realtor
  sectionRealtor: props => ({
    href: `/${props.langPrefix}realtor`,
    title: props.title || props.name
  }),
  // Realtors
  sectionRealtors: props => ({
    href: `/${props.langPrefix}realtors`,
    title: props.title || props.name
  }),
  */
  // Stocks
  sectionStocks: props => ({
    href: `/${props.langPrefix}akcii`,
    title: props.title || props.name
  }),
  // contacts
  sectionContacts: props => ({
    href: `/${props.langPrefix}contacts`,
    title: props.title || props.name
  }),
  // o-kompanii
  sectionAbout: props => ({
    href: `/${props.langPrefix}o-kompanii`,
    title: props.title || props.name
  }),
  // construction
  pageConstruction: props => ({
    href: `/${props.langPrefix}under-construction`,
    title: props.name
  }),
  // terms of use
  sectionTermsUse: props => ({
    href: `/${props.langPrefix}copyright.html${props.url ? props.url : ''}`,
    title: props.title || props.name
  }),
  sectionTariffPlans: props => ({
    href: `/${props.langPrefix}tariff-plans`,
    title: props.title || props.name
  }),
  // sectionTermsUse: props => {
  //   const lp = props.langPrefix;
  //   const lpu = lp === 'uk/' ? 'copyright.html' : 'copyright.html';
  //
  //   return {
  //     href: `/${lp}${lpu}${props.url ? props.url : ''}`,
  //     title: props.title || props.name
  //   };
  // },
  // terms
  sectionTerms: props => ({
    href: `/${props.langPrefix}soglashenie`,
    title: props.title || props.name
  }),
  // registrationCondition
  sectionRegistrationCondition: props => ({
    href: `/${props.langPrefix}confidence`,
    title: props.title || props.name
  }),
  // konvertorValyut
  sectionConverter: props => ({
    href: `/${props.langPrefix}finansy/konvertor-valyut.html`,
    title: props.title || props.name
  }),
  // Registration
  pageRegistration: props => ({
    href: `/${props.langPrefix}profile/registration`,
    title: props.title || props.name
  }),
  // Confidentiality
  pageConfidentiality: props => ({
    href: `/${props.langPrefix}profile/confidentiality`,
    title: props.title || props.name
  }),
  // Confidentiality
  pageTerms: props => ({
    href: `/${props.langPrefix}profile/terms`,
    title: props.title || props.name
  }),
  // Notifications/Messages
  pageMessages: props => ({
    href: `/${props.langPrefix}profile/messages`,
    title: props.title || props.name
  }),
  // Profile
  pageProfile: props => ({
    href: `/${props.langPrefix}profile/profile`,
    title: props.name
  }),
  // PublicProfile
  /*
  TODO: Шеф попросил пока убрать ссылки на Профайлы - на пострелиз
  pagePublicProfile: props => ({
    href: `/${props.langPrefix}profile/profile-id${props.id}`,
    title: props.name
  }),
  */
  // Profile
  pageMyObjects: props => ({
    href: `/${props.langPrefix}profile/my-objects`,
    title: props.name
  }),
  pageAddAd: props => ({
    href: `/${props.langPrefix}profile/add-ad`,
    title: props.name
  }),
  pageObjectsApi: props => ({
    href: `/${props.langPrefix}profile/objects-api`,
    title: props.name
  }),
  // Profile
  pageUserAllObjects: props => ({
    href: `/${props.langPrefix}profile/all-objects-u${props.id}`,
    title: props.name
  }),
  pageUserAllObjectsByPhones: props => ({
    href: `/${props.langPrefix}profile/all-objects-search`,
    title: props.name,
    query: { customPhones: props.phones }
  }),
  // Add object
  pageObjectAdd: props => ({
    href: `/${props.langPrefix}profile/object-add`,
    title: props.name
  }),
  // Edit object
  pageObjectEdit: props => ({
    href: `/${props.langPrefix}profile/object-edit/${props.id}`,
    title: props.name
  }),
  // object
  pageObject: props => ({
    href: props.url,
    title: props.name
  }),
  // Billing
  pageBilling: props => ({
    href: `/${props.langPrefix}profile/billing`,
    title: props.name
  }),

  pageNewsRoot: props => {
    const lp = props.langPrefix;
    const lpu = lp === 'uk/' ? 'novini' : 'novosti';

    return {
      href: `/${lp}${lpu}`
    };
  },

  // Landing page
  sectionLanding: props => ({
    href: addPageSlash(props.url, props.page),
    query: props.query,
    title: props.title || props.name,
    hash: props.hash
  }),

  // Favorites
  pageFavorites: props => ({
    href: `/${props.langPrefix}profile/favorites/${props.project}`,
    title: props.name || props.title
  }),

  // Publication preview
  pagePublPreview: props => ({
    href: `/${props.langPrefix}accessory/publication-preview/${props.id}`,
    title: props.name || props.title
  }),
};

function addPageSlash(url = '', page) {
  if (page) {
    return `${url}${lastIndexOf(url, '/') === url.length - 1 ? '' : '/'}${PAGE_ID}${page}`;
  }
  return url;
}

export default init(linksDomik);
