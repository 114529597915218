import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { VALIDATION_VALID } from './form-consts';

import AbstractForm from './abstractForm';

export default class InputSubform extends AbstractForm {
  static propTypes = {
    ...AbstractForm.propTypes,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    ...AbstractForm.defaultProps,
  };

  static noDataBindChildren = true;

  constructor(props, ctx) {
    super(props, ctx);
    this.lastValidationState = null;
    this.data = props.value || {};
    this.changing = true;
  }

  componentDidMount() {
    this._setSchema(this.props.schema || {});
    return super.componentDidMount();
  }

  componentDidUpdate() {
    this.changing = false;

    // Schema from props
    if (!isEqual(this.state.schema, this.props.schema)) {
      this._setSchema(this.props.schema || {});
    }

    // Data from props
    if (!isEqual(this.props.value, this.data)) {
      this.data = this._setDefaultValuesFromSchema(
        this.props.schema,
        this.props.value || {}
      );
      this._fire('onChange', [this.data]);
    }

    // Validation state from props - when submitting
    const vState =
      this.props.validationState &&
      this.props.validationState.messages &&
      this.props.validationState.messages[0];
    if (vState && !isEqual(vState, this.lastValidationState)) {
      this.lastValidationState = vState;
      const { subformState = {} } = this.props.validationState.messages[0];
      this._setValidationStatesFromValidator(
        subformState,
        true
      );
    }
  }

  _getData() {
    return this.data;
  }

  _setSchema(schema, other) {
    super._setSchema(schema, other, () =>
      this._fire(
        'onChange',
        [this._setDefaultValuesFromSchema(
          schema,
          this.data || {}
        )]
      )
    );
  }

  /**
   * Change field value in data state
   * @param field
   * @param value
   * @param markAsDirty
   * @param scope
   * @private
   */
  _changeField(field, value, markAsDirty, scope) {
    this.changing = true;
    this.data[field] = value;
    this.validationStates[field] = { state: VALIDATION_VALID, message: null };
    this._fire('onChange', [this.data]);
    super._changeField(field, value, markAsDirty, scope);
  }

  render() {
    if (__AMP__) return null;

    const {
      schema = {},
    } = this.state;

    return (
      this.renderChildren(
        this.props.children,
        this.data,
        schema,
        this.validationStates
      )
    );
  }
}
