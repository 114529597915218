/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import each from 'lodash/each';

export function init(config) {
  each(config, (val, key) => {
    if (typeof config[key] === 'function') {
      config[key].project = config.project;
    }
  });
  return config;
}
