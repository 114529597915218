export default {
  AUTH: '$auth',

  // Location
  LOCATION_MANAGE_ALL: 'location/manage_all',
  LOCATION_MANAGE_OWN: 'location/manage_own',
  LOCATION_PHOTOGRAPHER: 'location/photographer',
  LOCATION_DELETE_LOCATION: 'location/delete_location',

  // Storage
  STORAGE_MANAGE_ALL_IMAGES: 'storage/manage_all_images',

  // Users
  USERS_MANAGE_SYSTEM_ROLES: 'users/manage_all_roles',
  USERS_MANAGE_COMPANY_ROLES: 'users/manage_company_roles',
  USERS_MANAGE_ROLE_AUTOASSIGN: 'users/manage_role_autoassign',
  USERS_GRANT_SYSTEM_ROLE: 'users/grant_system_role',
  USERS_GRANT_COMPANY_ROLE: 'users/grant_company_role',
  USERS_TECH_SECTION: 'users/tech_section',
  USERS_MANAGE_ALL: 'users/manage_all',
  USERS_MANAGE_COMPANY: 'users/manage_company',
  USERS_MANAGE_DEPARTMENT: 'users/manage_department',
  USERS_MANAGE_BRANCH: 'users/manage_branch',

  // Objects
  OBJECT_ADD_ANY_OBJECT: 'object/add_any_object',
  OBJECT_EDIT_ANY_OBJECT: 'object/edit_any_object',
  OBJECT_EDIT_OWN_OBJECT: 'object/edit_own_object',
  OBJECT_EDIT_UNIT_OBJECT: 'object/edit_unit_object',
  OBJECT_DELETE_ANY_OBJECT: 'object/delete_any_object',
  OBJECT_DELETE_OWN_OBJECT: 'object/delete_own_object',
  OBJECT_DELETE_UNIT_OBJECT: 'object/delete_unit_object',
  OBJECT_MANAGE_ALL_NEWBUILD_OBJECTS: 'object/manage_all_newbuild_objects',
  OBJECT_MANAGE_OWN_NEWBUILD_OBJECTS: 'object/manage_own_newbuild_objects',
  OBJECT_API: 'object/api',

  // Comments
  COMMENTS_POST: 'comments/post',
  COMMENTS_EDIT_OWN: 'comments/edit_own',
  COMMENTS_EDIT_ANY: 'comments/edit_any',
  COMMENTS_DELETE_OWN: 'comments/delete_own',
  COMMENTS_DELETE_ANY: 'comments/delete_any',

  // Moderation
  MODERATION_COMMENTS_LC: 'comments/moderator_comments_lc',
  // Discounts
  MANAGEMENT_DISCOUNTS: 'billing/managementDiscounts',

  // Management/Administrative
  MANAGEMENT_DATA_VIEW: 'management/data_view',
  MANAGEMENT_HELPERS: 'management/helpers',
  MANAGEMENT_DICTS: 'management/dicts',
  MANAGEMENT_CHARGEABLE_SERVICES: 'management/chargeable',
  MANAGEMENT_SEO: 'management/seo',
  MANAGEMENT_CONTENT_BLOCK: 'management/contentBlock',
  RELIABILITY_NEWBUILD: 'management/reliability_newbuild',
  MANAGEMENT_REGION_PROMOTIONAL_LINK: 'region/promotional_links',
  MANAGEMENT_AD_UNITS: 'management/ad_units',
  RELIABILITY_LONG_TERM_CONSTRUCTION: 'management/reliability_long_term_construction',

  RELIABILITY_DEVELOPER: 'management/reliability_developer',
  RELIABILITY_SEND_ORDER: 'management/reliability_send_order',
  RELIABILITY_SET_IMAGE_PREVIEW: 'management/reliability_set_image_preview',
  RELIABILITY_GET_PDF: 'management/reliability_get_pdf',

  // Permission for advert on the forum project.
  FORUM_ADD_AD: 'forum/add_ad',
  FORUM_EDIT_ALL_AD: 'forum/edit_all_ad',
  FORUM_DELETE_ALL_AD: 'forum/delete_all_ad',

  USERADVERT_ADD: 'userAdvert/add',
  USERADVERT_MANAGE: 'userAdvert/manage',
  USERADVERT_SITES: 'userAdvert/sites',

  STATISTIC_NEWBILD: 'statistic/newbild',

  CONTROL_ALL_TARIFF: 'tariff/control_all_tariff',
  CONTROL_ORDER_TARIFF: 'tariff/control_order_tariff',

  // Permission for advert on the domik project.
  DOMIK_ADD_AD: 'domik/add_ad',
  DOMIK_EDIT_ALL_AD: 'domik/edit_all_ad',
  DOMIK_DELETE_ALL_AD: 'domik/delete_all_ad',

  // Permission for notes on the forum project.
  FORUM_CONTROL_NOTES: 'forum/control_notes',

  // Permission for notes on the domik project.
  DOMIK_CONTROL_NOTES: 'domik/control_notes',

  DOMIK_NOTIFICATIONS_ADMIN: 'domik/notifications_admin',

  // Permission for control publications on the domik project.
  DOMIK_CONTROL_RUBRICS: 'domik/control_rubrics',
  DOMIK_CONTROL_PUBLICATIONS: 'domik/control_publications',
  DOMIK_VIEW_STAT_PUBLICATIONS: 'domik/stat_publications',
  DOMIK_ADD_PUBLICATIONS: 'domik/add_publications',
  DOMIK_EDIT_OWN_PUBLICATIONS: 'domik/edit_own_publications',
  DOMIK_DELETE_OWN_PUBLICATIONS: 'domik/delete_own_publications',

  // Permission for club on the forum project.
  FORUM_JOIN_TO_CLUB: 'forum/join_to_club',
  FORUM_ADD_CLUB: 'forum/add_club',
  FORUM_EDIT_ALL_CLUB: 'forum/edit_all_club',
  FORUM_EDIT_OWN_CLUB: 'forum/edit_own_club',
  FORUM_DELETE_ALL_CLUB: 'forum/delete_all_club',
  FORUM_DELETE_OWN_CLUB: 'forum/delete_own_club',

  // Permission for forum on the forum project.
  FORUM_ADD_FORUM: 'forum/add_forum',
  FORUM_EDIT_ALL_FORUM: 'forum/edit_all_forum',
  FORUM_EDIT_OWN_FORUM: 'forum/edit_own_forum',
  FORUM_DELETE_ALL_FORUM: 'forum/delete_all_forum',
  FORUM_DELETE_OWN_FORUM: 'forum/delete_own_forum',

  // Permission for medals on the forum project.
  FORUM_ADD_MEDAL: 'forum/add_medal',
  FORUM_EDIT_ALL_MEDAL: 'forum/edit_all_medal',
  FORUM_EDIT_OWN_MEDAL: 'forum/edit_own_medal',
  FORUM_DELETE_ALL_MEDAL: 'forum/delete_all_medal',
  FORUM_DELETE_OWN_MEDAL: 'forum/delete_own_medal',

  // Permission for topics on the forum project.
  FORUM_ADD_TOPIC: 'forum/add_topic',
  FORUM_EDIT_ALL_TOPIC: 'forum/edit_all_topic',
  FORUM_EDIT_OWN_TOPIC: 'forum/edit_own_topic',
  FORUM_DELETE_ALL_TOPIC: 'forum/delete_all_topic',
  FORUM_DELETE_OWN_TOPIC: 'forum/delete_own_topic',
  FORUM_CONTROL_ALL_TOPIC: 'forum/control_all_topic',

  // Permission for posts on the forum project.
  FORUM_ADD_POST: 'forum/add_post',
  FORUM_EDIT_ALL_POST: 'forum/edit_all_post',
  FORUM_EDIT_OWN_POST: 'forum/edit_own_post',
  FORUM_DELETE_ALL_POST: 'forum/delete_all_post',
  FORUM_DELETE_OWN_POST: 'forum/delete_own_post',
  FORUM_PREMODERATION_POST: 'forum/premoderation_post',
  FORUM_CONTROL_PREMODERATION_POST: 'forum/control_premoderation_post',
  FORUM_CONTROL_PREMODERATION_AVATAR: 'forum/control_premoderation_avatar',
  FORUM_CHANGE_AUTHOR_POST: 'forum/change_author_post',

  // Permission for questions on the forum project.
  FORUM_ADD_QUESTION: 'forum/add_question',
  FORUM_EDIT_ALL_QUESTION: 'forum/edit_all_question',
  FORUM_EDIT_OWN_QUESTION: 'forum/edit_own_question',
  FORUM_DELETE_ALL_QUESTION: 'forum/delete_all_question',
  FORUM_DELETE_OWN_QUESTION: 'forum/delete_own_question',

  // Permission for likes/dislikes on the forum project.
  FORUM_ADD_LIKE: 'forum/add_like',
  FORUM_DELETE_ALL_LIKE: 'forum/delete_all_like',

  // Permission for roles(only for forum admin/moderator) on the forum project.
  FORUM_READ_FORUM_ROLE: 'forum/read_forum_role',
  FORUM_ADD_FORUM_ROLE: 'forum/add_role',
  FORUM_EDIT_FORUM_ROLE: 'forum/edit_all_role',
  FORUM_DELETE_FORUM_ROLE: 'forum/delete_all_role',

  // Permission for complaint on the forum project.
  FORUM_ADD_COMPLAINT: 'forum/add_complaint',
  FORUM_CONTROL_COMPLAINT: 'forum/control_complaint',

  // Permission for auto filter on the forum project.
  FORUM_CONTROL_AUTOFILTER: 'forum/control_autofilter',
  FORUM_CONTROL_BAD_WORDS: 'forum/control_bad_words',

  // Permission for statistics on the forum project.
  FORUM_READ_STATISTICS: 'forum/read_statistics',

  // Permission for statistics on the forum project.
  FORUM_CONTROL_CONTEXT: 'forum/control_context',

  FORUM_NOTIFICATIONS_ADMIN: 'forum/notifications_admin',

  // Permission for CRM
  CRM_CONTROL_ALL_CRM: 'crm/control_all_crm',
  CRM_CONTROL_ROLES: 'crm/control_roles',
  CRM_CONTROL_STRUCTURE_COMPANY: 'crm/structure_company',
  CRM_CONTROL_PRODUCTS_COMPANY: 'crm/products_company',
  CRM_CONTROL_SALES_COMPANY: 'crm/sales_company',
  CRM_CONTROL_CUSTOMERS_COMPANY: 'crm/customers_company',
  CRM_CONTROL_LC_AND_COTTAGE_TOWNS: 'crm/control_lc_and_cottage_towns',
  CRM_OBJECTS_SEARCH: 'crm/objects_search',

  STATISTIC_NEWBUILD_OWN: 'statistic/newbild_own'
};
