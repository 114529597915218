/**
 * Created by Kotkin on 23.06.2017.
 */
import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import { resetScroll } from 'helpers/noScroll';
import { emit } from 'helpers/global-events';
import Link from 'components/link';
import Row, { Col } from 'components/row';
import Form, { Input, Checkbox } from 'containers/form';
import Button, { btnThemes } from 'components/button';

import linksDomik from 'src-sites/domik/links';

import SocialAuth from './social-auth';
import * as actions from './redux';

import translates from './auth-i18n.json';

import 'styles/base/auth/_login-form.scss';

const bemClasses = new Bem('formLogin');

@connect(state => ({
  auth: state.auth,
}), { ...actions }, null, { forwardRef: true })
@I18nHoc(translates)
export default class LoginForm extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    email: PropTypes.string,
    page: PropTypes.string,
    actionAuthGetData: PropTypes.func,
    auth: PropTypes.shape({
      authenticated: PropTypes.bool
    }),
    onAuthenticated: PropTypes.func,
    closeModal: PropTypes.func,
    changeHeader: PropTypes.func,
    onlySocialAuth: PropTypes.bool
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      page: props.page || 'login',
      email: props.email || null
    };
    this.passRepair = this.passRepair.bind(this);
    this.authForm = this.authForm.bind(this);
    this.closeModalGoNext = this.closeModalGoNext.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.auth.authenticated !== nextProps.auth.authenticated &&
      nextProps.auth.authenticated && this.props.onAuthenticated
    ) {
      this.props.onAuthenticated(true);
    }
  }

  submitForm() {
    this.form.getWrappedInstance().submit();
  }

  closeModalGoNext() {
    if (this.props.closeModal) {
      resetScroll();
      return this.props.closeModal();
    }
  }

  passRepair() {
    this.setState({ page: 'passrepair' });
    if (this.props.changeHeader) {
      this.props.changeHeader(this.i18n('passRecovery'));
    }
  }

  authForm() {
    this.setState({ page: 'login' });
    if (this.props.changeHeader) {
      this.props.changeHeader(null);
    }
  }

  renderLoginForm() {
    const { i18n } = this;
    const { className, actionAuthGetData, onlySocialAuth } = this.props;
    const mods = { onlySocial: onlySocialAuth };

    return (
      <Form
        {...bemClasses({ modifiers: mods, extra: className })}
        onSubmit={actionAuthGetData}
        onChange={data => this.setState({ email: data.email })}
        controller="user/auth"
        ref={el => this.form = el}
        defaultErrorHeader={i18n('authenticationError')}
        defaultSuccessHeader={i18n('loggedIn')}
        destroyWhenUnmount
        hFull
      >
        <Row mpColumn {...bemClasses('formContent')}>
          {!onlySocialAuth &&
            <>
              <Col {...bemClasses('formInputsContainer')} mdAdjust={400} mtAdjust={310} allPr={0}>
                <div {...bemClasses('welcome')}>
                  <span {...bemClasses('text')} children={i18n('enterDetails')} />
                </div>
                <Input {...bemClasses('input')} dataBind="email" />
                <Input {...bemClasses('input')} dataBind="password" password />
                <Checkbox
                  {...bemClasses('checkbox')}
                  dataBind="remember"
                  withIcon
                  noError
                  children={i18n('rememberMe')}
                />
                <RepairPassword
                  {...bemClasses('repairPassword')}
                  dataBind="email"
                  passRepair={this.passRepair}
                />
                <Row {...bemClasses('formBottom')}>
                  <Col {...bemClasses('col', 'submit')} allPr={0}>
                    <Button
                      {...bemClasses('btn', 'submit')}
                      type="submit"
                      name="post"
                      label={i18n('comeIn')}
                      title={i18n('comeIn')}
                      theme={btnThemes.olive}
                    />
                  </Col>
                  <Col {...bemClasses('col', 'registration')} allPr={0}>
                    <span {...bemClasses('text')} children={i18n('doNotAccount')} />
                    <Link
                      {...bemClasses('linkColor')}
                      builder={linksDomik.pageRegistration}
                      project="domik"
                      onClick={this.closeModalGoNext}
                    >
                      <span {...bemClasses('linkContent')}>
                        <span {...bemClasses('text')} children={i18n('register')} />
                      </span>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col mdCw={160} mtCw={160} allPr={0} {...bemClasses('flexOr')}>
                <span {...bemClasses('flexOrSeparate')} />
                <span {...bemClasses('text')} children={i18n('or')} />
              </Col>
            </>
          }
          <Col mdCw={240} mtCw={160} allPr={0} {...bemClasses('listSocial')}>
            <div {...bemClasses('welcome')}>
              <span {...bemClasses('text')} children={i18n('topicSocials')} />
            </div>
            <SocialAuth onlySocialAuth={onlySocialAuth} />
          </Col>
        </Row>
      </Form>
    );
  }

  renderPassRepair() {
    const { i18n } = this;

    return (
      <div>
        <Form
          {...bemClasses({ extra: this.props.className })}
          controller="user/pass-repair-request"
          defaultErrorHeader={i18n('passReminderError')}
          defaultSuccessHeader={i18n('passRequestSend')}
          defaultValues={{ email: this.state.email }}
          onSubmit={this.authForm}
          hFull
        >
          <Row mpColumn {...bemClasses('formContent')}>
            <Col {...bemClasses('formInputsContainer')} allPr={0}>
              <div {...bemClasses('welcome')}>
                <span {...bemClasses('text')} children={i18n('passRecovery')} />
              </div>
              <Input {...bemClasses('input')} dataBind="email" dataTest="emailField" />
              <Row {...bemClasses('formBottom')}>
                <Col {...bemClasses('col', 'submit')} allPr={0}>
                  <Button
                    {...bemClasses('btn', 'submit')}
                    type="submit"
                    name="post"
                    label={i18n('passRecoveryText')}
                    title={i18n('passRecoveryText')}
                    theme={btnThemes.olive}
                  />
                </Col>
                {/*
                <Col {...bemClasses('col', 'cancel')} allPr={0}>
                  <Button
                    data-test="cancelButton"
                    onClick={this.authForm}
                    title={i18n('cancel')}
                    label={i18n('cancel')}
                    theme={btnThemes.light}
                    big
                  />
                </Col>
                */}
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  render() {
    const { page } = this.state;

    if (page === 'login') {
      return <div children={this.renderLoginForm()} />;
    }
    if (page === 'passrepair') {
      return this.renderPassRepair();
    }
  }
}

@I18nHoc(translates)
class RepairPassword extends Component {
  static propTypes = {
    className: PropTypes.string,
    // eslint-disable-next-line
    email: PropTypes.string,
    // eslint-disable-next-line
    validationState: PropTypes.any,
    // eslint-disable-next-line
    value: PropTypes.any,
    passRepair: PropTypes.func
  };

  callPasswordRepair =()=> {
    this.props.passRepair();
    emit('callPasswordRepair', { email: this.props.value })
  }

  render() {
    const { i18n } = this;

    return (
      <div {...bemClasses('callPasswordRepair', null, this.props.className)} data-test="recoveryPassword">
        <Button
          {...bemClasses('btn', 'callPasswordRepair')}
          onClick={this.callPasswordRepair}
          label={i18n('passRepair')}
          title={i18n('passRepair')}
          theme={btnThemes.sky}
        />
      </div>
    )
  }
}
