/**
 * Created by Kotkin on 08.08.2018.
 */

/**
 * Очищает урл от 'http', 'https' и сносит все после первого слеша.
 * Используется для более короткого линка видного для пользователя.
 *
 * Например:
 * было - https://www.sitepoint.com/react-router-v4-complete-guide/
 * стало - www.sitepoint.com
 *
 * @param {string} url
 * @param {boolean} onlyDomain
 * @returns {string}
 */
export default (url, onlyDomain = true) => {
  const cleanUrlStr = url.replace(/(https?):\/\//, '');
  const index = cleanUrlStr.indexOf('/');

  if (onlyDomain && index !== -1) return cleanUrlStr.substring(0, cleanUrlStr.indexOf('/'));

  return cleanUrlStr;
};
