/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Bem from 'bemHelper';
import sensor from 'components/sensor';
import SlickSlider from 'components/slick-slider';
import Image, { Thumb } from 'components/image';
import Modal, { Header, Content } from 'components/modal';
import TabJSControl from 'components/tab-js-control';

const bemClasses = new Bem('sliderWithModalTabs');
const bemClassesSliderTile = new Bem('sliderTile');

@sensor
export default class SliderWithModalTabs extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    images: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.shape({})
    ]).isRequired,
    tabConfig: PropTypes.arrayOf(
      PropTypes.shape({
        noMainCard: PropTypes.bool
      })
    ).isRequired,
    children: PropTypes.node,
    modalHeader: PropTypes.string
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.openModal = this.openModal.bind(this);

    this.state = {
      modalIsOpened: false,
      tabSelected: 0
    };
  }

  openModal() {
    this.setState({ modalIsOpened: !this.state.modalIsOpened });
  }

  render() {
    const { images, tabConfig, children, modalHeader } = this.props;
    const breakpointDesktop = (this.sensor.getVariable('breakpointDesktop') || 1250);
    const breakpointTablet = (this.sensor.getVariable('breakpointTablet') || 768) - 1;

    return (
      <>
        <div>
          <div {...bemClasses('imgContainer', 'shadow')}>
            <div {...bemClasses('img')}>
              <SlickSlider {...bemClasses('imgPic')}>
                {map(images, (img, index) => (
                  <Image
                    key={index}
                    image={{
                      ...img,
                      renderer: img.renderer || 'orig'
                    }}
                    onClick={() => this.setState({ modalIsOpened: true, tabSelected: 0 })}
                  />
                ))}
              </SlickSlider>
            </div>
          </div>
        </div>
        {children && children}
        <div>
          <SlickSlider
            {...bemClassesSliderTile('slider')}
            slidesToShow={4}
            responsive={[
              {
                breakpoint: breakpointDesktop,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: breakpointTablet,
                settings: {
                  slidesToShow: 2
                }
              }
            ]}
          >
            {map(tabConfig, (tab, key) => {
              if (tab.noMainCard) return null;

              return (
                <SlideCard
                  key={key}
                  data={tab}
                  onClick={() => this.setState({ modalIsOpened: true, tabSelected: key })}
                />
              );
            })}
          </SlickSlider>
        </div>
        {this.state.modalIsOpened &&
          <Modal
            onCloseRequired={this.openModal}
            size="adminka"
            renderModal
          >
            <Header closeButton noLine>{modalHeader}</Header>
            <Content noPaddingT addPaddingB hFull>
              <TabJSControl
                onChange={(tab, index) => this.setState({ tabSelected: index })}
                selectedTab={this.state.tabSelected}
                scrollToActiveTab
                tabContentScroll
              >
                {map(tabConfig, (tab, key) => (
                  <tab title={tab.title} key={key}>{tab.render()}</tab>
                ))}
              </TabJSControl>
            </Content>
          </Modal>
        }
      </>
    );
  }
}

class SlideCard extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string
    }).isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { className, data = {}, onClick } = this.props;

    return (
      <div // eslint-disable-line
        {...bemClassesSliderTile({ extra: className })}
        onClick={onClick}
      >
        <div {...bemClassesSliderTile('imgContainer')}>
          <div {...bemClassesSliderTile('img')}>
            <Thumb
              {...bemClassesSliderTile('imgPic')}
              image={data.image || {}}
            />
            <div {...bemClassesSliderTile('onPhoto')}>
              <div {...bemClassesSliderTile('onPhotoOverlay', 'bottom')}>
                <div {...bemClassesSliderTile('title')}>
                  <span {...bemClassesSliderTile('text')}>{data.title}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div {...bemClassesSliderTile('dataContainer')}>
          <div {...bemClassesSliderTile('data')}>
            <div {...bemClassesSliderTile('dataMain')}>
              <span {...bemClassesSliderTile('text')}>{data.description || 'Нет данных'}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
