/**
 * Created by Kotkin on 24.08.2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import toString from 'lodash/toString';
import trim from 'lodash/trim';
import limitMax from 'helpers/limitMaxCount';
import Modal from 'components/modal';
import Button from 'components/button';
import { iconTypes, socialsIconConfig } from 'components/icon';

import AbstractInput from './abstractInput';

import 'styles/base/containers/form/_input-hide.scss';

const bemClasses = new Bem('inputHide');

/**
 * === InputHide Component ===
 * Рендерит данные в модалке
 *
 * = Example =
 *
 * <InputHide
 *   dataBind="subways"
 *   size="content"
 *   horizontal
 * >
 *   <Header>
 *     {header content}
 *   </Header>
 *   <Content>
 *     {modal content}
 *   </Content>
 *   <Footer>
 *     {footer content}
 *   </Footer>
 * </InputHide>
 */

export default class InputHide extends AbstractInput {
  static propTypes = {
    ...AbstractInput.props,
    children: PropTypes.node.isRequired,

    // Required modal size
    size: PropTypes.oneOf(['small', 'medium', 'big', 'content']).isRequired,

    // TODO: check if still needed
    // showSelected: PropTypes.bool,

    // Render social buttons with modal
    socials: PropTypes.bool,

    // Render to modal height: 100%
    hFull: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
    this.toggleContent = this.toggleContent.bind(this);
    this.state = {
      renderModal: false
    };
  }

  toggleContent() {
    this.setState(state => ({ renderModal: !state.renderModal }));
  }

  render() {
    const {
      className, children, size, hFull, schema = {}, value, horizontal, dataBind, socials
    } = this.props;
    const { renderModal } = this.state;
    const socialKey = socials && dataBind.split('.').pop();

    let length = 0;
    if (isObject(value) && isArray(value.features)) length = value.features.length;
    if (isArray(value)) length = value.length;
    if (socials) length = trim(toString(value)).length;

    return (
      <div {...bemClasses(null, { horizontal, socials }, className)}>
        <Button
          {...bemClasses('btn', { [socialKey]: socialKey, active: length > 0 })}
          onClick={this.toggleContent}
          label={!socials && (schema.label || 'default')}
          iconType={socials && (socials ? socialsIconConfig[socialKey] : iconTypes.telegram)}
          labelSecond={!socials && length > 0 && limitMax(length)}
        />
        {renderModal &&
          <Modal
            {...bemClasses('modal')}
            renderModal={renderModal}
            onCloseRequired={this.toggleContent}
            size={size}
            hFull={hFull}
            disableNotification
            closeEvent={dataBind}
            children={children}
          />
        }
      </div>
    );
  }
}
