import { Leaflet } from 'containers/maps';

import markerIcon from './map/markerYellow.png';
import markerIconRed from './map/markerRed.png';
import markerIconGreen from './map/markerGreen.png';
import markerIconYellow from './map/markerYellow.png';
import markerIconGray from './map/markerGray.png';
import markerIconBlue from './map/markerBlue.png';
import markerIconRedBig from './map/markerRedBig.png';
import markerIconGreenBig from './map/markerGreenBig.png';
import markerIconYellowBig from './map/markerYellowBig.png';
import markerIconGrayBig from './map/markerGrayBig.png';
import markerIconBlueBig from './map/markerBlueBig.png';

const markerIcons = {
  standart: markerIcon,
  yellow: markerIconYellow,
  yellowBig: markerIconYellowBig,
  green: markerIconGreen,
  greenBig: markerIconGreenBig,
  red: markerIconRed,
  redBig: markerIconRedBig,
  gray: markerIconGray,
  grayBig: markerIconGrayBig,
  blue: markerIconBlue,
  blueBig: markerIconBlueBig,
};

const markerSizes = {
  standart: [40, 44],
  yellowBig: [60, 66],
  redBig: [60, 66],
  greenBig: [60, 66],
  grayBig: [60, 66],
  blueBig: [60, 66]
};

export default function marker(icon = 'standart') {
  if (__SERVER__) return null; // eslint-disable-line
  const size = markerSizes[icon] || markerSizes.standart;
  return Leaflet.icon({
    iconUrl: markerIcons[icon],
    iconSize: size,
    shadowSize: [0, 0],
    iconAnchor: [size[0] / 2, size[1] - 7],
    shadowAnchor: [0, 0]
  });
}
