import React from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';
import get from 'lodash/get';
import Bem from 'bemHelper';

import AbstractInput from './abstractInput';
import InputLabel from './input-label';
import ValidationState from './validationState';

const bemClasses = new Bem('input');
const DESTROY = 'autosize:destroy';
const UPDATE = 'autosize:update';
const RESIZED = 'autosize:resized';

export default class Text extends AbstractInput {
  static propTypes = {
    ...AbstractInput.propTypes,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disableValidationState: PropTypes.bool,
    autoExpand: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onResize: PropTypes.func,
    focusCallback: PropTypes.func,
    blurCallback: PropTypes.func,
    showSmiles: PropTypes.bool,
    noError: PropTypes.bool,
    textarea: PropTypes.bool,
    timeValidation: PropTypes.number,
    rows: PropTypes.number,
    hasLabel: PropTypes.bool,
    tabIndex: PropTypes.number
  };

  static defaultProps = {
    timeValidation: 0,
    hasLabel: true,
    rows: 1,
    autoExpand: true
  };

  constructor(props, context) {
    super(props, context);
    this.focus = this.focus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.clearVTimeouts = this.clearVTimeouts.bind(this);
    this.dispatchEvent = this.dispatchEvent.bind(this);
    // this.checkValueIsEmpty = this.checkValueIsEmpty.bind(this);
    // this.setEmojiIntoText = this.setEmojiIntoText.bind(this);

    this.value = null;
    this.delay = null;
    this.blurDelay = null;
  }

  componentDidMount() {
    const { autoExpand, onResize, autoFocus, focusCallback } = this.props;
    if (autoExpand) autosize(this.textareaRef);
    if (onResize) this.textareaRef.addEventListener(RESIZED, onResize);
    if (autoFocus && focusCallback) focusCallback();
  }

  componentDidUpdate() {
    // TODO: update autoresize
    if (this.props.autoExpand && this.value !== this.textareaRef.value) this.dispatchEvent(UPDATE);
  }

  componentWillUnmount() {
    const { autoExpand, onResize } = this.props;
    if (autoExpand) {
      this.textareaRef.removeEventListener(RESIZED, onResize);
      this.dispatchEvent(DESTROY);
    }
    this.value = null;
  }

  dispatchEvent(EVENT_TYPE) {
    const event = document.createEvent('Event');
    event.initEvent(EVENT_TYPE, true, false);

    this.textareaRef.dispatchEvent(event);
  }

  focus() {
    this.textareaRef.focus();
  }

  onChange(event) {
    if (event === null) {
      if (!this.props.onChange) return false;
      return this.props.onChange(event);
    }

    event.persist();
    this.value = event.target.value;
    this.props.onChange(event.target.value, event);

    this.clearVTimeouts();
    this.delay = setTimeout(() =>
      this.performValidation(event.target.value),
    this.props.timeValidation);
  }

  handleFocus(event) {
    if (this.isMouseRightClick(event)) {
      event.preventDefault();
      return false;
    }

    const { focusCallback } = this.props;
    if (focusCallback) focusCallback(event, this);
  }

  handleBlur(event) {
    event.persist();
    this.clearVTimeouts();

    const { blurCallback } = this.props;
    if (blurCallback) blurCallback(event, this);
    this.blurDelay = setTimeout(() =>
      this.performValidation(event.target.value),
    30);
  }

  isMouseRightClick(e) { // eslint-disable-line
    let rightClick = false;
    if (!e) return false;
    if (e.which) {
      rightClick = e.which === 3;
    } else if (e.button) {
      rightClick = e.button === 2;
    }

    return rightClick;
  }

  clearVTimeouts() {
    clearTimeout(this.blurDelay);
    clearTimeout(this.delay);
  }

  insertAtCursor(text) {
    const start = this.textareaRef.selectionStart;
    const end = this.textareaRef.selectionEnd;
    const { value } = this.props;
    if (!value) {
      this.props.onChange(text);
    }
    const newValue = value.substring(0, start)
      + text
      + value.substring(end, value.length);
    this.props.onChange(newValue);
  }


  // DEPRECATED
  // checkValueIsEmpty(event) {
  //   const inputValue = event ? event.target.value : this.props.value;
  //   return !inputValue || !inputValue.trim();
  // }
  //
  // setEmojiIntoText({ colons }) {
  //   const value = !this.checkValueIsEmpty() ? `${this.props.value} ${colons}` : colons;
  //   this.props.onChange(value);
  //
  //   this.clearVTimeouts();
  //   this.delay = setTimeout(() =>
  //     this.performValidation(value),
  //   this.props.timeValidation);
  // }

  render() {
    const {
      schema = {}, value, validationState, hasLabel, autoFocus,
      noError, textarea, rows, className
    } = this.props;
    const required = schema.required;
    const empty = this.props.disableValidationState;
    const error = get(validationState, 'state') === 'invalid';
    const mods = { textarea: true, required, empty, error };

    return (
      <div {...bemClasses(null, mods, className)}>
        <div {...bemClasses('inputWrapper')} data-test="inputWrapper">
          <div {...bemClasses('inputContainer')} data-test="inputTextContainer">
            {hasLabel &&
              <InputLabel
                {...bemClasses('inputLabel')}
                schema={schema}
                value={value}
                validationState={validationState}
                inside
              />
            }
            <textarea
              {...bemClasses('input', {
                filled: this.props.value !== null && this.props.value !== ''
              })}
              ref={ref => this.textareaRef = ref}
              value={value || ''}
              onMouseDown={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.onChange}
              placeholder={schema.placeholder || null}
              autoFocus={autoFocus}
              rows={rows}
              tabIndex={this.props.tabIndex || '-1'}
            />
            <span {...bemClasses('bottomLine')} />
          </div>
          {!noError &&
            <div {...bemClasses('errorWrapper')} data-test="errorWrapper">
              {!this.props.disableValidationState &&
                <ValidationState
                  validationState={validationState}
                />
              }
            </div>
          }
        </div>
      </div>
    );
  }
}
