/**
 * Created by sem on 20.10.16.
 */
/* eslint-disable react/no-unused-prop-types */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* @abstract */
export default class AbstractInput extends PureComponent {
  static propTypes = {
    dataBind: PropTypes.string,
    className: PropTypes.string,
    password: PropTypes.bool,
    value: PropTypes.any, // eslint-disable-line
    onChange: PropTypes.func,
    onMultiChange: PropTypes.func,
    onInternalChange: PropTypes.func,
    onValidation: PropTypes.func,
    /**
     * Calls, when possible partially form data
     * update needed - when list item selected, checkbox changed,
     * or other cases
     */
    onDataUpdateNeeded: PropTypes.func,
    /**
     * This function calls in form controller,
     * when onDataUpdateNeeded fires.
     * This function will return a propmise,
     * which returns a new data.
     * You can define this function as prop in input,
     * and it will be call with form controller, and
     * update the data
     */
    dataUpdater: PropTypes.func,
    validationState: PropTypes.shape({
      state: PropTypes.string,
      messages: PropTypes.array
    }),
    _form: PropTypes.object, // eslint-disable-line
    _inheritOnChange: PropTypes.func,
    schema: PropTypes.shape({
      class: PropTypes.string,
      isArray: PropTypes.bool,
      defaultValue: PropTypes.any, // eslint-disable-line
      label: PropTypes.string,
      max: PropTypes.number,
      min: PropTypes.number,
      name: PropTypes.string,
      readOnly: PropTypes.bool,
      required: PropTypes.bool
    })
  };

  constructor(props, ctx) {
    super(props, ctx);
    // Needed for dynamic validation behavior correction.
    // Can be overrided, if behavior changes needed
    this.changed = true;
  }

  performValidation(value) {
    if (typeof this.props.onValidation === 'function' && this.changed) {
      this.props.onValidation(value);
    }
  }

  performDataUpdate(value, item) {
    if (typeof this.props.onDataUpdateNeeded === 'function') {
      this.props.onDataUpdateNeeded(value, item);
    }
  }
}
