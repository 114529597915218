import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import map from 'lodash/map';
import Link from 'components/link';

import 'styles/base/containers/content-block/blocks/_landing-link.scss';

const bemClasses = new Bem('landingLink');

export default class LandingLink extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.shape({})
    ]),
  };

  renderLink(val, key) {
    return (
      <div {...bemClasses('item')} key={key}>
        <Link
          {...bemClasses('link')}
          title={val.data.header}
          href={val.url}
        >
          <span {...bemClasses('linkContent')}>
            <span {...bemClasses('text')} children={val.data.anchor} />
          </span>
        </Link>
      </div>
    );
  }

  render() {
    const { className, content } = this.props;
    if (!content || (content.length === 0)) return null;

    return <div {...bemClasses({ extra: className })} children={map(content, this.renderLink)} />;
  }
}
