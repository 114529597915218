/* eslint-disable */
/* eslint-disable class-methods-use-this */
import Leaflet from 'leaflet';
import { MarkerClusterGroup, MarkerCluster } from 'leaflet.markercluster';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { ExtendableFeatureGroup } from 'react-leaflet-extendable';
import { withLeaflet } from 'react-leaflet';

import standartMarkerIcon from './standartMarkerIcon';

// Leaflet.MarkerClusterGroup = MarkerClusterGroup;
// Leaflet.MarkerCluster = MarkerCluster;
// Leaflet.markerClusterGroup = opts => new MarkerClusterGroup(opts);
// Leaflet.markerCluster = opts => new MarkerCluster(opts);

/**
 * Layer, that can show and interact
 * with GeoJSON list of geography features
 */
class InfoLayer extends ExtendableFeatureGroup {
  static propTypes = {
    // Cluster markers if needed
    cluster: PropTypes.bool,
    // Layer order (upper - higher)
    order: PropTypes.number,
    // Min zoom level, when we show a labels
    labelsMinZoom: PropTypes.number,
    // Max zoom level, which approved for fitBounds
    fitMaxZoom: PropTypes.number,
    // Minimal zoom, when layer is visible
    minZoom: PropTypes.number,
    // Maximal zoom level, when layer is visible
    maxZoom: PropTypes.number,
    // eslint-disable-next-line
    data: PropTypes.array,
    // Marker properties for marker rendering
    markerProps: PropTypes.shape({}),
    // Function-checker, that says is update needed
    isUpdateNeeded: PropTypes.func,
    // If defined - add a labels with content of this field
    labelField: PropTypes.string,
    // Styles, that apply to feature depending of its type
    typeStyles: PropTypes.shape({}),
    // Feature property, that defines its style
    typeStyleProp: PropTypes.string,
    // Disable auto-fit after update polygons
    noAutoFit: PropTypes.bool,
    // Calls when feature styling needed
    onGetStyle: PropTypes.func,
    // Calls when feature created
    onEachFeature: PropTypes.func,
    // Calls when marker will be add
    onEachMarker: PropTypes.func,
    // Override standart marker renderer
    pointToLayer: PropTypes.func,
    // Calls on each feature.
    // If function returns options - place a marker
    // with this options in center of the feature
    onCentralMarker: PropTypes.func,
    // Calls when user clicks on feature
    onFeatureClick: PropTypes.func,
    // Calls when cluster expanded to "spider"
    onClusterExpand: PropTypes.func,
    // Calls when cluster collapsed from spider
    onClusterCollapse: PropTypes.func,
    // Calls when all polygons updated
    onUpdated: PropTypes.func
  };

  static defaultProps = {
    cluster: false,
    labelsMinZoom: 12,
    fitMaxZoom: 15,
    minZoom: 0,
    maxZoom: 999
  };

  componentDidMount() {
    this._checkLabelZoomLevel(this._map.getZoom());
    this._checkLayerZoomLevel(this._map.getZoom());
    this.updatePolygons(this.props);
  }

  componentWillUnmount() {
    this._mounted = false;
    this.leafletElement.clearLayers();
    this.labelsGroup.clearLayers();
    this.centralMarkers.clearLayers();
    this._map._unregisterGisLayer(this.leafletElement);
    this._map.removeLayer(this.labelsGroup);
    this._map.removeLayer(this.centralMarkers);
    this._map.off('zoomend', this._zoomEnd);
    return super.componentWillUnmount();
  }

  createLeafletElement(props) {
    this._map = props.leaflet.map;
    this._mounted = true;
    this.handleFeatureClick = this.handleFeatureClick.bind(this);
    this._zoomEnd = this._zoomEnd.bind(this);
    this._queueUpdateStart = this._queueUpdateStart.bind(this);
    this._queueUpdateEnd = this._queueUpdateEnd.bind(this);

    const el = props.cluster ?
      new MarkerClusterGroup(props) :
      super.createLeafletElement(props);

    el.on('spiderfied', evt => this._fire('onClusterExpand', [evt.markers]));
    el.on('unspiderfied', evt => this._fire('onClusterCollapse', [evt.markers]));

    this.labelsGroup = Leaflet.layerGroup();
    this.centralMarkers = props.cluster ?
      new MarkerClusterGroup(props) : Leaflet.layerGroup();
    this.centralMarkers.addTo(this._map);
    this._map.on('zoomend', this._zoomEnd);

    if (props.order) {
      el._gisOrder = props.order;
    }
    this._map._registerGisLayer(el);

    return el;
  }

  updateLeafletElement(prevProps, props) {
    const { isUpdateNeeded } = props;
    let isUpdate = !isEqual(prevProps.data, props.data);
    if (isUpdateNeeded) {
      isUpdate = isUpdateNeeded(prevProps, props);
    }
    if (isUpdate) {
      this.updatePolygons(props);
    }
  }

  _fire(prop, params) {
    if (this.props[prop]) {
      return this.props[prop](...params);
    }
  }

  handleFeatureClick(evt) {
    this._fire('onFeatureClick', [evt]);
  }

  /**
   * @param evt
   * @protected
   */
  _zoomEnd(evt) {
    this._checkLabelZoomLevel(evt.target.getZoom());
    this._checkLayerZoomLevel(evt.target.getZoom());
  }

  /**
   * Show labels only in corresponding zoom levels
   * @private
   */
  _checkLabelZoomLevel(zoom) {
    const { labelsMinZoom } = this.props;
    const map = this._map;
    const lg = this.labelsGroup;
    if (!labelsMinZoom) {
      return;
    }
    if (zoom >= labelsMinZoom && !map.hasLayer(lg)) {
      lg.addTo(map);
    }
    if (zoom < labelsMinZoom && map.hasLayer(lg)) {
      lg.removeFrom(map);
    }
  }

  /**
   * Show layer only in corresponding zoom levels
   * @private
   */
  _checkLayerZoomLevel(zoom) {
    const { minZoom, maxZoom } = this.props;
    const map = this._map;
    const layer = this.leafletElement;

    if (!layer) return;
    if (!minZoom && !maxZoom) {
      return;
    }
    if (zoom >= minZoom && zoom <= maxZoom && !map.hasLayer(layer)) {
      layer.addTo(map);
    }
    if ((zoom < minZoom || zoom > maxZoom) && map.hasLayer(layer)) {
      layer.removeFrom(map);
    }
  }

  /**
   * Bind a label to feature if needed
   * @param layer
   * @private
   */
  _bindLabel(feature, layer) {
    const { labelField } = this.props;
    if (!labelField) {
      return;
    }
    layer.on('add', () => {
      const outerSpan = document.createElement('span');
      outerSpan.className = 'infoLayerLabelContent';
      const innerSpan = document.createElement('span');
      innerSpan.className = 'infoLayerLabelText';
      const textEl = document.createElement('div');
      textEl.innerText = feature.properties[labelField];
      // textEl.addEventListener('click', f => console.log('lololo', f, feature)); // It's doesn't work
      innerSpan.appendChild(textEl);
      outerSpan.appendChild(innerSpan);

      const icon = Leaflet.divIcon({
        className: 'infoLayerLabel',
        html: outerSpan,
        iconSize: [220, 80]
      });
      this.labelsGroup.addLayer(
        Leaflet.marker(
          layer.getCenter ? layer.getCenter() : layer.getLatLng(),
          {
            interactive: false,
            keyboard: false,
            icon
          })
      );
    });
    layer.on('remove', () => this.labelsGroup.clearLayers());
  }

  _queueUpdateStart() {

  }

  _queueUpdateEnd() {

  }

  /**
   * Bind marker on center of each feature,
   * if corresponding event function returns
   * not null/false, but marker options
   * @param layer
   * @private
   */
  _bindCenterMarker(feature, layer) {
    const mark = this._fire('onCentralMarker', [feature, layer]);
    if (mark) {
      const {
        onClick = () => null,
        ...otherProps
        } = mark;
      layer.on('add', () => {
        const l = this.centralMarkers.addLayer(
          Leaflet.marker(
            layer.getCenter ? layer.getCenter() : layer.getLatLng(),
            { feature, ...otherProps }
            ).on('click', evt => onClick(evt.target.options.feature))
        );
      });
    }
  }

  setData(data) {
    this.updatePolygons({
      ...this.props,
      data
    });
  }

  /**
   * Force redraw polygons (and readdign styles)
   */
  redrawPolygons() {
    this.updatePolygons(this.props);
  }

  updatePolygons(props) {
    if (!this._mounted) return;
    this.leafletElement.clearLayers();
    this.labelsGroup.clearLayers();
    this.centralMarkers.clearLayers();
    if (props.data === null) {
      return;
    }

    const {
      onGetStyle,
      typeStyleProp,
      typeStyles,
      noAutoFit,
      pointToLayer,
      fitMaxZoom
    } = this.props;

    let featureCount = 0;
    Leaflet.geoJSON(props.data, {
      pointToLayer: (point, latLng) => {
        if (pointToLayer) {
          return pointToLayer(point, latLng, this.leafletElement)
        }
        this._fire('onEachMarker', [point, latLng, this.leafletElement]);
        return Leaflet.marker(latLng, {
          icon: standartMarkerIcon,
          ...props.markerProps
        });
      },

      style: (feature) => {
        let style = {};
        if (typeStyleProp && typeStyles) {
          style = typeStyles[feature.properties[typeStyleProp]];
        }
        if (onGetStyle) {
          const newStyle = onGetStyle(feature);
          style = {
            ...style,
            ...newStyle
          };
        }
        return style;
      },

      onEachFeature: (feature, layer) => {
        featureCount += 1;
        this._fire('onEachFeature', [feature, layer, this.leafletElement]);

        if (layer.options.sort) {
          for (let i = 1; i <= layer.options.sort; i += 1) {
            layer.bringToFront();
          }
        }

        layer.on('click', this.handleFeatureClick);
        this._bindLabel(feature, layer);
        this._bindCenterMarker(feature, layer);
        this.leafletElement.addLayer(layer);
      }
    });
    this._fire('onUpdated', []);
    this._map._orderGisLayers();

    if (!noAutoFit && featureCount > 0) {
      this._map.invalidateSize();
      setTimeout(() => {
        if (!this._mounted) return;
        this._map.fitBounds(this.leafletElement.getBounds(), {
          maxZoom: fitMaxZoom
        });
      }, 0);
    }
  }
}

export default withLeaflet(InfoLayer);
export { InfoLayer as InfoLayerExtendable };
