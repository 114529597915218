import PropTypes from 'prop-types';
import Leaflet from 'leaflet';
import { ExtendableFeatureGroup } from 'react-leaflet-extendable';
import { withLeaflet } from 'react-leaflet';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import icon from './standartMarkerIcon';

class ObjectMarker extends ExtendableFeatureGroup {
  static propTypes = {
    // eslint-disable-next-line
    data: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.drawMarker = this.drawMarker.bind(this);
  }

  drawMarker(point, latLng) {
    if (!point.radius) {
      return Leaflet.marker(latLng, { icon: get(point, 'icon') || icon });
    }
    return Leaflet.circle(latLng, point.radius);
  }

  updateMarker(props) {
    this.leafletElement.clearLayers();
    // const draggable = this.props.draggable;
    if (props.data === null) {
      return;
    }
    const json = new Leaflet.GeoJSON(props.data, {
      pointToLayer: this.drawMarker
    }).on('add', evt => this._map.fitBounds(evt.target.getBounds(), {
      maxZoom: 16
    }));
    this.leafletElement.addLayer(json);
  }

  createLeafletElement(props) {
    this._map = props.leaflet.map;
    this.leafletElement = super.createLeafletElement(props);
    this.updateMarker(this.props);
    return this.leafletElement;
  }

  updateLeafletElement(prevProps, props) {
    if (!isEqual(prevProps.data, props.data)) {
      this.updateMarker(props);
    }
  }
}

export default withLeaflet(ObjectMarker);
