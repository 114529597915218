/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import apiDecorator from 'api/api-decorator';
import Notification from 'components/notification';
import Button, { btnThemes } from 'components/button';
import Icon, { iconTypes } from 'components/icon';

import imgAfterReg from './assets/afterReg.jpg';

import translates from './auth-i18n.json';

import * as actions from './redux';

import 'styles/base/auth/_page-confirm.scss';

const bemClasses = new Bem('pageConfirm');

@connect(state => ({
  auth: state.auth,
}), { ...actions }, null, { forwardRef: true })
@apiDecorator
@I18nHoc(translates)
export default class ConfirmBlock extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    actionAuthGetData: PropTypes.func,
    auth: PropTypes.shape({
      authenticated: PropTypes.bool
    }),
    onAuthenticated: PropTypes.func,
  };

  resend() {
    this.api.get('action/user/action-common/resend-register-confirm').then(
      () => Notification.info(this.i18n('messSentAgain'))
    );
  }

  render() {
    const { i18n } = this;
    const { className } = this.props;

    return (
      <div {...bemClasses({ extra: className })}>
        <div {...bemClasses('imgContainer')}>
          <img {...bemClasses('img')} src={imgAfterReg} alt="afterReg" loading="lazy" decoding="async" />
          <Icon {...bemClasses('icon', 'check')} type={iconTypes.check} />
        </div>
        <div {...bemClasses('content')}>
          <p {...bemClasses('text', 'description')}>
            <span children={i18n('messRegistrationComplete')} />
          </p>
          <p {...bemClasses('text', 'description')}>
            <span children={i18n('messRegistrationCompleteSpam')} />
          </p>
          <br />
          <p {...bemClasses('text', 'description')}>
            <span children={i18n('didNtEmail')} />
            <Button
              {...bemClasses('btn', 'send')}
              onClick={this.resend.bind(this)}
              label={i18n('emailResend')}
              title={i18n('emailResend')}
              theme={btnThemes.olive}
              big
            />
          </p>
        </div>
      </div>
    );
  }
}
