/* eslint-disable */

leaflet = require('leaflet');
iconMarker = require('./assets/markerRadius.png');
/*
 * leaflet.CircleEditor is an extension of leaflet.Circle, just to add the edition part (remember, radius in meters).
 */
leaflet.CircleEditor = leaflet.Circle.extend ({



/*  options: {
/!*    icon: new leaflet.icon({
      // iconSize: new leaflet.Point(20, 20),
      // iconUrl: iconMarker,
      // className: 'leaflet-div-icon leaflet-editing-icon'
    }),*!/
    // extendedIconClass : 'extend-icon'
  },*/

  onAdd: function (map) {
    leaflet.Path.prototype.onAdd.call(this, map);
    this.addHooks();
  },

  onRemove: function (map) {
    this.removeHooks();

    leaflet.Path.prototype.onRemove.call(this, map);
  },


  addHooks: function () {
    if (this._map) {
      if (!this._markerGroup) {
        this._initMarkers();
      }
      this._map.addLayer(this._markerGroup);
    }
  },

  removeHooks: function () {
    if (this._map) {
      this._markerGroup.clearLayers();
      this._map.removeLayer(this._markerGroup);
      delete this._markerGroup;
      delete this._markers;
    }
  },

  updateMarkers: function () {
    this._markerGroup.clearLayers();
    this._initMarkers();
  },

  _initMarkers: function () {
    this._markerGroup = new leaflet.LayerGroup();
    this._markers = [];

/*    var markerCenter = this._createMarker(this._latlng, 0, true);
    this._markers.push(markerCenter);*/

    var circleBounds = this.getBounds(),
      center = circleBounds.getCenter(),
      neCoord = circleBounds.getNorthEast(),
      swCoord = circleBounds.getSouthWest(),
      northCenterCoord = new leaflet.LatLng(center.lat, neCoord.lng, true),
      eastCenterCoord = new leaflet.LatLng(center.lat, swCoord.lng, true),
      markerEastCenter = this._createMarker(eastCenterCoord, 1, true);
      // markerNorthCenter = this._createMarker(northCenterCoord, 0);
      if (this.options.helperCoords) {
       var markerNorthCenter = this._createMarker(this.options.helperCoords, 0);
      // var markerEastCenter = this._createMarker(this.options.helperCoords, 1, true);
      } else {
        var markerNorthCenter = this._createMarker(northCenterCoord, 0);
      }

    this._markers.push(markerNorthCenter);
    this._markers.push(markerEastCenter);
  },

  _createMarker: function (latlng, index, isHelper) {
    var markerIcon = leaflet.icon({
      // iconSize: new leaflet.Point(17, 17),
      iconSize: new leaflet.Point(20, 20),
      iconUrl: iconMarker,
    });
    if (isHelper) {
      var draggable = false
    } else { var draggable = true };
    var marker = new leaflet.Marker(latlng, {
      draggable: draggable,
      icon: markerIcon
    });

/*    if (isHelper === undefined) {
      isHelper = false;
    }*/

    marker._origLatLng = latlng;
    marker._index = index;
    if (isHelper) {
      marker.setOpacity(0);
      // marker.on('add', this._setOpacityToZero, this)
    }
    marker.on('drag', this._onMarkerDrag, this)
      .on('dragend', this._fireEdit, this)
      .on('mouseover', this._onMouseOver, this)
      .on('mouseout', this._onMouseOut, this);

    this._markerGroup.addLayer(marker);

    return marker;
  },

  _setOpacityToZero: function () {
    this.markers[1].setOpacity(0);
  },

  _onMouseOver: function (e) {
    var target = e.target,
      icon = target._icon,
      classValues = icon.getAttribute("class");
    // icon.setAttribute("class", this.options.extendedIconClass + " " + classValues);
  },

  _onMouseOut: function (e) {
    var target = e.target,
      icon = target._icon,
      classValues = icon.getAttribute("class");
    //icon.setAttribute("class", classValues.replace("extend-icon", ""));
    // icon.setAttribute("class", classValues.replace(this.options.extendedIconClass, ""));
  },

  _fireEdit: function () {
    if (this.needToCutRadius) {
      this._markers[0].setLatLng(this.pointHelperLatLng);
      this._markers[0].setOpacity(1);
      this._markers[1].setOpacity(0);
    }
    this.fire('edit');
  },

  _onCircleEditDragStart: function () {
    if (!this.centerStartLatLng && !this.resizeStartLatLng) {
      var getCircleBounds = this.getBounds();
      this.centerStartLatLng = getCircleBounds.getCenter();
      this.resizeStartLatLng = this._markers[0].getLatLng();
    }
    this._moveMarkerRadiusToDelta();
    this.redraw();
  },

  _onCircleEditDrag: function () {
    if (!this.centerStartLatLng && !this.resizeStartLatLng) {
      var getCircleBounds = this.getBounds();
      this.centerStartLatLng = getCircleBounds.getCenter();
      this.resizeStartLatLng = this._markers[0].getLatLng();
    }
    this._moveMarkerRadiusToDelta();
    this.redraw();
  },

  _onCircleEditDragEnd: function () {
    // this._markers[0].setOpacity(1);
    if (this.centerStartLatLng && this.resizeStartLatLng) {
      this.centerStartLatLng = false;
      this.resizeStartLatLng = false;
    }
    this.fire('circleDraged');
  },

  _moveMarkerRadiusToDelta: function () {
    var getCircleBounds = this.getBounds();
    var centerEnd = getCircleBounds.getCenter();
    var deltaLat =  centerEnd.lat - this.centerStartLatLng.lat;
    var deltaLng = centerEnd.lng - this.centerStartLatLng.lng;
    var newResize = new leaflet.LatLng(this.resizeStartLatLng.lat + deltaLat, this.resizeStartLatLng.lng + deltaLng, true);
    this._markers[0].setLatLng(newResize);
  },

  _onMarkerDrag: function (e) {
    var marker = e.target;
    var getCircleBounds = this.getBounds();
    var center = getCircleBounds.getCenter();
    var axis = marker._latlng;
    var distance = center.distanceTo(axis);
    var mm = this._markers[0];
    if (distance > this.options.maxRadius || distance < this.options.minRadius) {
      if (distance > this.options.maxRadius) {
        distance = this.options.maxRadius;
      } else if (distance < this.options.minRadius) {
        distance = this.options.minRadius;
      }
        this.needToCutRadius = true;
        var getCircleBounds = this.getBounds();
        var center = getCircleBounds.getCenter();
        var southWest = getCircleBounds.getSouthWest();
        var southWestCenterCoord = new leaflet.LatLng(center.lat, southWest.lng, true);
        var southWestCenterCoordDeCart = leaflet.Projection.SphericalMercator.project(southWestCenterCoord);
        this.point1deCart = leaflet.Projection.SphericalMercator.project(center);
        var centrDeCart = this.point1deCart;
        var x1 = this.point1deCart.x;
        var y1 = this.point1deCart.y;
        this.point2coord = this._markers[0].getLatLng();
        this.point2deCart = leaflet.Projection.SphericalMercator.project(this.point2coord);
        var x2 = this.point2deCart.x;
        var y2 = this.point2deCart.y;
        var r = Math.abs(centrDeCart.x - southWestCenterCoordDeCart.x);
        var m = (y2-y1)/(x2-x1);
        if (x2 > x1) {
          var xComponent = x1 + r/Math.sqrt(1+ m*m);
        } else {
          var xComponent = x1 - r/Math.sqrt(1+ m*m);
        }
        if (y2 > y1) {
          var yComponent = y1 + r/Math.sqrt(1+1/(m*m));
        } else {
          var yComponent = y1 - r/Math.sqrt(1+1/(m*m));
        }
        var p3 = {x: xComponent, y: yComponent};
        this.pointHelperLatLng = leaflet.Projection.SphericalMercator.unproject(p3);
        this._markers[1].setLatLng(this.pointHelperLatLng);
        mm.setOpacity(0);
        this._markers[1].setOpacity(1);
        this._markers[1].setZIndexOffset(-100);
    } else {
      this.needToCutRadius = false;
      mm.setOpacity(1);
      this._markers[1].setOpacity(0);
    }

    this.setRadius(distance);
    this.redraw();
    this.fire('radiuschange');
  },

   centerchange: function() {},
   radiuschange: function() {}
});
