import searchTranslates from 'src-sites/domik/section-search/form/search-i18n.json';

export default function () {
  return {
    ...searchTranslates,

    canChooseMetroOnMap: {
      ru: 'Вы можете выбрать станцию метро прямо на карте',
      ua: 'Ви можете обрати станцію метро прямо на карті'
    },
    mapLegendTopic: {
      ru: 'Индекс надёжности',
      ua: 'Індекс надійності'
    },
    mapLegendItemHigh: {
      ru: 'высокий',
      ua: 'високий'
    },
    mapLegendItemMedium: {
      ru: 'средний',
      ua: 'середній'
    },
    mapLegendItemLow: {
      ru: 'низкий',
      ua: 'низький'
    },
    mapLegendItemNA: {
      ru: 'не проверен',
      ua: 'не перевірений'
    },
    mapLegendItemReady: {
      ru: 'жк сдан',
      ua: 'жк зданий'
    },
    mapMinimize: {
      ru: 'Свернуть',
      ua: 'Згорнути'
    },
    mapExpand: {
      ru: 'Развернуть',
      ua: 'Розгорнути'
    },

    searchPlaceholder: {
      ru: 'Поиск',
      ua: 'Пошук'
    },
    searchPlaceholderBase: {
      ru: 'ЖК, застройщик',
      ua: 'ЖК, забудовник'
    },
    searchPlaceholderLocality: {
      ru: 'Район, жк, застройщик',
      ua: 'Район, жк, забудовник'
    },
    searchPlaceholderLocalitySubways: {
      ru: 'Район, метро, жк, застройщик',
      ua: 'Район, метро, жк, забудовник'
    },
    searchPlaceholderRegion: {
      ru: 'Город, район, жк, застройщик',
      ua: 'Місто, район, жк, забудовник'
    },
    searchPlaceholderSubregion: {
      ru: 'Город, село, жк, застройщик',
      ua: 'Місто, село, жк, забудовник'
    },
    searchPlaceholderCountry: {
      ru: 'Область, город, район, жк, застройщик',
      ua: 'Область, місто, район, жк, забудовник'
    },

    statusReady: {
      ru: 'Готовые ЖК',
      ua: 'Готові ЖК'
    },

    filters: {
      ru: 'Фильтры',
      ua: 'Фільтри'
    },

    moreFilters: {
      ru: 'Еще фильтры',
      ua: 'Ще фільтри'
    },
    moreParameters: {
      ru: 'Еще параметры',
      ua: 'Ще параметри'
    },

    mainTab: {
      ru: 'Главное',
      ua: 'Головне'
    },
    properties: {
      ru: 'Параметры',
      ua: 'Параметри'
    },
    insideLC: {
      ru: 'Внутри ЖК',
      ua: 'Всередині ЖК'
    },
    insideCT: {
      ru: 'Внутри КП',
      ua: 'Всередині КМ'
    },

    price: {
      ru: 'Цена',
      ua: 'Ціна'
    },
    rooms: {
      ru: 'Комнат',
      ua: 'Кімнат'
    },
    type: {
      ru: 'Тип',
      ua: 'Тип'
    },

    popular: {
      ru: 'Популярные',
      ua: 'Популярні'
    },
    cheap: {
      ru: 'Дешевый метр',
      ua: 'Дешевий метр'
    },
    expensive: {
      ru: 'Дорогой метр',
      ua: 'Дорогий метр'
    },
    earlier: {
      ru: 'Сдаются раньше',
      ua: 'Здаються раніше'
    },
    later: {
      ru: 'Сдаются позже',
      ua: 'Здаються пізніше'
    },
    reliable: {
      ru: 'Надёжные',
      ua: 'Надійні'
    }
  };
}
