import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import indexOf from 'lodash/indexOf';
import Bem from 'bemHelper';
import localStorage from 'helpers/local-storage';
import Accordion from 'components/accordion';

const bemClassesBlockAccordion = new Bem('blockAccordion');

/**
 * === AccordionBlock Component ===
 * Выглядит как блок с хедером, ведет себя как открытый по-умолчанию аккордеон.
 * Может запоминать закрыл его пользователь или нет, чтобы так же и отрендериться в следующий раз.
 *
 * Также может проверять прилетел ли новый элемент в Блок, после закрытия пользователем аккордеона,
 * если да, то аккордеон отрендерится открытым несмотря на предыдущее действие пользователя.
 *
 * = Example =
 * <AccordionBlock
 *   panelTopic="Объявления"
 *   trackAs="forumAdverts"
 *   latestItemId={Math.max(...map(data, it => it.ad_id))}
 * >
 *   {content}
 * </AccordionBlock>
 */
export default class AccordionBlock extends PureComponent {
  static propTypes = {
    className: PropTypes.string,

    // Block content children
    children: PropTypes.node,

    // String-key for localStorage. If enabled Component will check it in localStorage
    // and remember if it should be opened/closed by default
    trackAs: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),

    // Max id value of the elements in Block content. If enabled Component will check
    // whether new element is present.
    // If so and user didn't see it Component will be rendered as opened despite of
    // the previous user actions.
    latestItemId: PropTypes.number,

    // Accordion is opened by default
    firstlyOpened: PropTypes.bool,

    // Fired right after accordion is opened (optional)
    openCallBack: PropTypes.func,

    // Accordion title
    panelTopic: PropTypes.string.isRequired,

    // icon on the left side (if panelBefore does not exist)
    panelIconType: PropTypes.string,

    // element on left side (node)
    panelBefore: PropTypes.node,

    // number (usually, amount of chosen/active items in Accordion content)
    panelTopicRight: PropTypes.string,

    // number (usually, amount of chosen/active items in Accordion content)
    dataView: PropTypes.string,

    // убирает боковые padding
    noPaddingLR: PropTypes.bool
  };

  static defaultProps = {
    firstlyOpened: true,
    dataView: 'viewBlockMain'
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.defineOpenCondition = this.defineOpenCondition.bind(this);
    this.saveIdToStorage = this.saveIdToStorage.bind(this);

    this.state = {
      preOpened: !!props.firstlyOpened
    };
  }

  componentDidMount() {
    this.defineOpenCondition();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.preOpened && this.props.latestItemId > prevProps.latestItemId) {
      this.accordRef.toggle();
      this.saveIdToStorage();
    }
    if (prevProps.trackAs !== this.props.trackAs) this.defineOpenCondition();
  }

  defineOpenCondition() {
    const { trackAs, firstlyOpened } = this.props;
    if (!trackAs) return null;
    const AccordionList = localStorage.get('AccordionList') || [];
    const index = indexOf(AccordionList, trackAs);

    if (index === -1) {
      return this.setState({ preOpened: firstlyOpened });
    }

    return this.setState({ preOpened: !firstlyOpened });
  }

  saveIdToStorage() {
    const { trackAs } = this.props;
    if (!trackAs) return null;

    const AccordionList = localStorage.get('AccordionList') || [];
    const index = indexOf(AccordionList, trackAs);
    if (index === -1) {
      AccordionList.push(trackAs);
    } else {
      AccordionList.splice(index, 1);
    }

    return localStorage.emit('AccordionList', AccordionList);
  }

  render() {
    const { className, children, ...accordionProps } = this.props;

    return (
      <div {...bemClassesBlockAccordion({ extra: className })}>
        <Accordion
          {...bemClassesBlockAccordion('accordion')}
          {...accordionProps}
          ref={el => this.accordRef = el}
          firstlyOpened={this.state.preOpened}
          onPanelClick={this.saveIdToStorage}
        >
          <panel {...bemClassesBlockAccordion('panel')}>
            <div {...bemClassesBlockAccordion('accordionContent')} children={children} />
          </panel>
        </Accordion>
      </div>
    );
  }
}
