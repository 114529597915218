/**
 * Created by Kotkin on 02.08.2017.
 */
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
// import { animateScroll } from 'react-scroll';
// import toNumber from 'lodash/toNumber';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import sensor from 'components/sensor';

import translates from './paginator-i18n.json';

const scrollStore = {};

const bemClassesNoData = new Bem('noData');

@sensor
@I18nHoc(translates)
export default class PaginatorContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,

    // Pages data from DataProvider
    paginator: PropTypes.any, //eslint-disable-line

    // Pages number from DataProvider
    paginatorCount: PropTypes.number,

    // Change page (to previous/next) function
    changePage: PropTypes.func,

    // Current page index
    currentPage: PropTypes.number,

    // Max items per page
    limit: PropTypes.number,

    // Смещение скрола при переключении страниц
    scrollOffset: PropTypes.number,

    // Reload method from DataProvider
    reloadProvider: PropTypes.func,

    // Scroll to controll element
    // scrollToControll: PropTypes.bool,

    // Scroll to very top of the page
    scrollToTop: PropTypes.bool,

    // Element ref which should be scrolled to after changing page
    scrollToRef: PropTypes.shape({}),

    // Render Pages component also on top of Container
    withTopPages: PropTypes.bool,

    // Doesn't render pages block
    noPages: PropTypes.bool,

    // Add padding-bottom and background-color
    addPdBgBsh: PropTypes.bool,

    // render block before pages
    blockBeforePages: PropTypes.shape({}),

    // If set - save and restore scroll position. Key will be unique
    scrollRestoreKey: PropTypes.string
  };

  static getElements(children, noPages, withTopPages) {
    const childrenLength = children.length;
    const elements = {
      controlElem: null,
      pagesTopElem: null,
      containerElem: null,
      pagesElem: null
    };

    if (noPages) {
      elements.controlElem = children[0];
      elements.containerElem = children[childrenLength - 1];
      return elements;
    }

    if (childrenLength >= 3) elements.controlElem = children[0];
    elements.containerElem = children[childrenLength - 2];
    elements.pagesElem = children[childrenLength - 1];
    if (withTopPages) elements.pagesTopElem = elements.pagesElem;
    return elements;
  }

  componentDidMount() {
    this.mounted = true;
    this._restoreScrollPosition();
    if (this.props.scrollRestoreKey) {
      window.addEventListener('scroll', this._saveScrollPosition);
    }
  }

  componentDidUpdate(prevProps) {
    const key = this.props.scrollRestoreKey;
    if (this.mounted && prevProps.currentPage !== this.props.currentPage && key) {
      delete scrollStore[key];
    }
    this._restoreScrollPosition();
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.props.scrollRestoreKey && window) {
      window.removeEventListener('scroll', this._saveScrollPosition);
    }
  }

  _saveScrollPosition = (evt) => { // eslint-disable-line
    const key = this.props.scrollRestoreKey;
    // React router behavior reset scroll position when url changed, but before component unmounts
    // And we need this workaround
    if (key && window && window.scrollY) {
      scrollStore[key] = { x: window.scrollX, y: window.scrollY };
    }
  };

  _restoreScrollPosition() {
    const key = this.props.scrollRestoreKey;
    const stor = scrollStore[key];
    const { paginator = [] } = this.props;
    if (key && window && paginator.length && stor) {
      window.scrollTo(stor.x, stor.y);
      delete scrollStore[key];
    }
  }

  /*
  scrollToSection() {
    const firstElem = this.props.scrollToControll
      ? this.controllRef
      : this.props.scrollToRef || this.pagesTopRef || this.containerRef;

    const transitionDuration = toNumber(this.sensor.getVariable('timeDuration'));
    const heightHeader = toNumber(this.sensor.getVariable('heightHeaderPx'));
    const heightHeaderTablet = toNumber(this.sensor.getVariable('heightHeaderTabletPx'));
    const heightHeaderPhone = toNumber(this.sensor.getVariable('heightHeaderPhonePx'));
    const offsetConfig = {
      desktop: heightHeader,
      tablet: heightHeaderTablet,
      phone: heightHeaderPhone
    };
    const { scrollOffset = 0, scrollToTop } = this.props;
    let position = 0;
    if (!scrollToTop) {
      const { media: { tablet, phone } = {}, scrollTop } = this.sensor.getRuntime();
      const { top } = firstElem.getBoundingClientRect();
      let offset = offsetConfig.desktop;
      if (tablet) offset = offsetConfig.tablet;
      if (phone) offset = offsetConfig.phone;

      position = (top + scrollTop + scrollOffset) - offset;
    }

    animateScroll.scrollTo(position, {
      duration: transitionDuration,
      smooth: true,
      ignoreCancelEvents: true
    });
  }
  */

  renderNoData() {
    const {noData} = this.props;

    if (noData) return noData;

    return (
      <div {...bemClassesNoData(null, this.props.addPdBgBsh)}>
        <span {...bemClassesNoData('text')} children={this.i18n('noDataText')} />
      </div>
    );
  }

  render() {
    const {
      children,
      paginator = [], paginatorCount, limit,
      changePage, currentPage,
      noPages, addPdBgBsh, withTopPages, blockBeforePages,
      ...restProps
    } = this.props;
    const noData = !paginator.length;

    if (paginatorCount === 0 && noData) return this.renderNoData();

    const pagesAmount = Math.ceil(paginatorCount / limit);

    const {
      controlElem,
      pagesTopElem,
      containerElem,
      pagesElem
    } = PaginatorContainer.getElements(children, noPages, withTopPages);

    return (
      <>
        {controlElem && cloneElement(
          controlElem, {
            ...restProps,
            paginator,
            paginatorCount,
            limit,
            currentPage,
            pagesAmount,
            addPdBgBsh,
            controlRef: el => this.controllRef = el,
            key: 'controlElem'
          }
        )}
        {pagesTopElem && cloneElement(
          pagesTopElem, {
            pagesAmount,
            changePage,
            currentPage,
            addPdBgBsh,
            limit,
            pagesOnTop: true,
            pagesRef: el => this.pagesTopRef = el,
            key: 'pagesElemTop'
          }
        )}
        {!noData ?
          cloneElement(
            containerElem, {
              paginator,
              pagesAmount,
              limit,
              changePage,
              // className,
              currentPage,
              containerRef: el => this.containerRef = el,
              key: 'containerElem'
            }
          ) :
          this.renderNoData()
        }
        {blockBeforePages}
        {pagesElem && cloneElement(
          pagesElem, {
            pagesAmount,
            changePage,
            currentPage,
            addPdBgBsh,
            limit,
            key: 'pagesElem'
          }
        )}
      </>
    );
  }
}
