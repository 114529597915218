import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { reportError } from 'api/api-errors';
import apiDecorator from 'api/api-decorator';
import getDisplayName from "react-display-name";
import extend from "extend";
import ProjectConfig from "../../../config";

/**
  For example:
  {this.needCount &&
    <Counter
      entity={{
        topic_id: dataObj.topic_id,
        topic_title: dataObj.topic_title
      }}
      action="view"
    />
  }
*/

@apiDecorator
export default class Counter extends React.PureComponent {
  static propTypes = {
    entity: PropTypes.shape({}),
    action: PropTypes.string,
    pageUrl: PropTypes.string,
    mode: PropTypes.oneOf(['view', 'click', 'both']).isRequired,
    injectClickName: PropTypes.string,
    children: PropTypes.node,
    doNotInjectCountAction: PropTypes.bool
  };

  static defaultProps = {
    injectClickName: 'onCounterClick',
    doNotInjectCountAction: false
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.stat = this.stat.bind(this);
  }

  componentDidMount() {
    if (this.props.mode === 'view') this.stat();
    if (this.props.mode === 'both' && this.props.entity)
      this.statAction(this.props.entity, 'view');
  }

  componentDidUpdate(prevProps) {
    if (this.props.mode === 'view' && this.props.pageUrl !== prevProps.pageUrl) this.stat();
  }

  statAction = (entity, action) => {
    this.api.get('action/statistics/actions-user-activity/count', {
      queryString: {
        project: __PROJECT__,
        entity: JSON.stringify(entity),
        action,
        r: Math.random()
      }
    })
      .then()
      .catch(error => reportError(error));
  }

  stat() {
    const { entity = {}, action } = this.props;
    this.statAction(entity, action);
  }

  render() {
    if (this.props.mode === 'view') return null;
    const inject = {
      [this.props.injectClickName]: this.stat,
    };
    if (!this.props.doNotInjectCountAction) {
      inject.countAction = this.statAction;

    }
    return cloneElement(this.props.children, inject);
  }
}

export function counterDecorator(mode = 'both') {
  return function wrapComponent(WrapComponent) {
    return class ComponentWithCounter extends WrapComponent {
      static displayName = `Counter(${getDisplayName(WrapComponent)})`;
      render() {
        return (
          <Counter mode={mode}>
            <WrapComponent {...this.props} />
          </Counter>
          );
      }
    };
  };
}

