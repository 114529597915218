import React, { Component } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import PropTypes from 'prop-types';
import { authConnect, perms } from 'auth';
import loadableDeprecated from 'helpers/loadable';
import { btnViews } from 'components/button';

const ModalButton = loadableDeprecated(() => import('components/modal/modal-button'));
const Header = loadableDeprecated(() => import('components/modal/headerModal'));
const Content = loadableDeprecated(() => import('components/modal/contentModal'));
const Editor = loadableDeprecated(() => import('./editor'));

@authConnect()
export default class EditorButton extends Component {
  static propTypes = {
    checkPermission: PropTypes.func
  };

  /*
  constructor(props, ctx) {
    super(props, ctx);
  }
  */

  render() {
    if (!this.props.checkPermission(perms.MANAGEMENT_CONTENT_BLOCK)) {
      return null;
    }

    return (
      <LazyHydrate
        whenIdle
        className="btnEditorAdmin"
        style={{
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'auto',
          maxWidth: '100%',
          border: 0,
          padding: 0,
          lineHeight: 0
        }}
      >
        <ModalButton
          style={{ width: 'auto' }}
          buttonProps={{
            iconType: 'build',
            title: `Управление контент-блоком ${this.props.place}`,
            label: 'Управление блоком',
            dataView: btnViews.unitControl
          }}
          size="full"
          hideModalBtn
        >
          <Header closeButton>Управление блоком</Header>
          <Content hFull>
            <Editor {...this.props} />
          </Content>
        </ModalButton>
      </LazyHydrate>
    );
  }
}
