import each from 'lodash/each';
import isObject from 'lodash/isObject';
import CommonError from 'errors/commonError';
import { parseTemplateSync, rawEvaluate } from 'helpers/templateParser';
import DataProvider from 'api/data-provider';

import defaultConfig from './config';

/**
 * Preloader/configurator for content blocks
 */
const preloader = function (params, projectConfig, wait = []) { // eslint-disable-line
  const cfg = {};

  // *** Contains block configuration parameters
  cfg.$blocksParams = [cfg => Promise.resolve({ // eslint-disable-line
    wait,
    url: cfg.url,
    page: cfg.page || 1,
    ...params
  }), { url: '$route.path', page: '$route.params.page' }];

  // *** Contains block places for entity/landing/url
  cfg.$blocksPlaces = {
    $wait: ['$blocksParams', ...wait],
    $callback: payload => ({
      data: [blocksPlacesPreloader, { payload }],
      vars: [() => Promise.resolve(payload)]
    })
  };

  // *** Contains blocks content from block's data providers
  cfg.$blocksContent = {
    $wait: ['$blocksPlaces', '$blocksParams'],
    $callback: data => getBlocksContent(params, projectConfig, data)
  };
  return cfg;
};

/**
 * Evaluate all vars in "data" object
 * @param data
 * @param vars
 */
export function evaluateVars(data, vars, singleRaw = false) {
  const newData = {};
  each(data, (expr, key) => {
    if (expr && typeof expr === 'string' && expr.indexOf('{') > -1) {
      try {
        newData[key] = parseTemplateSync(expr, vars, singleRaw);
      } catch (err) {
        console.error('Content block expression parsing failed', expr, vars);
        console.error(err);
      }
    } else {
      newData[key] = expr;
    }
  });
  return newData;
}

/**
 * Evaluate variables in place query and props
 * @param places
 * @param vars
 */
function blockPlacesEvaluator(places, vars) {
  const newPlaces = {};

  each(places, (place, placeKey) => { // eslint-disable-line
    let condParsed = null;
    try {
      condParsed = rawEvaluate(place.condition, vars);
    } catch (e) {
      console.error(`Block condition evaluation failed for place ${placeKey}`, place.condition);
      console.error(e);
      condParsed = false;
    }

    newPlaces[placeKey] = {
      ...place,
      parsedQuery: evaluateVars(place.query, vars, true),
      parsedProps: evaluateVars(place.props, vars),
      parsedCondition: condParsed
    };
  });
  return newPlaces;
}

/**
 * Load blocks places data and call variable resolver in query data
 * @param params
 */
function blocksPlacesPreloader(params) {
  const { api, payload } = params;
  const { $blocksParams, ...vars } = payload;

  return new Promise((accept) => {
    // Get blocks places for page
    api.get('blocks', {
      clientCache: 1,
      queryString: {
        project: __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__,
        url: $blocksParams.url,
        group_id: $blocksParams.group_id,
        entity: $blocksParams.entity,
      }
    }).then(blockPlaces => accept(blockPlacesEvaluator(blockPlaces, vars)));
  });
}

/**
 * Retrieve blocks content
 *
 * @param params
 * @param data
 * @return {{$params: *[]}}
 */
function getBlocksContent(params, projectConfig, data) {
  const newConfig = {};

  each(data.$blocksPlaces.data, (block, blockName) => {
    const dCfg = defaultConfig[block.type] || {};
    const pCfg = projectConfig[block.type] || {};
    const blockConfig = { ...dCfg, ...pCfg };
    const enabled = !block.condition || (block.condition && block.parsedCondition);

    const otherQuery = {};

    if (blockConfig.orders && typeof block.props.orderId !== 'undefined') {
      if (isObject(blockConfig.orders[block.props.orderId])) {
        otherQuery.order = blockConfig.orders[block.props.orderId].param;
      }
    }


    if (blockConfig.provider && enabled) {
      newConfig[`${blockName}Content`] = [DataProvider.preload, {
        url: blockConfig.provider,
        query: { ...block.parsedQuery, ...otherQuery },
        cache: 60,
        clientCache: blockName,
        queryJson: true
      }];
    }
  });
  return newConfig;
}

export default preloader;
