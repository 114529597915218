/**
 * Created by Kotkin on 18.07.17.
 */
import simpleStore from './simpleStore';

let offsetPosition = null;


/**
 * simply reset all scrolling locks
 */
function resetScroll() {
  document.body.style.top = '';
  document.body.classList.remove('noScroll');
  offsetPosition = null;
}

/**
 * lock scrolling inside modals
 */
function preventScrolling() {
  if (__CLIENT__) {
    const registeredModals = simpleStore.get('registeredModals') || [];

    if (offsetPosition === null && registeredModals.length) {
      offsetPosition = window.scrollY;
      document.body.style.top = `${(offsetPosition / 10) * -1}rem`;
      return document.body.classList.add('noScroll');
    }

    if (offsetPosition !== null && !registeredModals.length) {
      document.body.style.top = '';
      document.body.classList.remove('noScroll');
      window.scrollTo(0, offsetPosition);
      return offsetPosition = null;
    }
  }
}

/**
 * lock scrolling
 */
function noScroll() {
  if (__CLIENT__) {
    if (offsetPosition === null) {
      offsetPosition = window.scrollY;
      document.body.style.top = `${(offsetPosition / 10) * -1}rem`;
      return document.body.classList.add('noScroll');
    }

    if (offsetPosition !== null) {
      document.body.style.top = '';
      document.body.classList.remove('noScroll');
      window.scrollTo(0, offsetPosition);
      return offsetPosition = null;
    }
  }
}

export {
  noScroll as default,
  resetScroll,
  preventScrolling
};
