/**
 * Created by Kotkin on 16.11.2017.
 */
import toString from 'lodash/toString';

/**
 * usage:
 * beautifyNumber('355740')
 *
 * returns:
 * '355 740'
 *
 *
 * @param {string || number} val
 * @returns {string}
 */

export default function beautifyNumber(val) {
  let string = typeof val === 'string' ? val : toString(val);
  const index = string.indexOf('.');
  if (index > -1) {
    string = string.substr(0, index + 1) +
      string.slice(index).replace(/\./g, '');
  }

  string = string.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

  if (string.split('.').length === 2) {
    const pointIndex = string.indexOf('.');
    const firstString = string.substring(0, pointIndex);
    const secondString = string.substring(pointIndex).replace(/ /g, '');
    string = firstString + secondString;
  }
  // return string.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  return string;
}
