import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ServerRendering extends Component {
  static propTypes = {
    serverRenderer: PropTypes.shape({}).isRequired,
    clientRenderer: PropTypes.shape({}).isRequired
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.state = {
      serverRendering: true
    };
  }

  componentDidMount() {
    this.changeServerState();
  }

  changeServerState = () => {
    this.setState({serverRendering: false})
  };

  render() {
    const {serverRenderer, clientRenderer} = this.props;
    if (this.state.serverRendering) return serverRenderer;

    return clientRenderer;
  }
};


