import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Icon, { iconTypes } from 'components/icon';
import OAuthRequest from 'components/oauth-request';

import 'styles/base/auth/_social-auth.scss';

const bemClasses = new Bem('socialAuth');

export default class SocialAuth extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    horizontal: PropTypes.bool,
    onlySocialAuth: PropTypes.bool
  };

  render() {
    const { horizontal, onlySocialAuth, className } = this.props;
    const mods = { horizontal, onlySocial: onlySocialAuth };

    return (
      <div
        {...bemClasses({
          modifiers: mods,
          extra: className
        })}
      >
        <div {...bemClasses('content')}>
          <div {...bemClasses('linkContainer')}>
            <OAuthRequest
              {...bemClasses('link', 'main fb')}
              title="facebook.com"
              rel="nofollow"
              socOauthName="fb"
            >
              <span {...bemClasses('linkContent')} data-test="AuthFacebook">
                <Icon {...bemClasses('icon')} type={iconTypes.socialFacebook} />
                <span {...bemClasses('text')} children="Facebook" />
              </span>
            </OAuthRequest>
          </div>
          <div {...bemClasses('linkContainer')} data-test="AuthGPlus">
            <OAuthRequest
              {...bemClasses('link', 'main google')}
              title="google.com"
              rel="nofollow"
              socOauthName="google"
            >
              <span {...bemClasses('linkContent')}>
                <Icon {...bemClasses('icon')} type={iconTypes.socialGoogle} />
                <span {...bemClasses('text')} children="Google" />
              </span>
            </OAuthRequest>
          </div>
        </div>
      </div>
    );
  }
}
