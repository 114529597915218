/* eslint-disable */

import React, { Component } from 'react'
import Leaflet from 'leaflet';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import {withLeaflet} from 'react-leaflet';

/**
 * Helper class for send bbox to address validator
 */
class BboxInput extends Component {
  static propTypes = {
    // eslint-disable-next-line
    value: PropTypes.object,
  };

  static contextTypes = {
    map: PropTypes.any // eslint-disable-line
  }

  constructor(props, context) {
    super(props, context);
    this._map = props.leaflet.map;
    this.handleBboxChange = this.handleBboxChange.bind(this);
    this._map.on('load', this.handleBboxChange);
    this._map.on('zoomend', this.handleBboxChange);
    this._map.on('moveend', this.handleBboxChange);
  }

  componentDidMount() {
    this.props.onChange(this._map.getBounds().toGeoJSON());
  }

  componentWillUnmount() {
    this._map.off('load', this.handleBboxChange);
    this._map.off('zoomend', this.handleBboxChange);
    this._map.off('moveend', this.handleBboxChange);
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.value, this.props.value) || !this.props.value || !this.props.value.coordinates) {
      return null;
    }
    if (this.props.value.$fit) {
      const json = Leaflet.geoJSON(this.props.value);
      this._map.fitBounds(json.getBounds());
    }
  }

  handleBboxChange(evt) {
    this.props.onChange(evt.target.getBounds().toGeoJSON());
  }

  render() {
    return null;
  }

}

export default withLeaflet(BboxInput);
