/**
 * Created by Kotkin on 06.07.2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
// import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Bem from 'bemHelper';
import noScroll from 'helpers/noScroll';
import sensor from 'components/sensor';
import Icon, { iconTypes } from 'components/icon';

import FullScreenSlider from './fullscreen-slider';

import getUnnamedImg from './get-unnamed-img';

import 'styles/base/components/image/_image.scss';

const classes = new Bem('image');

/**
 * === Image Component ===
 * Renders image. Can show fullscreen Carousel of images combined in group.
 *
 * = Example =
 * <Image
 *   image={image object}
 *   fullScreenSwitch="click"
 *   group="lolo"
 * />
 *
 * = Images src =
 * Если в props.image нету url и ext: /images/{props.image.renderer}/{view}/{id}
 * Если в props.image есть url и ext: /images/{props.image.renderer}/{view}/{url}-{id}.{ext}
 *
*/

const screenSwitchOptions = {
  none: 'none',
  click: 'click',
  button: 'button'
};

const imageGroups = {};
const imgGroupData = {};

@sensor
export default class Image extends Component {
  static propTypes = {
    className: PropTypes.string,
    // Image object. Id and Renderer are required!
    image: PropTypes.shape({
      id: PropTypes.string,
      renderer: PropTypes.string,
      alt: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      ext: PropTypes.string
    }).isRequired,

    // Define possibility of viewing fullscreen image:
    //  - none(default) - not clickable;
    //  - button - click on top right button to see full-sized image;
    //  - click - whole image is clickable.
    fullScreenSwitch: PropTypes.oneOf(['none', 'button', 'click']),

    // Делает, чтоб картинка отображалась оригинаольного размера и целиком
    imgOrigin: PropTypes.bool,

    // seo props for img
    // seoPropsImg: PropTypes.shape({}),

    // Group name to combine images in carousel for full-screen view
    group: PropTypes.string,

    onClick: PropTypes.func,
  };

  static defaultProps = {
    fullScreenSwitch: 'click'
  };

  static getFullImageLink(image, device = 'desktop', idKey = 'id') {
    const id = image[idKey];
    const { ext } = image;
    const rExt = ext ? `.${ext}` : '';
    if (!id) return getUnnamedImg();
    return `/images/orig/full/${id}${rExt}`;
  }

  constructor(props, context) {
    super(props, context);
    this.imageClicked = null;
    this.state = {
      popup: null,
      device: 'desktop',
    };

    this.onResize = this.updateDimensions;

    this.updateDimensions = this.updateDimensions.bind(this);
    this.maximize = this.maximize.bind(this);
    this.toggleFullScreen = this.toggleFullScreen.bind(this);
    this.renderImage = this.renderImage.bind(this);
  }

  componentDidMount() {
    if (!this.sensor && this.props.sensor) {
      this.sensor = this.props.sensor;
    }
    this._addImageToGroup();
    this.updateDimensions(this.sensor.getRuntime());
  };

  componentDidUpdate(prevProps) {
    const { image, group } = this.props;

    if (!group || !__CLIENT__) {
      return;
    }

    if (!imageGroups[group]) {
      imageGroups[group] = [];
    }

    if (image.id !== prevProps.image.id) {
      const idx = imageGroups[group].indexOf(prevProps.image.id);
      if (idx > -1) {
        imageGroups[group][idx] = image.id;
        imgGroupData[image.id] = image;
      } else {
        this._addImageToGroup();
      }
    }
    imageGroups[group] = uniq(imageGroups[group]);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps.image.id, this.props.image.id) ||
      !isEqual(nextProps.fullScreenSwitch, this.props.fullScreenSwitch) ||
      !isEqual(nextState, this.state);
  }

  componentWillUnmount() {
    this._removeImageFromGroup(this.props)
  }

  _addImageToGroup() {
    const { group, image } = this.props;
    if (!__CLIENT__ || !group) {
      return;
    }
    if (!imageGroups[group]) {
      imageGroups[group] = [];
    }
    const idx = imageGroups[group].indexOf(image.id);
    if (idx === -1) {
      imageGroups[group].push(image.id);
      imgGroupData[image.id] = image;
    }
  }

  _removeImageFromGroup(props) {
    const { group, image } = props;
    if (!__CLIENT__ || !group || !imageGroups[group]) {
      return;
    }

    const idx = imageGroups[group].indexOf(image.id);
    delete imgGroupData[image.id];
    if (idx > -1) {
      imageGroups[group].splice(idx, 1);
    }
    if (imageGroups[group].length === 0) {
      delete imageGroups[group];
    }
  }

  updateDimensions({ media }) {
    const { device } = this.state;
    const { desktop, tablet, phone } = media;

    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  maximize(e, image) {
    e.preventDefault();

    this.imageClicked = image;
    if (__CLIENT__ && this.props.group) return this.toggleFullScreen(true);
  }

  toggleFullScreen(popup = null) {
    this.setState(
      { popup },
      () => noScroll()
    );
  }

  renderImage() {
    const { image, fullScreenSwitch, imgOrigin /* , seoPropsImg = {} */ } = this.props;
    const { id, title, url, ext } = image;
    const alt = image.alt || this.props.alt || '';
    const comProps = {
      href: Image.getFullImageLink(image, this.state.device)
    };

    if (isNil(id)) {
      return (
        <img
          {...classes('imgImage')}
          src={getUnnamedImg()}
          alt={alt}
          title={title}
          loading="lazy"
          decoding="async"
        />
      );
    }

    const srcFull = `/images/orig/full/${id}${ext ? `.${ext}` : ''}`;
    const srcThumb = imgOrigin ? srcFull : `/images/thumb/full/${id}.jpg`;
    const srcMThumb = imgOrigin ? srcFull : `/images/thumb/micro/${id}.jpg`;

    const picture = (
      <img
        {...classes('imgImage', (fullScreenSwitch === 'none') ? null : 'toFull')}
        src={srcFull}
        srcSet={`${srcFull} 2048w 3x, ${srcFull} 1024w 2x, ${srcThumb} 800w 1x`}
        sizes="(max-width: 1250px) 100vw, 900px"
        alt={alt}
        title={title}
        loading="lazy"
        decoding="async"
        onClick={fullScreenSwitch === 'none' ? null : e => this.maximize(e, image)}
        // {...seoPropsImg}
      />
    );

    if (fullScreenSwitch === screenSwitchOptions.button) {
      return (
        <div {...classes('content')}>
          {picture}
          <div {...classes('iconsContainer', 'top right')}>
            <a
              {...classes('btn', 'fullscreen')}
              {...comProps}
              title="Во весь экран"
            >
              <span {...classes('btnContent')}>
                <Icon {...classes('icon')} type={iconTypes.fullscreen} />
              </span>
            </a>
          </div>
        </div>
      );
    }

    return picture;
  }

  renderFullScreen() {
    const { image, group } = this.props;
    const index = imageGroups[group].indexOf(this.imageClicked.id);
    const images = map(imageGroups[group], (img) => {
      return { ...imgGroupData[img], id: img, src: Image.getFullImageLink( { id: img }, this.state.device) };
    });

    return (
      <FullScreenSlider
        images={images}
        selectedImage={index > -1 ? index : 0}
        onCloseCallback={this.toggleFullScreen}
      />
    );
  }

  render() {
    const { className, imgOrigin } = this.props;

    return (
      <div // eslint-disable-line
        {...classes(null, { imgOrigin }, className)}
        onClick={this.props.onClick}
      >
        {this.renderImage()}
        {this.state.popup && this.renderFullScreen()}
      </div>
    );
  }
}
