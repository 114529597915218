/**
 * Created by Kotkin on 02.08.2017.
 */
/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent, cloneElement, Fragment, Children } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import map from 'lodash/map';

const classes = new Bem('paginatorContainer');

class Container extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,

    // RenderComponent injected property name for DataProvider data
    injectPropName: PropTypes.string,

    // Pages data from DataProvider
    paginator: PropTypes.any, //eslint-disable-line

    // Amount of pages to show
    pagesAmount: PropTypes.number,

    // Objects per page
    limit: PropTypes.number,

    // Current page index
    currentPage: PropTypes.number,

    // Change page (to previous/next) function
    changePage: PropTypes.func,

    // Render two columns on page
    twoColumns: PropTypes.node,

    // Instead findDOMNode
    containerRef: PropTypes.func,

    // Content that renders after all items
    afterItemsContent: PropTypes.func
  };

  static defaultProps = {
    injectPropName: 'paginatorItem'
  };

  renderPageView() {
    const {
      children, paginator, injectPropName, afterItemsContent
    } = this.props;
    const itemRenderer = Children.toArray(children)[0];

    return (
      <Fragment>
        {map(paginator, (item, index) => (
          <ContainerItem
            key={index}
            item={item}
            injectPropName={injectPropName}
            itemRenderer={itemRenderer}
            number={index}
          />
        ))}
        {afterItemsContent && afterItemsContent()}
      </Fragment>
    );
  }

  render() {
    const {
      className, twoColumns, paginator,
      pagesAmount, changePage, currentPage, containerRef
    } = this.props;

    return (
      <div
        {...classes({ extra: className })}
        ref={(node) => {
          if (containerRef) containerRef(node);
        }}
        data-test="paginatorContainer"
      >
        {!twoColumns ?
          this.renderPageView() :
          cloneElement(
            twoColumns, {
              data: paginator,
              pagesProps: {
                pagesAmount,
                changePage,
                currentPage
              }
            })
        }
      </div>
    );
  }
}

class ContainerItem extends PureComponent {
  static propTypes = {
    // Компонент в пагинаторе (элемент списка/плитки)
    itemRenderer: PropTypes.node.isRequired,

    // Данные для itemRenderer из ДатаПровайдера
    item: PropTypes.shape({}),

    // Key for inserted item data
    injectPropName: PropTypes.string
  };

  render() {
    const { item, itemRenderer, injectPropName, number } = this.props;
    return cloneElement(itemRenderer, {
      [injectPropName]: item,
      ref: el => this.nodeEl = el,
      number
    });
  }
}

export default Container;
