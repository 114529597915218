import dateFormat from 'dateformat';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';

/* Локализация формата даты/времени */

const timeNames = [
  'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
];

const translates = {
  ru: {
    dayNames: [
      'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб',
      'Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'
    ],
    monthNames: [
      'янв', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сен', 'окт', 'ноя', 'дек',
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря',
    ],
    onlyMonthNames: [
      'январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'
    ],
    timeNames
  },
  ua: {
    dayNames: [
      'Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб',
      'Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота'
    ],
    monthNames: [
      'січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд',
      'січня', 'лютого', 'березня', 'квітня', 'травня', 'червня', 'липня', 'серпня', 'вересня', 'жовтня', 'листопада', 'грудня',
    ],
    onlyMonthNames: [
      'січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'
    ],
    timeNames
  }
};

const daysSegments = [];
daysSegments.year = 365;
daysSegments.month = 30;
daysSegments.week = 7;
daysSegments.day = 1;

/**
 * Функция формата даты.
 * Проверяет валидность даты и форматирует по правилу переданному в format.
 * Если нужно исключительно время в формате {'hh:MM'} можно передать render = 'time'.
 *
 * @param {date || string || number} date
 * @param {string} format
 * @param {['date', 'time']} render
 * @returns {null || string}
 */
export default function formatDate({ date, format = 'dd mmm yyyy', render = 'date' }, lang) {
  const parsedDate = Date.parse(date);

  if (isNaN(parsedDate)) return null;
  dateFormat.i18n = translates[lang] || translates['ru'];

  if (render === 'time') return dateFormat(parsedDate, 'HH:MM');

  return dateFormat(parsedDate, format);
}

export function getOnlyMonthAndYear(date, lang) {
  if (!date) return '';

  const parsedDate = new Date(Date.parse(date));
  const currentYear = getCurrentDateItems().currentYear;
  dateFormat.i18n = translates[lang] || translates['ru'];

  const month = get(dateFormat, `i18n.onlyMonthNames[${parsedDate.getMonth()}]`);
  const year = parsedDate.getFullYear();

  return year === currentYear ? month : `${month} ${year}`;
}

/**
 * Return a human's name of current month
 * @param format - f1 or f2
 */
export function getCurrentMonth(format = 'f1', lang) {
  const parsedDate = new Date();
  dateFormat.i18n = translates[lang] || translates['ru'];

  if (format === 'f1') {
    return get(dateFormat, `i18n.onlyMonthNames[${parsedDate.getMonth()}]`);
  }
  return get(dateFormat, `i18n.monthNames[${parsedDate.getMonth() + 12}]`);
}

// Сегодня + время
// Вчера
// И далее 1 неделю назад, 1 месяц назад ...
export function dateLife(date = '') {
  if (!date) return '';

  const diffDays = difDays(date);
  const daysSegments = [];

  daysSegments.year = 365;
  daysSegments.month = 30;
  daysSegments.week = 7;
  daysSegments.day = 1;

  for (let segment in daysSegments) { // eslint-disable-line
    if (daysSegments[segment] > diffDays && !(daysSegments[segment] === 1 && diffDays === 0)) {
      continue;  // eslint-disable-line
    }

    const amount = Math.floor(diffDays / daysSegments[segment]);

    return getSegmentName.call(this, segment, amount, date);
  }
}

// Сегодня + время
// Вчера + время
// 14 сентября
// И далее просто дата и месяц + год, год не текущий
export function dateLifeV2(date = '') {
  if (!date) return '';

  const diffDays = difDays(date);

  for (let segment in daysSegments) { // eslint-disable-line
    if (daysSegments[segment] > diffDays && !(daysSegments[segment] === 1 && diffDays === 0)) {
      continue;  // eslint-disable-line
    }

    const amount = Math.floor(diffDays / daysSegments[segment]);

    return getSegmentNameV2.call(this, segment, amount, date);
  }
}

export function daysDifAndTime(date = '') {
  if (!date) return '';

  const diffDays = difDays(date);
  const segmentName = dateLife.call(this, date);

  if (diffDays > 2) return formatDate({date: get(date.split(' '), '[0]'), format: 'dd.mm.yyyy'});

  return segmentName;
}

export function difDays(date) {
  if (!date) return '';

  const date_life = new Date(get(date.split(' '), '[0]'));
  const today = new Date();
  const timeDiff = Math.abs(today.getTime() - date_life.getTime());
  const millisecond = 1000 * 3600 * 24;
  let diffDays = 0;

  if (today.getDay() !== date_life.getDay()) {
    diffDays = Math.ceil(timeDiff / millisecond);
  } else {
    diffDays = Math.floor(timeDiff / millisecond);
  }
  return diffDays;
}

export function difDaysWithoutAbs(date) {
  if (!date) return '';

  const date_life = new Date(get(date.split(' '), '[0]'));
  const today = new Date();
  const timeDiff = today.getTime() - date_life.getTime();
  const millisecond = 1000 * 3600 * 24;
  let diffDays = 0;

  if (today.getDay() !== date_life.getDay()) {
    diffDays = Math.ceil(timeDiff / millisecond);
  } else {
    diffDays = Math.floor(timeDiff / millisecond);
  }
  return diffDays;
}

export function getCurrentDateItems() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentQuarter = Math.floor((today.getMonth() + 3) / 3);
  const currentMonth = today.getMonth() + 1;

  return {
    currentYear, currentQuarter, currentMonth
  };
}

function getSegmentName(segment = '', amount = 0, date) {
  if (!segment || amount == null) return '';

  const { i18n } = this;
  let result = '';

  if (segment === 'year') {
    if (amount === 1) {
      result = `${amount} ${i18n('year')}`;
    } else if (amount === 2 || amount === 3 || amount === 4) {
      result = `${amount} ${i18n('yearDeclination')}`;
    } else {
      result = `${amount} ${i18n('yearMultiple')}`;
    }
  } else if (segment === 'month') {
    if (amount === 1) {
      result = `${amount} ${i18n('month')}`;
    } else if (amount === 2 || amount === 3 || amount === 4) {
      result = `${amount} ${i18n('monthDeclination')}`;
    } else {
      result = `${amount} ${i18n('monthMultiple')}`;
    }
  } else if (segment === 'week') {
    if (amount === 1) {
      result = `${amount} ${i18n('week')}`;
    } else if (amount === 2 || amount === 3 || amount === 4) {
      result = `${amount} ${i18n('weekDeclination')}`;
    }
  } else if (segment === 'day') {
    if (amount === 0) {
      result = i18n('today');
    } else if (amount === 1) {
      result = i18n('yesterday');
    } else if (amount === 2) {
      result = i18n('dayBeforeYesterday');
    } else if (amount === 3 || amount === 4) {
      result = `${amount} ${i18n('dayDeclination')}`;
    } else if (amount === 5 || amount === 6) {
      result = `${amount} ${i18n('dayMultiple')}`;
    }
  }
  if (
    result !== i18n('today') &&
    result !== i18n('yesterday') &&
    result !== i18n('dayBeforeYesterday')
  ) {
    return `${result} ${i18n('ago')}`;
  }
  if (
    result === i18n('today')
  ) {
    let time = get(date.split('T'), '[1]');
    if (time) {
      time = time.substring(0, 5);
      return `${result}, ${time}`;
    }
  }

  return result;
}

function getSegmentNameV2(segment = '', amount = 0, date) {
  if (!segment || amount == null) return '';

  const { i18n } = this;
  let result = '';
  if (segment === 'day' && amount <= 2) {
    if (amount === 0) {
      result = i18n('today');
    } else if (amount === 1) {
      result = i18n('yesterday');
    } else if (amount === 2) {
      result = i18n('dayBeforeYesterday');
    }
    let time = get(date.split('T'), '[1]');
    if (time) {
      time = time.substring(0, 5);
      return `${result}, ${time}`;
    }
  } else {
    const lang = this && this.i18nGetLang ? this.i18nGetLang() : 'ru';
    const today = new Date();
    const currentYear = today.getFullYear();
    const parsedDate = new Date(date);
    const dateYear = parsedDate.getFullYear();
    if (currentYear === dateYear) {
      //вывод даты без года
      return formatDate({ date: date.split(' '), format: 'dd mmmm' }, lang);
    } else {
      //вывод даты с годом
      return formatDate({ date: date.split(' '), format: 'dd mmmm yyyy' }, lang);
    }
  }
}

/**
 * Add days to date
 * @param date
 * @param days
 */
export function addDays(date, days) {
  const dat = new Date(date.valueOf());

  dat.setDate(dat.getDate() + days);

  return dat;
}
