import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'react-schemaorg';
import map from 'lodash/map';
import Bem from 'bemHelper';
import absoluteUrl from 'helpers/absoluteUrl';
import { I18nPropHoc, langIsoMapBack } from 'helpers/i18n';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';

import 'styles/base/components/breadcrumbs/_breadcrumbs.scss';

const classes = new Bem('breadCrumbs');

@I18nPropHoc()
export default class Breadcrumbs extends Component {
  static propTypes = {
    className: PropTypes.string,
    replace: PropTypes.shape({}),
    data: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
        builder: PropTypes.func,
        builderProps: PropTypes.shape({})
      })
    ),
    noJson: PropTypes.bool
  };

  renderCrumb = (item, key, isLast, toJson = false) => {
    const { replace } = this.props;
    const linkProps = {};
    let { name } = item;

    if (replace) {
      if (replace[name]) {
        name = replace[name];
      }
    }

    if (item.builder) {
      linkProps.builder = item.builder;
      linkProps.builderProps = item.builderProps;
    }

    if (item.url) {
      linkProps.href = item.url;
      linkProps.title = name;
    }

    if (toJson) {
      let urlFinal = item.url;

      if (item.builder) {
        const injectProps = {};
        const { nonDefaultLang } = this.props;
        injectProps.langPrefix = nonDefaultLang ? `${langIsoMapBack[nonDefaultLang]}/` : '';
        urlFinal = item.builder({ ...injectProps, ...item.builderProps }).href;
      }

      return {
        '@type': 'ListItem',
        name,
        position: key + 1,
        item: absoluteUrl(urlFinal)
      };
    }

    const linkContent = (
      <span {...classes('linkContent')}>
        <span {...classes('text', { last: isLast, link: !isLast })} children={name} />
      </span>
    );
    const itemContent = isLast ?
      <span {...classes('link')} title={name} children={linkContent} /> :
      (<>
        <Link
          {...classes('link')}
          {...linkProps}
          children={linkContent}
        />
        <Icon {...classes('icon', 'arrow')} type={iconTypes.arrowSliderRight} />
      </>);

    return <li {...classes('item')} key={key} children={itemContent} />;
  };

  render() {
    const { className, data, noJson } = this.props;

    if (!data || data.length === 0) return null;

    const jsonLd = !noJson && (
      <JsonLd
        item={{
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: map(data, (item, key) =>
            this.renderCrumb(item, key, key === data.length - 1, true)
          )
        }}
      />
    );

    return (
      <>
        <ol
          {...classes({ extra: className })}
          children={map(data, (item, key) => this.renderCrumb(item, key, key === data.length - 1))}
        />
        {jsonLd}
      </>
    );
  }
}
