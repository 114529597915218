import LandingLink from './blocks/landingLink';

/**
 * Content blocks general list
 * Must be overrided in project-specific configs
 *
 * Available params:
 *   - name: Human's name of the content block
 *   - provider - Data provider, that will be used for block data retrieve
 *   - controller - Data controller, that will be used for block editing
 *   - views - Different block views, format:
 *     viewKey:
 *       - name - Human's name of the view
 *       - component - Component, that renders a block.
 *       - props - Advanced props, that will be send as props to view component
 *   - orders - array, that define block provider orders.
 *     Must be an array (not object!) with structure:
 *      - id - order ID, that starts with 0
 *      - value - human's order name
 *      - param - array in data provider's order format,
 *        such as [['order1', 'asc'], ['order2', 'asc']]
 */

const config = {
  landingLink: {
    name: 'Посадочные страницы',
    provider: 'core/landings',
    controller: 'admin/content-blocks/landings',
    views: {
      default: {
        name: 'По умолчанию',
        component: LandingLink
      }
    }
  },
  // This block must be defined directly in project
  newbuildsList: {
    name: 'Новостройки/Коттеджные городки'
  },
  // This block must be defined directly in project
  nbStocksList: {
    name: 'Акции новостроек'
  },
  bannerLcBlock: {
    name: 'Баннеры новостроек'
  },
  bannerList: {
    name: 'Баннеры',
  },
  lastTopics: {
    name: 'Последние обсуждения в Категории Форума',
  },
  news: {
    name: 'Новости',
  },
  developers: {
    name: 'Застройщики',
  },
  userAdvertList: {
    name: 'Реклама на форуме',
  },
  staticBlock: {
    name: 'Статические блоки'
  }
};

export default config;
