export const FIELD_INT = 'RuleInt';
export const FIELD_FLOAT = 'RuleFloat';
export const FIELD_STRING = 'RuleString';

// Mode constant values must be match on backend and frontend controllers
export const MODE_UNKNOWN = 'unknown';
export const MODE_INSERT = 'insert';
export const MODE_UPDATE = 'update';

export const VALIDATION_VALID = 'valid';
export const VALIDATION_INVALID = 'invalid';
