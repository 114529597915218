/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import each from 'lodash/each';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import DataProvider from 'api/data-provider';
import { rawEvaluate } from 'helpers/templateParser';
import { subscribe, unsubscribe } from 'helpers/global-events';

import { evaluateVars } from './preloader';
import EditorButton from './editorButton';
import DefaultWrapper from './defaultWrapper';
import defaultConfig from './config';

import 'styles/base/containers/content-block/_admin-editor.scss';

export default class Block extends Component {
  static propTypes = {
    // Project-specific blocks config object
    config: PropTypes.shape().isRequired,
    // Block place name. Must be unique in page
    place: PropTypes.string.isRequired,
    // Defined by block preloader properties
    params: PropTypes.shape({}).isRequired,
    places: PropTypes.shape({
      data: PropTypes.shape({})
    }).isRequired,
    content: PropTypes.shape({}).isRequired,

    // Wrap block with this component, if block with content exists
    wrapper: PropTypes.func,
    // Wrapper props types
    wrapperProps: PropTypes.shape({}),

    // Show block if content is empty
    showWithoutContent: PropTypes.bool
    // If any other props defined - this props will override or will be added
    // to variables from preloader, and query and props templates will be recompiled
  };

  static defaultProps = {
    showWithoutContent: false
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.firstRender = true;
  }

  componentDidMount() {
    this.firstRender = false;
    subscribe('gLangChanged', this._langChangeRerequest);
  }

  shouldComponentUpdate(nextProps) {
    const oldPlaces = this.props.places || { data: {} };
    const newPlaces = nextProps.places || { data: {} };
    const oldPlaceData = oldPlaces.data[this.props.place] || {};
    const newPlaceData = newPlaces.data[nextProps.place] || {};

    if (!isEqual(oldPlaceData.parsedQuery, newPlaceData.parsedQuery)) return true;
    if (!isEqual(oldPlaceData.parsedProps, newPlaceData.parsedProps)) return true;
    if (!isEqual(oldPlaceData.parsedCondition, newPlaceData.parsedCondition)) return true;
    // if (!isEqual(oldPlaceData.parsedCondition, newPlaceData.parsedCondition)) return true;

    // Compare otherProps
    let differ = false;
    each(this.props, (val, key) => {
      if (['place', 'places', 'content', 'wrapper', 'wrapperProps'].indexOf(key) > -1) return;
      if (!isEqual(this.props[key], nextProps[key])) differ = true;
    });

    return differ;
  }

  componentWillUnmount() {
    unsubscribe('gLangChanged', this._langChangeRerequest);
  }

  _langChangeRerequest = () => {
    this.forceUpdate();
  }

  render() {
    const {
      config = {},
      places = {},
      params = {},
      content = {},
      place,
      wrapper,
      wrapperProps,
      showWithoutContent,
      ...otherProps
    } = this.props;
    const { withContainer = true, classNameWrapper = '', external = false, gtmProps = {}, location } = otherProps;

    let blockContent = content[`${place}Content`] ? content[`${place}Content`] : null;
    const placesData = places.data || {};
    const placeData = placesData[place] || {};
    const blockConfig = {
      ...defaultConfig[placeData.type],
      ...config[placeData.type]
    };
    const BlockComponent =
      (!isEmpty(blockConfig) && get(blockConfig, `views[${placeData.view}]`)) ?
        blockConfig.views[placeData.view].component :
        null;

    const blockViewProps =
      (!isEmpty(blockConfig) && get(blockConfig, `views[${placeData.view}]`)) ?
        blockConfig.views[placeData.view].props :
        {};

    let blockQuery = placeData.parsedQuery;
    let blockProps = placeData.parsedProps;
    let blockCondition = placeData.parsedCondition;

    if (!isEmpty(otherProps)) {
      const vars = {};
      merge(vars, places.vars, otherProps);
      blockQuery = evaluateVars(placeData.query, vars, true);
      blockProps = evaluateVars(placeData.props, vars);

      if (!isEqual(blockQuery, placeData.parsedQuery) && this.firstRender) {
        blockContent = null;
      }
      if (!isEqual(blockProps, placeData.parsedProps) && this.firstRender) {
        blockContent = null;
      }

      try {
        blockCondition = rawEvaluate(placeData.condition, vars);
      } catch (e) {
        blockCondition = false;
        console.error('Block condition parse failed');
      }
    }

    const blockVisible = !placeData.condition || (placeData.condition && blockCondition);

    if (blockConfig && blockConfig.orders && typeof blockProps.orderId !== 'undefined') {
      if (isObject(blockConfig.orders[blockProps.orderId])) {
        blockQuery.order = blockConfig.orders[blockProps.orderId].param;
      }
    }

    const BlockWrapper = wrapper || DefaultWrapper;
    const BlockRendererParams = { ...blockProps, ...gtmProps };
    if (location) BlockRendererParams.location = location;

    const blockComponentContent = (
      <>
        <EditorButton
          place={place}
          places={places}
          params={params}
          content={content}
          projectConfig={config}
          overridedVars={otherProps}
        />
        {BlockComponent && blockConfig && blockConfig.provider && blockVisible &&
          <DataProvider
            injectPropName="content"
            url={blockConfig.provider}
            cache={60}
            queryJson
            query={blockQuery}
            preloaded={blockContent}
          >
            <BlockRenderer
              BlockComponent={BlockComponent}
              Wrapper={BlockWrapper}
              wrapperProps={wrapperProps}
              params={BlockRendererParams}
              viewProps={blockViewProps}
              showWithoutContent={showWithoutContent}
              external={external}
            />
          </DataProvider>
        }
        {BlockComponent && !get(blockConfig, 'provider') && blockVisible &&
          <BlockRenderer
            BlockComponent={BlockComponent}
            Wrapper={BlockWrapper}
            params={BlockRendererParams}
            wrapperProps={wrapperProps}
            viewProps={blockViewProps}
            showWithoutContent
            external={external}
          />
        }
      </>
    );

    return (
      withContainer ?
        <div
          className={`containerEditorAdmin ${classNameWrapper}`}
          children={blockComponentContent}
        /> :
        blockComponentContent
    );
  }
}

class BlockRenderer extends Component {
  static propTypes = {
    Wrapper: PropTypes.func,
    BlockComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    wrapperProps: PropTypes.shape({}),
    viewProps: PropTypes.shape({}),
    content: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.shape({})
    ]),
    params: PropTypes.shape({}),
    showWithoutContent: PropTypes.bool,
    external: PropTypes.bool
  };

  render() {
    const {
      Wrapper, BlockComponent, wrapperProps, content, params,
      viewProps, showWithoutContent, external
    } = this.props;

    if (!showWithoutContent && (!content || content.length === 0)) {
      return null;
    }

    return (
      <Wrapper
        content={content}
        params={params}
        {...wrapperProps}
      >
        <BlockComponent
          content={content}
          params={params}
          external={external}
          {...viewProps}
        />
      </Wrapper>
    );
  }
}
