/**
 * Created by Kotkin on 13.06.2017.
 */
import React, { Component, Children } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import uniqueId from 'lodash/uniqueId';
import includes from 'lodash/includes';
import { preventScrolling } from 'helpers/noScroll';
import simpleStore from 'helpers/simpleStore';
import {
  subscribe,
  unsubscribe,
  ALL_MODALS_CLOSE
} from 'helpers/global-events';
import Row, { Col } from 'components/row';
import Button, { btnThemes } from 'components/button';

import ContentModal from './contentModal';
import FooterModal from './footerModal';

import 'styles/base/components/modal/_modal.scss';

const bemClasses = new Bem('modal');
const bemClassesModalAskToClose = new Bem('modalAskToClose');
const sizes = [
  'small',
  'medium',
  'big',
  'content',
  'full',
  'adminka'
];

/**
 *
 * === Modal component ===
 *
 * = Context =
 * close - closing modal window method for Header and Footer children-components
 *
 * = Note =
 * Modal window can be opened/closed using:
 * 1. Open/Close methods - via ref;
 * 2. Passing prop - 'opened'.
 * 3. Passing prop 'renderModal' - opens modal with animation.
 *
 * = Example =
 * <Modal
 *   onCloseRequired={onCloseRequired}
 *   size="adminka"
 *   renderModal
 * >
 *   <Header closeButton>
 *     Header content
 *   </Header>
 *   <Content adaptiveHeight>Trololo</Content>
 *   <Footer>
 *     <Button close>Close</Button>
 *     <Button type="submit">Ok</Button>
 *   </Footer>
 * </Modal>
 *
 */

export default class Modal extends Component {
  static propTypes = {
    className: PropTypes.string,

    // Should contain Header, Content and Footer (optionally)
    children: PropTypes.node,

    // Required modal size
    size: PropTypes.oneOf(sizes).isRequired,

    // Modal height: 100%
    hFull: PropTypes.bool,

    // Open/close parameter
    opened: PropTypes.bool,

    // If true - modal will open with animation
    // Note: to close with animation should have onCloseRequired method
    renderModal: PropTypes.bool,

    // Removes 'notification' className for handling reactClickOutside inside modal
    disableNotification: PropTypes.bool,

    // Callback after closing modal
    onCloseRequired: PropTypes.func,

    // Global event name. Close modal when emitted.
    closeEvent: PropTypes.string,
    askToClose: PropTypes.bool,
    // Do not close modal on clickOutside event
    noClickOutside: PropTypes.bool
  };

  static defaultProps = {
    opened: false,
    noClickOutside: false
  };

  static childContextTypes = {
    close: PropTypes.func
  };

  static renderSingleChild(props) {
    const children = Children.toArray(props.children);
    return children[0] || null;
  }

  static getModalIds() {
    return simpleStore.get('registeredModals') || [];
  }

  static getDerivedStateFromProps(props) {
    return {
      renderModal: props.renderModal,
      isModalOpenProps: props.opened
    };
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      showFormAskToClose: false
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.registerModalId = this.registerModalId.bind(this);

    this.modalId = uniqueId();
  }

  getChildContext() {
    return {
      close: this.close
    };
  }

  componentDidMount() {
    subscribe(ALL_MODALS_CLOSE, this.close);
    if (this.props.closeEvent) subscribe(this.props.closeEvent, this.close);

    if (this.props.renderModal) this.open();
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentDidUpdate() {
    this.approveNeeded = this.props.askToClose;
  }

  componentWillUnmount() {
    unsubscribe(ALL_MODALS_CLOSE, this.close);
    if (this.props.closeEvent) unsubscribe(this.props.closeEvent, this.close);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  registerModalId(unregister = false) {
    const registeredModals = Modal.getModalIds();
    if (!unregister) {
      registeredModals.push(this.modalId);
    } else {
      const index = registeredModals.indexOf(this.modalId);
      registeredModals.splice(index, 1);
    }
    simpleStore.set('registeredModals', registeredModals);
  }

  escFunction(event) {
    const registeredModals = Modal.getModalIds();
    const length = registeredModals.length - 1;
    let last = null;
    if (length >= 0) last = registeredModals[length];

    if (event.keyCode === 27 && last === this.modalId) {
      this.close();
    }
  }

  toggleModal(opened) {
    this.registerModalId(!opened);

    this.setState({ isModalOpenHandle: opened });
    preventScrolling();
  }

  handleClickOutside(e) {
    e.preventDefault();
    if (this.props.noClickOutside) {
      return;
    }

    if (!this.modalRef.contains(e.target)) {
      return this.close();
    }
  }

  open() {
    this.registerModalId();

    this.setState({ isModalOpenHandle: true }, () => preventScrolling());
  }

  approveToClose =()=> {
    this.approveNeeded = false;
    this.setState({ showFormAskToClose: false });
    this.close();
  };

  stayInModal =()=> {
    this.setState({ showFormAskToClose: false });
  };

  close() {
    const { renderModal, onCloseRequired, askToClose } = this.props;
    if (askToClose && this.approveNeeded) {
      return this.setState({ showFormAskToClose: true });
    }
    if (onCloseRequired) {
      if (renderModal) return this.renderModal(false);
      onCloseRequired();
    }
    this.toggleModal(false);
  }

  renderModal(renderModal, extraAction = false) {
    if (!renderModal) {
      this.toggleModal(false);
      this.props.onCloseRequired(null, extraAction);
    }
  }

  renderFormAskToClose() {
    const wrapperClassName = `modalWrapper modalWrapper_small ${bemClassesModalAskToClose().className}`;

    return (
      <div className={wrapperClassName}>
        <div {...bemClasses(null, 'small')}>
          <ContentModal>
            <div>
              <div><span {...bemClassesModalAskToClose('text')} children="Вы действительно хотите уйти со страницы?" /></div>
              <div><span {...bemClassesModalAskToClose('text')} children="Все изменения будут потеряны!" /></div>
            </div>
          </ContentModal>
          <FooterModal>
            <Row>
              <Col allPr={0}>
                <Button
                  label="Да"
                  onClick={this.approveToClose}
                  theme={btnThemes.light}
                />
              </Col>
              <Col allFlex allJustifyCFlexEnd allPl={20} allPr={0}>
                <Button
                  label="Нет"
                  onClick={this.stayInModal}
                  theme={btnThemes.sky}
                />
              </Col>
            </Row>
          </FooterModal>
        </div>
      </div>
    );
  }

  render() {
    const { children, size, hFull, disableNotification, className } = this.props;
    let wrapperClassName = `modalWrapper modalWrapper_${size} ${className || ''}`.trim();

    if (!disableNotification) wrapperClassName += ' notification';

    return (
      ReactDOM.createPortal(
        <>
          {(
            (this.state.isModalOpenProps || this.state.isModalOpenHandle || this.state.hOpen) &&
              includes(sizes, size)
          ) ?
            <div //eslint-disable-line
              className={wrapperClassName}
              onClick={this.handleClickOutside}
            >
              <div
                {...bemClasses(null, {[size]: size, hFull})}
                ref={ref => this.modalRef = ref}
              >
                {this.state.showFormAskToClose && this.renderFormAskToClose()}
                {children}
              </div>
            </div> :
            <div />
          }
        </>,
        document.getElementById('content')
      )
    );
  }
}
