/* eslint-disable react/no-multi-comp */
import React, { PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import { canGoNext } from './utils/innerSliderUtils';

const bemClasses = new Bem('slickSlider');

class AbstractArrow extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    slideCount: PropTypes.number,
    // eslint-disable-next-line
    slidesToShow: PropTypes.number,
    // eslint-disable-next-line
    currentSlide: PropTypes.number,
    // eslint-disable-next-line
    infinite: PropTypes.bool,
    clickHandler: PropTypes.func
  };

  getArrowProps(props = {}, next = false) {
    const { infinite, currentSlide, slideCount, slidesToShow } = props;
    const arrowClasses = { prev: !next, next };
    let arrowHandler = this.clickHandler;

    if (
      (!next && !infinite &&
      (currentSlide === 0 || slideCount <= slidesToShow)) ||
      (next && !canGoNext(props))
    ) {
      arrowClasses.disabled = true;
      arrowHandler = null;
    }

    return {
      ...bemClasses('arrow', arrowClasses),
      key: next ? '1' : '0',
      'data-role': 'none',
      onClick: arrowHandler
    };
  }

  clickHandler(options, e) {
    if (e) e.preventDefault();
    this.props.clickHandler(options, e);
  }
}

export class PrevArrow extends AbstractArrow {
  static propTypes = {
    ...AbstractArrow.props,
    prevArrow: PropTypes.node
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.clickHandler = this.clickHandler.bind(this, { message: 'previous' });
  }

  renderArrow() {
    const { prevArrow, currentSlide, slideCount } = this.props;
    const arrowProps = this.getArrowProps(this.props);

    if (prevArrow) {
      return (
        cloneElement(prevArrow, {
          ...arrowProps,
          currentSlide,
          slideCount
        })
      );
    }

    return (
      <Button
        key="0"
        type="button"
        iconType={iconTypes.arrowSliderLeft}
        {...arrowProps}
      />
    );
  }

  render() {
    return this.renderArrow();
  }
}

export class NextArrow extends AbstractArrow {
  static propTypes = {
    ...AbstractArrow.props,
    nextArrow: PropTypes.node
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.clickHandler = this.clickHandler.bind(this, { message: 'next' });
  }

  renderArrow() {
    const { nextArrow, currentSlide, slideCount } = this.props;
    const arrowProps = this.getArrowProps(this.props, true);

    if (nextArrow) {
      return (
        cloneElement(nextArrow, {
          ...arrowProps,
          currentSlide,
          slideCount
        })
      );
    }

    return (
      <Button
        key="1"
        type="button"
        iconType={iconTypes.arrowSliderRight}
        {...arrowProps}
      />
    );
  }

  render() {
    return this.renderArrow();
  }
}
