/**
 * Created by Vit on 11.08.2017.
 */
/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import CSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import getUid from 'uniqueId';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import toNumber from 'lodash/toNumber';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import sensor from 'components/sensor';
import Link from 'components/link';
import Image, { Thumb } from 'components/image';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

const classesBem = new Bem('thumbSlider');
const classesImageSlider = new Bem('imageSlider');
const classesStokesSlider = new Bem('stokesSlider');

/**
 * === PictureSlider Component ===
 * Renders small carousel with images/thumbs.
 *
 * = Note =
 * Only one (active) image exists in DOM
 *
 * = Example =
 * <PictureSlider
 *   {...classesBem('pictureSlider')
 *   imagesCount={11}
 *   titleImage={titleImage}
 *   images={images}
 *   header={header}
 *   showISlidermagesCount
 *   renderImageSlider
 * >
 *   <div {...classes('onPhoto')>
 *     <div
 *       {...classes('iconsContainer', 'top right')
 *       children={isPhone && this.showOnMapBtn(isPhone)}
 *     />
 *   </div>
 * </PictureSlider>
 *
 * <PictureSlider
 *   {...classes('pictureSlider')
 *   imagesCount={11}
 *   titleImage={titleImage}
 *   images={images}
 *   header={header}
 *   showImagesCount
 * />
 *
 */

const cssTransitionConfig = {
  enter: 'enter',
  leave: 'leave',
  classes: {
    up: 'Up',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    fadeIn: 'FadeIn',
    fadeOut: 'FadeOut'
  }
};

@sensor
@getUid
class PictureSlider extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    // Render additional elements on Slider
    children: PropTypes.node,

    // Amount of images
    imagesCount: PropTypes.number,

    // Main image to show at first render. Id is required!
    titleImage: PropTypes.shape({
      id: PropTypes.string
    }),

    // Array of image objects. Id is required!
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    ),

    // Array of stocks to slide in carousel
    stocksData: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.oneOfType([
        PropTypes.shape({ id: PropTypes.string, renderer: PropTypes.string }),
        PropTypes.string
      ]),
      stocks: PropTypes.shape({})
    })),

    // Set limit of dots under slider
    dotsLimit: PropTypes.number,

    // Set link to go after click on Slider
    urlBuilder: PropTypes.func,
    urlBuilderProps: PropTypes.shape({}),

    // Set header (title) on Slider
    header: PropTypes.string,

    // Show/not to show header text
    showHeaderText: PropTypes.bool,

    // Show/not to show index of active image out of general images amount (1/6)
    showImagesCount: PropTypes.bool,

    // Not to show Slider arrows
    hideSliderControls: PropTypes.bool,

    // Not to show pagination dots
    hideSliderPagination: PropTypes.bool,

    // Render slider with Images instead of Thumbs
    renderImageSlider: PropTypes.bool
  };

  static defaultProps = {
    images: [],
    dotsLimit: 5
  };

  static setTransitionClass(dataBlock, action, direction) {
    const start = `${dataBlock}__picMain_${action}${direction}`;
    const end = `${start}Active`;
    return {
      [action]: start,
      [`${action}Active`]: end
    };
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.changeImage = this.changeImage.bind(this);
    this.updateComponent = this.updateComponent.bind(this);
    this.touchStartHandle = this.touchStartHandle.bind(this);
    this.touchEndHandle = this.touchEndHandle.bind(this);

    this.onResize = this.updateComponent;

    this.state = {
      desktop: false,
      phone: false,
      pagination: false,
      imageCurrent: null,
      stockCurrent: null,
      transitionEnter: { enter: null, enterActive: null },
      transitionLeave: { leave: null, leaveActive: null }
    };
    this.index = 0;
    this.mainImageID = null;
    this.images = [];
    this.stocksMode = !!props.stocksData;
    this.dotsArray = [];
    this.dotsIndex = 0;
  }

  UNSAFE_componentWillMount() {
    if (!this.stocksMode) {
      const { titleImage } = this.props;
      const images = cloneDeep(this.props.images);
      this.mainImageID = this.getUid();
      this.images = (images && images.length) ?
        map(images, (image, index) => {
          if (!index) image.uniqID = this.mainImageID; else image.uniqID = this.getUid(); //eslint-disable-line
          return image;
        }) :
        [{ id: null, uniqID: this.mainImageID }];
      this.setState({
        pagination: !!this.images.length,
        imageCurrent: (titleImage && titleImage.id)
          ? { ...titleImage, uniqID: this.mainImageID } :
          { ...titleImage, id: null, uniqID: this.mainImageID }
      });
    } else {
      this.setState({
        stockCurrent: this.props.stocksData.length ?
          this.props.stocksData[this.index] :
          { id: null, uniqID: this.mainImageID }
      });
      this.dotsArray.length = this.props.dotsLimit;
    }
  }

  componentDidMount() {
    if (this.images.length > 1 || (this.stocksMode && this.props.stocksData.length > 1)) {
      this.node.addEventListener('touchstart', this.touchStartHandle, false);
      this.node.addEventListener('touchend', this.touchEndHandle, false);
    }
    this.updateComponent(this.sensor.getRuntime());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { enter, leave, classes } = cssTransitionConfig;
    let state = { ...this.state };

    if (!this.stocksMode) {
      if (
        !isEqual(omit(nextProps, ['children', 'hideSliderControls']),
          omit(this.props, ['children', 'hideSliderControls']))
      ) {
        const images = cloneDeep(nextProps.images);
        const { titleImage } = nextProps;
        const activeDataBlock = this.props.renderImageSlider ? 'imageSlider' : 'thumbSlider';
        state = {
          ...state,
          pagination: !!images.length,
          imageCurrent: (titleImage && titleImage.id)
            ? { ...titleImage, uniqID: this.mainImageID } :
            { ...titleImage, id: null, uniqID: this.mainImageID },
          transitionEnter: PictureSlider.setTransitionClass(activeDataBlock, enter, classes.fadeIn),
          transitionLeave: PictureSlider.setTransitionClass(activeDataBlock, leave, classes.fadeOut)
        };
        this.mainImageID = this.getUid();
        this.images = (images && images.length) ?
          map(cloneDeep(images), (image, index) => {
            if (!index) image.uniqID = this.mainImageID; else image.uniqID = this.getUid(); //eslint-disable-line
            return image;
          }) :
          [{ id: null, uniqID: this.mainImageID }];
        this.index = 0;
      }
    } else if (this.stocksMode && !isEqual(omit(nextProps, ['children']), omit(this.props, ['children']))) {
      state = {
        ...state,
        stockCurrent: this.props.stocksData[0],
        transitionEnter: PictureSlider.setTransitionClass('stocksSlider', enter, classes.fadeIn),
        transitionLeave: PictureSlider.setTransitionClass('stocksSlider', leave, classes.fadeOut)
      };
      this.index = 0;
      this.dotsIndex = 0;
    }
    if (!isEqual(state, this.state)) return this.setState(state);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.node && !this.stocksMode &&
      !isEqual(prevProps.images, this.props.images) &&
      this.props.images.length > 1) ||
      (this.node && this.stocksMode &&
      !isEqual(prevProps.stocksData, this.props.stocksData) &&
      this.props.stocksData.length > 1)
    ) {
      this.node.addEventListener('touchstart', this.touchStartHandle, false);
      this.node.addEventListener('touchend', this.touchEndHandle, false);
    } else if (
      (this.node && !this.stocksMode && this.props.images.length < 1) ||
      (this.node && this.stocksMode && this.props.stocksData.length < 1)
    ) {
      this.node.removeEventListener('touchstart', this.touchStartHandle);
      this.node.removeEventListener('touchend', this.touchEndHandle);
    }
    this.updateComponent(this.sensor.getRuntime());
  }

  componentWillUnmount() {
    if (this.node) {
      this.node.removeEventListener('touchstart', this.touchStartHandle);
      this.node.removeEventListener('touchend', this.touchEndHandle);
    }
  }

  touchStartHandle(event) {
    this.startTime = (new Date()).getTime();
    this.startX = event.changedTouches[0].pageX;
  }

  touchEndHandle(event) {
    const diffX = this.startX - event.changedTouches[0].pageX;
    const elapsed = (new Date()).getTime() - this.startTime;
    if (elapsed < 500 && Math.abs(diffX) > 50) {
      if (diffX < 0) return this.slideImage(-1, this.index);
      return this.slideImage(1, this.index);
    }
  }

  updateComponent({ media: { desktop, phone } = {} }) {
    let state = { ...this.state };
    if (desktop !== state.desktop) state = { ...state, desktop };
    if (phone !== state.phone) state = { ...state, phone };

    if (!isEqual(state, this.state)) this.setState(state);
  }

  changeImage(imageCurrent, direction = 0) {
    const { enter, leave, classes } = cssTransitionConfig;
    const { dotsLimit } = this.props;
    this.index = (!this.stocksMode ?
      findIndex(this.images, image => image.uniqID === imageCurrent.uniqID) :
      findIndex(this.props.stocksData, stock => stock.id === imageCurrent.id)
    );
    if (!this.stocksMode && imageCurrent !== this.state.imageCurrent) {
      const neededClass = direction >= 0 ? classes.left : classes.right;
      const currentDataBlock = this.props.renderImageSlider ? 'imageSlider' : 'thumbSlider';
      this.setState({
        imageCurrent,
        transitionEnter: PictureSlider.setTransitionClass(currentDataBlock, enter, neededClass),
        transitionLeave: PictureSlider.setTransitionClass(currentDataBlock, leave, neededClass)
      });
    } else if (this.stocksMode && imageCurrent !== this.state.stockCurrent) {
      if (direction >= 0) {
        this.dotsIndex = this.dotsIndex === dotsLimit - 1 ? 0 : this.dotsIndex += 1;
      } else {
        this.dotsIndex = this.dotsIndex === 0 ? dotsLimit - 1 : this.dotsIndex -= 1;
      }
      const neededClass = direction >= 0 ? classes.left : classes.right;
      this.setState({
        stockCurrent: imageCurrent,
        transitionEnter: PictureSlider.setTransitionClass('stokesSlider', enter, neededClass),
        transitionLeave: PictureSlider.setTransitionClass('stokesSlider', leave, neededClass)
      });
    }
  }

  slideImage(direction, index) {
    const { stocksData } = this.props;
    const length = (!this.stocksMode ? this.images : stocksData).length - 1;
    const nextIndex = index + direction;
    if (nextIndex < 0) {
      return this.changeImage((!this.stocksMode ? this.images : stocksData)[length], direction);
    }
    if (nextIndex > length) {
      return this.changeImage((!this.stocksMode ? this.images : stocksData)[0], direction);
    }
    return this.changeImage((!this.stocksMode ? this.images : stocksData)[nextIndex], direction);
  }

  renderSliderControls(image = null, classes) {
    return (
      <div {...classes('sliderControls', 'onPhoto')}>
        <Button
          {...classes('btn', { left: !!image })}
          onClick={() => this.slideImage(-1, this.index)}
          iconType={iconTypes.arrowSliderLeft}
          noPadding
        />
        {!!image && !this.stocksMode &&
          <Button
            {...classes('btn', 'center')}
            title="Во весь экран"
            onClick={e =>
              this[`image_${this.state.imageCurrent.uniqID}`].maximize(e, image)}
          />
        }
        {!!image && this.stocksMode &&
          <Link
            {...classes('btn', 'center')}
            title="Подробнее"
            href={this.state.stockCurrent.stocks.link}
            external
            blank
          >
            <span />
          </Link>
        }
        <Button
          {...classes('btn', { right: !!image })}
          onClick={() => this.slideImage(1, this.index)}
          iconType={iconTypes.arrowSliderRight}
          noPadding
        />
      </div>
    );
  }

  renderDots(length, stocksData) {
    const useDotsArray = length > this.props.dotsLimit;
    const children = map((useDotsArray ? this.dotsArray : stocksData), (item, index) => (
      <li
        {...classesStokesSlider('dot', { active: (useDotsArray ? this.dotsIndex : this.index) === index })}
        key={index}
      />
    ));
    return (
      <ul {...classesStokesSlider('dots')} children={children} />
    );
  }

  renderImageSlider({ enter, enterActive }, { leave, leaveActive }) {
    const transitionDuration = toNumber(this.sensor.getVariable('timeDuration'));
    const {
      imagesCount, header, titleImage,
      showImagesCount,
      children, className
    } = this.props;
    const { desktop, phone, imageCurrent } = this.state;
    const image = {
      // TODO: Refactor after images will be fixed on backend
      title: header,
      renderer: imageCurrent.renderer,
      id: imageCurrent.id
    };

    return (
      <div
        {...classesImageSlider({
          extra: className
        })}
      >
        <div {...classesImageSlider('img')} ref={node => this.node = node}>
          <CSSTransitionGroup
            component="div"
            {...classesImageSlider('group')}
            transitionName={{
              enter,
              enterActive,
              leave,
              leaveActive
            }}
            transitionEnterTimeout={transitionDuration}
            transitionLeaveTimeout={transitionDuration}
          >
            <Image
              {...classesImageSlider('picMain')}
              itemprop="image"
              key={imageCurrent.uniqID}
              image={image}
              ref={ref => this[`image_${imageCurrent.uniqID}`] = ref}
              group={(titleImage && titleImage.id) || (titleImage && titleImage.uniqID)}
              fullScreenSwitch={phone ? 'click' : 'button'}
            />
          </CSSTransitionGroup>
          {imagesCount > 0 && showImagesCount &&
            <div {...classesImageSlider('placeholderCounter')}>
              <span {...classesImageSlider('text')}>{`${this.index + 1} / ${imagesCount}`}</span>
            </div>
          }
          {desktop &&
            <div {...classesImageSlider('onPhoto')}>
              <div {...classesImageSlider('sliderControlsContainer')}>
                {imagesCount > 1 && this.renderSliderControls(image, classesImageSlider)}
              </div>
            </div>
          }
          {children && children}
        </div>
        {this.images && this.images.length > 1 &&
          <ThumbsList
            images={this.images}
            imageCurrent={imageCurrent}
            changeImage={this.changeImage}
            activeImgIndex={this.index}
          />
        }
      </div>
    );
  }

  renderStocksSlider({ enter, enterActive }, { leave, leaveActive }) {
    const { stocksData = [], children } = this.props;
    const { desktop, stockCurrent } = this.state;
    const { id, image, stocks } = stockCurrent;
    const transitionDuration = toNumber(this.sensor.getVariable('timeDuration'));
    const transitionTimeout = transitionDuration * 2;

    // TODO: Refactor after images will be fixed on backend
    const newImage = {
      id: image,
      renderer: 'orig'
    };
    const stocksDataLength = stocksData.length;

    return (
      <div
        {...classesStokesSlider({
          extra: this.props.className
        })}
        ref={node => this.node = node}
      >
        <CSSTransitionGroup
          component="div"
          {...classesStokesSlider('group')}
          transitionName={{
            enter,
            enterActive,
            leave,
            leaveActive
          }}
          transitionEnterTimeout={transitionTimeout}
          transitionLeaveTimeout={transitionTimeout}
        >
          <div {...classesStokesSlider('picMain')} key={id}>
            {children && React.cloneElement(children, {
              stocks,
              image: newImage
            })}
          </div>
        </CSSTransitionGroup>
        {desktop &&
          <div {...classesStokesSlider('sliderControlsContainer')}>
            {stocksDataLength > 1 && this.renderSliderControls(true, classesStokesSlider)}
          </div>
        }
        {stocksDataLength > 1 &&
          <div {...classesStokesSlider('controlDots')}>
            {this.renderDots(stocksDataLength, stocksData)}
          </div>
        }
      </div>
    );
  }

  render() {
    const {
      className,
      // hideSliderPagination,
      header, showHeaderText, stocksData,
      imagesCount, urlBuilder, urlBuilderProps, titleImage,
      showImagesCount, renderImageSlider, hideSliderControls, children
    } = this.props;
    const {
      // pagination,
      imageCurrent, transitionEnter, transitionLeave
    } = this.state;
    const transitionDuration = toNumber(this.sensor.getVariable('timeDuration'));
    const { enter, enterActive } = transitionEnter;
    const { leave, leaveActive } = transitionLeave;

    if (renderImageSlider) return this.renderImageSlider(transitionEnter, transitionLeave);
    if (stocksData) return this.renderStocksSlider(transitionEnter, transitionLeave);
    return (
      <React.Fragment>
        <div
          {...classesBem({
            extra: className
          })}
          ref={node => this.node = node}
        >
          <CSSTransitionGroup
            component="div"
            {...classesBem('group')}
            transitionName={{
              enter,
              enterActive,
              leave,
              leaveActive
            }}
            transitionEnterTimeout={transitionDuration}
            transitionLeaveTimeout={transitionDuration}
          >
            <Thumb
              {...classesBem('picMain')}
              itemprop="image"
              key={imageCurrent.uniqID}
              image={imageCurrent}
            />
          </CSSTransitionGroup>
          {
            ((!imagesCount && titleImage && !!titleImage.id) || imagesCount > 0) &&
            showImagesCount &&
            <div {...classesBem('placeholderCounter')}>
              <div {...classesBem('placeholderCounterContent')}>
                <span {...classesBem('text')}>{`${this.index + 1} / ${imagesCount || 1}`}</span>
              </div>
            </div>
          }
          {!urlBuilder && header &&
            <span {...classesBem('imgLinkOverlay')}>
              <span {...classesBem('text')}>{header}</span>
            </span>
          }
          {urlBuilder &&
            <Link
              {...classesBem('imgLink', { overlay: showHeaderText && header })}
              builder={urlBuilder}
              builderProps={urlBuilderProps}
              blank={!!showHeaderText && !!header}
              external
            >
              {(showHeaderText && header) ?
                <span {...classesBem('imgLinkOverlay')}>
                  <span {...classesBem('text')}>{header}</span>
                </span> :
                <span />
              }
            </Link>
          }
          {children && children}
        </div>
        <div {...classesBem('sliderControlsContainer')}>
          {/*
{pagination && imagesCount > 1 && !hideSliderPagination &&
  <div {...classesBem('sliderControls', 'bottom')}>
    {map(this.images, sliderItem =>
      (<div
        {...classesBem('sliderControlsItem', { active: sliderItem.uniqID === imageCurrent.uniqID })}
        key={`pagination_${sliderItem.uniqID}`}
        // onClick={() => this.changeImage(sliderItem)}
      />)
    )}
  </div>
}
          */}
          {imagesCount > 1 && !hideSliderControls && this.renderSliderControls(null, classesBem)}
        </div>
      </React.Fragment>
    );
  }
}

const classesThumbsList = new Bem('thumbsList');

class ThumbsList extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    // Array of images to show in thumbs list
    images: PropTypes.array, //eslint-disable-line

    // Main image to show at first render
    imageCurrent: PropTypes.shape({}),

    // onChange image callback
    changeImage: PropTypes.func,

    // Index of an active image
    // to define the side to slide (left, right)
    activeImgIndex: PropTypes.number
  };

  constructor(...props) {
    super(...props);
    this.updatedScrollLeft = 0;
    this.scrollinProgress = false;
    this.slideTimer = null;
    this.coors = { clientX: 0, clientY: 0 };

    this.setCoors = this.setCoors.bind(this);
    this.handleThumbClick = this.handleThumbClick.bind(this);
  }

  componentDidMount() {
    this.updateComponent(false);
  }

  componentDidUpdate() {
    this.updateComponent();
  }

  setCoors(e) {
    e.preventDefault();
    if (!e.changedTouches) return this.coors = { clientX: e.clientX, clientY: e.clientY };
    this.coors = {
      clientX: e.changedTouches[0].pageX,
      clientY: e.changedTouches[0].pageY
    };
  }

  updateComponent(update = true) {
    const { clientWidth, scrollWidth } = this.thumbsRef;
    this.halfThumbsSize = clientWidth / 2;
    this.maxScroll = scrollWidth - clientWidth;

    if (update) {
      const { images, activeImgIndex } = this.props;
      const firstLastItem = activeImgIndex === 0 || activeImgIndex === images.length - 1;
      this.scrollToActiveThumb(this.props.imageCurrent, !firstLastItem);
    }
  }

  scrollToActiveThumb(imageCurrent, inside) {
    const { clientWidth } = this.thumbsRef;
    const elem = this[`thumb${imageCurrent.uniqID}`];
    let elemOffset = 0;
    if (elem && elem.offsetLeft) {
      elemOffset = elem.offsetLeft;
    }

    let elemSize = 0;
    if (elem && elem.scrollWidth) {
      elemSize = elem.scrollWidth;
    }

    const elemPosToCenter = elemOffset - (
      this.thumbsRef.scrollLeft + this.halfThumbsSize
    );
    const direction = Math.sign(elemPosToCenter);
    const scrollDiff = Math.abs(this.updatedScrollLeft - this.thumbsRef.scrollLeft);

    const smoothScroll = (distance = 100, speed) => {
      this.scrollinProgress = true;
      this.updatedScrollLeft += direction === -1 ? distance * direction : distance;
      if (this.updatedScrollLeft < 0) this.updatedScrollLeft = 0;
      if (this.updatedScrollLeft > this.maxScroll) this.updatedScrollLeft = this.maxScroll;

      let scrollAmount = 0;
      let step = 5;
      if (!inside) step = clientWidth / 12;
      this.slideTimer = setInterval(() => {
        if (direction === -1) {
          this.thumbsRef.scrollLeft -= step;
        } else {
          this.thumbsRef.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          clearInterval(this.slideTimer);
          this.scrollinProgress = false;
        }
      }, speed);
    };

    if (!inside) {
      smoothScroll(this.scrollinProgress ? this.maxScroll + scrollDiff : this.maxScroll, 10);
    } else {
      const scrollDistance = Math.abs(elemPosToCenter + (elemSize / 2));
      smoothScroll(
        this.scrollinProgress ? scrollDistance + scrollDiff : scrollDistance,
        scrollDistance > 100 ? 5 : 20
      );
    }
  }

  handleThumbClick(e, image) {
    e.preventDefault();
    const positionX = e.changedTouches ? e.changedTouches[0].pageX : e.clientX;
    const positionY = e.changedTouches ? e.changedTouches[0].pageY : e.clientY;

    // TODO prevent click while dragging
    if (positionX !== 0 &&
      (
        Math.abs(positionX - this.coors.clientX) >= 10 ||
        Math.abs(positionY - this.coors.clientY) >= 10
      )
    ) {
      this.coors = { clientX: positionX, clientY: positionY };
    } else {
      const index = findIndex(this.props.images, item => item.uniqID === image.uniqID);
      const direction = Math.sign(index - this.props.activeImgIndex);
      this.props.changeImage(image, direction);
    }
  }

  render() {
    const { className, images, imageCurrent } = this.props;

    return (
      <div
        {...classesThumbsList({
          extra: className
        })}
        ref={el => this.thumbsRef = el}
      >
        <div {...classesThumbsList('content')}>
          {map(images, thumb => (
            <Thumb
              key={`thumb_${thumb.uniqID}`}
              thumbRef={el => this[`thumb${thumb.uniqID}`] = el}
              {...classesThumbsList('item', { active: thumb.uniqID === imageCurrent.uniqID })}
              image={thumb}
              onTouchStart={this.setCoors}
              onTouchEnd={e => this.handleThumbClick(e, thumb)}
              onMouseDown={this.setCoors}
              onMouseUp={e => this.handleThumbClick(e, thumb)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default PictureSlider;
