import React, { Component } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Link from 'components/link';

const bemClasses = new Bem('viewTile');

export default class TileView extends Component {
  static propTypes = {
    className: PropTypes.string,

    // Data to render
    data: PropTypes.shape({
      header: PropTypes.string,
      action: PropTypes.bool
    }),

    dataMods: PropTypes.shape({}),

    // seo props for Tile
    // seoPropsTile: PropTypes.shape({}),

    // Instead findDOMNode
    // tileRef: PropTypes.func,

    // Link builder
    urlBuilder: PropTypes.func,
    linkCard: PropTypes.shape({
      filledCard: PropTypes.bool,
      filledImg: PropTypes.bool,
      filledContent: PropTypes.bool,
    }),
    // *** Render methods
    renderImage: PropTypes.func,
    renderContent: PropTypes.func,
    renderSideBlock: PropTypes.func,
    renderFooter: PropTypes.func,
    renderUnderneath: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func
  };

  static defaultProps = {
    linkCard: {
      filledImg: true
    }
  };

  renderLink() {
    const { data = {}, urlBuilder } = this.props;
    if (!urlBuilder) return null;

    return (
      <Link
        {...bemClasses('link', 'fill')}
        builder={urlBuilder}
        builderProps={data}
        children={<span />}
      />
    );
  }

  render() {
    const {
      data = {}, className, dataMods = {}, // seoPropsTile = {},
      renderContent, renderSideBlock,
      renderFooter, renderUnderneath, renderImage,
      linkCard, onMouseOver, onMouseOut
      // tileRef
    } = this.props;
    // const { action } = data;
    const events = { onMouseOver, onMouseOut };

    return (
      <LazyHydrate
        whenVisible
        style={{}}
        {...bemClasses(null, dataMods, className)}
        // {...seoPropsTile}
        {...events}
        // ref={(ref) => {
        //   if (tileRef) tileRef(ref);
        // }}
        data-test="viewTileBild"
      >
        {linkCard.filledCard && this.renderLink()}
        <div {...bemClasses('imgContainer')} data-test="imgResultContainer">
          <div {...bemClasses('img')}>
            {linkCard.filledImg && this.renderLink()}
            {renderImage && renderImage(data, bemClasses)}
            {renderSideBlock &&
              <div {...bemClasses('iconsContainer')}>
                {renderSideBlock(data, bemClasses)}
              </div>
            }
          </div>
        </div>
        <div {...bemClasses('dataContainer')}>
          {linkCard.filledContent && this.renderLink()}
          <div {...bemClasses('data')}>
            {renderContent &&
              <div {...bemClasses('dataMain')}>
                {renderContent(data, bemClasses)}
              </div>
            }
          </div>
          {renderFooter && renderFooter(data, bemClasses)}
        </div>
        {renderUnderneath && renderUnderneath(data, bemClasses)}
      </LazyHydrate>
    );
  }
}
