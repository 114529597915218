import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DefaultWrapper extends Component {
  static propTypes = {
    params: PropTypes.shape({
      header: PropTypes.string,
      header_url: PropTypes.string
    }),
    children: PropTypes.node
  };

  render() {
    const { params, children } = this.props;
    const { header, header_url } = params;

    return (
      <>
        {header && !header_url && <h3>{header}</h3>}
        {children}
      </>
    );
  }
}
