import React from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import onClickOutside from 'react-onclickoutside';
import Button from 'components/button';

import Input from './input';
import AbstractInput from './abstractInput';

/* import {
  VALIDATION_VALID // TODO VALIDATION
} from './form-consts'; */

const bemClassesInputH = new Bem('inputHelper');

@onClickOutside
class HelperInput extends AbstractInput {
  static defaultProps = {
    dictKey: 'id',
    dictValue: 'value'
  };

  static propTypes = {
    ...AbstractInput.propTypes,
    // tipSuffix: PropTypes.string,
    injectPropName: PropTypes.string,
    // Enable outside click event
    enableOnClickOutside: PropTypes.func,
    // Disable outside click event
    disableOnClickOutside: PropTypes.func,
    // No error message below input (doesn't render empty area)
    noError: PropTypes.bool,
    schema: PropTypes.shape({
      readOnly: PropTypes.bool,
      tips: PropTypes.arrayOf(PropTypes.number),
      tipSuffix: PropTypes.string
    }),
    noLabel: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    this.onChange = this.onChange.bind(this);
    this.blurCallback = this.blurCallback.bind(this);
    this.focusCallback = this.focusCallback.bind(this);
    this.onKeyDownCallback = this.onKeyDownCallback.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      focused: false
    };
  }

  handleClickOutside() {
    if (this.state.focused) this.setState({ focused: false });
  }

  onKeyDownCallback(event) {
    const charCode = event.which || event.keyCode;
    if (charCode === 9 && this.state.focused) this.setState({ focused: false });
  }

  blurCallback() {
    this.setState({ focused: false });
  }

  focusCallback() {
    this.setState({ focused: true });
  }

  onChange(onChangeValue) {
    if (this.props.onChange) this.props.onChange(onChangeValue);
  }

  onChangeFromTips(onChangeValue, fromTips) {
    if (this.props.onChange) this.props.onChange(onChangeValue);
    if (fromTips) this.setState({ focused: false });
  }

  render() {
    const { className, schema, value, labelSuffix, noError, noLabel } = this.props;
    const { tips, tipSuffix, readOnly } = schema || {};

    return (
      <div {...bemClassesInputH(null, { disabled: readOnly }, className)}>
        <Input
          {...bemClassesInputH('input')}
          onChange={this.onChange}
          schema={schema}
          value={value}
          noLabel={noLabel}
          tipSuffix={(tipSuffix && (!this.state.focused || readOnly)) ? tipSuffix : false}
          onBlurCallback={this.blurCallback}
          onFocusCallback={this.focusCallback}
          onKeyDown={this.onKeyDownCallback}
          labelSuffix={labelSuffix}
          noError={noError}
        />
        {this.state.focused && !readOnly && (
          <div {...bemClassesInputH('contentContainer', { hidden: !this.state.focused })}>
            <div {...bemClassesInputH('content')}>
              {tips && tips.map(it => (
                <Button
                  key={it}
                  onClick={() => this.onChangeFromTips(it, true)}
                  title={`${it} ${tipSuffix}`}
                  label={`${it} ${tipSuffix}`}
                  inListDD
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default HelperInput;
