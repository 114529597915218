import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Button from 'components/button';

import Modal from './modal';

const classesModal = new Bem('modalBtn');
const classesBtn = new Bem('btnModal');

/**
 * === ModalButton Component ===
 * Рендерит конпку вызова модального окна.
 *
 * = Example =
 * <ModalButton
 *   buttonProps={{
 *     iconType: 'reply',
 *     title: 'Ответить',
 *     label: 'Ответить',
 *     dataView: 'float'
 *   }}
 *   size="content"
 *   hideModalBtn
 * >
 *   <Header closeButton>{header text}</Header>
 *   <Content>
 *     {modal content}
 *   </Content>
 * </ModalButton>
 */

export default class ModalButton extends Component {
  static propTypes = {
    className: PropTypes.string,

    classNameModal: PropTypes.string,

    // Render small icon in ModalButton
    smallIcon: PropTypes.bool,

    // Hide modal button when modal window is opened
    hideModalBtn: PropTypes.bool,

    // Callback function before opening modal
    onOpenCallback: PropTypes.func,

    // Callback function after closing modal
    onCloseCallback: PropTypes.func,

    buttonProps: PropTypes.shape({
      // Title for ModalButton
      title: PropTypes.string,

      // Label text for ModalButton
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
      ]),

      // Icon type for ModalButton
      iconType: PropTypes.string,

      // IconSecond type for ModalButton
      iconSecondType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
      ]),

      // ModalButton specific dataView
      dataView: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]),

      // Button for list (if first-child to add style margin-top, if last-child - add margin-bottom)
      inListDD: PropTypes.bool,

      // Button size
      small: PropTypes.bool,
      big: PropTypes.bool,

      // Button content to left
      contentLeft: PropTypes.bool,
      // Button content to right
      contentRight: PropTypes.bool
    }),

    askToClose: PropTypes.bool
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      opened: false
    };
    this.toggle = this.toggle.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggle() {
    if (this.mounted) {
      const { opened } = this.state;
      if (opened && this.props.onCloseCallback) this.props.onCloseCallback();
      this.setState({ opened: !opened });
    }
  }

  closeModal() {
    if (this.modal) this.modal.close();
  }

  renderModal(props, className = null, classNameModal) {
    return (
      <Modal
        disableNotification
        {...props}
        {...classesModal({ extra: { [className]: className, [classNameModal]: classNameModal } })}
        ref={el => this.modal = el}
        onCloseRequired={this.toggle}
        renderModal
      />
    );
  }

  render() {
    const {
      className, classNameModal, buttonProps = {}, smallIcon, hideModalBtn, onOpenCallback,
      ...restProps
    } = this.props;

    if (hideModalBtn && this.state.opened) {
      return this.renderModal(restProps, className, classNameModal);
    }

    return (
      <>
        <Button
          {...buttonProps}
          {...classesBtn(null, { smallIcon }, className)}
          onClick={() => {
            if (onOpenCallback) onOpenCallback();
            this.toggle();
            if (buttonProps.onClick) return buttonProps.onClick();
          }}
        />
        {!hideModalBtn && this.state.opened && this.renderModal(restProps, null, classNameModal)}
      </>
    );
  }
}
