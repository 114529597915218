import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import get from 'lodash/get';
import replace from 'lodash/replace';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import AskQuestion from 'subsys/ask-question';
import Link from 'components/link';
import Button from 'components/button';
import DropDown from 'components/drop-down';
import { iconTypes } from 'components/icon';
import { ModalButton, Header, Content, Footer } from 'components/modal';

import { getDeveloperLink } from './render-common';
import marker from '../assets/map-marker';

import translates from './project-i18n';

const bemClasses = new Bem('project');
const bemClassesDDList = new Bem('dropDownList');

@I18nHoc(translates())
export default class ContactToDeveloper extends Component {
  static propTypes = {
    developer: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.array // eslint-disable-line
    ]),
    projectId: PropTypes.number,
    projectUrl: PropTypes.string,
    salerPhone: PropTypes.string,
    salerEmail: PropTypes.string,
    askDevHeader: PropTypes.shape({}),
    free_taxi: PropTypes.bool,
    otherPropsDD: PropTypes.shape({
      btnText: PropTypes.string,
      btnIconSecondHide: PropTypes.bool,
      btnIconSecond: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
      ]),
      modalCallBackBtnProps: PropTypes.shape({})
    })
  };

  render() {
    const { i18n } = this;
    const {
      developer, projectId, projectUrl, askDevHeader, salerPhone, salerEmail, otherPropsDD = {}, free_taxi
    } = this.props;

    if (!salerEmail && !salerPhone) return null;

    // const developerPhone = get(developer, 'phones[0]');
    const { btnText, btnIconSecondHide, btnIconSecond, modalCallBackBtnProps } = otherPropsDD;
    const btnTextMain = btnText || i18n('connect');
    const btnIconSecondMain = btnIconSecondHide ? null : btnIconSecond || iconTypes.phoneCalls;

    return (
      <DropDown
        {...bemClasses('developerDD')}
        ref={ref => this.ddRef = ref}
        alwaysDD
        list
      >
        <Button
          {...bemClasses('btn', 'dd')}
          iconSecondType={btnIconSecondMain}
          label={btnTextMain}
          title={btnTextMain}
        />
        <div {...bemClassesDDList('contentContainer')}>
          <div {...bemClassesDDList('content')}>
            {salerEmail &&
              <ModalButton
                {...bemClasses('btn', 'inListDD')}
                classNameModal="modalQuestionMeeting"
                buttonProps={{
                  title: free_taxi ? i18n('questionMeetingTaxi') : i18n('questionMeeting'),
                  label: i18n('questionMeeting'),
                  iconSecondType: free_taxi ? iconTypes.taxi : null,
                  inListDD: true
                }}
                size="big"
              >
                <Header closeButton children={askDevHeader} />
                <Content hFull adaptiveHeight>
                  <div>
                    <AskQuestion
                      {...bemClasses('blockAskQuestion')}
                      controller="support/ask-question/ask-developer-question"
                      entityId={projectId}
                      entity="ask_question"
                      url={projectUrl}
                      otherProps={{
                        notificationMessageSuccess: i18n('askQuestionMessage'),
                        inputPhoneProps: { noLabel: true, noElementBefore: true },
                        additionalMain: false,
                        headerTopic: false,
                        firstSeller: developer,
                        hideTelephoneBtnIconType: iconTypes.phoneMob,
                        free_taxi,
                        marker,
                        counterProps: {
                          action: 'askDeveloperQuestion',
                          entity: {
                            project_id: projectId
                          }
                        }
                      }}
                    />
                  </div>
                </Content>
              </ModalButton>
            }
            {salerPhone &&
              <ModalButton
                {...bemClasses('btn', 'inListDD')}
                classNameModal="modalCall"
                buttonProps={{
                  title: i18n('call'),
                  label: i18n('call'),
                  inListDD: true
                }}
                size="medium"
              >
                <Header closeButton children={i18n('consultantContacts')} />
                <Content hFull adaptiveHeight>
                  <div {...bemClasses('linkToTel')}>
                    <Link
                      {...bemClasses('link', 'toTel')}
                      href={`tel:${replace(salerPhone, /[\s\(\)]/g, '')}`}
                      rel="nofollow"
                      external
                    >
                      <span {...bemClasses('linkContent')}>
                        <span {...bemClasses('text', 'tel')} children={salerPhone} />
                      </span>
                    </Link>
                  </div>
                </Content>
                <Footer>
                  <div {...bemClasses('row', 'modalInfoDeveloper')}>
                    <div {...bemClasses('itemInfoDeveloper', 'labelDeveloper')}>
                      <span {...bemClasses('text', 'label')} children={i18n('developerTopic')} />
                    </div>
                    {getDeveloperLink(bemClasses, developer)}
                  </div>
                </Footer>
              </ModalButton>
            }
            {salerEmail &&
              <ModalCallBack
                classes={bemClasses}
                askDevHeader={askDevHeader}
                projectId={projectId}
                projectUrl={projectUrl}
                developer={developer}
                developerEmail={salerEmail}
                modalBtnProps={modalCallBackBtnProps}
              />
            }
          </div>
        </div>
      </DropDown>
    );
  }
}

@I18nHoc(translates())
export class ModalCallBack extends Component {
  static propTypes = {
    classes: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.any),
      PropTypes.func
    ]),
    developer: PropTypes.shape({}),
    projectId: PropTypes.number,
    projectUrl: PropTypes.string,
    developerEmail: PropTypes.string,
    askDevHeader: PropTypes.shape({}),
    modalBtnProps: PropTypes.shape({})
  };

  render() {
    const { i18n } = this;
    const {
      developer, projectId, projectUrl, askDevHeader, classes, modalBtnProps = {}, developerEmail
    } = this.props;

    if (!projectId || !projectUrl || !developer) return null;

    return (
      <ModalButton
        {...classes('btn', 'inListDD')}
        classNameModal="modalCallBack"
        buttonProps={{
          title: i18n('backCall'),
          label: i18n('backCall'),
          inListDD: true,
          ...modalBtnProps
        }}
        size="medium"
      >
        <Header closeButton children={askDevHeader} />
        <Content hFull adaptiveHeight>
          <div>
            <AskQuestion
              {...classes('blockAskQuestion')}
              controller="support/ask-question/developer-back-call"
              entityId={projectId}
              entity="ask_question"
              type="backCall"
              url={projectUrl}
              otherProps={{
                notificationMessageSuccess: i18n('askQuestionMessage'),
                inputPhoneProps: { noLabel: true, noElementBefore: true },
                additionalMain: false,
                headerTopic: true,
                headerTopicText: i18n('orderCallBack'),
                firstSeller: developer,
                hideTelephoneBtnIconType: iconTypes.phoneMob,
                developerEmail,
                marker,
                counterProps: {
                  action: 'orderCallBack',
                  entity: {
                    project_id: projectId
                  }
                }
              }}
            />
          </div>
        </Content>
      </ModalButton>
    );
  }
}
