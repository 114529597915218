import reliabilityIndexTranslates from 'src-sites/bild/section-project/reliability-index-i18n.json';

export default function () {
  return {
    ...reliabilityIndexTranslates,

    urlToPdf: {
      ru: '//bildcontrol.ua/assets/st-resources/reliability.pdf',
      ua: '//bildcontrol.ua/assets/st-resources/reliability-ua.pdf'
    },

    h2: {
      ru: 'Индекс надёжности новостройки\u00a0*',
      ua: 'Індекс надійності новобудови\u00a0*'
    },
    description: {
      ru: 'Индекс надёжности новостройки - это оценка, которую получил ЖК в результате анализа по {{placeholderRU}} методологии.',
      ua: 'Індекс надійності новобудови - це оцінка, яку отримав ЖК в результаті аналізу по {{placeholderUA}} методології.'
    },
    linkToExampleTitle: {
      ru: 'Пример отчёта',
      ua: 'Приклад звіту'
    },
    linkToExampleLabel: {
      ru: 'Пример детального Отчёта о надёжности новостройки',
      ua: 'Приклад детального Звіту про надійність новобудови'
    },
    h3: {
      ru: 'Мы проверяем:',
      ua: 'Ми перевіряємо:'
    },
    descriptionItemTopic1: {
      ru: 'Законность новостройки',
      ua: 'Законність новобудови'
    },
    descriptionItemDescription1: {
      ru: 'Проверяем, можно ли строить конкретный проект на конкретном участке',
      ua: 'Перевіряємо, чи можна будувати конкретний проєкт на конкретній ділянці'
    },
    descriptionItemTopic2: {
      ru: 'Разрешения и запреты',
      ua: 'Дозволи і заборони'
    },
    descriptionItemDescription2: {
      ru: 'Собираем разрешительную документацию и изучаем реестры',
      ua: 'Збираємо дозвільну документацію і вивчаємо реєстри'
    },
    descriptionItemTopic3: {
      ru: 'Договор инвестора',
      ua: 'Договір інвестора'
    },
    descriptionItemDescription3: {
      ru: 'Рассматриваем главные риски и даем рекомендации',
      ua: 'Розглядаємо головні ризики і даємо рекомендації'
    },
    descriptionItemTopic4: {
      ru: 'Репутацию застройщика',
      ua: 'Репутацію забудовника'
    },
    descriptionItemDescription4: {
      ru: 'Изучаем историю застройщика и проекта новостройки',
      ua: 'Вивчаємо історію забудовника і проєкту новобудови'
    },
    hintTopic: {
      ru: 'Индекс надёжности новостройки',
      ua: 'Індекс надійності новобудови'
    },
    hintDescription: {
      ru: 'отображается в правом нижнем углу карточки новостройки. Он показывает сколько баллов набрала новостройка при оценке надёжности, из возможных',
      ua: 'відображається в правому нижньому кутку картки новобудови. Він показує скільки балів набрала новобудова при оцінці надійності, з можливих'
    },
    labelYoutube: {
      ru: 'Смотреть про индекс надёжности',
      ua: 'Дивитися про індекс надійності'
    },
    hintClose: {
      ru: 'Закрыть',
      ua: 'Закрити'
    },
    hintDescriptionBottom1: {
      ru: '* Индекс надёжности считаем по авторской методике, основанной на анализе данных о новостройках и застройщиках, взятых из государственных реестров, предоставленных застройщиками, рассчитанных самостоятельно и данных открытых источников',
      ua: '* Індекс надійності рахуємо за авторською методикою, заснованою на аналізі даних про новобудови і забудовників, взятих з державних реєстрів, наданих забудовниками, розрахованих самостійно і даних відкритих джерел'
    },
    hintDescriptionBottom2: {
      ru: '** Выводы о надёжности или ненадёжности новостройки, сделанные в результате анализа, являются оценочным суждением авторов.',
      ua: '** Висновки про надійність або ненадійність новобудови, зроблені в результаті аналізу, є оціночним судженням авторів.'
    }
  };
}
