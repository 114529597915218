import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import getDisplayName from 'react-display-name';
import jexl from 'jexl';
import { Helmet } from 'react-helmet';
import parseTemplate from 'helpers/templateParser';
import loadableDeprecated from 'helpers/loadable';
import each from 'lodash/each';
import map from 'lodash/map';
import CommonError from 'errors/commonError';
import absoluteUrl from 'helpers/absoluteUrl';
import { langIsoMapBack } from 'helpers/i18n';

import config from '../../config';



const SeoManager = loadableDeprecated(
  () => import('../../src-sites/backoffice/section-admin/seo/manage/editor'),
  { noDisplayLoading: true }
);
const SeoManagerButton = loadableDeprecated(
  () => import('../../src-sites/backoffice/section-admin/seo/manage/editorButton'),
  { noDisplayLoading: true }
);
const InvalidateCacheButton = loadableDeprecated(
  () => import('../../src-sites/backoffice/section-admin/seo/manage/cacheInvalidate'),
  { noDisplayLoading: true }
);

export const seoDataShape = PropTypes.shape({
  // Pahe header (H1)
  header: PropTypes.string,
  // Title
  title: PropTypes.string,
  // Description
  description: PropTypes.string
});

/**
 * Inject SEO data to page
 * Params can contains:
 *   * advancedVars - advanced custom variables for page,
 *     can be object or function, that returns this object
 *     args of function: (entity)
 *     format - varName: varHumanDescription
 *   * defaultTitle - Default title (if not defined in seo data)
 *
 * @return {wrapComponent}
 */
function seoDecorator(params = {}) {
  return function wrapComponent(BaseComponent) {
    return class ComponentWithSeo extends Component {
      static propTypes = {
        $seoManagement: PropTypes.shape({}),
        $seoData: PropTypes.shape({}),
        $seoTemplate: PropTypes.oneOfType([
          PropTypes.shape({}),
          PropTypes.arrayOf(PropTypes.shape({}))
        ]),
        // dataVisualize: PropTypes.arrayOf(PropTypes.string)
      };

      static displayName = `seo(${getDisplayName(BaseComponent)})`;

      constructor(props, context) {
        super(props, context);
        this.project = __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__;
        this.state = {
          manage: false
        };
      }

      switchManage = () => {
        this.setState({ manage: !this.state.manage });
      };

      render() {
        const { $seoManagement } = this.props;
        let manage = this.state.manage;
        if ($seoManagement) {
          manage = true;
          if ($seoManagement.restoreProjectGlobal) {
            this.project = window.__PROJECT__;
            window.__PROJECT__ = $seoManagement.restoreProjectGlobal;
          }
        }

        if (manage) {
          return (
            <Fragment>
              {!$seoManagement &&
                <SeoManagerButton
                  title="Вернуть в зад"
                  onClick={this.switchManage}
                />
              }
              <SeoManager
                title="Вернуть взад"
                $project={this.project}
                $advancedParams={params}
                {...this.props}
              />
            </Fragment>
          );
        }

        const { $seoData = {}, $seoTemplate, ...otherProps } = this.props;
        const { payload = {} } = $seoData;
        const { location } = otherProps;
        const title = payload.title || params.defaultTitle;
        const defaultDescription = config.defaultDescription[__PROJECT__]; // eslint-disable-line
        const titleSuffix = config.titleSuffix[__PROJECT__]; // eslint-disable-line
        const description = payload.description || defaultDescription;

        const pageMatch = location.pathname.match(/(.*)\/page-(\d+)/);
        const pageNum = pageMatch ? ` - страница ${pageMatch[2]}` : '';
        const canonicalLink = pageMatch ? pageMatch[1] : location.pathname;

        const activeRoute = this.props.routes[this.props.routes.length - 1];
        const pageLang = activeRoute.lang;
        const pageLangs = activeRoute.langs;
        const pageDefLang = activeRoute.defLang;

        return (
          <Fragment>
            <Helmet>
              {title && <title children={`${title}${pageNum}${titleSuffix}`} />}
              {title && <meta property="og:title" content={`${title}${pageNum}${titleSuffix}`} />}
              {description && <meta name="description" content={description + pageNum} />}
              {description && <meta property="og:description" content={description + pageNum} />}
              {payload.keywords && <meta name="keywords" content={payload.keywords} />}
              {canonicalLink && <link rel="canonical" href={absoluteUrl(canonicalLink)} />}
              {pageLangs && map(pageLangs, (langUrl, lang) =>
                <link key={lang} rel="alternate" hrefLang={langIsoMapBack[lang]} href={absoluteUrl(langUrl)} />
              )}
              {pageDefLang && pageLang !== config.locale &&
              <link rel="alternate" hrefLang="x-default" href={absoluteUrl(pageDefLang)} />
              }
            </Helmet>
            <SeoManagerButton
              title="SEO Manager"
              onClick={this.switchManage}
            />
            <InvalidateCacheButton
              title="SEO Manager"
              onClick={this.switchManage}
            />
            <BaseComponent {...otherProps} seoData={payload} />
          </Fragment>
        );
      }
    };
  };
}

/**
 * Load SEO templates from server
 * @param params
 */
function seoPreloader(params) {
  const { api, ...config } = params; // eslint-disable-line
  return api.get('seo', {
    clientCache: 1,
    queryString: {
      project: __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__,
      ...config
    }
  });
}

/**
 * Function for asynchronous process conditions using jexl expression parser
 * @param data
 * @return {Promise<void>}
 * @private
 */
function _getCond(data, content) {
  const compilers = [];
  let defaultCond = null;
  each(data, (val) => {
    if (val.condition !== 'default') {
      compilers.push(
        jexl.eval(val.condition, content)
          .then(res => res ? val : null)
          .catch((err) => {
            console.error('SEO Condition parse error', err, val.condition, content);
            return null;
          })
      );
    } else {
      defaultCond = val;
    }
  });

  return Promise.all(compilers).then((res) => {
    let trueCond = null;
    each(res, (item) => {
      if (item) {
        trueCond = item;
      }
    });
    return trueCond || defaultCond;
  });
}

/**
 * Request to resolve conditional templates and parse templates using jexl evaluator
 * @param props
 */
function tplProcessorResolver(props) {
  return new Promise((accept, reject) => { // eslint-disable-line
    _getCond(props.data, props.content)
      .then((resp) => {
        const { condition, ...templates } = resp;
        const compilers = [];
        each(templates, (tpl, tplKey) => compilers.push(
          parseTemplate(tpl, props.content)
            .catch((error) => {
              throw new CommonError(error, {
                component: 'SEO Template Processor',
                template: tpl,
                content: props.content
              });
            }).then(ctx => ({ tpl: tplKey, ctx }))
        ));
        Promise.all(compilers).then((parsed) => {
          const tpls = {};
          each(parsed, item => tpls[item.tpl] = item.ctx);
          accept(tpls);
        });
      });
  });
}

/**
 * Generate sub-config for SEO processor using returned from server seo templates
 * @param resp
 * @return {*}
 */
function seoProcessorConfig(resp) {
  const { $seoTemplate, ...seoContent } = resp;
  if (!$seoTemplate) {
    return {};
  }
  const seoData = $seoTemplate.data;
  if (!seoData) {
    return {};
  }

  return {
    payload: [tplProcessorResolver, { data: seoData, content: seoContent }]
  };
}

/**
 * Preload config generator for SEO processor
 *
 * @param config
 * @param wait
 * @return {{$seoTemplate: *[], $seoData: {$wait: *[], $callback: seoTemplateProcess}}}
 */
function seoPreload(config, wait) { // eslint-disable-line
  return {
    $seoConfig: [() => Promise.resolve({
      config,
      wait
    })],
    $seoTemplate: [seoPreloader, {
      url: '$route.path',
      ...config
    }],
    $seoData: {
      $wait: [...wait, '$seoTemplate'],
      $callback: seoProcessorConfig
    }
  };
}

export {
  seoPreload as default,
  seoDecorator
};
