import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Link from 'components/link';

const bemClasses = new Bem('viewList');

export default class CardView extends Component {
  static propTypes = {
    className: PropTypes.string,

    // Data to render
    data: PropTypes.shape({
      action: PropTypes.bool
    }),

    // Style correction for myObjects layout
    myObject: PropTypes.bool,

    // Ref func instead findDOMNode (optional)
    cardRef: PropTypes.func,

    // Link builder
    urlBuilder: PropTypes.func,

    // *** Render methods
    renderHeader: PropTypes.func,
    renderImage: PropTypes.func,
    renderContent: PropTypes.func,
    renderSideBlock: PropTypes.func,
    renderFooter: PropTypes.func,
    renderUnderneath: PropTypes.func
  };

  renderImageBlock() {
    const { data = {}, urlBuilder, renderImage } = this.props;
    if (!urlBuilder) {
      if (!renderImage) return null;
      return renderImage(data, bemClasses);
    }

    return (
      <React.Fragment>
        <Link
          className="thumbSlider__imgLink thumbSlider__imgLink_overlay"
          builder={urlBuilder}
          builderProps={data}
        >
          <span />
        </Link>
        {renderImage && renderImage(data, bemClasses)}
      </React.Fragment>
    );
  }

  render() {
    const {
      data = {}, className, cardRef, myObject,
      renderHeader,
      renderContent, renderSideBlock,
      renderFooter, renderUnderneath
    } = this.props;
    const { action } = data;

    return (
      <div
        {...bemClasses({ modifiers: { action, myObject }, extra: className })}
        ref={(node) => { if (cardRef) cardRef(node); }}
      >
        <div {...bemClasses('item')}>
          <div {...bemClasses('column', 'full')}>
            <div {...bemClasses('row')}>
              {renderHeader && renderHeader(data, bemClasses)}
            </div>
            <div {...bemClasses('row', 'main')}>
              <div {...bemClasses('column')}>
                <div {...bemClasses('img')}>
                  {this.renderImageBlock()}
                </div>
              </div>
              <div {...bemClasses('column', 'full')}>
                <div {...bemClasses('row')}>
                  <div {...bemClasses('column', 'full')}>
                    {renderContent && renderContent(data, bemClasses)}
                  </div>
                  <div {...bemClasses('column', 'mainRight')}>
                    {renderSideBlock && renderSideBlock(data, bemClasses)}
                  </div>
                </div>
                <div {...bemClasses('column', 'bottom')}>
                  {renderFooter && renderFooter(data, bemClasses)}
                </div>
              </div>
            </div>
          </div>
          {renderUnderneath && renderUnderneath(data, bemClasses)}
        </div>
      </div>
    );
  }
}
