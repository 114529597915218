import React from 'react';
import PropTypes from 'prop-types';

export default class DependentFields extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    values: PropTypes.shape({}),
    schemas: PropTypes.shape({}),
    onChange: PropTypes.func
  };

  render() {
    const { children, values, schemas, onChange } = this.props;
    return children({ values, schemas, onChange });
  }
}
