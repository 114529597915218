/* eslint-disable class-methods-use-this */
import Leaflet from 'leaflet';
import { MapControl, withLeaflet } from 'react-leaflet';

class LocateBtn extends MapControl {
  createLeafletElement(props) {
    this._map = props.leaflet.map;
    const Ext = Leaflet.Control.extend({
      options: {
        position: 'topright',
        ...props
      },
      onAdd: function onAdd() {
        const divContainer = Leaflet.DomUtil.create('div', 'leaflet-control-button');
        divContainer.onclick = () => this._map.locate({
          setView: true,
          enableHighAccuracy: true
        });
        divContainer.innerHTML = 'GEOLOCATEME';
        return divContainer;
      }
    });
    return new Ext();
  }
}

export default withLeaflet(LocateBtn);
