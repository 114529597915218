import React, { Component } from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import map from 'lodash/map';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Button from 'components/button';

import translates from './paginator-i18n.json';

const classesPaginatorStatic = new Bem('paginatorStatic');
const classesPaginator = new Bem('paginator');

@I18nHoc(translates)
export default class StaticPaginator extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({}), // data to render inside static paginator
    limitPerPage: PropTypes.number, // items per page
    renderItem: PropTypes.func // callback for rendering each item on page
  };

  static cutData({ data, limitPerPage }) {
    if (!data) return null;

    const items = values(data);
    const dataMap = {};
    let perPage = limitPerPage;
    let page = 0;
    Array.prototype.forEach.call(items, (it, index) => {
      if (index + 1 === perPage) {
        perPage += limitPerPage;
        page += 1;
      }
      if (!dataMap[page]) dataMap[page] = [];
      dataMap[page].push(it);
    });
    return dataMap;
  }

  constructor(props, args) {
    super(props, args);
    this.changePage = this.changePage.bind(this);

    this.state = {
      currentPage: 0
    };
  }

  static getDerivedStateFromProps(props) {
    const items = values(props.data);
    const pagesArray = [];
    pagesArray.length = Math.ceil(items.length / props.limitPerPage);
    return {
      dataMap: StaticPaginator.cutData(props),
      pagesArray
    };
  }

  changePage(page) {
    if (this.state.currentPage !== page) {
      this.setState({ currentPage: page });
    }
  }

  renderPages() {
    return (
      <div {...classesPaginator()}>
        <div {...classesPaginator('content')}>
          {map(this.state.pagesArray, (it, index) => (
            <Button
              key={index}
              {...classesPaginator('link', { active: index === this.state.currentPage })}
              title={`${this.i18n('page')} ${index + 1}`}
              onClick={() => this.changePage(index)}
              customRenderer
            >
              <span {...classesPaginator('linkContent')}>
                <span {...classesPaginator('text')} children={index + 1} />
              </span>
            </Button>
          ))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div {...classesPaginatorStatic({ extra: this.props.className })}>
        {map(this.state.dataMap[this.state.currentPage], (it, index) =>
          this.props.renderItem(it, index))
        }
        {this.renderPages()}
      </div>
    );
  }
}
