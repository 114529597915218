/* eslint-disable class-methods-use-this */
import Leaflet from 'leaflet';
import { MapControl, withLeaflet } from 'react-leaflet';

const screen = "<button type='button' id='fullscreen' class='leaflet-control-fullscreen-out icon icon_fullscreen' title='Включить полноэкранный режим'>\ue936</button>";
const fullscreen = "<button type='button' id='fullscreen' class='leaflet-control-fullscreen-in icon icon_screen' title='Отключить полноэкранный режим'>\ue936</button>";

class FullscreenControl extends MapControl {
  componentWillUnmount() {
    return null;
  }

  updateLeafletElement(prevProps, props) {
    if (prevProps.isFullscreen !== props.isFullscreen) {
      this.leafletElement._container.innerHTML = prevProps.isFullscreen ? screen : fullscreen;
    }
  }

  createLeafletElement(props) {
    this._map = props.leaflet.map;

    const Ext = Leaflet.Control.extend({
      options: {
        position: 'topright',
        ...props
      },
      _renderIcon() {
        return this.fullscreenSelected ? fullscreen : screen;
      },
      onAdd: function onAdd() {
        const divContainer = Leaflet.DomUtil.create('div', 'leaflet-control-fullscreen leaflet-bar');
        divContainer.onclick = () => {
          this.fullscreenSelected = !this.fullscreenSelected;
          divContainer.innerHTML = this._renderIcon();
          props.onScreenIconClick();
        };
        divContainer.innerHTML = this._renderIcon();
        return divContainer;
      }
    });
    return new Ext();
  }
}
 export default withLeaflet(FullscreenControl);
