/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/newline-after-import */

const Dummy = require('./ssr-dummy').default;

let Leaflet = Dummy;
let InputMap = Dummy;
let InputBbox = Dummy;
let InputGeometry = Dummy;
let Control = Dummy;
let GoogleLayer = Dummy;
let BaseLayers = Dummy;
let BaseLayersUser = Dummy;
let InfoLayer = Dummy;
let InfoLayerBboxWrapper = Dummy;
let InfoLayerInputWrapper = Dummy;
let ObjectMarker = Dummy;
let MeasureControl = Dummy;
let LayerSwitch = Dummy;
let LocateBtn = Dummy;
let marker = Dummy;
// React Leaflet
let AttributionControl = Dummy;
let Circle = Dummy;
let CircleMarker = Dummy;
let FeatureGroup = Dummy;
let GeoJSON = Dummy;
let GridLayer = Dummy;
let ImageOverlay = Dummy;
let LayerGroup = Dummy;
let LayersControl = Dummy;
let Map = Dummy;
let MapComponent = Dummy;
let MapControl = Dummy;
let MapLayer = Dummy;
let Marker = Dummy;
let Pane = Dummy;
let Path = Dummy;
let Polygon = Dummy;
let Polyline = Dummy;
let Popup = Dummy;
let Rectangle = Dummy;
let ScaleControl = Dummy;
let TileLayer = Dummy;
let Tooltip = Dummy;
let VideoOverlay = Dummy;
let WMSTileLayer = Dummy;
let ZoomControl = Dummy;
let FullscreenControl = Dummy;
let withLeaflet = function() {};

if (__CLIENT__) {
  const ReactLeaflet = require('react-leaflet');
  require('leaflet-editable');
  require('leaflet-contextmenu');
  // require('./vendor/leaflet-path-drag');
  // require('./vendor/leaflet-editable-drag');
  require('./vendor/path-drag');
  require('./circleEditor');
  require('./leaflet-switch');
  require('./leaflet-helpers');

  Leaflet = require('leaflet');
  InputMap = require('./input-map').default;
  InputBbox = require('./input-bbox').default;
  InputGeometry = require('./input-geometry').default;
  Control = require('react-leaflet-control').default;
  GoogleLayer = require('./layers').GoogleLayer;
  BaseLayers = require('./layers').BaseLayers;
  BaseLayersUser = require('./layers').BaseLayersUser;
  InfoLayer = require('./info-layer').default;
  InfoLayerInputWrapper = require('./info-layer-input-wrapper').default;
  InfoLayerBboxWrapper = require('./info-layer-bbox-wrapper').default;
  ObjectMarker = require('./objectMarker').default;
  MeasureControl = require('./leaflet-measure').default;
  LayerSwitch = require('./leaflet-layerswitch').default;
  LocateBtn = require('./leaflet-locate-btn').default;
  marker = require('./marker-icons').marker;
  FullscreenControl = require('./fullscreen').default;

  AttributionControl = ReactLeaflet.AttributionControl;
  Circle = ReactLeaflet.Circle;
  CircleMarker = ReactLeaflet.CircleMarker;
  FeatureGroup = ReactLeaflet.FeatureGroup;
  GeoJSON = ReactLeaflet.GeoJSON;
  GridLayer = ReactLeaflet.GridLayer;
  ImageOverlay = ReactLeaflet.ImageOverlay;
  LayerGroup = ReactLeaflet.LayerGroup;
  LayersControl = ReactLeaflet.LayersControl;
  Map = ReactLeaflet.Map;
  MapComponent = ReactLeaflet.MapComponent;
  MapControl = ReactLeaflet.MapControl;
  MapLayer = ReactLeaflet.MapLayer;
  Marker = ReactLeaflet.Marker;
  Pane = ReactLeaflet.Pane;
  Path = ReactLeaflet.Path;
  Polygon = ReactLeaflet.Polygon;
  Polyline = ReactLeaflet.Polyline;
  Popup = ReactLeaflet.Popup;
  Rectangle = ReactLeaflet.Rectangle;
  ScaleControl = ReactLeaflet.ScaleControl;
  TileLayer = ReactLeaflet.TileLayer;
  Tooltip = ReactLeaflet.Tooltip;
  VideoOverlay = ReactLeaflet.VideoOverlay;
  WMSTileLayer = ReactLeaflet.WMSTileLayer;
  ZoomControl = ReactLeaflet.ZoomControl;
  withLeaflet = ReactLeaflet.withLeaflet;
}

export {
  Leaflet,
  InputMap,
  InputBbox,
  InputGeometry,
  Control,
  GoogleLayer,
  BaseLayers,
  BaseLayersUser,
  InfoLayer,
  InfoLayerInputWrapper,
  InfoLayerBboxWrapper,
  ObjectMarker,
  MeasureControl,
  LayerSwitch,
  LocateBtn,
  marker,
  AttributionControl,
  Circle,
  CircleMarker,
  FeatureGroup,
  GeoJSON,
  GridLayer,
  ImageOverlay,
  LayerGroup,
  LayersControl,
  Map,
  MapComponent,
  MapControl,
  MapLayer,
  Marker,
  Pane,
  Path,
  Polygon,
  Polyline,
  Popup,
  Rectangle,
  ScaleControl,
  TileLayer,
  Tooltip,
  VideoOverlay,
  WMSTileLayer,
  ZoomControl,
  FullscreenControl,
  withLeaflet
};
