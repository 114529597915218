import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import Swipe from 'react-easy-swipe';
import cssClasses from './helpers/cssClasses';
import { outerWidth } from './helpers/dimensions';
import cssTranslate from './helpers/cssTranslate';

class Thumbs extends Component {
  static displayName = 'Thumbs';

  static propTypes = {
    children: PropTypes.node.isRequired,
    transitionTime: PropTypes.number,
    selectedItem: PropTypes.number,
    onSelectItem: PropTypes.func,
    axis: PropTypes.string
  };

  static defaultProps = {
    selectedItem: 0,
    transitionTime: 350,
    axis: 'horizontal'
  };

  constructor(props) {
    super(props);
    this.onSwipeStart = this.onSwipeStart.bind(this);
    this.onSwipeMove = this.onSwipeMove.bind(this);
    this.onSwipeEnd = this.onSwipeEnd.bind(this);
    this.moveTo = this.moveTo.bind(this);
    this.slideLeft = this.slideLeft.bind(this);
    this.slideRight = this.slideRight.bind(this);
    this.handleClickItem = this.handleClickItem.bind(this);
    this.updateSizes = this.updateSizes.bind(this);

    this.state = {
      initialized: false,
      selectedItem: props.selectedItem,
      firstItem: this.getFirstItem(props.selectedItem),
      images: []
    };
  }

  componentDidMount() {
    if (!this.props.children) return;
    this.setupThumbs();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.selectedItem !== this.state.selectedItem) {
      this.setState({
        selectedItem: props.selectedItem,
        firstItem: this.getFirstItem(props.selectedItem)
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.children && this.props.children && !this.state.initialized) this.setupThumbs();
  }

  componentWillUnmount() {
    this.destroyThumbs();
  }

  onSwipeStart() {
    this.setState({ swiping: true });
  }

  onSwipeEnd() {
    this.setState({ swiping: false });
  }

  onSwipeMove(deltaX, event) {
    const leftBoundry = 0;
    const list = event.currentTarget;
    const wrapperSize = list.clientWidth;

    const currentPosition = -this.state.firstItem * this.itemSize;
    const lastLeftBoundry = -this.visibleItems * this.itemSize;

    // prevent user from swiping left out of boundaries
    if (currentPosition === leftBoundry && deltaX > 0) deltaX = 0; //eslint-disable-line

    // prevent user from swiping right out of boundaries
    if (currentPosition === lastLeftBoundry && deltaX < 0) deltaX = 0; //eslint-disable-line

    const position = `${currentPosition + (100 / (wrapperSize / deltaX))}%`;

    // if 3d isn't available we will use left to move
    [
      'WebkitTransform',
      'MozTransform',
      'MsTransform',
      'OTransform',
      'transform',
      'msTransform'
    ].forEach((prop) => {
      list.style[prop] = cssTranslate(position, this.props.axis);
    });
  }

  getImages() {
    const images = Children.map(this.props.children, (item) => {
      let img = item;

      // if the item is not an image, try to find the first image in the item's children.
      if (item.type !== 'img') {
        img = Children.toArray(item.props.children).filter(children => children.type === 'img')[0];
      }

      if (!img || img.length === 0) return null;
      return img;
    });

    if (images.filter(image => image !== null).length === 0) {
      // eslint-disable-next-line
      console.warn('No images found! Can\'t build the thumb list without images. If you don\'t need thumbs, set showThumbs={false} in the Carousel. Note that it\'s not possible to get images rendered inside custom components. More info at https://github.com/leandrowd/react-responsive-carousel/blob/master/TROUBLESHOOTING.md');
      return null;
    }

    return images;
  }

  setupThumbs() {
    // as the widths are calculated, we need to resize
    // the carousel when the window is resized
    document.addEventListener('resize', this.updateSizes);
    // issue #2 - image loading smaller
    document.addEventListener('DOMContentLoaded', this.updateSizes);

    const images = this.getImages();
    if (!images) return;

    this.setState({
      initialized: true,
      images
    });

    // when the component is rendered we need to calculate
    // the container size to adjust the responsive behaviour
    this.updateSizes();
  }

  getFirstItem(selectedItem) {
    if (!this.showArrows) return 0;

    let firstItem = selectedItem;
    if (selectedItem >= this.lastPosition) firstItem = this.lastPosition;
    if (selectedItem < (this.state.firstItem + this.visibleItems)) firstItem = this.state.firstItem;
    if (selectedItem < this.state.firstItem) firstItem = selectedItem;

    return firstItem;
  }

  moveTo(position) {
    // position can't be lower than 0
    position = position < 0 ? 0 : position; //eslint-disable-line
    // position can't be higher than last postion
    position = position >= this.lastPosition ? this.lastPosition : position; //eslint-disable-line

    this.setState({
      firstItem: position,
      // if it's not a slider, we don't need to set position here
      selectedItem: this.state.selectedItem
    });
  }

  slideLeft(positions) {
    this.moveTo(this.state.firstItem + (typeof positions === 'Number' ? positions : 1)); //eslint-disable-line
  }

  slideRight(positions) {
    this.moveTo(this.state.firstItem - (typeof positions === 'Number' ? positions : 1)); //eslint-disable-line
  }

  handleClickItem(index, item) {
    const handler = this.props.onSelectItem;
    if (typeof handler === 'function') handler(index, item);
  }

  updateSizes() {
    if (!this.state.initialized) return;

    const total = this.props.children.length;
    this.wrapperSize = this.itemsWrapper.clientWidth;
    this.itemSize = outerWidth(this.thumb0);
    this.visibleItems = Math.floor(this.wrapperSize / this.itemSize);
    this.lastPosition = total - this.visibleItems;
    this.showArrows = this.visibleItems < total;
  }

  destroyThumbs() {
    // removing listeners
    document.removeEventListener('resize', this.updateSizes);
    document.removeEventListener('DOMContentLoaded', this.updateSizes);
  }

  renderItems() {
    return this.state.images.map((img, index) => {
      const itemClass = cssClasses.item(
        false,
        index === this.state.selectedItem
      );
      const thumbProps = {
        key: index,
        ref: ref => this[`thumb${index}`] = ref,
        className: itemClass,
        onClick: this.handleClickItem.bind(this, index, this.props.children[index])
      };

      return <li {...thumbProps} children={img} />;
    });
  }

  render() {
    if (!this.props.children || this.state.images.length === 0) return null;
    // show left arrow?
    const hasPrev = this.showArrows && this.state.firstItem > 0;
    // show right arrow
    const hasNext = this.showArrows && this.state.firstItem < this.lastPosition;
    const currentPosition = `${(-this.state.firstItem * this.itemSize) / 10} + rem`;
    const transformProp = cssTranslate(currentPosition, this.props.axis);
    const transitionTime = `${this.props.transitionTime} + ms`;

    // obj to hold the transformations and styles
    const itemListStyles = {
      WebkitTransform: transformProp,
      MozTransform: transformProp,
      MsTransform: transformProp,
      OTransform: transformProp,
      transform: transformProp,
      msTransform: transformProp,
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      MsTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime
    };

    return (
      <div className={cssClasses.carousel(false, true)}>
        <div
          className={cssClasses.wrapper(false)}
          ref={node => this.itemsWrapper = node}
        >
          <button
            type="button"
            className={cssClasses.arrowPrev(!hasPrev)}
            onClick={this.slideRight}
          />
          <Swipe
            tagName="ul"
            selectedItem={this.state.selectedItem}
            className={cssClasses.slider(false, this.state.swiping)}
            onSwipeLeft={this.slideLeft}
            onSwipeRight={this.slideRight}
            onSwipeMove={this.onSwipeMove}
            onSwipeStart={this.onSwipeStart}
            onSwipeEnd={this.onSwipeEnd}
            style={itemListStyles}
            ref={node => this.itemList = node}
          >
            {this.renderItems()}
          </Swipe>
          <button
            type="button"
            className={cssClasses.arrowNext(!hasNext)}
            onClick={this.slideLeft}
          />
        </div>
      </div>
    );
  }
}

export default Thumbs;
