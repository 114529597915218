/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import GeoLandings from 'subsys/geo-landings';
import { dictConnect } from 'api/data-dict';
import Row, { Col } from 'components/row';
import Link from 'components/link';
import Image from 'components/image';

import translates from './help-block-i18n.json';

import 'styles/bild/page-home/blocks/_block-help.scss';

const classesBlockHelp = new Bem('blockHelp');

export default class HelpBlock extends Component {
  static propTypes = {
    className: PropTypes.string,
    complexTree: PropTypes.string,
    otherProps: PropTypes.shape({})
  };

  render() {
    const { className, complexTree, otherProps = {} } = this.props;

    return (
      <GeoLandings base={{}}>
        <GeoHelpBlock className={className} complexTree={complexTree} otherProps={otherProps} />
      </GeoLandings>
    );
  }
}

@dictConnect({
  url: 'location/region-promotional-links',
  injectPropName: 'regionPromotionalLinks',
})
@I18nHoc(translates)
class GeoHelpBlock extends Component {
  static propTypes = {
    className: PropTypes.string,
    geolocation: PropTypes.shape({}),
    regionPromotionalLinks: PropTypes.array, // eslint-disable-line
    complexTree: PropTypes.string,
    otherProps: PropTypes.shape({})
  };

  render() {
    const { i18n } = this;
    const {
      className, geolocation = {}, complexTree, regionPromotionalLinks, otherProps = {}
    } = this.props;
    const selectedAgency = checkIsRegionFounded(geolocation, complexTree, regionPromotionalLinks);

    if (!selectedAgency) return null;

    const head = get(selectedAgency, 'head');
    const name = get(selectedAgency, 'name');
    const url = get(selectedAgency, 'url');
    const description = get(selectedAgency, 'description');
    const image = get(selectedAgency, 'image');
    const dataLinkProps = get(otherProps, 'dataLink', null);

    return (
      <div {...classesBlockHelp({ extra: className })}>
        <Row {...classesBlockHelp('row')} mtColumn mpColumn>
          <Col
            {...classesBlockHelp('col', 'info')}
            allPr={0}
            mdAdjust={670}
            mtOrder={1}
            mpOrder={1}
          >
            <h2
              {...classesBlockHelp('text', 'h2')}
              children={head}
            />
            <div {...classesBlockHelp('description')}>
              <span
                {...classesBlockHelp('text', 'description')}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <Link
              {...classesBlockHelp('link')}
              href={url}
              builderProps={{ title: i18n('link') }}
              rel="nofollow"
              dataProps={{ 'target': name || 'other', 'type': 'help', ...dataLinkProps }}
              external
              blank
            >
              <span {...classesBlockHelp('linkContent')}>
                <span {...classesBlockHelp('linkShadow')} />
                <span {...classesBlockHelp('text', 'link')} children={i18n('link')} />
              </span>
            </Link>
          </Col>
          <Col {...classesBlockHelp('col', 'img')} allPr={0} mdCw={670}>
            <div {...classesBlockHelp('imgContainer')}>
              <Image
                {...classesBlockHelp('img')}
                image={{ id: image, renderer: 'orig' }}
                fullScreenSwitch="none"
              />
              <div {...classesBlockHelp('onPhoto')}>
                <div {...classesBlockHelp('onPhotoBlock')}>
                  <span {...classesBlockHelp('img', 'substrate')} />
                  <Row {...classesBlockHelp('row')}>
                    {name &&
                      <Col {...classesBlockHelp('col', 'topic')} allPr={0}>
                        <span {...classesBlockHelp('text')} children={name} />
                      </Col>
                    }
                    <Col {...classesBlockHelp('col', 'text')} allPr={0}>
                      <span {...classesBlockHelp('text')} children={i18n('textOnPhoto')} />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export function checkIsRegionFounded(geolocation, complexTree, regionPromotionalLinks) {
  const tree = complexTree || get(geolocation, 'location.tree');
  const confirmed = get(geolocation, 'confirmed');
  if (!complexTree && !confirmed) return null;

  const treeArr = tree ? tree.split('.') : [];
  let currentRegion = null;

  forEach(treeArr, (val) => {
    const index = findIndex(regionPromotionalLinks, { region_id: +val });
    if (index > -1) {
      return currentRegion = regionPromotionalLinks[index];
    }
  });

  const agencyArray = get(currentRegion, 'links.agency_array');
  if (!agencyArray) return null;

  const random = agencyArray.length > 1 ?
    Math.floor(Math.random() * agencyArray.length) :
    0;

  return agencyArray[random];
}
