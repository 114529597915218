import React, { Component, cloneElement } from 'react';
// import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-addons-css-transition-group';
import Bem from 'bemHelper';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import findIndex from 'lodash/findIndex';
import each from 'lodash/each';
import sensor from 'components/sensor';
import CommonError from 'errors/commonError';


const bemClasses = new Bem('modalMultiStep');
const cssTransitionConfig = {
  enter: 'enter',
  leave: 'leave',
  classes: {
    right: 'Right',
    left: 'Left'
  }
};

@sensor
export default class StepChanger extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.shape({})
    ]),
    renderContent: PropTypes.func.isRequired,
    idField: PropTypes.string,
    headerField: PropTypes.string,
    transitionEnter: PropTypes.bool,
    transitionLeave: PropTypes.bool,
    stepRef: PropTypes.func
  };

  static defaultProps = {
    idField: 'id',
    headerField: 'title',
    transitionEnter: true,
    transitionLeave: true
  };

  /**
   * Устанавливает классы для анимации переключения контента между шагами
   *
   * @param action
   * @param direction
   * @returns {[action: string]: string}
   */
  static setTransitionClass(action, direction) {
    const start = `modalMultiStep__modalContent_${action}${direction}`;
    const end = `${start}Active`;
    return {
      [action]: start,
      [`${action}Active`]: end
    };
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.goBackwards = this.goBackwards.bind(this);
    this.goForward = this.goForward.bind(this);
    this.changeStep = this.changeStep.bind(this);

    const { enter, leave, classes } = cssTransitionConfig;
    this.allowedSteps = [1, -1];
    this.parent = props.data;
    this.idxPath = [];

    this.state = {
      currentStep: 0,
      transitionEnter: StepChanger.setTransitionClass(enter, classes.left),
      transitionLeave: StepChanger.setTransitionClass(leave, classes.left)
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    // this.props.stepRef(findDOMNode(this));
  }

  /**
   * Определяет родителя следующего/предыдущего шага,
   * а также собирает путь из ключей для объектов или из индексов для массивов.
   *
   * @param item
   */
  getParent(item = null) {
    if (!item) this.idxPath = this.idxPath.slice(0, -1);

    const { data, idField } = this.props;
    const length = this.idxPath.length;
    let parent = data;
    if (length) each(this.idxPath, idx => parent = parent[idx].children);

    if (item) {
      if (parent && isArray(parent)) {
        const index = findIndex(parent, it => it[idField] === item[idField]);
        this.parent = parent[index];
        this.idxPath.push(index);
      } else if (parent) {
        this.parent = parent[item[idField]];
        this.idxPath.push(item[idField]);
      }
    } else {
      this.parent = parent;
    }
  }

  /**
   * Переход на шаг назад
   */
  goBackwards(e, stateModifier = null) {
    const { enter, leave, classes } = cssTransitionConfig;

    this.getParent();
    // Дополнительные изменения в стейте через колбек
    const stateAdds = stateModifier ? stateModifier(this.state) : {};

    const newState = {
      ...this.state,
      ...stateAdds,
      currentStep: this.state.currentStep - 1,
      transitionEnter: StepChanger.setTransitionClass(enter, classes.right),
      transitionLeave: StepChanger.setTransitionClass(leave, classes.right)
    };

    this.setState(newState);
  }

  /**
   * Переход на шаг вперед.
   * Принимает аргументом объект выбранного элемента, для рендера след шага.
   *
   * @param stateModifier
   * @param item
   */
  goForward(stateModifier = null, item = null) {
    if (!item) {
      // eslint-disable-next-line
      throw new CommonError('В метод "goForward" не передан обязательный агумент - объект выбранного шага');
    }

    const { enter, leave, classes } = cssTransitionConfig;

    this.getParent(item);
    // Дополнительные изменения в стейте через колбек
    const stateAdds = stateModifier ? stateModifier(this.state, item) : {};

    const newState = {
      ...this.state,
      ...stateAdds,
      currentStep: this.state.currentStep + 1,
      transitionEnter: StepChanger.setTransitionClass(enter, classes.left),
      transitionLeave: StepChanger.setTransitionClass(leave, classes.left)
    };

    this.setState(newState);
  }

  /**
   * Смена шага: +1, -1
   *
   * @param step
   */
  changeStep(step) {
    if (!isNumber(step) || !(this.allowedSteps.indexOf(step) >= 0)) {
      // eslint-disable-next-line
      throw new CommonError(`Метод "changeStep" ожидает аргумент равный либо +1 либо -1. Передано значение - ${step}`);
    }

    const { enter, leave, classes } = cssTransitionConfig;
    const goToPrev = Math.sign(step) < 0;
    const newState = { ...this.state };

    newState.currentStep += step;
    newState.transitionEnter = StepChanger.setTransitionClass(enter, classes[goToPrev ? 'right' : 'left']);
    newState.transitionLeave = StepChanger.setTransitionClass(leave, classes[goToPrev ? 'right' : 'left']);

    this.setState(newState);
  }

  renderContent() {
    const { data, renderContent } = this.props;
    const { currentStep } = this.state;

    return renderContent({
      goForward: this.goForward,
      changeStep: this.changeStep,
      parent: data ? this.parent.children || this.parent : null,
      currentStep
    });
  }

  render() {
    const { currentStep, transitionEnter, transitionLeave } = this.state;
    const transitionDuration = (toNumber(this.sensor.getVariable('timeDuration')) || 0) * 2;
    const { enter, enterActive } = transitionEnter;
    const { leave, leaveActive } = transitionLeave;
    const content = this.renderContent();

    return (
      <CSSTransitionGroup
        component="div"
        {...bemClasses('contentGroup', null, this.props.className)}
        transitionName={{
          enter,
          enterActive,
          leave,
          leaveActive
        }}
        transitionEnter={this.props.transitionEnter}
        transitionLeave={this.props.transitionLeave}
        transitionEnterTimeout={transitionDuration}
        transitionLeaveTimeout={transitionDuration}
      >
        {cloneElement(content,
          {
            key: `modalContent_${currentStep}`,
            className: content.props.className
              ? `${bemClasses('modalContent').className} ${content.props.className}`
              : bemClasses('modalContent').className
          }
        )}
      </CSSTransitionGroup>
    );
  }
}
