import React, { Component } from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { authConnect, UserProp } from 'auth';
import localStorage from 'helpers/local-storage';
import DataProvider from 'api/data-provider';
import { subscribe, unsubscribe } from 'helpers/global-events';

@authConnect()
export default class FavoriteWrapper extends Component {
  static propTypes = {
    project: PropTypes.string.isRequired,
    entity: PropTypes.string,
    auth: UserProp,
    children: PropTypes.func,
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.state = {
      clientFirstLoad: !get(props, 'auth.authenticated', false),
      localStorageChanged: 0
    };

    subscribe('favoriteCount', this.localStorageChanged);
  }

  componentDidMount() {
    if (this.state.clientFirstLoad) this.setFirstLoadFalse();
  }

  shouldComponentUpdate(nextState) {
    if (this.state.clientFirstLoad !== nextState.clientFirstLoad) return true;
    if (this.state.localStorageChanged !== nextState.localStorageChanged) return true;
    return false;
  }

  componentWillUnmount() {
    unsubscribe('favoriteCount', this.localStorageChanged);
  }

  setFirstLoadFalse = () => {
    this.setState({ clientFirstLoad: false });
  };

  localStorageChanged = () => {
    if (!get(this, 'props.auth.authenticated')){
      this.setState({ localStorageChanged: this.state.localStorageChanged + 1});
    }
  };

  render() {
    if (this.state.clientFirstLoad) return this.props.children({ data: [] });

    const { auth, project, entity } = this.props;
    const query = { project };
    if (entity) query.entity = entity;

    if (!auth.authenticated || this.state.localStorageChanged) {
      let favStorage = localStorage.get('favorites') || [];
      favStorage = filter(favStorage, query);

      return this.props.children({
        data: favStorage
      });
    }

    return (
      <DataProvider
        disableLoader
        url="domik/favorites/favorites"
        query={query}
        updateEvent="countFavoritesProvider"
        renderer={this.props.children}
      />
    );
  }
}
