import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import Bem from 'bemHelper';

import AbstractInput from './abstractInput';
import { VALIDATION_VALID } from './form-consts';

import 'styles/base/containers/form/_input-label.scss';

const bemClasses = new Bem('inputLabel');

/**
 * === InputLabel Component ===
 * Renders label/value text
 *
 * = Example =
 * <InputLabel dataBind={dataBind} tagSpan />
 */

export default class InputLabel extends AbstractInput {
  static propTypes = {
    ...AbstractInput.props,

    // Renders label with absolute styles
    inside: PropTypes.bool,

    // Renders text from value instead of label
    valueToShow: PropTypes.bool,

    // Renders label inside span tag
    tagSpan: PropTypes.bool,

    // Renders bold label text
    bold: PropTypes.bool,

    // Label text width auto
    horizontal: PropTypes.bool
  };

  static getNeededHtml(text, required) {
    return (
      <>
        <span {...bemClasses('text', 'label')}>{text}</span>
        {required && <>&nbsp;<span {...bemClasses('text', 'asterisk')}>*</span></>}
      </>
    );
  }

  renderContent() {
    const { schema = {}, value, valueToShow } = this.props;
    const { label, required } = schema;

    // renders label
    if (!valueToShow && schema && label) return InputLabel.getNeededHtml(label, required);

    // renders value
    if (valueToShow && value) return InputLabel.getNeededHtml(value, false);

    return null;
  }

  render() {
    if (!this.props.schema) return null;

    const {
      schema: { required } = {}, value,
      inside, valueToShow, tagSpan, bold, horizontal, className
    } = this.props;
    const HtmlElement = tagSpan ? 'span' : 'div';
    const validationState =
      this.props.validationState ? this.props.validationState.state : VALIDATION_VALID;
    const mods = {
      required,
      error: validationState !== VALIDATION_VALID,
      inside,
      bold,
      horizontal,
      filled: inside && (isArray(value) ? !!value.length : value || value === 0),
      valueToShow
    };

    return (
      <HtmlElement {...bemClasses(null, mods, className)} data-test="inputLabel">
        <HtmlElement {...bemClasses('labelContainer')} children={this.renderContent()} />
      </HtmlElement>
    );
  }
}
