import Leaflet from 'leaflet';
import each from 'lodash/each';

Leaflet.Control.ButtonSwitchControl = Leaflet.Control.extend({
  options: {
    position: 'topright',
    buttons: {},
    title: null,
    icon: null,
    // Mode, when click on already selected button turns this button off
    switchMode: false
  },

  setSelected(key) {
    this._select(this.options.buttons[key], key, true);
  },

  _select(val, key, nc, evt) {
    if (evt) {
      evt.stopPropagation();
    }

    // Switch button off if needed
    if (
      this.selected &&
      this.options.switchMode &&
      this.selected === this.options.buttons[key]
    ) {
      Leaflet.DomUtil.removeClass(this.selected.element, 'mapButton__btn_selected');
      this.selected = null;
      if (!nc && this.options.onSelect) {
        this.options.onSelect(null, null);
      }
      return;
    }

    if (this.options.buttons[key]) {
      const sBtn = this.options.buttons[key];
      if (this.selected && !sBtn.onClick) {
        Leaflet.DomUtil.removeClass(this.selected.element, 'mapButton__btn_selected');
      }

      if (!sBtn.onClick) {
        this.selected = this.options.buttons[key];
        Leaflet.DomUtil.addClass(this.selected.element, 'mapButton__btn_selected');
      }
      if (!nc && this.options.onSelect) {
        this.options.onSelect(val, key);
      }
    }
  },

  _updateButtons(buttons, div) {
    div.innerHTML = ''; // eslint-disable-line
    if (this.options.title) {
      const el = this._innerStructure('mapButtonTitle', this.options.title, false, 'text');
      div.appendChild(el);
    }

    let selectedKey = this.selected ? this.selected.id : null;
    each(buttons, (val, key) => {
      const textOrIcon = val.icon ? 'icon' : 'text';
      const el = this._innerStructure('mapButton', val.icon || val.label, true, textOrIcon, val.iconClass, val.hint, key);
      el.onclick = evt => this._select(val, key, false, evt);
      el.ondblclick = evt => evt.stopPropagation();
      el.onmousedown = evt => evt.stopPropagation();
      el.onmousemove = evt => evt.stopPropagation();
      el.onmouseup = evt => evt.stopPropagation();
      el.onmouseout = evt => evt.stopPropagation();
      el.onmouseover = evt => evt.stopPropagation();
      this.options.buttons[key].element = el;
      div.appendChild(el);
      if (val.checked && !selectedKey) selectedKey = key;
    });
    if (selectedKey) this.setSelected(selectedKey);
  },

  updateButtons(buttons) {
    this.options.buttons = buttons;
    const container = this.getContainer();
    if (container) {
      const div = container.firstElementChild;
      this._updateButtons(buttons, div);
    }
  },

  _innerStructure(className, content, button, textOrIcon, iconClass, hint, key) {
    if (!button) {
      const el = Leaflet.DomUtil.create('span', className);
      const text = Leaflet.DomUtil.create('span', `${className}__${textOrIcon}`, el);
      text.innerHTML = content;
      return el;
    }
    const btnClassName = key ? `${className}__btn ${className}__btn_${key}` : `${className}__btn`;
    const btn = Leaflet.DomUtil.create('button', btnClassName);
    const btnContent = Leaflet.DomUtil.create('span', `${className}__btnContent`, btn);
    let innerClassName = `${className}__${textOrIcon}`;
    if (iconClass) innerClassName = `${textOrIcon} ${textOrIcon}_${iconClass} ${innerClassName}`;
    const innerElement = Leaflet.DomUtil.create('span', innerClassName, btnContent);
    innerElement.innerHTML = content;
    if (hint) btn.title = hint;
    btn.type = 'button';
    return btn;
  },

  onAdd: function onAdd() {
    const divContainer = Leaflet.DomUtil.create('div', 'mapButtonSwitchContainer');
    const div = Leaflet.DomUtil.create('div', 'mapButtonSwitch', divContainer);

    this._updateButtons(this.options.buttons, div);
    return divContainer;
  }
});
