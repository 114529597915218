import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Container from 'components/container';
import Row, { Col } from 'components/row';
import Link from 'components/link';

import imgApartments from '../assets/imgApartments.jpg';
import imgCommercial from '../assets/imgCommercial.jpg';
import imgCottage from '../assets/imgCottage.jpg';

import translates from './links-block-i18n.json';

const classesBlockLinks = new Bem('blockLinks');

@I18nHoc(translates)
export default class LinksBlock extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  renderCol() {
    const { i18n } = this;
    const bildLinks = [
      {
        name: i18n('topic1'),
        url: i18n('urlLC'),
        src: imgApartments
      },
      {
        name: i18n('topic2'),
        url: i18n('urlCottageTown'),
        src: imgCottage
      },
      {
        name: i18n('topic3'),
        url: i18n('urlCommerce'),
        src: imgCommercial
      }
    ];

    return map(bildLinks, (item, index) => (
      <Col {...classesBlockLinks('col')} md={4} mt={4} allPr={0} key={index}>
        <div {...classesBlockLinks('block')}>
          <img {...classesBlockLinks('img')} src={item.src} alt={item.name} loading="lazy" decoding="async" />
          <Link
            {...classesBlockLinks('link')}
            href={item.url}
            blank
          >
            <span {...classesBlockLinks('linkShadow')} />
            <span {...classesBlockLinks('linkContent')}>
              <span {...classesBlockLinks('imgShape', 'before')} />
              <span {...classesBlockLinks('imgShape', 'before lighter')} />
              <span {...classesBlockLinks('arrow', 'top right')}>
                <span {...classesBlockLinks('arrowLine')} />
              </span>
              <span {...classesBlockLinks('text', 'h3')} children={item.name} />
            </span>
          </Link>
        </div>
      </Col>
    ));
  }

  render() {
    const { className } = this.props;

    return (
      <div {...classesBlockLinks({ extra: className })}>
        <Container body>
          <Row {...classesBlockLinks('row')} mpColumn>
            {this.renderCol()}
          </Row>
        </Container>
      </div>
    );
  }
}
