import React, { Children, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import map from 'lodash/map';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';

const bemClasses = new Bem('paginatorInner');

/**
 * === InnerLinks Component ===
 * Компонент рендерит ссылки внутри рендер-айтема пагинатора
 * на соответствующую страницу данной темы/новости и тд.
 *
 * = Example =
 * <InnerLinks
 *   linkBuilder={linkBuilder}
 *   linkBuilderProps={obj}
 * />
 *
 * = Note =
 * Пропс linkBuilderProps должен содержать ключ page,
 * который определяет количество страниц в данном рендер-айтеме.
 */

export default class InnerLinks extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    linkBuilder: PropTypes.func.isRequired,
    linkBuilderProps: PropTypes.shape({
      page: PropTypes.number
    }).isRequired
  };

  constructor(props, ctx) {
    super(props, ctx);

    const { linkBuilderProps: { page } = {} } = props;
    this.pagesAmount = [];
    this.lastPages = [];
    this.lastPages.length = 2;

    this.state = {};
  }

  static getDerivedStateFromProps({ linkBuilderProps: { page = 0 } = {} }, state) {
    if (state.pagesAmountLength !== page) return { pagesAmountLength: page };
    return null;
  }

  setLink(page) {
    const { linkBuilder, linkBuilderProps } = this.props;
    return (
      <Link
        key={page}
        {...bemClasses('link')}
        builder={linkBuilder}
        builderProps={{
          ...linkBuilderProps,
          innerLinkTitle: `страница ${page}`,
          page
        }}
      >
        <span {...bemClasses('linkContent')}>
          <span {...bemClasses('text')}>{page}</span>
        </span>
      </Link>
    );
  }

  renderLinks() {
    const { length } = this.pagesAmount;

    if (length <= 3) {
      return map(this.pagesAmount, (it, index) => this.setLink(index + 1));
    }

    return (
      <React.Fragment>
        {this.setLink(1)}
        <span {...bemClasses('btn', 'dots', 'btn')}>
          <span {...bemClasses('btnContent', null, 'btn__content')}>
            <span {...bemClasses('text')}>. . .</span>
          </span>
        </span>
        {map(this.lastPages, (it, index) => this.setLink(length - (1 - index)))}
      </React.Fragment>
    );
  }

  render() {
    if (this.state.pagesAmountLength < 2) return null;

    return (
      <div {...bemClasses({ extra: this.props.className })}>
        <Icon {...bemClasses('icon', 'pages')} type={iconTypes.pages} />
        {this.renderLinks()}
      </div>
    );
  }
}
