import formatDateTranslates from 'helpers/format-date-i18n.json';
import numDescTranslates from 'helpers/num-desc-i18n.json';
import reliabilityIndexTranslates from '../section-project/reliability-index-i18n.json';
import cardStockTranslates from '../section-stocks/stocks-i18n.json';
import configLabelsTranslates from '../section-project/config-labels-i18n';

export default function () {
  return {
    ...formatDateTranslates,
    ...numDescTranslates,
    ...reliabilityIndexTranslates,
    ...cardStockTranslates,
    ...configLabelsTranslates,

    textMinutes: {
      ru: 'мин.',
      ua: 'хв.'
    },
    textFrom: {
      ru: 'от',
      ua: 'від'
    },
    price: {
      ru: 'Цена',
      ua: 'Ціна'
    },
    verified: {
      ru: 'Проверено',
      ua: 'Перевірено'
    },
    notOnMapText: {
      ru: 'нет на карте',
      ua: 'немає на карті'
    },
    overflight: {
      ru: 'Аэрооблёт',
      ua: 'Аерообліт'
    },

    noOffersText: {
      ru: 'Предложений нет',
      ua: 'Пропозицій нема'
    },

    noDataText: {
      ru: 'нет данных',
      ua: 'даних нема'
    },

    premises: {
      ru: 'Помещения',
      ua: 'Приміщення'
    },

    btnViewListTitle: {
      ru: 'Вид списком',
      ua: 'Вигляд списком'
    },
    btnViewTileTitle: {
      ru: 'Вид плиткой',
      ua: 'Вигляд плиткою'
    },

    btnCardSoldHide: {
      ru: 'Скрыть проданные планировки',
      ua: 'Сховати продані планування'
    },
    btnCardSoldShow: {
      ru: 'Показать проданные планировки',
      ua: 'Показати продані планування'
    },

    floor: {
      ru: 'Этаж',
      ua: 'Поверх'
    },
    numberOfStoreys: {
      ru: 'Этажность',
      ua: 'Поверховість'
    },
    sold: {
      ru: 'Продано',
      ua: 'Продано'
    },
    aboutDiscount: {
      ru: 'Узнать о скидках',
      ua: 'Дізнатись про знижки'
    },
    aboutDetail: {
      ru: 'Уточнить детали',
      ua: 'Уточнити деталі'
    }
  };
}
