import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import simpleStore from 'helpers/simpleStore';
import Button from 'components/button';

import translates from './hide-telephone-i18n.json';

import 'styles/base/components/hide-telephone/_hide-telephone.scss';

const bemClasses = new Bem('hideTelephone');

@I18nHoc(translates)
export default class HideTelephone extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    phone: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    classMods: PropTypes.shape({}),
    btnLabel: PropTypes.string
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.openThisPhone = this.openThisPhone.bind(this);
    this.state = { openedPhone: !!props.active };

    this.openedPhones = simpleStore.get('openedPagePhones');
    if (props.active) this.addToStore(props.phone);
  }

  componentWillMount() {
    if (this.openedPhones && this.openedPhones.indexOf(this.props.phone) !== -1) {
      this.setState({ openedPhone: true });
    }
  }

  componentWillReceiveProps({ phone, active }) {
    let phoneWasOpened = !!this.openedPhones && this.openedPhones.indexOf(phone) !== -1;
    if (active && !phoneWasOpened) {
      this.addToStore(phone);
      phoneWasOpened = true;
    }

    if (this.props.phone !== phone) {
      this.setState({ openedPhone: phoneWasOpened });
    }
  }

  addToStore(phone) {
    if (!this.openedPhones) return null;

    const index = this.openedPhones.indexOf(phone);
    if (index === -1) {
      this.openedPhones.push(phone);
      simpleStore.set('openedPagePhones', this.openedPhones);
    }
  }

  openThisPhone() {
    if (this.state.openedPhone) return;
    if (this.props.onClick) this.props.onClick();
    this.addToStore(this.props.phone);
    this.setState({ openedPhone: true });
  }

  render() {
    const { openedPhone } = this.state;
    const { className, phone, classMods = {}, btnLabel } = this.props;
    const label = btnLabel || `${phone.substr(0, (phone.length - 4))}X XX`;

    if (openedPhone) {
      return (
        <a
          {...bemClasses(null, { opened: openedPhone, ...classMods }, className)}
          href={`tel:${phone}`}
          title={phone}
        >
          <span {...bemClasses('btnContent')}>
            <span {...bemClasses('text')} children={phone} />
          </span>
        </a>
      );
    }

    return (
      <Button
        {...bemClasses(null, { opened: openedPhone, ...classMods }, className)}
        onClick={this.openThisPhone}
        title={this.i18n('phoneShowSeller')}
        label={label}
      />
    );
  }
}
