/* eslint-disable */
/**
 * Created by sem on 09.06.17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { connect } from 'react-redux';
import intersection from 'lodash/intersection';
import CommonError from 'errors/commonError';
import { configureScope as sentryConfigureScope } from '@sentry/browser';

import * as actions from './redux';
import LoginFormContainer from './login-form-container';
import ConfirmContainer from './confirm-container';

const bemClasses = new Bem('formLogin');

import 'styles/base/auth/_login-form.scss';

export const UserProp = PropTypes.shape({
  authenticated: PropTypes.bool,
  id: PropTypes.number,
  email: PropTypes.string,
  name: PropTypes.string,
  afterLoginTrigger: PropTypes.shape({
    id: PropTypes.string,
    action: PropTypes.func,
  })
});

// let requesting = false;

const HEADER = 'Требуется авторизация';

/**
 * Request authentication state (if needed) and inject
 * auth prop.
 * Also can check authentication state and return authenticate
 * block instead of component.
 * Can check permissions and block access, if user has no permissions
 * to access.
 * Config:
 *   * required - Authentication required. If user not authenticated -
 *     returns auth component
 *   * loginTitle - Login page (in "required" mode) title
 * @return {wrapComponent}
 */
export function authConnect(config = {}) {
  return function wrapComponent(BaseComponent) {
    @connect(state => ({
      auth: state.auth,
    }), { ...actions }, null, { forwardRef: true })
    class AuthWrap extends Component {
      static propTypes = {
        auth: PropTypes.shape({
          loading: PropTypes.bool,
          loaded: PropTypes.bool,
          failure: PropTypes.bool,
          data: UserProp
        }),
        actionAuthGetData: PropTypes.func
      };

      constructor(props, ctx) {
        super(props, ctx);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.checkPermission = this.checkPermission.bind(this);
        // this.rerequestUserData = this.rerequestUserData.bind(this);
        this.defHeader = config.loginTitle || HEADER;
        this.state = {
          header: this.defHeader
        }
      }

      componentDidMount() {
        const { loaded } = this.props.auth;
        if (loaded) {
          this._registerSentryContext(this.props);
        }
      }

      componentDidUpdate() {
        const { loaded } = this.props.auth;
        if (loaded) {
          this._registerSentryContext(this.props);
        }
      }

      _registerSentryContext(props) {
        const { data = {} } = props.auth;
        sentryConfigureScope((scope) => {
          scope.setUser( {
            id: data.id,
            username: data.name,
            email: data.email
          })
        });
      }

      /**
       * Check "virtual" permissions - such as user
       * authenticated, approved, confirmed etc
       * @return bool
       */
      _checkVirtPermissions() {
        const { authenticated = false } = this.props.auth.data;
        return authenticated;
      }

      /**
       * Check, is user has a corresponding permission
       * @param perm
       * @return bool
       */
      checkPermission(perm) {
        const { permissions = []} = this.props.auth.data;
        return (
          this._checkVirtPermissions() &&
          permissions.indexOf(perm) > -1
        );
      }

      /**
       * Check, is user has any of corresponding permissions
       * @param perms
       * @return bool
       */
      checkPermissions(perms) {
        const { permissions = []} = this.props.auth.data;
        return (
          this._checkVirtPermissions() &&
          intersection(permissions, perms).length > 0
        );
      }

      /**
       * Render
       */
      renderComponent() {
        const {
          auth,
          wRef,
          rcForwRef,
          ...props
        } = this.props;
        if (wRef) {
          props.ref = wRef;
        }
        if (rcForwRef) {
          props.ref = rcForwRef;
        }

        return React.createElement(
          BaseComponent,
          {
            auth: {
              afterLoginTrigger: auth.afterLoginTrigger,
              ...auth.data
            },
            authRequested: auth.loaded,
            checkPermission: this.checkPermission,
            checkPermissions: this.checkPermissions,
            ...props
          }
        );
      }

      /**
       * Render authentication form
       */
      renderAuthBlock() {
        return (
          <>
            <div {...bemClasses('topic')}>
              <div {...bemClasses('text', 'h1')}>
                <h1 children={this.state.header} />
              </div>
            </div>
            <LoginFormContainer
              changeHeader={h => this.setState({ header: h || this.defHeader })}
            />
          </>
        );
      }

      renderUnconfirmed() {
        return <ConfirmContainer />;
      }

      renderForbidden() {
        return (
          <>
            <div {...bemClasses('topic')}>
              <div {...bemClasses('text', 'h1')}>
                <h1 children="You don't have permissions to access" />
              </div>
            </div>
            <div>
              <span {...bemClasses('text')} children="You don't have permissions to access" />
            </div>
          </>
        );
      }

      render() {
        const { auth } = this.props;
        const { loaded, failure, data = {} } = auth;

        if (__DEV_CLI__) {
          if (config.permissions && !config.required) {
            throw new CommonError('AuthConnect config: if permissions is set, you also need a "required" params');
          }
        }

        if (config.required) {
          if (failure) {
            return 'Authentication state loading failure. Try again or later.';
          }
          if (!loaded) {
            return <div />;
          }
          // *** Auth required
          if (!data.authenticated) {
            return this.renderAuthBlock();
          }
          // *** Unconfirmed auth check
          if (
            data.auth &&
            data.auth.unconfirmed
          ) {
            return this.renderUnconfirmed();
          }
          // *** Permissions check
          if (
            config.permissions &&
            !this.checkPermissions(config.permissions)
          ) {
            return this.renderForbidden();
          }
        }

        return this.renderComponent();
      }
    }
    return React.forwardRef((props, ref) => {
      return <AuthWrap {...props} rcForwRef={ref} />
    });
    //return AuthWrap;
  };
}
