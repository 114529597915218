/**
 * Created by Kotkin on 04.05.2017.
 */
import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import get from 'lodash/get';
import Bem from 'bemHelper';
import Icon from 'components/icon';
import Button from 'components/button';

import 'styles/base/components/drag-scroll/_drag-scroll.scss';

const classes = new Bem('dragScroll');

const delayTimeout = 500;

/**
 *
 * === DragScroll Component ===
 *
 * компонент для перетаскивания чилдов, которые не помещаются на странице в ряд
 * Используется в табах
 *
 */

class DragScroll extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,

    // Icon type in button which scrolls to the containers very end/start position
    scrollIconType: PropTypes.string.isRequired,

    // Child index which should be scrolled into visible area after update
    scrollTo: PropTypes.number
  };

  constructor(props, context) {
    super(props, context);
    this.onActionStart = this.onActionStart.bind(this);
    this.onActionEnd = this.onActionEnd.bind(this);
    this.onActionMove = this.onActionMove.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScrollClick = this.handleScrollClick.bind(this);

    this.state = { dragging: false, showButton: false, scrolledToEnd: false };

    this.unmounted = false;
    this.clicked = false;
    this.delay = null;
    this.slideTimer = null;
  }

  componentDidMount() {
    // TODO add event listeners on mouse events
    document.addEventListener('mouseup', this.onActionEnd);
    document.addEventListener('mousemove', this.onActionMove);

    if (this.props.scrollTo) this.scrollToTab();
  }

  componentDidUpdate(prevProps) {
    this.updateDimension = true;
    if (this.props.scrollTo !== prevProps.scrollTo) this.scrollToTab();
  }

  componentWillUnmount() {
    // TODO remove event listeners on mouse events
    document.removeEventListener('mouseup', this.onActionEnd);
    document.removeEventListener('mousemove', this.onActionMove);

    this.unmounted = true;
  }

  onActionStart(e) {
    if (this.isMouseRightClick(e)) return false;
    if (!this.state.dragging) {
      this.lastClientX = e.type !== 'mousedown' ? e.changedTouches[0].clientX : e.clientX;
      this.setState({ dragging: true });
    }
  }

  onActionEnd(e) {
    if (this.state.dragging) {
      // TODO check which side closer user stop dragging component to
      if (this.container.scrollLeft <= 20 && this.state.scrolledToEnd) {
        this.afterScrollingAction();
      }
      if (
        this.container.scrollWidth - this.container.offsetWidth - this.container.scrollLeft <= 20 &&
        !this.state.scrolledToEnd
      ) this.afterScrollingAction();

      this.setState({ dragging: false });

      e.preventDefault();
    }
  }

  onActionMove(e) {
    if (this.state.dragging) {
      this.container.scrollLeft -= -this.lastClientX +
        (this.lastClientX = e.type !== 'mousemove' ? e.changedTouches[0].clientX : e.clientX);
    }
  }

  /**
   * Checks whether it was a click with right mouse button or not
   * @returns {boolean}
   */
  isMouseRightClick(e) { // eslint-disable-line
    let rightClick = false;
    if (e.which) {
      rightClick = e.which === 3;
    } else if (e.button) {
      rightClick = e.button === 2;
    }
    return rightClick;
  }

  updateDimensions() {
    clearTimeout(this.delay);

    // Compare container's viewport and scroll width
    // to find out whether we need to render scroll button or not.
    // Delay is needed to prevent changing state every millisecond on resizing
    this.delay = setTimeout(() => {
      if (this.unmounted || !this.container) return false;

      const distance = this.container.scrollWidth - this.container.offsetWidth;
      const buttonWidth = this.button ? this.button.offsetWidth : 0;

      if (this.state.showButton && distance <= buttonWidth) {
        return this.setState({ showButton: false });
      }
      if (!this.state.showButton && distance > 1) {
        return this.setState({ showButton: true });
      }
    }, delayTimeout);
  }

  scrollToTab() {
    const { offsetWidth, scrollLeft, childNodes, lastChild } = this.container;
    const childOffsetRight = lastChild.offsetLeft + lastChild.offsetWidth;
    if (offsetWidth - childOffsetRight < 0) {
      const difference = scrollLeft - get(childNodes, `[${this.props.scrollTo}].offsetLeft`);
      const direction = difference < 0 ? 'right' : 'left';

      this.smoothScroll(this.container, Math.abs(difference), direction);
    }
  }

  handleScrollClick() {
    if (!this.clicked) {
      this.clicked = !this.clicked;
      const distance = this.container.scrollWidth - this.container.offsetWidth;

      if (this.state.scrolledToEnd) {
        this.afterScrollingAction();
        return this.smoothScroll(this.container, distance, 'left');
      }
      this.afterScrollingAction();
      return this.smoothScroll(this.container, distance);
    }
  }

  afterScrollingAction() {
    if (this.state.scrolledToEnd) return this.setState({ scrolledToEnd: false });
    return this.setState({ scrolledToEnd: true });
  }

  smoothScroll(element, distance = 100, direction, speed = 15, step = 10) {
    let scrollAmount = 0;
    clearInterval(this.slideTimer);

    this.slideTimer = setInterval(() => {
      if (direction === 'left') {
        element.scrollLeft -= step; // eslint-disable-line
      } else {
        element.scrollLeft += step; // eslint-disable-line
      }

      scrollAmount += step;
      if (scrollAmount >= distance) {
        this.clicked = false;
        clearInterval(this.slideTimer);
      }
    }, speed);
  }

  renderChild(item, parentClassName) {
    if (this.updateDimension) this.updateDimensions();
    this.updateDimension = false;
    return (
      <div
        {...classes({
          extra: parentClassName
        })}
        ref={node => this.drag = node}
      >
        {cloneElement(item, {
          onMouseDown: this.onActionStart,
          onMouseUp: this.onActionEnd,
          // onTouchStart: this.onActionStart,
          // onTouchEnd: this.onActionEnd,
          // onTouchMove: this.onActionMove,
          ref: ref => this.container = ref
        })}
        {!this.state.showButton &&
          <ReactResizeDetector
            onResize={this.updateDimensions}
            handleWidth
          />
        }
        {this.state.showButton &&
          <Button
            {...classes('btn', this.state.scrolledToEnd ? 'rotate' : null)}
            btnRef={ref => this.button = ref}
            onClick={this.handleScrollClick}
            customRenderer
          >
            <span {...classes('btnContent')}>
              <span {...classes('translucentHorizontal')} />
              <Icon {...classes('icon')} type={this.props.scrollIconType} />
            </span>
          </Button>
        }
      </div>
    );
  }

  render() {
    return this.renderChild(this.props.children, this.props.className);
  }
}

export default DragScroll;
