/**
 * Created by Kotkin on 28.08.2017.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import some from 'lodash/some';
import each from 'lodash/each';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Button from 'components/button';

import translates from './input-clearfield-i18n.json';

import 'styles/base/containers/form/_input-clearfield.scss';

const bemClasses = new Bem('inputClearField');

/**
 * === InputClearField ===
 * Очищает пропс value в переданном ему dataBind или dataVisualize
 *
 * = Example =
 * <InputClearField
 *   dataBind={dataBind}
 * />
 *
 * <InputClearField
 *   dataVisualize={[
 *     dataBind111,
 *     dataBind222,
 *     dataBind333
 *   ]}
 * />
 */

@I18nHoc(translates)
export default class InputClearField extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    // eslint-disable-next-line
    value: PropTypes.any,
    values: PropTypes.shape({}),
    inputClearProps: PropTypes.shape({})
  };

  static checkAnyValNotEmpty(values) {
    return some(values, (val) => {
      if (Array.isArray(val)) {
        return !!val.length;
      }
      if (isObject(val)) return !!val.features && !!val.features.length;
      return !!val;
    });
  }

  static checkForValLength(val) {
    if (isObject(val) && isArray(val.features)) return val.features.length;
    if (isArray(val)) return val.length;
    return !!val;
  }

  constructor(...args) {
    super(...args);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    if (this.props.values) {
      return each(this.props.values, (val, key) =>
        this.props.onChange(key, null)
      );
    }

    this.props.onChange(null);
  }

  render() {
    const { value, values, className, inputClearProps } = this.props;

    if (
      values
        ? !InputClearField.checkAnyValNotEmpty(values)
        : !InputClearField.checkForValLength(value)
    ) return null;

    const otherProps = inputClearProps || { label: this.i18n('clear') };

    return (
      <div {...bemClasses({ extra: className })}>
        <Button
          {...bemClasses('btn')}
          onClick={this.onChange}
          {...otherProps}
        />
      </div>
    );
  }
}
