import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';

import {
  lazyStartIndex,
  lazyEndIndex,
  getPreClones
} from './utils/innerSliderUtils';

const bemClasses = new Bem('slickSlider');

export default class Track extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    slidesToScroll: PropTypes.number,
    slidesToShow: PropTypes.number,
    currentSlide: PropTypes.number,
    rtl: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    infinite: PropTypes.bool,
    fade: PropTypes.bool,
    lazyLoadedList: PropTypes.arrayOf(PropTypes.number),
    focusOnSelect: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
    trackStyle: PropTypes.shape({}),
    slideProps: PropTypes.shape({}),
    trackRef: PropTypes.func
  };

  // given specifications/props for a slide,
  // fetch all the classes that need to be applied to the slide
  static getSlideClasses(spec) {
    let slickActive;
    let slickCenter;
    const index = spec.rtl ? spec.slideCount - 1 - spec.index : spec.index;
    const slickCurrent = index === spec.currentSlide;
    const slickCloned = index < 0 || index >= spec.slideCount;

    if (spec.centerMode) {
      const centerOffset = Math.floor(spec.slidesToShow / 2);
      slickCenter = (index - spec.currentSlide) % spec.slideCount === 0;
      if (
        index > spec.currentSlide - centerOffset - 1 &&
        index <= spec.currentSlide + centerOffset
      ) {
        slickActive = true;
      }
    } else {
      slickActive =
        spec.currentSlide <= index &&
        index < spec.currentSlide + spec.slidesToShow;
    }

    return {
      active: slickActive,
      center: slickCenter,
      cloned: slickCloned,
      current: slickCurrent // dubious in case of RTL
    };
  }

  static getSlideStyle(spec) {
    const style = {};

    if (spec.variableWidth === undefined || spec.variableWidth === false) {
      style.width = spec.slideWidth;
    }

    if (spec.fade) {
      style.position = 'relative';
      if (spec.vertical) {
        style.top = -spec.index * parseInt(spec.slideHeight, 10);
      } else {
        style.left = -spec.index * parseInt(spec.slideWidth, 10);
      }
      style.opacity = spec.currentSlide === spec.index ? 1 : 0;
      style.transition = `opacity ${spec.speed}ms ${spec.cssEase}, visibility ${spec.speed}ms ${spec.cssEase}`;
      style.WebkitTransition = `opacity ${spec.speed}ms ${spec.cssEase}, visibility ${spec.speed}ms ${spec.cssEase}`;
    }

    return style;
  }

  onChildClick(e, child, options) {
    if (child.props && child.props.onClick) child.props.onClick(e);
    if (this.props.focusOnSelect) {
      this.props.focusOnSelect(options);
    }
  }

  renderSlides() {
    const {
      children, slidesToScroll, slidesToShow, currentSlide, rtl,
      lazyLoad, lazyLoadedList, infinite, fade, slideProps = {}
    } = this.props;

    let key;
    const slides = [];
    const preCloneSlides = [];
    const postCloneSlides = [];
    const childrenCount = Children.count(children);
    const startIndex = lazyStartIndex(this.props);
    const endIndex = lazyEndIndex(this.props);

    Children.forEach(children, (elem, index) => {
      let child;
      const childOnClickOptions = {
        message: 'children',
        index,
        slidesToScroll,
        currentSlide
      };

      // in case of lazyLoad, whether or not we want to fetch the slide
      if (
        !lazyLoad ||
        (lazyLoad && lazyLoadedList.indexOf(index) >= 0)
      ) {
        child = elem;
      } else {
        child = <div />;
      }

      const slideClass = child.props.className || '';
      const childStyle = Track.getSlideStyle({ ...this.props, index });
      let slideClasses = Track.getSlideClasses({ ...this.props, index });

      const commonProps = {
        tabIndex: '-1',
        'aria-hidden': !slideClasses.active,
        style: { ...(child.props.style || {}), ...childStyle },
        onClick: e => this.onChildClick(e, child, childOnClickOptions),
        ...slideProps
      };

      // push a cloned element of the desired slide
      slides.push(
        cloneElement(child, {
          ...bemClasses('slide', slideClasses, slideClass),
          key: `original${child.key || index}`,
          'data-index': index,
          ...commonProps,
          style: { outline: 'none', ...(child.props.style || {}), ...childStyle }
        })
      );

      // if slide needs to be precloned or postcloned
      if (infinite && fade === false) {
        const preCloneNo = childrenCount - index;

        if (
          preCloneNo <= getPreClones(this.props) &&
          childrenCount !== slidesToShow
        ) {
          key = -preCloneNo;
          if (key >= startIndex) {
            child = elem;
          }
          slideClasses = Track.getSlideClasses({ ...this.props, index: key });
          preCloneSlides.push(
            cloneElement(child, {
              ...bemClasses('slide', slideClasses, slideClass),
              key: `precloned${child.key || key}`,
              'data-index': key,
              ...commonProps
            })
          );
        }

        if (childrenCount !== slidesToShow) {
          key = childrenCount + index;
          if (key < endIndex) {
            child = elem;
          }
          slideClasses = Track.getSlideClasses({ ...this.props, index: key });
          postCloneSlides.push(
            cloneElement(child, {
              ...bemClasses('slide', slideClasses, slideClass),
              key: `postcloned${child.key || key}`,
              'data-index': key,
              ...commonProps
            })
          );
        }
      }
    });

    if (rtl) return [...preCloneSlides, ...slides, ...postCloneSlides].reverse();
    return [...preCloneSlides, ...slides, ...postCloneSlides];
  }

  render() {
    const { onMouseEnter, onMouseOver, onMouseLeave, trackStyle, trackRef } = this.props;
    const mouseEvents = { onMouseEnter, onMouseOver, onMouseLeave };

    return (
      <div
        ref={(el) => {
          if (trackRef) trackRef(el);
        }}
        {...bemClasses('group')}
        style={trackStyle}
        {...mouseEvents}
      >
        {this.renderSlides()}
      </div>
    );
  }
}
