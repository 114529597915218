/* eslint-disable */
import PropTypes from 'prop-types';
import Leaflet from 'leaflet';
import { MapControl, withLeaflet } from 'react-leaflet';
import 'leaflet.polylinemeasure';

/**
 * For more information and available options list
 * @see https://github.com/ppete2/Leaflet.PolylineMeasure
 */
class MeasureControl extends MapControl {
  static propTypes = {
    position: PropTypes.string,
    unit: PropTypes.string,
    measureControlTitleOn: PropTypes.string,
    measureControlLabel: PropTypes.string,
    measureControlClasses: PropTypes.arrayOf(PropTypes.string),
    cursor: PropTypes.string,
    clearMeasurementsOnStop: PropTypes.bool,
    showMeasurementsClearControl: PropTypes.bool
  };

  static defaultProps = {
    position: 'topright',
    unit: 'metres',
    measureControlTitle: 'Измерить расстояние',
    measureControlLabel: '\ue98d',
    measureControlClasses: ['leaflet-control-measure', 'icon', 'icon_locationMeasure'],
    backgroundColor: 'rgba(0, 0, 0, .5)',
    cursor: 'crosshair',
    clearMeasurementsOnStop: true,
    showMeasurementsClearControl: false
  };

  createLeafletElement(props) {
    return Leaflet.control.polylineMeasure(props);
  }

  componentWillUnmount() {
    return null;
  }
}

export default withLeaflet(MeasureControl);
