import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import Swipe from 'react-easy-swipe';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import cssTranslate from './helpers/cssTranslate';
import cssClasses from './helpers/cssClasses';
import Thumbs from './thumbs';

import 'styles/base/components/carousel/_carousel.scss';

const noop = () => {};

/**
 * = Carousel Component =
 *
 * === Example ===
 * 1. Horizontal Rows
 * <Carousel>
 *   {map(this.props...., (val) => <ImageBlock data={val} />)}
 * </Carousel>
 *
 * <Carousel>
 *   <SomeComponent>
 *     <div>Some content</div>
 *   </SomeComponent>
 *   <SomeComponent>
 *     Some Content
 *   </SomeComponent>
 *   ...
 * </Carousel>
 *
 * 2. Vertical Multiple Rows
 * <Carousel slidesToShow={2}>
 *   <verticalRow>
 *     <Object />
 *     <Object />
 *     <Object />
 *   </verticalRow>
 *   <verticalRow>
 *     <Object />
 *     <Object />
 *     <Object />
 *   </verticalRow>
 *   <verticalRow>
 *     <Object />
 *     <Object />
 *     <Object />
 *   </verticalRow>
 *   ...
 * </Carousel>
 *
 */

class Carousel extends Component {
  static displayName = 'Carousel';

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,

    // Show prev and next arrows
    showArrows: PropTypes.bool,

    // Show index of the current item (1/8)
    showStatus: PropTypes.bool,

    // Show little dots at the bottom with links for changing the item
    showIndicators: PropTypes.bool,

    // Infinite loop sliding
    infiniteLoop: PropTypes.bool,

    // Show thumbnails of the images (works only with tag img)
    showThumbs: PropTypes.bool,

    // Selects an item though props/defines the initial selected item
    selectedItem: PropTypes.number,

    // Amount of items to show on the screen at the same time
    slidesToShow: PropTypes.number,

    // Fired when an item is clicked
    onClickItem: PropTypes.func.isRequired,

    // Fired when a thumb is clicked
    onClickThumb: PropTypes.func.isRequired,

    // Fired after changing item
    onChange: PropTypes.func.isRequired,

    // Changes sliding orientation - accepts 'horizontal' and 'vertical'
    axis: PropTypes.oneOf(['horizontal', 'vertical']),

    // Adds support to next and prev through keyboard arrows
    useKeyboardArrows: PropTypes.bool,

    autoPlay: PropTypes.bool,

    // Stop auto play while mouse is over the carousel
    stopOnHover: PropTypes.bool,

    // Interval of auto play
    interval: PropTypes.number,

    // duration of slide transitions (in milliseconds)
    transitionTime: PropTypes.number,

    // Allows scroll when the swipe movement occurs in a different direction
    // than the carousel axis and within the tolerance:
    // - Increase for loose,
    // - Decrease for strict.
    swipeScrollTolerance: PropTypes.oneOfType([PropTypes.number]),

    // Adjust the carousel height if required (Do not work with vertical axis)
    dynamicHeight: PropTypes.bool,

    // Allows mouse to simulate swipe
    emulateTouch: PropTypes.bool,

    // Callback after changing positions
    onPageChanged: PropTypes.func,

    // Carousel have to be fullScreen
    fullScreen: PropTypes.bool
  };

  static defaultProps = {
    showIndicators: true,
    showArrows: true,
    showStatus: false,
    showThumbs: false,
    slidesToShow: 1,
    infiniteLoop: false,
    selectedItem: 0,
    axis: 'horizontal',
    useKeyboardArrows: false,
    autoPlay: false,
    stopOnHover: true,
    interval: 3000,
    transitionTime: 350,
    swipeScrollTolerance: 5,
    dynamicHeight: false,
    emulateTouch: false,
    onClickItem: noop,
    onClickThumb: noop,
    onChange: noop,
    onPageChanged: noop,
    fullScreen: true
  };

  constructor(props) {
    super(props);
    this.onSwipeStart = this.onSwipeStart.bind(this);
    this.onSwipeMove = this.onSwipeMove.bind(this);
    this.onSwipeEnd = this.onSwipeEnd.bind(this);
    this.setMountState = this.setMountState.bind(this);
    this.getInitialImage = this.getInitialImage.bind(this);
    this.getVariableImageHeight = this.getVariableImageHeight.bind(this);
    this.modifyChildren = this.modifyChildren.bind(this);
    this.autoPlay = this.autoPlay.bind(this);
    this.clearAutoPlay = this.clearAutoPlay.bind(this);
    this.resetAutoPlay = this.resetAutoPlay.bind(this);
    this.stopOnHover = this.stopOnHover.bind(this);
    this.navigateWithKeyboard = this.navigateWithKeyboard.bind(this);
    this.updateSizes = this.updateSizes.bind(this);
    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleClickThumb = this.handleClickThumb.bind(this);
    this.decrement = this.decrement.bind(this);
    this.increment = this.increment.bind(this);
    this.moveTo = this.moveTo.bind(this);
    this.selectItem = this.selectItem.bind(this);

    this.state = {
      initialized: false,
      selectedItem: props.selectedItem > -1 ? props.selectedItem : 0,
      hasMount: false
    };
  }

  UNSAFE_componentWillMount() {
    this.vertical = false;
    this.modifyChildren(this.props.children);
  }

  componentDidMount() {
    if (!this.props.children) return;
    this.setupCarousel();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItem !== this.state.selectedItem) {
      this.updateSizes();
      this.setState({
        selectedItem: nextProps.selectedItem
      });
    }

    if (nextProps.autoPlay !== this.props.autoPlay) {
      if (nextProps.autoPlay) return this.setupAutoPlay();
      return this.destroyAutoPlay();
    }

    if (this.props.children !== nextProps.children) {
      this.modifyChildren(nextProps.children);
    }

    if (this.props.slidesToShow !== nextProps.slidesToShow) {
      this.modifyChildren(nextProps.children, nextProps.slidesToShow);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.children && this.props.children && !this.state.initialized) this.setupCarousel();
  }

  componentWillUnmount() {
    this.destroyCarousel();
  }

  onSwipeStart(event) {
    this.startXPos = event.changedTouches[0].clientX;
    this.startYPos = event.changedTouches[0].clientY;
    this.setState({ swiping: true });
    this.clearAutoPlay();
  }

  onSwipeEnd() {
    this.setState({ swiping: false });
    this.autoPlay();
    if (this.props.axis === 'horizontal') {
      // TODO: allow scroll up/down the page without changing slide
      if (Math.abs(this.swipedX) < 20 ||
        Math.abs(this.swipedY) - Math.abs(this.swipedX) > 20) return false;
      if (Math.sign(this.swipedX) === -1) return this.increment();
      if (Math.sign(this.swipedX) === 1) return this.decrement();
    }
  }

  onSwipeMove(delta = {}, event) {
    const list = event.currentTarget;
    const isHorizontal = this.props.axis === 'horizontal';

    const initialBoundry = 0;

    const currentPosition = -this.state.selectedItem * 100;
    const finalBoundry = -(this.children ? this.children.length - 1 : 0) * 100;

    const axisDelta = isHorizontal ? delta.x : delta.y;

    this.swipedX = event.changedTouches[0].clientX - this.startXPos;
    this.swipedY = event.changedTouches[0].clientY - this.startYPos;

    let handledDelta = axisDelta;

    // prevent user from swiping left out of boundaries
    if (currentPosition === initialBoundry && axisDelta > 0) handledDelta = 0;

    // prevent user from swiping right out of boundaries
    if (currentPosition === finalBoundry && axisDelta < 0) handledDelta = 0;

    let position = `${currentPosition + (100 / (this.state.itemSize / handledDelta))}%`;
    // TODO: allow scroll up/down the page without changing slide
    if (isHorizontal && Math.abs(this.swipedX) > 20) {
      if (Math.abs(this.swipedY) > 20) position = currentPosition;
      [
        'WebkitTransform',
        'MozTransform',
        'MsTransform',
        'OTransform',
        'transform',
        'msTransform'
      ].forEach((prop) => {
        list.style[prop] = cssTranslate(position, this.props.axis);
      });
    }

    // allows scroll if the swipe was within the tolerance
    const hasMoved = Math.abs(axisDelta) > this.props.swipeScrollTolerance;
    if (hasMoved && !this.state.cancelClick) this.setState({ cancelClick: true });

    return hasMoved;
  }

  setupCarousel() {
    this.bindEvents();

    if (this.props.autoPlay) this.setupAutoPlay();

    this.setState({ initialized: true });

    const initialImage = this.getInitialImage();
    if (initialImage) {
      // if it's a carousel of images, we set the mount state after the first image is loaded
      initialImage.addEventListener('load', this.setMountState);
    } else {
      this.setMountState();
    }
  }

  setupAutoPlay() {
    this.autoPlay();
    const carouselWrapper = this.carouselWrapper;

    if (this.props.stopOnHover && carouselWrapper) {
      carouselWrapper.addEventListener('mouseenter', this.stopOnHover);
      carouselWrapper.addEventListener('mouseleave', this.autoPlay);
    }
  }

  setMountState() {
    // this.setState({ hasMount: true });
    this.updateSizes();
  }

  getInitialImage() {
    const selectedItem = this.props.selectedItem;
    const item = this[`item${selectedItem}`];
    const images = item && item.getElementsByTagName('img');
    return images && images[selectedItem];
  }

  getVariableImageHeight(position) {
    const item = this[`item${position}`];
    const images = item && item.getElementsByTagName('img');
    if (this.state.hasMount && images.length > 0) {
      const image = images[0];

      if (!image.complete) {
        // if the image is still loading, the size won't be available
        // so we trigger a new render after it's done
        const onImageLoad = () => {
          this.forceUpdate();
          image.removeEventListener('load', onImageLoad);
        };

        image.addEventListener('load', onImageLoad);
      }

      const height = image.clientHeight;
      return height > 0 ? height : null;
    }

    return null;
  }

  modifyChildren(props, slidesToShow = this.props.slidesToShow) {
    const itemsLength = props.length;
    let elem = [];
    const children = [];
    let step = 0;
    this.dotAmount = itemsLength < 5 ? itemsLength : 5;
    this.dotActive = 1;

    if (!props.length) return;
    if (props.some(child => child.type === 'verticalRow')) this.vertical = true;
    if (
      slidesToShow === 1 ||
      slidesToShow === itemsLength
    ) return this.children = props || [];

    // TODO children modification depending on amount of the slides needed to be shown
    forEach(props, (child, index) => {
      if (step === slidesToShow) {
        children.push(elem);
        elem = [];
        step = 0;
      } else if (index === itemsLength - 1) {
        elem.push(
          child.type !== 'verticalRow' ?
            createElement('div', { children: child, className: 'slider__slide_horizontal' }) :
            createElement('div', { children: child.props.children, className: 'slider__slide_vertical' })
        );
        return children.push(elem);
      }
      elem.push(
        child.type !== 'verticalRow' ?
          createElement('div', { children: child, className: 'slider__slide_horizontal' }) :
          createElement('div', { children: child.props.children, className: 'slider__slide_vertical' })
      );
      step += 1;
    });
    this.children = children || [];
  }

  destroyCarousel() {
    if (this.state.initialized) {
      this.unbindEvents();
      this.destroyAutoPlay();
    }
  }

  destroyAutoPlay() {
    this.clearAutoPlay();
    const carouselWrapper = this.carouselWrapper;

    if (this.props.stopOnHover && carouselWrapper) {
      carouselWrapper.removeEventListener('mouseenter', this.stopOnHover);
      carouselWrapper.removeEventListener('mouseleave', this.autoPlay);
    }
  }

  bindEvents() {
    // as the widths are calculated, we need to resize
    // the carousel when the window is resized
    document.addEventListener('resize', this.updateSizes);
    // issue #2 - image loading smaller
    document.addEventListener('DOMContentLoaded', this.updateSizes);

    if (this.props.useKeyboardArrows) document.addEventListener('keydown', this.navigateWithKeyboard);
  }

  unbindEvents() {
    // removing listeners
    document.removeEventListener('resize', this.updateSizes);
    document.removeEventListener('DOMContentLoaded', this.updateSizes);

    const initialImage = this.getInitialImage();
    if (initialImage) initialImage.removeEventListener('load', this.setMountState);

    if (this.props.useKeyboardArrows) document.removeEventListener('keydown', this.navigateWithKeyboard);
  }

  autoPlay() {
    if (!this.props.autoPlay) return;

    this.timer = setTimeout(() => this.increment(), this.props.interval);
  }

  clearAutoPlay() {
    if (!this.props.autoPlay) return;
    clearTimeout(this.timer);
  }

  resetAutoPlay() {
    this.clearAutoPlay();
    this.autoPlay();
  }

  stopOnHover() {
    this.clearAutoPlay();
  }

  navigateWithKeyboard(e) {
    const nextKeys = ['ArrowDown', 'ArrowRight'];
    const prevKeys = ['ArrowUp', 'ArrowLeft'];
    const allowedKeys = nextKeys.concat(prevKeys);

    if (allowedKeys.indexOf(e.key) > -1) {
      if (nextKeys.indexOf(e.key) > -1) {
        this.increment();
      } else if (prevKeys.indexOf(e.key) > -1) {
        this.decrement();
      }
    }
  }

  updateSizes() {
    if (!this.state.initialized) return;

    const isHorizontal = this.props.axis === 'horizontal';
    const firstItem = this.item0;
    const itemSize = isHorizontal ? firstItem.clientWidth : firstItem.clientHeight;

    this.setState({
      itemSize,
      wrapperSize: isHorizontal ? itemSize * this.children.length : itemSize
    });
  }

  handleClickItem(index, item) {
    if (this.state.cancelClick) return this.setState({ cancelClick: false });

    this.props.onClickItem(index, item);
    if (index !== this.state.selectedItem) this.setState({ selectedItem: index });
  }

  handleOnChange(index, item) {
    this.props.onChange(index, item);
  }

  handleClickThumb(index, item) {
    this.props.onClickThumb(index, item);
    this.selectItem({ selectedItem: index });
  }

  decrement(positions) {
    const selected = this.state.selectedItem;

    if (this.dotActive === 1 ||
      selected === 1) this.dotActive = 5; else this.dotActive -= 1;

    this.moveTo(selected - (typeof positions === 'number' ? positions : 1));
  }

  increment(positions) {
    const selected = this.state.selectedItem;

    if (
      this.dotActive === 5 ||
      (this.children && selected === this.children.length - 2)
    ) this.dotActive = 1; else this.dotActive += 1;

    this.moveTo(selected + (typeof positions === 'number' ? positions : 1));
  }

  moveTo(position) {
    const lastPosition = this.children ? this.children.length - 1 : 0;

    if (position < 0) position = this.props.infiniteLoop ? lastPosition : 0; //eslint-disable-line
    if (position > lastPosition) position = this.props.infiniteLoop ? 0 : lastPosition; //eslint-disable-line

    this.selectItem({
      // if it's not a slider, we don't need to set position here
      selectedItem: position
    });
    this.props.onPageChanged(position);

    if (this.props.autoPlay) this.resetAutoPlay();
  }

  // changeItem = (e) => {
  //   const newIndex = e.target.value;
  //   this.selectItem({ selectedItem: newIndex });
  // };

  selectItem(state) {
    this.setState(state);
    this.handleOnChange(state.selectedItem, this.children[state.selectedItem]);
  }

  renderItems() {
    if (!this.children) return null;

    return map(this.children, (item, index) => {
      const itemClass = cssClasses.item(true, index === this.state.selectedItem);
      return (
        <li //eslint-disable-line
          ref={ref => this[`item${index}`] = ref}
          key={`itemKey${index}`}
          className={itemClass}
          children={item.type === 'verticalRow' ? item.props.children : item}
          onClick={() => this.handleClickItem(index, item)}
        />
      );
    });
  }

  renderControls() {
    if (!this.props.showIndicators) return null;
    const controls = [];
    for (let i = 1; i <= this.dotAmount; i += 1) {
      controls.push(
        <li
          className={cssClasses.dot(i === this.dotActive)}
          // onClick={this.changeItem}
          key={i}
        />
      );
    }

    return (
      <ul className="control-dots" children={controls} />
    );
  }

  renderStatus() {
    if (!this.props.showStatus) return null;
    return <p className="carousel-status">{this.state.selectedItem + 1} of {this.children ? this.children.length : 0}</p>;
  }

  renderThumbs() {
    if (!this.props.showThumbs || (this.children && this.children.length === 0)) return null;
    return (
      <Thumbs
        onSelectItem={this.handleClickThumb}
        selectedItem={this.state.selectedItem}
        transitionTime={this.props.transitionTime}
        children={this.children}
      />
    );
  }

  render() {
    if (!this.children || this.children.length === 0) return null;

    const itemsLength = this.children.length;
    const isHorizontal = this.props.axis === 'horizontal';
    const canShowArrows = this.props.showArrows && itemsLength > 1;

    // show left arrow?
    const hasPrev = canShowArrows && (this.state.selectedItem > 0 || this.props.infiniteLoop);
    // show right arrow
    const hasNext = canShowArrows &&
      (this.state.selectedItem < itemsLength - 1 || this.props.infiniteLoop);
    const currentPosition = `${-this.state.selectedItem * 100}%`;

    // if 3d is available, let's take advantage of the performance of transform
    const transformProp = cssTranslate(currentPosition, this.props.axis);

    // obj to hold the transformations and styles
    const itemListStyles = {
      WebkitTransform: transformProp,
      MozTransform: transformProp,
      MsTransform: transformProp,
      OTransform: transformProp,
      transform: transformProp,
      msTransform: transformProp
    };

    const isSingleVertical = this.props.slidesToShow === 1 && this.vertical;
    const swiperProps = {
      selectedItem: this.state.selectedItem,
      className: cssClasses.slider(
        true, this.props.fullScreen, this.state.swiping,
        isSingleVertical ? !isSingleVertical : this.props.slidesToShow === 1 || this.vertical
      ),
      onSwipeMove: this.onSwipeMove,
      onSwipeStart: this.onSwipeStart,
      onSwipeEnd: this.onSwipeEnd,
      style: itemListStyles,
      tolerance: this.props.swipeScrollTolerance,
      ref: ref => this.itemList = ref
    };

    const containerStyles = {};

    if (isHorizontal) {
      if (this.props.dynamicHeight) {
        const itemHeight = this.getVariableImageHeight(this.state.selectedItem);
        swiperProps.style.height = itemHeight || 'auto';
        containerStyles.height = itemHeight || 'auto';
      }
    } else {
      swiperProps.onSwipeLeft = noop;
      swiperProps.onSwipeRight = noop;
      swiperProps.style.height = this.state.itemSize;
      containerStyles.height = this.state.itemSize;
    }

    return (
      <div
        className={`carouselContainer ${this.props.className ? this.props.className : ''}`}
        ref={ref => this.carouselWrapper = ref}
      >
        <div className={cssClasses.carousel(true)}>
          <Button
            className={cssClasses.arrowPrev(!hasPrev, this.props.fullScreen)}
            onClick={this.decrement}
            iconType={iconTypes.arrowSliderLeft}
            noPadding
          />
          <div
            className={cssClasses.wrapper(true, this.props.axis)}
            style={containerStyles}
          >
            <Swipe
              tagName="ul"
              {...swiperProps}
              allowMouseEvents={this.props.emulateTouch}
            >
              {this.renderItems()}
            </Swipe>
          </div>
          <Button
            className={cssClasses.arrowNext(!hasNext, this.props.fullScreen)}
            onClick={this.increment}
            iconType={iconTypes.arrowSliderRight}
            noPadding
          />
        </div>
        {this.renderThumbs()}
        <div className="carousel">
          {this.renderControls()}
          {this.renderStatus()}
        </div>
      </div>
    );
  }
}

export default Carousel;
