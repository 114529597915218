/**
 * Created by Kotkin on 14.06.2017.
 */
import React, { Component, createElement, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import isObject from 'lodash/isObject';

import 'styles/base/components/modal/_modal-content.scss';

import BtnCloseModal from './btnCloseModal';

const classes = new Bem('modalContent');

/**
 * === ContentModal Component ===
 * Uses for rendering modal content.
 */

export default class ContentModal extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,

    // Content height depends on content inside
    adaptiveHeight: PropTypes.bool,

    // Says that after changing content it should be scrolled to very top
    scrollToTop: PropTypes.bool,

    // Content has closing button
    closeButton: PropTypes.bool,

    // No padding in Content (top sides)
    noPaddingT: PropTypes.bool,

    // No padding in Content (all sides)
    noPadding: PropTypes.bool,

    // Add padding in Content (all sides)
    addPaddingB: PropTypes.bool,

    // No padding in Content (left and right sides)
    list: PropTypes.bool,

    // Content тянется по всей возможной высоте
    hFull: PropTypes.bool,

    // Says this modal is opened on desktop (useful for inputListBox)
    desktop: PropTypes.bool
  };

  static childContextTypes = {
    desktop: PropTypes.bool
  };

  getChildContext() {
    return {
      desktop: this.props.desktop
    };
  }

  /*TODO: unused
  componentWillReceiveProps() {
    if (this.props.scrollToTop) this.node.scrollTop = 0;
  }
  */

  renderContent() {
    const { children } = this.props;
    const child = Children.toArray(children)[0];

    if (children && children.length > 1) return <div {...classes('container')} children={child} />;
    if (isObject(child)) {
      return cloneElement(child, {
        ...classes('container', null, child.props.className)
      });
    }
    return createElement('div', { ...classes('container'), children: child });
  }

  render() {
    const {
      className, closeButton, adaptiveHeight, noPaddingT, addPaddingB, noPadding, list, hFull
    } = this.props;
    const mods = { adaptive: adaptiveHeight, noPaddingT, addPaddingB, noPadding, list, hFull };

    return (
      <div //eslint-disable-line
        {...classes(null, mods, className)}
        ref={node => this.node = node}
        onClick={e => e.stopPropagation()}
      >
        {this.renderContent()}
        {closeButton && <BtnCloseModal />}
      </div>
    );
  }
}
