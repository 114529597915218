import React from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import StepChanger from 'components/step-changer';

import Modal from './modal';
import Header from './headerModal';
import Content from './contentModal';
import Footer from './footerModal';

const bemClasses = new Bem('modalMultiStep');

export default class MultiStepModal extends StepChanger {
  static displayName = 'MultiStepModal';

  static propTypes = {
    ...StepChanger.props,
    renderHeader: PropTypes.func,
    renderFooter: PropTypes.func,
    firstStepHeader: PropTypes.string,
    onModalClose: PropTypes.func,
    headerProps: PropTypes.shape({}),
    contentProps: PropTypes.shape({}),
    footerProps: PropTypes.shape({})
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.nextHeader = this.nextHeader.bind(this);
    this.onHandleBackwards = this.onHandleBackwards.bind(this);
    this.goForward = this.goForward.bind(this, this.nextHeader);
    this.onModalClose = this.onModalClose.bind(this);

    this.state = {
      ...this.state,
      header: [props.firstStepHeader]
    };
  }

  /**
   * Колбек после закрытия модалки
   */
  onModalClose() {
    if (this.props.onModalClose) this.props.onModalClose();
  }

  /**
   * Колбек при переходе на след шаг. Дополняет изменения в стейте `StepChanger`.
   * А именно - добавляет необходимый хедер следующего шага,
   * при условии отсутствия кастомной функции рендера хедера.
   *
   * @param state
   * @param item
   * @returns {{header}}
   */
  nextHeader(state, item) {
    const newState = { ...state };
    const { header, currentStep } = newState;

    if (!this.props.renderHeader && header.length - 1 < currentStep + 1) {
      header.push(item[this.props.headerField]);
    }

    return { header };
  }

  /**
   * Колбек при переходе на предыдущий шаг. Дополняет изменения в стейте `StepChanger`.
   * А именно - удаляет хедер предыдущего шага из стейта,
   * при условии отсутствия кастомной функции рендера хедера.
   *
   * @param state
   * @returns {{header}}
   */
  prevHeader(state) {
    const newState = { ...state };

    if (!this.props.renderHeader) newState.header = newState.header.slice(0, -1);

    return { header: newState.header };
  }

  /**
   * Расширение метода `goBackwards` компонента `StepChanger` на колбек модификации стейта.
   */
  onHandleBackwards() {
    this.goBackwards(null, state => this.prevHeader(state));
  }

  /**
   * Определение текущего хедера модалки
   *
   * @returns {string}
   */
  renderHeader() {
    if (this.props.renderHeader) return this.props.renderHeader(this.state.currentStep);
    return this.state.header[this.state.currentStep];
  }

  render() {
    const {
      className, renderFooter,
      headerProps = {}, contentProps = {}, footerProps = {},
      ...restProps
    } = this.props;
    const { currentStep } = this.state;

    return (
      <Modal
        {...bemClasses('modal', null, className)}
        {...restProps}
        onCloseRequired={this.onModalClose}
        // disableNotification
        renderModal
      >
        <Header
          closeButton
          buttonAction={this.onHandleBackwards}
          backButton={currentStep > 0}
          {...headerProps}
        >
          {this.renderHeader()}
        </Header>
        <Content {...contentProps}>
          {super.render()}
        </Content>
        {renderFooter &&
          <Footer {...footerProps}>
            {renderFooter(currentStep, this.changeStep)}
          </Footer>
        }
      </Modal>
    );
  }
}
