/* eslint no-cond-assign: "off" */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import { getErrorMessage } from 'api/api-errors';
import ApiError from 'errors/apiError';
import { emit, subscribe, unsubscribe } from 'helpers/global-events';
import Notification from 'components/notification';
import Loader from 'components/loader';

import { VALIDATION_VALID } from './form-consts';
import AbstractForm from './abstractForm';
import renderValidationMessages from './renderValidationMessages';

import translates from './form-i18n';

import 'styles/base/containers/form/_form.scss';

const bemClasses = new Bem('form');

/**
 * Basic Form Component
 *
 * For full data binding (receive data from loader and send to server) -
 * use dataBind="name" parameters in the form elements.
 * "name" for data binding MUST be unique.
 * If component with "dataBinding" prop found in form child element -
 * such properties are injecting to this element:
 *
 */

@I18nHoc(translates())
export default class Form extends AbstractForm {
  static propTypes = {
    ...AbstractForm.propTypes,
    className: PropTypes.string,
    // Entity ID for load and edit data
    entityId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    // Already loaded form data
    data: PropTypes.shape(),
    // Keep this fields when schema reload. In standart behavior when new controller received -
    // data resets. When keepData defined - reset all field except this
    keepData: PropTypes.arrayOf(PropTypes.string),
    // Global event, that emits, when form updated/deleted/inserted
    updateEvent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    // Modify data, that send to update event
    updateEventDataModifier: PropTypes.func,
    // If true - do not render FORM tag, for nested forms
    nested: PropTypes.bool,
    // Fires, when schema loaded
    onSchemaLoaded: PropTypes.func,
    // Used, but implicitly
    // eslint-disable-next-line
    onChange: PropTypes.func,
    // Fires after success submit
    // eslint-disable-next-line
    onSubmit: PropTypes.func,
    // Fires after submit with error
    // eslint-disable-next-line
    onErrorSubmit: PropTypes.func,
    // Fires after success data load
    // eslint-disable-next-line
    onLoad: PropTypes.func,
    // Fires after success delete
    onDelete: PropTypes.func,
    // Override internal submit handler
    submitHandler: PropTypes.func,
    // eslint-disable-next-line
    defaultErrorHeader: PropTypes.string,
    // eslint-disable-next-line
    defaultLoadErrorHeader: PropTypes.string,
    // eslint-disable-next-line
    defaultSuccessHeader: PropTypes.string,
    defaultDeleteHeader: PropTypes.string,
    showPopupValidationMessages: PropTypes.bool,
    reRequestDataWhenSchemaChanged: PropTypes.bool,
    // Reset form to default values and insert mode after each insert
    resetAfterSubmit: PropTypes.bool,
    // add style 'height: 100%;' to Form
    hFull: PropTypes.bool,
    // add style 'display: flex; flex-direction: column;' to Form
    flexColumn: PropTypes.bool,
    // Debug and testing features, such as save/load test data
    debug: PropTypes.string
  };

  static defaultProps = {
    ...AbstractForm.defaultProps,
    nested: false,
    showPopupValidationMessages: false,
    reRequestDataWhenSchemaChanged: false,
    resetAfterSubmit: false,
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this._handleUpdateEvents = this._handleUpdateEvents.bind(this);
    this.state = {
      ...this.state,
      schemaLoading: false,
      schemaLoaded: false,
      schemaLoadFail: false,
      dataLoading: false,
      dataLoaded: false,
      dataLoadFail: false,
      submitting: false
    };
    this.data = {};
    this.action = null;
    this.formUniqueId = uniqueId();
  }

  componentDidMount() {
    super.componentDidMount();
    this._loadSchema(this.props.controller);
    this.action = 'insert';
    if (this.props.entityId) {
      this._loadData(this.props.entityId, this.props.controller);
    }
    if (this.props.data) {
      this._setFormData(this.props.data);
    }
    this._subscribeUpdateEvents();
    subscribe('gLangChanged', this._langChangedHandler);
  }

  componentDidUpdate(prevProps) {
    // Reset to defaults if entityId set to null
    if (
      this.props.entityId !== prevProps.entityId &&
      !this.props.entityId &&
      this.state.schemaLoaded
    ) {
      this.reset();
    }

    // Reload schema if needed
    if (this.props.controller !== prevProps.controller) {
      if (!this.props.entityId) {
        const { keepData = [] } = this.props;
        const keep = {};
        forEach(keepData, val => keep[val] = this.data[val]);
        this.data = keep;
      }
      this._loadSchema(this.props.controller);
    }

    if (!this.props.entityId) {
      this.action = 'insert';
    }

    // Reload data if needed
    if (this.props.entityId !== prevProps.entityId && this.props.entityId) {
      this._loadData(this.props.entityId, this.props.controller);
    }

    // Set form data if needed
    if (this.props.data && !isEqual(this.props.data, prevProps.data)) {
      this._setFormData(this.props.data);
    }
  }

  componentWillUnmount() {
    this._unsubscribeUpdateEvents();
    unsubscribe('gLangChanged', this._langChangedHandler);
    super.componentWillUnmount();
  }

  _langChangedHandler = () => {
    this._loadSchema(this.props.controller);
  }

  _getData() {
    return this.data;
  }

  /**
   * Load schema from controller
   * @private
   */
  _loadSchema(controller) {
    this.setState({
      schemaLoading: true,
      schemaLoadFail: false
    });
    this._query('get', 'schema', {}, controller)
      .then((payload) => {
        this.data = this._setDefaultValuesFromSchema(
          payload,
          this.data
        );
        this._setSchema(payload, {},
          () => {
            this._fire('onSchemaLoaded', [this.data, this.state]);
            if (this.props.reRequestDataWhenSchemaChanged) {
              if (this.props.entityId) {
                this._loadData(this.props.entityId, this.props.controller);
              }
            }
          });
      })
      .catch((error) => {
        this._error(error, 'Schema load failure. Try again.');
        this.setState({
          schemaLoading: false,
          schemaLoaded: false,
          schemaLoadFail: true
        });
        throw new ApiError(error, {
          component: 'FormController',
          action: 'SchemaLoad'
        });
      });
  }

  _loadData(primaryKey, controller) {
    this.setState({
      dataLoading: true,
      dataLoadFail: false
    });
    this._query('get', 'data', { primaryKey }, controller)
      .then((payload) => {
        this._setFormData(payload.data);
      })
      .catch((error) => {
        this.data = {};
        this._error(error, this.getDefaultMessages().defaultLoadErrorHeader);
        this.setState({
          dataLoading: false,
          dataLoaded: false,
          dataLoadFail: true
        });
        this.forceUpdate();
      });
  }

  _setFormData(data) {
    if (this.state.schemaLoaded) {
      this.data = this._setDefaultValuesFromSchema(this.state.schema, data);
    } else {
      this.data = data;
    }

    this.action = 'update';
    this.setState({
      dataLoading: false,
      dataLoadFail: false,
      dataLoaded: true,
    });
    this.forceUpdate();
    this._fire('onLoad', [this.data, this.state]);
    this._fire('onChange', [this.data, this.state]);
  }

  /**
   * Change field value in data state
   * @param field
   * @param value
   * @param markAsDirty
   * @param scope
   * @private
   */
  _changeField(field, value, markAsDirty, scope) {
    this.dataDirty = true;
    this.data[field] = value;
    this.validationStates[field] = {
      state: VALIDATION_VALID,
      message: null
    };

    this._fire('onChange', [this.data, this.state]);
    if (scope._inheritOnChange) {
      scope._inheritOnChange(value);
    }
    super._changeField(field, value, markAsDirty, scope);
  }

  reset() {
    this.data = this._setDefaultValuesFromSchema(
      this.state.schema,
      {}
    );
    this.action = 'insert';
    this.dataDirty = false;
    this._setValidationStatesFromSchema(this.state.schema);
    this._fire('onChange', [this.data, this.state]);
    this.forceUpdate();
    this.setState({
      dataLoaded: false
    });
  }

  reloadSchema() {
    this._loadSchema(this.props.controller);
  }

  _walkUpdateEvents(cb) {
    const { updateEvent } = this.props;
    if (updateEvent) {
      const events = isArray(updateEvent) ? updateEvent : [updateEvent];
      forEach(events, event => cb(event));
    }
  }

  _subscribeUpdateEvents() {
    this._walkUpdateEvents(event => subscribe(event, this._handleUpdateEvents));
  }

  _unsubscribeUpdateEvents() {
    this._walkUpdateEvents(event => unsubscribe(event, this._handleUpdateEvents));
  }

  _handleUpdateEvents(evt) {
    if (!evt) {
      return;
    }
    if (evt.$uniqueId === this.formUniqueId) {
      return;
    }
    if (this.data[this.state.schema.primaryKeyField] !== evt[this.state.schema.primaryKeyField]) {
      return;
    }

    const newData = {};
    forEach(evt, (val, key) => {
      if (this.state.schema.rules[key] !== undefined) {
        newData[key] = val;
      }
    });
    this.updateData(newData);
  }

  _modifyUpdateEventData() {
    const { updateEventDataModifier } = this.props;
    if (updateEventDataModifier) {
      return updateEventDataModifier(this.data);
    }
    return this.data;
  }

  _emitUpdateEvent(action) {
    this._walkUpdateEvents(event =>
      emit(
        event,
        { $action: action, $uniqueId: this.formUniqueId, ...this._modifyUpdateEventData() }
      ));
  }

  injectOthers(element, values, schema, validationStates) {
    if (element.props['data-submit']) {
      return this._cloneElement(
        element,
        {
          onClick: this.handleSubmit,
          'data-item-id': this.data[this.state.schema.primaryKeyField],
          ...element.props
        },
        values,
        schema,
        validationStates,
        false
      );
    }

    if (element.props['data-delete']) {
      return this._cloneElement(
        element,
        {
          onClick: this.handleDelete,
          ...element.props
        },
        values,
        schema,
        validationStates,
        false
      );
    }
  }

  /**
   * Send form data to server
   */
  submit(callback = null) {
    if (this.state.submitting) return;

    const method =
      this.data[this.state.schema.primaryKeyField] ? 'put' : 'post';

    this.setState({
      submitting: true
    });

    this._query(method, 'data', this.data)
      .then((payload) => {
        this.data = payload.data;
        this._setValidationStatesFromSchema(this.state.schema);

        this.setState({
          submitting: false,
          dataDirty: false,
        }, () => {
          Notification.success(this.getDefaultMessages().defaultSuccessHeader);
          this._fire('onSubmit', [this.data, this.state, this.action]);
          this._emitUpdateEvent(this.action);
          if (callback) {
            callback(this.data, this.action);
          }
          this.action = 'update';
          if (this.props.resetAfterSubmit) {
            this.reset();
          }
        });
      })
      .catch((payload) => {
        this.setState({
          submitting: false,
        });

        if (
          isObject(payload) &&
          isObject(payload.response) &&
          isObject(payload.response.data) &&
          payload.response.data.class === 'ValidatorException'
        ) {
          const errorData = payload.response.data;
          this._setValidationStatesFromValidator(
            errorData.validatorMessages || {},
            true);

          if (this.props.showPopupValidationMessages) {
            Notification.warning(
              errorData.error.message,
              renderValidationMessages(errorData.validatorMessages));
          }
        } else {
          Notification.error(
            this.getDefaultMessages().defaultErrorHeader,
            getErrorMessage(payload));
          this._fire('onErrorSubmit', []);
          throw new ApiError(payload, {
            component: 'FormController',
            action: 'Submit',
            method
          });
        }
      });
  }

  /**
   * Delete a current record and reset the form
   */
  deleteRecord() {
    if (this.state.submitting) return;

    this.setState({
      submitting: true
    });

    this._query('delete', 'data', this.data)
      .then(() => {
        this._emitUpdateEvent('delete');
        this.setState({
          submitting: false,
        }, () => this.reset());
        Notification.success(this.getDefaultMessages().defaultDeleteHeader);
        this._fire('onDelete', [this.state]);
      })
      .catch((payload) => {
        this.setState({ submitting: false });
        Notification.error(
          this.getDefaultMessages().defaultErrorHeader,
          getErrorMessage(payload));
        throw new ApiError(payload, {
          component: 'FormController',
          action: 'Delete'
        });
      });
  }

  handleSubmit(event, callback = null) {
    if (event) {
      event.preventDefault();
    }
    if (this.props.submitHandler) {
      this.props.submitHandler(this.data, this.state);
      return;
    }
    this.submit(callback);
  }

  handleDelete() {
    const { i18n } = this;

    Notification.confirm(
      i18n('deleting'),
      i18n('deleteAreYouSure'),
      () => this.exactlyDelete(),
      {
        okText: i18n('okText'),
        cancelText: i18n('cancelText')
      }
    );
  }

  exactlyDelete =()=> {
    const { i18n } = this;

    Notification.confirm(
      i18n('deleting'),
      i18n('deleteAreYouExactlySure'),
      () => this.deleteRecord(),
      {
        okText: i18n('okText'),
        cancelText: i18n('cancelText')
      }
    );
  };

  renderLoader() {
    const {
      schemaLoading,
      dataLoading,
      submitting
    } = this.state;

    if (schemaLoading || dataLoading || submitting) return <Loader formMainSize />;
    return null;
  }

  renderDebug() {
    if (__DEVELOPMENT__) {
      if (this.props.debug) {
        return (
          <div>
            <button
              type="button"
              onClick={() => localStorage.setItem(`_formDebug_${this.props.debug}`, JSON.stringify(this.data))}>
              Save test data
            </button>
            <button
              type="button"
              onClick={() => {
                this.data = JSON.parse(localStorage.getItem(`_formDebug_${this.props.debug}`));
                this.forceUpdate();
                this._fire('onChange', [this.data, this.state]);
              }}>
              Load test data
            </button>
          </div>
        );
      }
      return null;
    }
  }

  getDefaultMessages = () => {
    const { i18n } = this;
    const {
      defaultSuccessHeader, defaultErrorHeader, defaultLoadErrorHeader, defaultDeleteHeader
    } = this.props;

    return {
      defaultErrorHeader: defaultErrorHeader || i18n('errorSave'),
      defaultLoadErrorHeader: defaultLoadErrorHeader || i18n('errorDataLoading'),
      defaultSuccessHeader: defaultSuccessHeader || i18n('saved'),
      defaultDeleteHeader: defaultDeleteHeader || i18n('deletedData')
    };
  };

  render() {
    if (__AMP__) return null;

    const {
      schema = {},
      schemaLoaded
    } = this.state;
    const { hFull, flexColumn, className, nested, loadPlaceholder } = this.props;
    const mods = { hFull, flexColumn };

    if (!schemaLoaded) {
      if (loadPlaceholder) return loadPlaceholder;
      if (!loadPlaceholder) return <div {...bemClasses({ modifiers: mods, extra: className })} />;
    }

    if (schemaLoaded && !schema.allow) {
      return (
        <div {...bemClasses({ modifiers: mods, extra: className })}>
          У Вас нет прав для доступа сюда
        </div>
      );
    }

    if (nested) {
      return (
        <div {...bemClasses(null, mods, className)}>
          {__DEVELOPMENT__ && this.renderDebug()}
          {this.renderLoader()}
          {this.renderChildren(
            this.props.children,
            this.data || {},
            schema,
            this.validationStates)
          }
        </div>
      );
    }

    return (
      <form
        noValidate
        {...bemClasses(null, mods, className)}
        onSubmit={this.handleSubmit}
      >
        {__DEVELOPMENT__ && this.renderDebug()}
        {this.renderLoader()}
        {this.renderChildren(
          this.props.children,
          this.data || {},
          schema,
          this.validationStates)
        }
      </form>
    );
  }
}
