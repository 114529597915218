import React from 'react';
import forEach from 'lodash/forEach';

/**
 * Render a notification popup with validation messages
 * @param messages
 * @return {XML}
 * @private
 */
// eslint-disable-next-line
export default function renderValidationMessages(messages) {
  const list = [];
  const renderedCMessages = {};
  forEach(messages, (val, key1) =>
    forEach(val.messages, (msg, key2) => {
      if (msg.constraint && renderedCMessages[msg.constraint]) {
        return null;
      }
      renderedCMessages[msg.constraint] = true;
      list.push(
        <li key={`${key1}-${key2}`}>
          {msg.text}
          {msg.subformState &&
            <ul>
              {renderValidationMessages(msg.subformState)}
            </ul>
          }
        </li>
      );
    })
  );
  return (<ul>{list}</ul>);
}
