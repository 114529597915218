/**
 * Created by macbookpro on 02.12.16.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from 'auth/redux';
import appMessage from 'helpers/appMessage';
import Link from 'components/link';

import globalConfig from '../../../config';

const configs = globalConfig.oauth || {};

@connect(state => ({
  auth: state.auth,
}), { ...actions }, null, { forwardRef: true })
class OAuthRequest extends Component {
  static propTypes = {
    socOauthName: PropTypes.oneOf(Object.keys(configs)).isRequired,
    actionAuthGetData: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.openOauthWindow = this.openOauthWindow.bind(this);
  }

  openOauthWindow() {
    // window.parent.__dangerousSocialAuthObject = this;
    const conf = configs[this.props.socOauthName];
    if (__APP__) {
      // event.preventDefault();
      if (conf.url === '/service/socialAuth/login/facebook') {
        appMessage({ goSdkLogin: 'FbSdkLogin' });
        return false;
      }
      // if (conf.url === '/service/socialAuth/login/vkontakte') {
      //   appMessage({ goSdkLogin: 'VkSdkLogin' });
      //   return false;
      // }
      if (conf.url === '/service/socialAuth/login/google') {
        appMessage({ goSdkLogin: 'GoogleSdkLogin' });
        return false;
      } appMessage({ goApp: `http://dev.domik.ua/${conf.url}` });
      return false;
    }
    const [width, height] = conf.window || [600, 400];
    const { availLeft = 0, availTop = 0, availWidth = 0, availHeight = 0 } = window.screen || {};
    const offsetLeft = (availLeft + (availWidth / 2)) - (width / 2);
    const offsetTop = (availTop + (availHeight / 2)) - (height / 2);
    const windowParam = `width= ${width}, height= ${height}, left= ${offsetLeft},top= ${offsetTop}`;
    window.__updateUserData = () => this.props.actionAuthGetData();
    window.open(`${conf.url}?project=${__PROJECT__}`, '_blank', windowParam); // eslint-disable-line
    return false;
  }

  render() {
    const { socOauthName, ...restProps } = this.props;
    const conf = configs[socOauthName];
    if (!conf) return null;
    /* eslint-disable jsx-a11y/anchor-has-content */
    return (
      <Link
        to={conf.url}
        {...restProps}
        onClick={this.openOauthWindow}
      />
    );
  }
}
export default OAuthRequest;
