/**
 * Created by Kotkin on 02.08.2017.
 */
import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import isEmpty from 'lodash/isEmpty';
import simpleStore from 'helpers/simpleStore';
import sensor from 'components/sensor';

const classes = new Bem('paginatorControl');

@sensor
export default class Control extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,

    // Add id to Control wrapper
    controlId: PropTypes.string,

    // Pages data array from DataProvider
    paginator: PropTypes.any, //eslint-disable-line

    // Pages number from DataProvider
    paginatorCount: PropTypes.number,

    // Number of reloads from DataProvider
    paginatorReloadCount: PropTypes.number,

    // Max items per page
    limit: PropTypes.number,

    // Current page index
    currentPage: PropTypes.number,

    // Amount of pages to show
    pagesAmount: PropTypes.number,

    // Reload method from DataProvider
    reloadProvider: PropTypes.func,

    // Save func for retreiving Controll coordinates into key
    calcCoorsKey: PropTypes.string,

    // Should Control element be sticky?
    sticky: PropTypes.bool,

    // Add padding-left and padding-right
    addPaddingLR: PropTypes.bool,

    // Add margin-bottom
    addMrBottom: PropTypes.bool,

    // Add padding-bottom and background-color
    addPdBgBsh: PropTypes.bool,

    // Instead findDOMNode
    controlRef: PropTypes.func
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.calcShow = this.calcShow.bind(this);
    this.getOffsetTopPos = this.getOffsetTopPos.bind(this);

    this.onScroll = props.sticky && this.calcShow;

    this.state = {
      top: false
    };

    this.stickyRef = null;

    if (props.calcCoorsKey) simpleStore.set(props.calcCoorsKey, this.getOffsetTopPos);
  }

  componentWillUnmount() {
    const { calcCoorsKey } = this.props;
    if (calcCoorsKey) simpleStore.remove(calcCoorsKey);
  }

  getOffsetTopPos() {
    let coors = null;
    if (this.stickyRef) coors = this.stickyRef.getBoundingClientRect();
    return coors;
  }

  calcShow({ media }) {
    const navDomOffsetTop = this.stickyRef ?
      this.stickyRef.getBoundingClientRect().top : 0;
    let top = false;
    if (navDomOffsetTop <= 0) top = true;
    if (media.phone || media.tablet) top = false;

    const newState = { top };
    if (this.state.top !== top) this.setState(newState);
  }

  render() {
    const {
      className, children, controlId, controlRef,
      sticky, addPaddingLR, addMrBottom, addPdBgBsh, // eslint-disable-line
      ...restProps
    } = this.props;

    if (isEmpty(children)) return null;
    return (
      <div
        id={controlId}
        {...classes({
          // element: this.props.sticky && 'sticky',
          modifiers: { addPaddingLR, addMrBottom, stickyTop: this.state.top, addPdBgBsh },
          extra: className
        })}
        ref={(ref) => {
          this.stickyRef = ref;
          if (controlRef) controlRef(ref);
        }}
      >
        {cloneElement(children, {
          ...restProps,
          stickyTop: this.state.top
        })}
      </div>
    );
  }
}
