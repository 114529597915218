/**
 * Created by Kotkin on 26.06.2017.
 */

import round from 'lodash/round';

/**
 * usage:
 * numDesc(11, 'день', 'дня', 'дней')
 *
 *
 * @param {number} number
 * @param {[string, ...]} titles (существительные в разных склонениях)
 * @returns {string}
 */
export default function numDesc(number, ...titles) {
  const text = numDescOnlyText(number, ...titles);

  return `${number} ${text}`;
}

export function numDescOnlyText(number, ...titles) {
  if (!Number.isInteger(number)) return `${titles[1]}`;

  const cases = [2, 0, 1, 1, 1, 2];
  number = Math.abs(number); //eslint-disable-line
  const position = (number % 100 > 4 && number % 100 < 20) ? 2 :
    cases[(number % 10 < 5) ? number % 10 : 5];

  return `${titles[position]}`;
}

export function humanizeBigNumbers(
  num,
  numberSignsThousand = 0,
  numberSignsMillion = 2,
  suffix = true
) {
  const { i18n } = this;
  const roundToMillion = round(num / 1000000, numberSignsMillion);
  const roundToThousand = round(num / 1000, numberSignsThousand);

  if (roundToMillion >= 1) return roundToMillion + (suffix && '\u00a0млн');
  if (roundToThousand >= 1) return roundToThousand + (suffix && `\u00a0${i18n('thousand')}`);

  return num;
}
