/**
 * Created by Kotkin on 02.08.2017.
 */
/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import { routerShape } from 'react-router';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import { Input } from 'containers/form';
import Link from 'components/link';
import Icon from 'components/icon';
import sensor from 'components/sensor';
import Button, { btnThemes } from 'components/button';
import { ModalButton, Header, Content, Footer } from 'components/modal';

import translates from './paginator-i18n.json';

const classes = new Bem('paginator');

// TODO: amount of separated in the middle pagination buttons (only ODD number)
const pagesInside = 3;

@I18nHoc(translates)
export default class Pages extends PureComponent {
  static propTypes = {
    className: PropTypes.string,

    // Amount of pages to show
    pagesAmount: PropTypes.number,

    // Change page (to previous/next) function
    changePage: PropTypes.func,

    // Current page index
    currentPage: PropTypes.number,

    // Add margin-bottom
    addMarginB: PropTypes.bool,

    // Add background-color and box-shadow
    addPdBgBsh: PropTypes.bool,

    // Do not render arrow buttons
    withArrows: PropTypes.bool,

    // Render button with Modal where user can set preferable page. Should have withArrows prop.
    withPageChange: PropTypes.bool,

    // Render any elements on right side. Should have withArrows prop.
    elementsRight: PropTypes.any, // eslint-disable-line

    // Link builder function for pagination links
    linkBuilder: PropTypes.func,

    // Link builder props object. Should contain props for appropriate link href forming.
    linkBuilderProps: PropTypes.shape({}),

    // props object. Other props for Button ChangePage.
    otherPropsBtnChangePage: PropTypes.shape({}),

    // Router for changing url from modal with input
    router: routerShape,

    // Define if it's rendered at the top
    pagesOnTop: PropTypes.bool,

    // pagesRow - add container around Pages
    pagesRow: PropTypes.bool,

    // Instead findDOMNode
    pagesRef: PropTypes.func
  };

  constructor(...props) {
    super(...props);
    this.pagesArray = [];
  }

  renderNavBtn(page = 0, divBlock = false, key = null) {
    const { i18n } = this;
    const {
      pagesAmount, changePage,
      linkBuilder, linkBuilderProps = {},
      limit
    } = this.props;
    const renderBtns = !linkBuilder;
    const mapKey = {};
    if (key >= 0) mapKey.key = key;

    if (divBlock) {
      return (
        <div
          {...mapKey}
          {...classes('link', 'active')}
          title={`${i18n('page')} ${page + 1}`}
        >
          <span {...classes('linkContent')} data-test="linkContent">
            <span {...classes('text')}>{page + 1}</span>
          </span>
        </div>
      );
    }

    if (renderBtns) {
      return (
        <Button
          {...mapKey}
          {...classes('link')}
          title={`${i18n('page')} ${page + 1}`}
          onClick={() => changePage(page, pagesAmount)}
          label={page + 1}
        />
      );
    }

    return (
      <Link
        {...mapKey}
        {...classes('link')}
        builder={linkBuilder}
        builderProps={{
          ...linkBuilderProps,
          page: page + 1 === 1 ? null : page + 1,
          offset: page + 1 === 1 ? null : page * limit,
          title: `${i18n('page')} ${page + 1}`
        }}
      >
        <span {...classes('linkContent')} data-test="linkContent">
          <span {...classes('text')}>{page + 1}</span>
        </span>
      </Link>
    );
  }

  renderModal() {
    return (
      <ChangePageModal
        pagesAmount={this.props.pagesAmount}
        onPageChange={page => this.props.changePage(page)}
        linkBuilder={this.props.linkBuilder}
        linkBuilderProps={this.props.linkBuilderProps}
        router={this.props.router}
        currentPage={this.props.currentPage}
        otherPropsBtnChangePage={this.props.otherPropsBtnChangePage}
      />
    );
  }

  renderArrowBtn =(empty, iconType, page = null, title)=> {
    let titleTranslate = title ? title : this.i18n('pageNext');

    if (empty) {
      return (
        <span {...classes('btn', 'arrow empty', 'btn')}>
          <span {...classes('btnContent', null, 'btn__content')} />
        </span>
      );
    }

    if (!this.props.linkBuilder) {
      return (
        <Button
          {...classes('link', 'arrow')}
          onClick={() => this.props.changePage(!page ? -1 : page, this.props.pagesAmount)}
          title={titleTranslate}
          iconType={iconType}
        />
      );
    }

    return (
      <Link
        {...classes('link', 'arrow')}
        builder={this.props.linkBuilder}
        builderProps={{
          ...this.props.linkBuilderProps,
          title: titleTranslate,
          page: page ? page + 1 : null
        }}
      >
        <span {...classes('linkContent')}>
          <Icon {...classes('icon')} type={iconType} />
        </span>
      </Link>
    );
  };

  renderPageButtons() {
    const { i18n } = this;
    const {
      pagesRow, pagesAmount, currentPage,
      withPageChange, elementsRight, withArrows
    } = this.props;
    if (pagesAmount && pagesAmount !== this.pagesArray.length && isArray(this.pagesArray)) {
      this.pagesArray.length = pagesAmount;
    }
    const minTwoPages = pagesAmount > 1;
    const dots = (
      <span {...classes('btn', 'dots', 'btn')}>
        <span {...classes('btnContent', null, 'btn__content')} data-test="btnShow">
          <span {...classes('text')}>. . .</span>
        </span>
      </span>
    );
    const arrowBtnAfter = (withArrows && currentPage < pagesAmount - 1 &&
      this.renderArrowBtn(
        currentPage === pagesAmount - 1,
        'arrowSliderRight',
        currentPage + 1,
        i18n('pageNext')
      )
    );
    const modalPages = withPageChange && minTwoPages && this.renderModal();

    if (pagesAmount <= pagesInside + 2) {
      const content = (
        <>
          {withArrows && currentPage > 0 &&
            this.renderArrowBtn(
              currentPage === 0,
              'arrowSliderLeft',
              null,
              i18n('pagePrev')
            )
          }
          {minTwoPages && map(this.pagesArray, (page, index) => (
            this.renderNavBtn(index, index === currentPage, index)
          ))}
          {arrowBtnAfter}
          {modalPages}
        </>
      );

      return (
        <div {...classes('content')}>
          {pagesRow ? <div {...classes('contentPages')} children={content} /> : content}
          {elementsRight &&
            <div
              {...classes(
                'aside',
                // { center: !minTwoPages }
              )}
            >
              {elementsRight}
            </div>
          }
        </div>
      );
    }

    const pagesLessHalf = Math.floor(pagesInside / 2);
    const pageButtons = map(this.pagesArray, (page, index) => {
      if (index !== 0 && index !== pagesAmount - 1) {
        if (
          (currentPage <= pagesInside && index <= pagesInside && index > pagesLessHalf) ||
          (
            currentPage >= pagesAmount - pagesInside &&
            index >= pagesAmount - pagesInside - 1 &&
            index < pagesAmount - pagesLessHalf
          ) ||
          Math.abs(index - currentPage) <= pagesLessHalf
        ) {
          return this.renderNavBtn(index, index === currentPage, index);
        }
      }
    });
    const content = (
      <>
        {withArrows && currentPage > 0 &&
          this.renderArrowBtn(
            currentPage === 0,
            'arrowSliderLeft',
            currentPage - 1 ? currentPage - 1 : null,
            i18n('pagePrev')
          )
        }
        {minTwoPages && this.renderNavBtn(0, !currentPage, 'first')}
        {Math.round(pagesInside / 2) - currentPage < 0 && dots}
        {minTwoPages && pageButtons}
        {pagesAmount - 1 - currentPage >= Math.round(pagesInside / 2) + 1 && dots}
        {minTwoPages && this.renderNavBtn(pagesAmount - 1, pagesAmount - 1 === currentPage, 'last')}
        {arrowBtnAfter}
        {modalPages}
      </>
    );

    return (
      <div {...classes('content')}>
        {pagesRow ? <div {...classes('contentPages')} children={content} /> : content}
        {elementsRight &&
          <div {...classes('aside', { center: !minTwoPages })}>
            {elementsRight}
          </div>
        }
      </div>
    );
  }

  render() {
    if (!this.props.elementsRight && this.props.pagesAmount <= 1) return null;

    return (
      <div
        {...classes(
          null,
          {
            addPdBgBsh: this.props.addPdBgBsh,
            addMarginB: this.props.addMarginB,
            withPageChange: this.props.withPageChange
          },
          this.props.className
        )}
        ref={(node) => {
          if (this.props.pagesRef) this.props.pagesRef(node);
        }}
      >
        {this.renderPageButtons()}
      </div>
    );
  }
}

@sensor
@I18nHoc(translates)
class ChangePageModal extends PureComponent {
  static propTypes = {
    onPageChange: PropTypes.func,
    pagesAmount: PropTypes.number,
    currentPage: PropTypes.number,
    linkBuilder: PropTypes.func,
    linkBuilderProps: PropTypes.shape({}),
    otherPropsBtnChangePage: PropTypes.shape({}),
    router: routerShape
  };

  constructor(...args) {
    super(...args);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      phone: false,
      userValue: null,
      inValid: false
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { phone } = {} }) {
    if (phone !== this.state.phone) this.setState({ phone });
  }

  onInputChange(userValue) {
    let state = { userValue };
    if (
      userValue !== null &&
      (userValue < 1 || userValue > this.props.pagesAmount)
    ) {
      state = { ...state, inValid: true };
    } else {
      state = { ...state, inValid: false };
    }
    this.setState(state);
  }

  onPageChange() {
    const { linkBuilder, linkBuilderProps, router, onPageChange, currentPage } = this.props;
    const { userValue } = this.state;

    if (!this.state.inValid && userValue) {
      if (linkBuilder && router) {
        if (+userValue === currentPage + 1) return false;
        const { href } = linkBuilder({
          ...linkBuilderProps,
          page: +userValue === 1 ? null : userValue
        });
        return router.push({
          pathname: href
        });
      }
      return onPageChange(userValue - 1);
    }
  }

  /**
   * Allows only specific keys
   *
   * @param e
   * @returns {boolean}
   */
  onKeyDown(e) {
    const key = e.keyCode;
    if (!(
      // allow numbers
      (key > 95 && key < 106) ||
      // allow numbers from numpad
      (key > 47 && key < 58) ||
      // allow backspace, delete, tab, escape, enter
      key === 8 || key === 9 || key === 46 || key === 27 || key === 13 ||
      // allow Ctrl+A, Ctrl+C, Ctrl+V
      ((key === 65 || key === 86 || key === 67) && (e.ctrlKey === true || e.metaKey === true)) ||
      // allow left, right, down, up
      (key >= 37 && key <= 40)
    )) {
      e.preventDefault();
      return false;
    }
  }

  render() {
    const {
      inValid, userValue,
      //phone
    } = this.state;

    // if (phone) return null;

    const { i18n } = this;
    const { pagesAmount, otherPropsBtnChangePage = {} } = this.props;
    const validationState = inValid ?
      { messages: [{ text: i18n('invalidMessage') }], state: 'invalid' } :
      { messages: null, state: 'valid' };

    return (
      <ModalButton
        {...classes('btn', 'pageNumber')}
        buttonProps={{
          title: i18n('pageNumberText'),
          label: i18n('pageNumberText'),
          ...otherPropsBtnChangePage
        }}
        size="medium"
      >
        <Header closeButton>{i18n('pageNumberText')}</Header>
        <Content>
          <div>
            <Input
              type="number"
              schema={{ label: i18n('pageNumberText') }}
              onKeyDown={this.onKeyDown}
              min={1}
              max={pagesAmount}
              value={userValue}
              onChange={this.onInputChange}
              validationState={validationState}
            />
          </div>
        </Content>
        <Footer>
          <button close theme={btnThemes.light}>{i18n('btnCancel')}</button>
          <button
            close={!inValid}
            onClick={this.onPageChange}
            theme={btnThemes.light}
          >
            {i18n('btnOk')}
          </button>
        </Footer>
      </ModalButton>
    );
  }
}
