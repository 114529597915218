/**
 * Created by Vit on 3.05.2019.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { iconTypes } from 'components/icon';
import Button from 'components/button';

const classes = new Bem('modal');

/**
 * === BtnCloseModal Component ===
 * Uses for rendering modal BtnCloseModal.
 */

export default class BtnCloseModal extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  // Action for closing modal window
  static contextTypes = {
    close: PropTypes.func.isRequired
  };

  render() {
    return (
      <Button
        {...classes('btn', 'close', this.props.className)}
        onClick={this.context.close}
        iconType={iconTypes.clear}
        data-test="closeButton"
        noPadding
      />
    );
  }
}
