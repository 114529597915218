/**
 * Created by Vit on 20.09.2016.
 */
import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import Bem from 'bemHelper';
import UniqueId from 'uniqueId';
import { emit } from 'helpers/global-events';
import { resetScroll } from 'helpers/noScroll';
import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import sensor from 'components/sensor';
import Loader from 'components/loader';
import Modal, { Header, Content, MultiStepModal } from 'components/modal';
import Button from 'components/button';

import * as redux from './redux';

import 'styles/base/components/drop-down/_drop-down.scss';

const classes = new Bem('dropdown');
const maxHeightDD = 300;

/**
 * === DropDown Component ===
 *
 * = Example =
 * <DropDown
 *   ref={ref => this.ddRef = ref}
 *   list
 * >
 *   {dropdown button}
 *   {dropdown content}
 * </DropDown>
 */

@connect(state => ({
  // Set id of an opened dropdown
  whichOpened: state.dropDown.whichOpened,

  // ignore click outside on elements with the following class
  outsideClickIgnoreClass: 'notification'
}), { ...redux }, null, { forwardRef: true })
@onClickOutside
@sensor
@UniqueId
export default class DropDown extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,

    // Get id of an opened dropdown (redux)
    whichOpened: PropTypes.number,

    // Dropdown opening action (redux)
    actionOpen: PropTypes.func,

    // Dropdown closing action (redux)
    actionClose: PropTypes.func,

    // Dropdown destroying action (redux)
    actionDestroyed: PropTypes.func,

    // Enable outside click event
    enableOnClickOutside: PropTypes.func,

    // Disable outside click event
    disableOnClickOutside: PropTypes.func,

    // Prevent closing on outside click event
    dontCloseOnOutsideClick: PropTypes.bool,

    // TODO: do we still need this?
    group: PropTypes.string,

    // Dropdown open callback
    onOpen: PropTypes.func,

    // Dropdown close callback
    onClose: PropTypes.func,

    // Calc which direction dropdown will open (full-size)
    autoDirection: PropTypes.bool,

    // TODO: remove this - now is default everywhere
    inForm: PropTypes.bool,

    // Adds top and bottom padding
    list: PropTypes.bool,

    // Modal other props
    modalCommonProps: PropTypes.any, // eslint-disable-line

    // Modal header text/component when dropdown opens modal (on phone)
    modalHeader: PropTypes.any, // eslint-disable-line

    // Modal header other props
    modalHeaderProps: PropTypes.any, // eslint-disable-line

    // Modal header style view
    modalHeaderView: PropTypes.oneOf(['green', 'gray']),

    // Prevent rendering dropdown content before it's opened
    disableContent: PropTypes.bool,

    // Dropdown dropdown left alignment
    left: PropTypes.bool,

    // Dropdown dropdown direction to top
    topDD: PropTypes.bool,

    // Dropdown width depends on it's content
    wAuto: PropTypes.bool,

    // Передается из ComboboxInputWrapper в Modal для 'padding:0'
    modalContentNoPadding: PropTypes.bool,

    // Dropdown ref
    ddRef: PropTypes.func,

    schema: PropTypes.shape({}),

    // Render multiStep modal
    multiStep: PropTypes.bool,

    // Data for multiStep modal
    multiStepData: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({}))
    ]),

    // Key for rendering header text in multistep modal
    multiStepHeaderField: PropTypes.string,

    // Render content method for multiStep modal
    multiStepContent: PropTypes.func,

    // always render DD instead modal
    alwaysDD: PropTypes.bool
  };

  static defaultProps = {
    dontCloseOnOutsideClick: false,
    group: 'default',
    modalHeader: ''
  };

  constructor(props, context) {
    super(props, context);

    this.onResize = this.updateDimensions;

    this.eventHandler = this.eventHandler.bind(this);
    this.debouncedClick = debounce(this.clickHandler.bind(this), 160);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.calcDirection = this.calcDirection.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.state = {
      desktop: false,
      renderModal: false,
      showContent: !props.disableContent
    };

    this.runtime = {
      isMounted: false,
      id: context.getUid(),
      group: props.group
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      direction: props.topDD ? 'top' : 'bottom'
    }
  }

  componentDidMount() {
    this.updateDimensions(this.sensor.getRuntime());
    this.runtime.isMounted = true;
    this.nodeRef.removeAttribute('data-dropdown-loading');
    if (!this.isThisOpened()) this.props.disableOnClickOutside();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { group = {} } = this.props;
    if (prevProps.group && (group !== prevProps.group)) this.runtime.group = group;
    if (prevState.renderModal && this.state.desktop) emit(`ddModal_${this.runtime.id}`);
  }

  componentWillUnmount() {
    if (this.whichOpened === this.runtime.id) {
      this.props.actionDestroyed(this.runtime);
    }
    if (this.state.renderModal && !this.state.desktop) resetScroll();
    this.debouncedClick.cancel();
  }

  isThisOpened(props = this.props) {
    return props.whichOpened === this.runtime.id;
  }

  toggleModal(opened = false) {
    this.debouncedClick.cancel();
    const { schema = {}, multiStep, disableOnClickOutside, onClose } = this.props;
    if (multiStep && !this.state.desktop && opened) disableOnClickOutside();

    if (!opened && onClose) onClose();
    if (!schema.readOnly) this.setState({ renderModal: opened });
  }

  eventHandler(event) {
    this.debouncedClick.cancel();
    event.preventDefault();

    const clicked = isNumber(event.which || event.button);
    const ddIsOpened = this.isThisOpened();
    if (!clicked && ddIsOpened) return;

    if (clicked) {
      this.clickHandler(ddIsOpened);
    } else {
      event.persist();
      this.debouncedClick(ddIsOpened);
    }
  }

  clickHandler(ddIsOpened) {
    // if (!this.state.desktop) return this.toggleModal(true);
    if (ddIsOpened) return this.close();
    return this.open();
  }

  open() {
    this.debouncedClick.cancel();
    if (this.isThisOpened()) return; // This is already opened

    this.calcDirection();
    this.focus();
    this.props.actionOpen(this.runtime);
    // Open dropdown-modal on tablet/phone
    if (!this.state.desktop && !this.state.renderModal) {
      this.toggleModal(true);
    }
    if (this.props.disableContent && !this.state.showContent) {
      this.setState({ showContent: !this.state.showContent });
    }
    if (this.props.onOpen) this.props.onOpen();
    if (!this.props.dontCloseOnOutsideClick) this.props.enableOnClickOutside();
  }

  close() {
    this.debouncedClick.cancel();
    if (!this.isThisOpened()) return; // This is already closed

    // Close dropdown-modal on tablet/phone
    if (!this.state.desktop && this.state.renderModal) {
      emit(`ddModal_${this.runtime.id}`);
    }
    this.props.actionClose(this.runtime);
    if (this.props.disableContent && this.state.showContent) {
      this.setState({ showContent: !this.state.showContent });
    }
    if (this.props.onClose) this.props.onClose();
    if (this.ddButton) this.ddButton.blur();
    this.props.disableOnClickOutside();
  }

  focus() {
    this.ddButton.focus();
  }

  handleClickOutside() {
    if (this.isThisOpened()) {
      this.debouncedClick.cancel();
      this.close();
    }
  }

  calcDirection() {
    if (!this.props.autoDirection) return;
    let windowHeight = null;
    if (typeof window.innerWidth !== 'undefined') {
      windowHeight = window.innerHeight;
    } else if (typeof document.documentElement !== 'undefined'
      && typeof document.documentElement.clientWidth !==
      'undefined' && document.documentElement.clientWidth !== 0) {
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowHeight = document.getElementsByTagName('body')[0].clientHeight;
    }
    const inputRect = this.ddButton.getBoundingClientRect();
    const toBottom = windowHeight - inputRect.top -
      inputRect.height;
    if ((toBottom < maxHeightDD) && (inputRect.top > maxHeightDD)) {
      this.setState({
        direction: 'top'
      });
    } else {
      this.setState({
        direction: 'bottom'
      });
    }
  }

  updateDimensions({ media: { desktop } = {} }) {
    if (desktop !== this.state.desktop) this.setState({ desktop });
  }

  renderContent(content, topDD) {
    const {
      list, modalCommonProps, modalHeader, modalHeaderProps, modalHeaderView, modalContentNoPadding,
      multiStep, multiStepData, multiStepContent, multiStepHeaderField, alwaysDD
    } = this.props;
    const { desktop, renderModal, showContent } = this.state;

    if (!desktop && renderModal && !alwaysDD) {
      const commonProps = {
        closeEvent: `ddModal_${this.runtime.id}`,
        size: 'big',
        ...modalCommonProps
      };

      if (multiStep) {
        return (
          <MultiStepModal
            {...commonProps}
            data={multiStepData}
            firstStepHeader={modalHeader}
            renderContent={multiStepContent}
            onModalClose={this.toggleModal}
            headerProps={{ colorScheme: modalHeaderView }}
            contentProps={{
              list,
              noPadding: modalContentNoPadding
            }}
            headerField={multiStepHeaderField}
          />
        );
      }

      return (
        <Modal
          {...classes('modal')}
          onCloseRequired={this.toggleModal}
          {...commonProps}
          renderModal
        >
          <Header
            closeButton
            colorScheme={modalHeaderView}
            {...modalHeaderProps}
          >
            {modalHeader}
          </Header>
          <Content
            list={list}
            noPadding={modalContentNoPadding}
          >
            <div
              {...content.props}
              {...classes(
                'modalContent',
                null,
                content.props ? content.props.className : null
              )}
            />
          </Content>
        </Modal>
      );
    }

    if ((desktop || alwaysDD) && showContent ) {
      return (
        <div {...content.props} {...classes('content', { topDD }, content.props.className)} />
      );
    }

    return null;
  }

  render() {
    const { className, inForm, left, wAuto, ddRef } = this.props;
    const children = Children.toArray(this.props.children);
    const [btn = {}, content = {}] = children;
    const attrs = {};
    if (!this.runtime.isMounted) attrs['data-dropdown-loading'] = true;
    const opened = this.isThisOpened();
    let topDD = false;
    if (this.state.direction === 'top') topDD = true;

    return (
      <div
        /* TODO: надо пофиксить в стилях
        {...classes(
          null,
          { opened, inForm: !opened && inForm, left, wAuto },
          className
        )}
        */
        {...classes({
          modifiers: (!inForm && opened) ?
            { opened, left, wAuto } :
            { opened, inForm, left, wAuto },
          extra: className
        })}
        {...attrs}
        ref={(node) => {
          if (ddRef) ddRef(node);
          this.nodeRef = node;
        }}
      >
        <Button
          {...btn.props}
          {...classes('btn', { topDD }, btn.props.className)}
          onClick={this.eventHandler}
          onFocus={this.eventHandler}
          btnRef={(el) => {
            if (btn.wRef) btn.wRef(el);
            this.ddButton = el;
          }}
        />
        {this.renderContent(content, topDD)}
        <div {...classes('loading')}>
          <Loader {...classes('loader')} gray />
        </div>
      </div>
    );
  }
}

export function close(instance) {
  if (!(instance instanceof DropDown)) {
    if (__DEV_CLI__) throw new Error('DropDown.close called without instance argument.');
    return false;
  }
  return instance.getWrappedInstance().getInstance().close();
}

export function open(instance) {
  if (!(instance instanceof DropDown)) {
    if (__DEV_CLI__) throw new Error('DropDown.open called without instance argument.');
    return false;
  }
  return instance.getWrappedInstance().getInstance().open();
}

export function focus(instance) {
  if (!(instance instanceof DropDown)) {
    if (__DEV_CLI__) throw new Error('DropDown.focus called without instance argument.');
    return false;
  }
  return instance.getWrappedInstance().getInstance().focus();
}

/*
 TODO: Improve on server loading
 <Live />
 <Helmet script={[{ type: 'text/javascript', innerHTML: JS }]} />
 */
