import React from 'react';

const all_sold = ['all_sold', 'asc'];
const sales_suspended_until = ['sales_suspended_until', 'asc'];
const all_house_and_area_sold = ['all_house_and_area_sold', 'asc'];
const projectCanceled = ['projectCanceled', 'asc'];

function reliable() {
  return {
    value: this ? this.i18n('reliable') : null,
    param: [
      projectCanceled,
      all_sold,
      all_house_and_area_sold,
      sales_suspended_until,
      ['statusReady', 'desc'],
      ['reliability_to_publish', 'desc'],
      ['pointsLevel', 'desc']
    ]
  };
}

function popular() {
  return {
    value: this ? this.i18n('popular') : null,
    param: [['translationList', 'asc'], ...reliable().param]
  };
}

function cheap() {
  return {
    value: this ? this.i18n('cheap') : null,
    param: [projectCanceled, all_sold, sales_suspended_until, ['minPrice', 'asc']]
  };
}

function expensive() {
  return {
    value: this ? this.i18n('expensive') : null,
    param: [projectCanceled, all_sold, sales_suspended_until, ['minPrice', 'desc']]
  };
}

function earlier() {
  return {
    value: this ? this.i18n('earlier') : null,
    param: [projectCanceled, all_sold, sales_suspended_until, ['readiness', 'asc']]
  };
}

function later() {
  return {
    value: this ? this.i18n('later') : null,
    param: [projectCanceled, all_sold, sales_suspended_until, ['readiness', 'desc']]
  };
}

function cottageTownCheap() {
  return {
    value: this ? this.i18n('cheap') : null,
    param: [projectCanceled, all_house_and_area_sold, sales_suspended_until, ['minPrice', 'asc']]
  };
}

function cottageTownExpensive() {
  return {
    value: this ? this.i18n('expensive') : null,
    param: [projectCanceled, all_house_and_area_sold, sales_suspended_until, ['minPrice', 'desc']]
  };
}

function cottageTownEarlier() {
  return {
    value: this ? this.i18n('earlier') : null,
    param: [projectCanceled, all_house_and_area_sold, sales_suspended_until, ['readiness', 'asc']]
  };
}

function cottageTownLater() {
  return {
    value: this ? this.i18n('later') : null,
    param: [projectCanceled, all_house_and_area_sold, sales_suspended_until, ['readiness', 'desc']]
  };
}

export function ordersLC() {
  return [
    { id: 0, ...popular.call(this) },
    { id: 1, ...reliable.call(this) },
    { id: 2, ...cheap.call(this) },
    { id: 3, ...expensive.call(this) },
    { id: 4, ...earlier.call(this) },
    { id: 5, ...later.call(this) },
  ];
}

export function cottageTownOrders() {
  return [
    // { id: 0, ...cottageTownEarlier() },
    // { id: 1, ...cottageTownLater() },
    { id: 0, ...popular.call(this) },
    { id: 1, ...cottageTownCheap.call(this) },
    { id: 2, ...cottageTownExpensive.call(this) },
  ];
}
