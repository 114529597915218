import React from 'react';
import get from 'lodash/get';
import formatDate from 'helpers/formatDate';
import Icon, { iconTypes } from 'components/icon';

export function renderDate(bemClasses, txt, date) {
  return (
    <div {...bemClasses('dateCheck')}>
      <span {...bemClasses('text', 'dateCheck')} children={`${txt} ${formatDate({ date: get(date.split(' '), '[0]'), format: 'dd.mm.yyyy' })}`} />
    </div>
  );
}

export function renderReliabilityDateActualize(bemClasses, data, txt) {
  const { i18n } = this;
  const {
    reliability_date_first_published, reliability_date_actualize, reliability_to_publish
  } = data;

  if (reliability_date_first_published && !reliability_date_actualize && reliability_to_publish) {
    return renderDate(bemClasses, txt || i18n('reportCreated'), reliability_date_first_published);
  }
  if (reliability_date_actualize && reliability_to_publish) {
    return renderDate(bemClasses, txt || i18n('reportUpdated'), reliability_date_actualize);
  }
}

export function reliabilityIndex(pointClasses, points, type, statusReady) {
  const readyTxt = this.i18n('readyText');
  const readyData = { statusReady, readyTxt };

  return totalReliabilityIndex.call(this, pointClasses, points, type, readyData);
}

export function reliabilityIndexComplex(pointClasses, points, type, statusReady) {
  const { i18n } = this;
  const readyTxt = type === 'cottage_town' ? i18n('readyCTText') : i18n('readyLCText');
  const readyData = { statusReady, readyTxt };

  return totalReliabilityIndex.call(this, pointClasses, points, type, readyData);
}

export function totalReliabilityIndex(pointClasses, points, type, readyData) {
  if (type === 'cottage_town' && !readyData.statusReady) return null;

  const { i18n } = this;
  const filledPoints = get(points, 'investorConfidence.filled');
  const allPoints = get(points, 'investorConfidence.all');
  const isPointed = points && points.investorConfidence;
  const title = isPointed ? `${i18n('reliabilityIndexText')} ${filledPoints} ${i18n('ofText')} ${allPoints}` : null;
  const level = get(points, 'level', 'NA');

  return (
    <div {...pointClasses('points')} title={title}>
      <Icon {...pointClasses('icon', { points: true, [level]: level })} type={iconTypes.bc} />
      <div {...pointClasses('pointsText')} data-test="textPoints">
        {isPointed && !readyData.statusReady &&
          <>
            <span {...pointClasses('text', 'points')} children={filledPoints} />
            <span {...pointClasses('text', 'pointsAll')} children={`${i18n('ofText')} ${allPoints}`} />
          </>
        }
        {!isPointed && !readyData.statusReady && (type !== 'cottage_town') &&
          <span {...pointClasses('text', 'points na')} children={i18n('naText')} />
        }
        {readyData.statusReady &&
          <span {...pointClasses('text', 'points ready')} children={readyData.readyTxt} />
        }
      </div>
    </div>
  );
}
