import Leaflet from 'leaflet';
import markerIcon from './assets/marker-icon.png';
import markerIconShadow from './assets/marker-shadow.png';

const icon = Leaflet.icon({
  iconUrl: markerIcon,
  iconSize: [25, 41],
  shadowUrl: markerIconShadow,
  shadowSize: [41, 41],
  iconAnchor: [13, 41],
  shadowAnchor: [13, 41]
});

export default icon;
