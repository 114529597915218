/**
 * Created by Kotkin on 28.09.2017.
 */
/* eslint-disable react/no-multi-comp */
import React, { PureComponent, Children } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import map from 'lodash/map';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import get from 'lodash/get';
import DragScroll from 'components/drag-scroll';
import { iconTypes } from 'components/icon';

import TabJSButton from './tab-js-button';

import 'styles/base/components/tab-js-control/_tab-js-control.scss';

const classes = new Bem('tabJSControl');

/**
 * = TabJSControl Component =
 *
 * May render Tabs in two possible configurations:
 *
 * == Example 1 ==
 * <TabJSControl>
 *   config={[
 *      {
 *        title: {title},
 *        renderer: func
 *      }, {
 *        title: {title},
 *        renderer: func
 *      },...
 *   ]}
 *   onChange={func}
 *   aside={aside content}
 * />
 *
 *  == Example 2 ==
 * <TabJSControl
 *   onChange={func}
 * >
 *    <tab title={tab title}>{tab content}</tab>
 *    <tab title={tab title}>{tab content}</tab>
 * </TabJSControl>
 *
 *  == Example 3 ==
 * <TabJSControl
 *   onChange={func}
 * >
 *   <tab>
 *     <title>{title}</title>
 *     <div>{tab content}</div>
 *   </tab>
 *   <tab>
 *     <title>{title}</title>
 *     <div>{tab content}</div>
 *   </tab>
 * </TabJSControl>
 *
 * == NOTE ==
 * title can be both Node or String
 * When it's needed to render Title as a separate component
 * it should't be covered with 'span' tag.
 * If title is covered with 'span' it will be rendered in the following structure:
 * <div>
 *   <span>
 *     <span>{Title}</span>
 *   </span>
 * </div>
 *
 * === Props ===
 * children - has title prop for rendering Tab button labels
 *            and their own children for rendering tab content.
 *            May have "mods" prop which will be added as data-mods to tabContent.
 * config - tab configuration:
 *          1. title - Tab button label,
 *          2. renderer - func that renders active tab content.
 * aside - add not underlined block on the right. Like search button, some info etc.
 * selectedTab - index of an active tab in config array.
 *
 */

class TabJSControl extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    config: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
      ]).isRequired,
      renderer: PropTypes.func
    })),
    onChange: PropTypes.func,
    selectedTab: PropTypes.number,
    aside: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    scrollToActiveTab: PropTypes.bool,
    hFull: PropTypes.bool,
    tabContentScroll: PropTypes.bool,
    linkMode: PropTypes.bool
  };

  static defaultProps = {
    scrollToActiveTab: true
  };

  static getTitle(tab) {
    if (get(tab, 'props.title')) return tab.props.title;

    const filterTab = filter(get(tab, 'props.children'), child => get(child, 'type') === 'title');
    const title = get(filterTab, '[0].props.children', '');
    return isArray(title) ? title[0] : title;
  }

  static getContent(content) {
    const contentArray = Children.toArray(content);
    return filter(contentArray, child => child.type !== 'title');
  }

  constructor(props, context) {
    super(props, context);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.setCoors = this.setCoors.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);

    this.coors = { clientX: 0, clientY: 0 };

    this.state = {activeTab: null}
  }

  static getDerivedStateFromProps(props, state) {
    const children = props.children ? Children.toArray(props.children) : null;
    if (state.oldPropsSelectedTad !== props.selectedTab) {
      return {
        children,
        activeTab: isNumber(props.selectedTab) ? props.selectedTab : 0,
        oldPropsSelectedTad: props.selectedTab
      }
    }

    return { children }
  }

  setActiveTab(activeTab) {
    if (activeTab !== this.state.activeTab) {
      // TODO: remove findDOMNode
      this.setState({ activeTab });
    }
  }

  setCoors(e) {
    e.preventDefault();
    if (!e.changedTouches) return this.coors = { clientX: e.clientX, clientY: e.clientY };
    this.coors = { clientX: e.changedTouches[0].pageX, clientY: e.changedTouches[0].pageY };
  }

  getTabBtnChildren(tab) {
    if (this.props.linkMode) return tab.props;
    if (this.state.children) return TabJSControl.getTitle(tab);
    return tab.title;
  }

  handleTabClick(e, activeTab) {
    if (this.isMouseRightClick(e)) return false;
    const { config, onChange, selectedTab } = this.props;
    if (e) {
      const positionX = e.changedTouches ? e.changedTouches[0].pageX : e.clientX;
      const positionY = e.changedTouches ? e.changedTouches[0].pageY : e.clientY;

      // TODO prevent click on tab li element while dragging
      if (positionX !== 0 &&
        (
          Math.abs(positionX - this.coors.clientX) >= 10 ||
          Math.abs(positionY - this.coors.clientY) >= 10
        )
      ) {
        this.coors = { clientX: positionX, clientY: positionY };
        e.preventDefault();
        return false;
      }
    }

    if (activeTab !== this.state.activeTab) {
      const tabObject = this.state.children ? this.state.children[activeTab].props : config[activeTab];
      if (onChange) onChange(tabObject, activeTab);
      if (!isNumber(selectedTab)) this.setActiveTab(activeTab);
    }
  }

  handleFocusEvent(id) {
    // TODO выбираем таб по нажатию на клавишу TAB
    this.handleTabClick(null, id);
  }

  isMouseRightClick(e) { // eslint-disable-line
    let rightClick = false;
    if (!e) return false;
    if (e.which) {
      rightClick = e.which === 3;
    } else if (e.button) {
      rightClick = e.button === 2;
    }

    return rightClick;
  }

  renderTabs() {
    const { config, aside, scrollToActiveTab, linkMode, selectedTab } = this.props;
    const { activeTab, children} = this.state;
    const currentTab = activeTab !== null ? activeTab : selectedTab || 0;
    const tabsToRender = config || children;
    return (
      <div {...classes('container')} data-test="container">
        <DragScroll
          {...classes('draggable')}
          scrollIconType={iconTypes.fastForward}
          scrollTo={scrollToActiveTab && currentTab}
        >
          <ul {...classes('tabs')}>
            {map(tabsToRender, (tab, index) => (
              <li
                {...classes('tab')}
                key={index}
                ref={btn => this[`tabControl_${index}`] = btn}
                data-test="tab"
              >
                <TabJSButton
                  {...classes('tabElement')}
                  active={index === currentTab}
                  onFocus={() => this.handleFocusEvent(index)}
                  onTouchStart={this.setCoors}
                  onTouchEnd={e => this.handleTabClick(e, index)}
                  onMouseDown={this.setCoors}
                  onMouseUp={e => this.handleTabClick(e, index)}
                  linkMode={linkMode}
                >
                  {this.getTabBtnChildren(tab)}
                </TabJSButton>
                <span {...classes('line')} />
              </li>
            ))}
          </ul>
        </DragScroll>
        {aside && <div {...classes('aside')} children={aside} />}
      </div>
    );
  }

  renderTabContent(activeTabContent, tabMods) {
    const content = [];
    if (this.state.children) {
      content.push(TabJSControl.getContent(activeTabContent));
    } else {
      content.push(activeTabContent());
    }
    const children = content[0] || [];
    if (children.length) {
      return (
        <div {...classes('tabsContent', { [tabMods]: tabMods })}>
          <div {...classes('content')} children={content} />
        </div>
      );
    }
  }

  render() {
    const { config, tabContentScroll, hFull, selectedTab, className } = this.props;
    const { activeTab, children} = this.state;
    const currentTab = activeTab !== null ? activeTab : selectedTab || 0;
    const configRenderer = config && config[currentTab] ? config[currentTab].renderer : null;
    const activeTabContent = (children && children[currentTab]) ?
      children[currentTab].props.children :
      configRenderer;
    const tabMods = children && children[currentTab] ?
      (children[currentTab].props.mods || false) :
      false;

    return (
      <div{...classes(null, { tabContentScroll, hFull }, className)}>
        {this.renderTabs()}
        {activeTabContent && this.renderTabContent(activeTabContent, tabMods)}
      </div>
    );
  }
}

export default TabJSControl;
