/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import { JsonLd } from 'react-schemaorg';
import PropTypes from 'prop-types';
import { authConnect, UserProp } from 'auth';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import localStorage from 'helpers/local-storage';
import Counter, { counterDecorator }  from 'subsys/counter';
import marketStatPush, { marketStatEvents } from 'subsys/market-stat';
import Form, {
  Text, Input, PhoneInput, InputDatePicker, Checkbox
  //, RecaptchaButton
} from 'containers/form';
import { Maps, InfoLayer, BaseLayersUser } from 'containers/maps';
import Link from 'components/link';
import Button from 'components/button';
import sensor from 'components/sensor';
import { Thumb } from 'components/image';
import Row, { Col } from 'components/row';
import Accordion from 'components/accordion';
import Icon, { iconTypes } from 'components/icon';
import Block, { BlockHeader, BlockContent, BlockFooter } from 'components/block';

import imgUklon from './assets/uklon.png';

import translates from './ask-question-i18n.json';

import 'styles/base/subsys/ask-question/_ask-question.scss';

const bemClasses = new Bem('blockAskQuestion');

@authConnect()
@counterDecorator()
@I18nHoc(translates)
@sensor
export default class AskQuestion extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    controller: PropTypes.string,
    entityId: PropTypes.number,
    entity: PropTypes.string,
    type: PropTypes.string,
    auth: UserProp,
    url: PropTypes.string,
    project: PropTypes.string,
    otherProps: PropTypes.shape({
      notificationMessageSuccess: PropTypes.string,
      inputPhoneProps: PropTypes.shape({}),
      otherBtnSendProps: PropTypes.shape({}),
      headerTopic: PropTypes.bool,
      headerTopicText: PropTypes.string,
      textAdditional: PropTypes.string,
      additionalMain: PropTypes.bool,
      textAdditionalMain: PropTypes.string,
      firstSeller: PropTypes.shape({}),
    })
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.state = { val: false };

    this.storageAskQuestionGet = this.storageAskQuestionGet.bind(this);
    this.storageAskQuestionPut = this.storageAskQuestionPut.bind(this);
    this.isDeveloperQuestion =
      props.controller === "support/ask-question/ask-developer-question" ||
      props.controller === "support/ask-question/ask-owner-question";
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      device: 'desktop'
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;
    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      get(this, 'props.auth.authenticated') &&
      !get(prevProps, 'auth.authenticated') &&
      typeof get(this, 'form.reloadSchema') === 'function'
    ) {
      this.form.reloadSchema();
    }
  }

  onErrorSubmit() {
    marketStatPush(marketStatEvents.successError, 'askQuestion', 'error');
  }

  onSubmit=()=> {
    const { otherProps = {}, countAction } = this.props;
    const { counterProps = {} } = otherProps;

    if (
      counterProps.entity &&
      counterProps.action &&
      typeof countAction === 'function'
    ) {
      countAction(
        counterProps.entity,
        counterProps.action
      );
    }
    marketStatPush(marketStatEvents.successError, 'askQuestion', 'success');
  }

  storageAskQuestionPut(value, entity) { // eslint-disable-line
    localStorage.emit(entity, value);
  }

  storageAskQuestionGet(key) { // eslint-disable-line
    return localStorage.get(`${this.props.entity}_${key}`) || '';
  }

  getData =()=> {
    const { entityId, entity, url } = this.props;
    const data = {};
    const developerEmail = get(this, 'props.otherProps.developerEmail');
    if (developerEmail) data.developerEmail = developerEmail;
    data.entity_id = entityId;
    data.url = url || '';
    // If Not logged in
    if (!get(this, 'props.auth.id')) {
      data.name = this.storageAskQuestionGet('name');
      data.phone = this.storageAskQuestionGet('phone');
      if (!data.phone) {
        delete data.phone;
      }
      data.email = this.storageAskQuestionGet('email');
    } else {
      if (get(this, 'props.auth.name')) data.name = get(this, 'props.auth.name');
      if (get(this, 'props.auth.phone')) data.phone = get(this, 'props.auth.phone');
      if (get(this, 'props.auth.email')) data.email = get(this, 'props.auth.email');

      this.storageAskQuestionPut(this.props.auth.name, `${entity}_name`);
      this.storageAskQuestionPut(this.props.auth.email, `${entity}_email`);
    }
    data.project = __PROJECT__;
    return data;
  }

  setTime =(time)=> {
    if (this.form && this.form.data) this.form.data['data.appointment_time'] = time;
  }

  render() {
    const { i18n } = this;
    const { className, controller, entity, entityId, otherProps = {}, getAskQuestionData, type } = this.props;

    if (type === 'backCall') return backCall.call(this);

    const {
      inputPhoneProps,
      otherBtnSendProps = {},
      headerTopic,
      headerTopicText,
      notificationMessageSuccess,
      firstSeller,
      additionalMain,
      textAdditionalMain,
      marker,
      free_taxi
    } = otherProps;
    const viberPhone = get(firstSeller, 'social_pages.viber') ||
      get(firstSeller, 'info.sn.viber');
    const telegramUrl = get(firstSeller, 'social_pages.telegram') ||
      get(firstSeller, 'info.sn.telegram');

    // const { avatar, name, company_name } = dataDeveloper;
    const avatar = get(firstSeller, 'avatar');
    const name = get(firstSeller, 'name');
    const company_name = get(firstSeller, 'company_name');
    const image = (typeof avatar === 'string' || !avatar) ? { id: avatar } : avatar;
    const avatarDeveloper = { ...image, alt: name, title: company_name };
    const { device } = this.state;

    const data = typeof getAskQuestionData === 'function' ? getAskQuestionData(): this.getData();

    const footer = (
      <>
        <Button
          {...bemClasses('btn', 'send')}
          type="submit"
          label={i18n('btnSendText')}
          data-test="btnSend"
          shadow
          {...otherBtnSendProps}
        />
        {viberPhone &&
          <ViberPhone
            device={device}
            phone={viberPhone}
            projectId={entityId}
            developerId={get(firstSeller, 'id')}
          />
        }
        {telegramUrl &&
          <TelegramPhone
            device={device}
            phone={telegramUrl}
            projectId={entityId}
            developerId={get(firstSeller, 'id')}
          />
        }
      </>
    );

    return (
      <div {...bemClasses({ extra: className })}>
        {headerTopic &&
          <BlockHeader
            {...bemClasses('header')}
            headerTopic={<h2 children={headerTopicText || i18n('headerTopic')} />}
            headerRight={!!avatar &&
              <div {...bemClasses('imgContainer')}>
                <div {...bemClasses('img')}>
                  <Thumb
                    {...bemClasses('imgPic')}
                    image={avatarDeveloper}
                    tagSpan
                  />
                  <JsonLd
                    item={{
                      '@context': 'https://schema.org',
                      '@type': 'ImageObject',
                      name: get(avatarDeveloper, 'alt', null),
                      description: get(avatarDeveloper, 'title', null),
                      contentUrl: get(image, 'id') ? absoluteUrl(`/images/thumb/full/${image.id}`) : null
                    }}
                  />
                </div>
              </div>
            }
          />
        }
        <Form
          {...bemClasses('form')}
          ref={el => this.form = el}
          controller={controller}
          showPopupValidationMessages
          resetAfterSubmit
          data={data}
          defaultValues={data}
          defaultSuccessHeader={notificationMessageSuccess}
          onErrorSubmit={this.onErrorSubmit}
          onSubmit={this.onSubmit}
        >
          <Block {...bemClasses('block')}>
            <BlockContent>
              <div {...bemClasses('content')}>
                <Row {...bemClasses('row', 'form')} mpColumn>
                  <Col {...bemClasses('col', 'userForm')} allPr={20}>
                    <Input
                      {...bemClasses('input')}
                      dataBind="name"
                      onChange={value => this.storageAskQuestionPut(value, `${entity}_name`)}
                      noLabel
                      left
                    />
                    <PhoneInput
                      {...bemClasses('input', 'phone')}
                      {...inputPhoneProps}
                      dataBind="phone"
                      onChange={value => this.storageAskQuestionPut(value, `${entity}_phone`)}
                    />
                    <Input
                      {...bemClasses('input')}
                      dataBind="email"
                      onChange={value => this.storageAskQuestionPut(value, `${entity}_email`)}
                      noLabel
                      left
                    />
                    {/*
                    <RecaptchaButton
                      {...bemClasses('recaptcha')}
                      dataBind="recaptcha"
                    />
                    */}
                  </Col>
                  <Col allFlex allPr={0}>
                    <div {...bemClasses('textareaContainer')} data-test="textareaContainer">
                      <Text
                        {...bemClasses('textarea')}
                        dataBind="question"
                        hasLabel={false}
                      />
                    </div>
                  </Col>
                  {!this.isDeveloperQuestion && additionalMain &&
                    <Col {...bemClasses('col', 'additionalMain')} allFlex allPr={0}>
                      <div
                        {...bemClasses('text', 'additionalMain')}
                        children={textAdditionalMain || i18n('textAdditionalMain')}
                      />
                    </Col>
                  }
                </Row>
                {this.isDeveloperQuestion &&
                  <Accordion
                    {...bemClasses('accordion')}
                    panelTopic={
                      <>
                        {i18n('makeAppointment')}
                        {free_taxi &&
                          <span
                            {...bemClasses('text', 'small')}
                            children={i18n('freeTaxiBildUa')}
                          />
                        }
                      </>
                    }
                    panelIconType={free_taxi ? iconTypes.taxi : iconTypes.calendar}
                  >
                    <panel {...bemClasses('panel')}>
                      <div {...bemClasses('accordionContent')}>
                        <Row {...bemClasses('row')} mpColumn>
                          <Col {...bemClasses('col', 'datePicker')} mdAuto mtAuto allPr={0}>
                            <InputDatePicker
                              dataBind="data.make_appointment"
                              withoutModal
                            />
                            {free_taxi &&
                              <Checkbox
                                {...bemClasses('checkbox', 'freeTaxi')}
                                dataBind="data.free_taxi"
                                withIcon
                                noError
                                children={(<>
                                  {i18n('orderFreeTaxi')}
                                  <img {...bemClasses('img', 'uklon')} src={imgUklon} alt="taxi uklon" loading="lazy" decoding="async" />
                                </>)}
                              />
                            }
                          </Col>
                          <Col {...bemClasses('col', 'appointmentHours')} allPr={0}>
                            <AppointmentHours
                              setTime={this.setTime}
                            />
                          </Col>
                        </Row>
                        {firstSeller && firstSeller.geo_centroid &&
                          <div {...bemClasses('blockMap')}>
                            <div {...bemClasses('text', 'label')} children={i18n('salesDepartmentLocation')} />
                            <div {...bemClasses('mapContainer')}>
                              <Maps
                                {...bemClasses('map')}
                                noAutoGeolocate
                                adaptiveScrollZoom={2000}
                              >
                                <BaseLayersUser key="bl" />
                                <InfoLayer
                                  key="gc"
                                  noAutoFit
                                  data={[firstSeller.geo_centroid]}
                                  maxZoom={18}
                                  markerProps={{ icon: marker('gray') }}
                                />
                                <InfoLayer
                                  fitMaxZoom={17}
                                  minZoom={19}
                                  key="il"
                                  data={[firstSeller.geo_centroid]}
                                />
                              </Maps>
                            </div>
                          </div>
                        }
                      </div>
                    </panel>
                  </Accordion>
                }
              </div>
            </BlockContent>
            <BlockFooter children={footer} />
          </Block>
        </Form>
      </div>
    );
  }
}

@I18nHoc(translates)
class AppointmentHours extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = { time: null };
  }

  setTime=(time)=>{
    this.props.setTime(time)
    this.setState({time});
  }

  renderTimeButton=(time)=>{
    return (
      <Button
        {...bemClasses('btn', { appointmentHours: true, active: time === this.state.time })}
        onClick={()=>this.setTime(time)}
        title={time}
        label={time}
      />
    )
  }

  render() {
    const { i18n } = this;

    return (
      <>
        <div {...bemClasses('appointmentHours', 'morning')}>
          <div {...bemClasses('text', 'label')} children={i18n('morning')} />
          {this.renderTimeButton('10:00')}
          {this.renderTimeButton('10:30')}
          {this.renderTimeButton('11:00')}
          {this.renderTimeButton('11:30')}
        </div>
        <div {...bemClasses('appointmentHours', 'afternoon')}>
          <div {...bemClasses('text', 'label')} children={i18n('afternoon')} />
          {this.renderTimeButton('12:00')}
          {this.renderTimeButton('12:30')}
          {this.renderTimeButton('13:00')}
          {this.renderTimeButton('13:30')}
          {this.renderTimeButton('14:00')}
          {this.renderTimeButton('14:30')}
          {this.renderTimeButton('15:00')}
          {this.renderTimeButton('15:30')}
        </div>
        <div {...bemClasses('appointmentHours', 'evening')}>
          <div {...bemClasses('text', 'label')} children={i18n('evening')} />
          {this.renderTimeButton('16:00')}
          {this.renderTimeButton('16:30')}
          {this.renderTimeButton('17:00')}
          {this.renderTimeButton('17:30')}
        </div>
      </>
    );
  }
}

@I18nHoc(translates)
export class ViberPhone extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    phone: PropTypes.string,
    device: PropTypes.string,
    projectId: PropTypes.number,
    developerId: PropTypes.number
  };

  render() {
    const { i18n } = this;
    const { className, phone, projectId, developerId, device } = this.props;
    const href = device === 'desktop' ?
      `viber://chat?number=${phone}` :
      `viber://add?number=${phone.substr(1)}`;

    return (
      <Counter
        action="viberDeveloperClick"
        entity={{
          project_id: projectId,
          developer_id: developerId
        }}
        mode="click"
        injectClickName="onClick"
      >
        <Link
          {...bemClasses('link', 'social viber', className)}
          href={href}
          title={i18n('linkToViberTitle')}
          rel="nofollow"
          // noRedirector
          external
          blank
        >
          <span {...bemClasses('linkContent')}>
            <Icon
              {...bemClasses('icon')}
              type={iconTypes.viberFilling}
            />
          </span>
        </Link>
      </Counter>
    );
  }
}

@I18nHoc(translates)
export class TelegramPhone extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    phone: PropTypes.string,
    device: PropTypes.string,
    projectId: PropTypes.number,
    developerId: PropTypes.number
  };

  render() {
    const { i18n } = this;
    const { className, phone, projectId, developerId, device } = this.props;

    if (device === 'desktop') return null;

    const  href = `tg://resolve?domain=${phone}`;

    return (
      <Counter
        action="telegramDeveloperClick"
        entity={{
          project_id: projectId,
          developer_id: developerId
        }}
        mode="click"
        injectClickName="onClick"
      >
        <Link
          {...bemClasses('link', 'social telegram', className)}
          href={href}
          title={i18n('linkToTelegramTitle')}
          rel="nofollow"
          // noRedirector
          external
          blank
        >
          <span {...bemClasses('linkContent')}>
            <Icon {...bemClasses('icon')} type={iconTypes.telegramFill} />
          </span>
        </Link>
      </Counter>
    );
  }
}

function backCall() {
  const { i18n } = this;
  const { className, controller, entity,  otherProps = {}, getAskQuestionData } = this.props;
  const {
    inputPhoneProps,
    otherBtnSendProps = {},
    headerTopic,
    headerTopicText,
    notificationMessageSuccess,
    firstSeller,
    textAdditional
  } = otherProps;
  const avatar = get(firstSeller, 'avatar');
  const name = get(firstSeller, 'name');
  const company_name = get(firstSeller, 'company_name');
  const image = (typeof avatar === 'string' || !avatar) ? { id: avatar } : avatar;
  const avatarDeveloper = { ...image, alt: name, title: company_name };

  const data = typeof getAskQuestionData === 'function' ? getAskQuestionData(): this.getData();

  const footer = (
    <>
      <Button
        {...bemClasses('btn', 'send')}
        type="submit"
        label={i18n('btnSendText')}
        data-test="btnSend"
        shadow
        {...otherBtnSendProps}
      />
    </>
  );

  return (
    <div {...bemClasses({ extra: className })}>
      {headerTopic &&
        <BlockHeader
          {...bemClasses('header')}
          headerTopic={<h2 children={headerTopicText || i18n('headerTopic')} />}
          headerRight={!!avatar &&
            <div {...bemClasses('imgContainer')}>
              <div {...bemClasses('img')}>
                <Thumb
                  {...bemClasses('imgPic')}
                  image={avatarDeveloper}
                  tagSpan
                />
                <JsonLd
                  item={{
                    '@context': 'https://schema.org',
                    '@type': 'ImageObject',
                    name: get(avatarDeveloper, 'alt', null),
                    description: get(avatarDeveloper, 'title', null),
                    contentUrl: get(image, 'id') ? absoluteUrl(`/images/thumb/full/${image.id}`) : null
                  }}
                />
              </div>
            </div>
          }
        />
      }
      <Form
        {...bemClasses('form')}
        ref={el => this.form = el}
        controller={controller}
        showPopupValidationMessages
        data={data}
        defaultValues={data}
        defaultSuccessHeader={notificationMessageSuccess}
        onErrorSubmit={this.onErrorSubmit}
        onSubmit={this.onSubmit}
      >
        <Block {...bemClasses('block')}>
          <BlockContent>
            <div {...bemClasses('content')}>
              <Row {...bemClasses('row')} mpColumn>
                <Col {...bemClasses('col', 'userForm')} allPr={20}>
                  <Input
                    {...bemClasses('input')}
                    dataBind="name"
                    onChange={value => this.storageAskQuestionPut(value, `${entity}_name`)}
                    noLabel
                    left
                  />
                  <PhoneInput
                    {...bemClasses('input', 'phone')}
                    {...inputPhoneProps}
                    dataBind="phone"
                    onChange={value => this.storageAskQuestionPut(value, `${entity}_phone`)}
                  />
                  <div
                    {...bemClasses('text', 'additional')}
                    children={textAdditional || i18n('textAdditional')}
                  />
                </Col>
              </Row>
            </div>
          </BlockContent>
          <BlockFooter children={footer} />
        </Block>
      </Form>
    </div>
  );
}
