/**
 * Created by PIV on 22.06.2016.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';

import 'styles/base/components/container/_container.scss';

const classes = new Bem('container');

/**
 * Container
 * Обвертка для всего сайта, умеет держать ширину сайта и центрироваться.
 */

export default class Container extends Component {
  static propTypes = {
    className: PropTypes.string,

    // props for SEO
    // seoProps: PropTypes.shape({}),

    // Будет удерживать максимальную ширину сайта
    body: PropTypes.bool,

    // выровняет лайаут по левому краю
    left: PropTypes.bool,

    // делает видимым правый Баннер
    withBannerR: PropTypes.bool,

    children: PropTypes.node,

    // Ручное задание стилей, deprecated
    style: PropTypes.any // eslint-disable-line
  };

  render() {
    const {
      className, body, left, withBannerR, children, style /* , seoProps = {} */
    } = this.props;
    const mods = {
      body: !!body,
      left: !!left,
      withBannerR: !!withBannerR
    };
    return (
      <div
        {...classes({
          modifiers: mods,
          extra: className
        })}
        // {...seoProps}
        style={style}
        children={children}
      />
    );
  }
}
