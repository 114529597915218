// https://react-slick.neostack.com/docs/get-started
// version 0.21.0

import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import json2mq from 'json2mq';
import map from 'lodash/map';
import get from 'lodash/get';
import each from 'lodash/each';
import filter from 'lodash/filter';
import Bem from 'bemHelper';

import defaultProps from './default-props';
import InnerSlider from './inner-slider';
import { canUseDOM } from './utils/innerSliderUtils';

import 'styles/base/components/slick-slider/_slick-slider.scss';

const enquire = canUseDOM() && require('enquire.js');

const bemClasses = new Bem('slickSlider');

export default class Slider extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    responsive: PropTypes.arrayOf(PropTypes.shape({}))
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.getUpdatedProps = this.getUpdatedProps.bind(this);
    this.slickPrev = this.slickPrev.bind(this);
    this.slickNext = this.slickNext.bind(this);
    this.slickGoTo = this.slickGoTo.bind(this);
    this.slickPause = this.slickPause.bind(this);
    this.slickPlay = this.slickPlay.bind(this);

    this.state = {
      breakpoint: null,
      clientRendered: false
    };

    this._responsiveMediaHandlers = [];
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({ clientRendered: true });

    this.handleResponsiveBreakpoints(this.props);
  }

  componentWillUnmount() {
    each(this._responsiveMediaHandlers, obj =>
      enquire.unregister(obj.query, obj.handler));
  }

  getUpdatedProps() {
    const { responsive } = this.props;
    // makes sure that children is an array, even when there is only 1 child
    let children = Children.toArray(this.props.children);

    let settings;
    let newProps;
    if (this.state.breakpoint) {
      newProps = filter(responsive, resp => resp.breakpoint === this.state.breakpoint);

      settings =
        newProps[0].settings === 'unslick'
          ? 'unslick'
          : { ...defaultProps, ...this.props, ...newProps[0].settings };
    } else {
      settings = { ...defaultProps, ...this.props };
    }
    settings.infinite = canUseDOM() && settings.infinite;
    settings.serverRendered = !canUseDOM();

    // force scrolling by one if centerMode is on
    if (settings.centerMode) {
      if (settings.slidesToScroll > 1 && process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        console.warn(
          `slidesToScroll should be equal to 1 in centerMode, you are using ${settings.slidesToScroll}`
        );
      }
      settings.slidesToScroll = 1;
    }

    // force showing one slide and scrolling by one if the fade mode is on
    if (settings.fade) {
      if (settings.slidesToShow > 1 && process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        console.warn(
          `slidesToShow should be equal to 1 when fade is true, you're using ${settings.slidesToShow}`
        );
      }

      if (settings.slidesToScroll > 1 && process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        console.warn(
          `slidesToScroll should be equal to 1 when fade is true, you're using ${settings.slidesToScroll}`
        );
      }
      settings.slidesToShow = 1;
      settings.slidesToScroll = 1;
    }

    // Children may contain false or null, so we should filter them
    // children may also contain string filled with spaces
    // (in certain cases where we use jsx strings)
    children = filter(children, (child) => {
      if (typeof child === 'string') return !!child.trim();
      return !!child;
    });
    const childrenLength = Children.count(children);

    // rows and slidesPerRow logic is handled here
    if (settings.variableWidth && (settings.rows > 1 || settings.slidesPerRow > 1)) {
      // eslint-disable-next-line
      console.warn('variableWidth is not supported in case of rows > 1 or slidesPerRow > 1');
      settings.variableWidth = false;
    }

    let newChildren = [];
    let currentWidth = null;

    for (
      let i = 0;
      i < childrenLength;
      i += settings.rows * settings.slidesPerRow
    ) {
      const newSlide = [];
      const secondLength = i + (settings.rows * settings.slidesPerRow);

      for (
        let j = i;
        j < secondLength;
        j += settings.slidesPerRow
      ) {
        const row = [];
        const thirdLength = j + settings.slidesPerRow;

        for (let k = j; k < thirdLength; k += 1) {
          if (settings.variableWidth && children[k].props.style) {
            currentWidth = children[k].props.style.width;
          }
          if (k >= children.length) break;
          row.push(
            cloneElement(children[k], {
              key: (100 * i) + (10 * j) + k,
              tabIndex: -1,
              style: {
                width: `${100 / settings.slidesPerRow}%`,
                display: 'inline-block'
              }
            })
          );
        }
        newSlide.push(<div {...bemClasses('slideContent')} key={(10 * i) + j}>{row}</div>);
      }

      if (settings.variableWidth) {
        newChildren.push(
          <div key={i} style={{ width: currentWidth }}>
            {newSlide}
          </div>
        );
      } else {
        newChildren.push(<div key={i}>{newSlide}</div>);
      }
    }

    if (newChildren.length <= settings.slidesToShow) {
      let lenDiff = settings.slidesToShow - newChildren.length;

      settings.unslick = true;
      newChildren = map(newChildren, child => (
        cloneElement(child, { ...bemClasses('slide', 'active') })
      ));

      while (lenDiff > 0) {
        newChildren.push(
          <div key={`empty_${lenDiff}`} {...bemClasses('slide', 'active')} />
        );
        lenDiff -= 1;
      }
    }

    return {
      settings,
      newChildren
    };
  }

  // handles responsive breakpoints
  handleResponsiveBreakpoints = (props) => {
    const { responsive } = props;

    if (responsive) {
      const client = canUseDOM();
      const breakpoints = map(responsive, breakpt => breakpt.breakpoint);
      // sort them in increasing order of their numerical value
      breakpoints.sort((x, y) => x - y);

      each(breakpoints, (breakpoint, index) => {
        // media query for each breakpoint
        let bQuery;
        if (index === 0) {
          bQuery = json2mq({ minWidth: 0, maxWidth: breakpoint });
        } else {
          bQuery = json2mq({
            minWidth: breakpoints[index - 1] + 1,
            maxWidth: breakpoint
          });
        }

        // when not using server side rendering
        if (client) {
          this.media(bQuery, () => {
            this.setState({ breakpoint });
          });
        }
      });

      // Register media query for full screen. Need to support resize from small to large
      // convert javascript object to media query string
      const query = json2mq({ minWidth: breakpoints.slice(-1)[0] });

      if (client) {
        this.media(query, () => {
          this.setState({ breakpoint: null });
        });
      }
    }
  };

  media(query, handler) {
    // javascript handler for css media query
    if (handler) {
      enquire.register(query, handler);
    }
    this._responsiveMediaHandlers.push({ query, handler });
  }

  slickPrev() {
    return this.innerSlider.slickPrev();
  }

  slickNext() {
    return this.innerSlider.slickNext();
  }

  slickGoTo(slide, dontAnimate = false) {
    return this.innerSlider.slickGoTo(slide, dontAnimate);
  }

  slickPause() {
    return this.innerSlider.pause('paused');
  }

  slickPlay() {
    return this.innerSlider.autoPlay('play');
  }

  render() {
    const updatedProps = this.getUpdatedProps();
    const { className, children } = this.props;
    const slidesToShow = get(this, 'props.slidesToShow') || defaultProps.slidesToShow || 1;
    const dots = get(this, 'props.dots') || defaultProps.dots || false;
    // const width = (100 / slidesToShow) * Children.count(children);
    let mods = {};
    let style = {};

    if (slidesToShow < 2) {
      style = { width: `${100 * Children.count(children)}%` };
    } else {
      mods = {
        [`slides_${slidesToShow}`]: !!slidesToShow,
        [`count_${Children.count(children)}`]: !!Children.count(children)
      };
    }

    if (updatedProps.settings === 'unslick' || updatedProps.settings.unslick) {
      return (
        <div {...bemClasses(null, null, `regular slider ${className || ''}`)}>
          <div {...bemClasses('groupContainer')}>
            <div {...bemClasses('group', mods)} style={style}>
              {updatedProps.newChildren}
            </div>
          </div>
        </div>
      );
    }

    if (!this.state.clientRendered) {
      return (
        <div {...bemClasses(null, 'initialized', className)}>
          <div {...bemClasses('groupContainer')}>
            <div {...bemClasses('group', mods)} style={style}>
              {Children.map(children, (child, index) => {
                if (!child) return null;

                return (
                  <div {...bemClasses('slide', { active: !index, current: !index })} key={index}>
                    <div {...bemClasses('slideContent')}>
                      {cloneElement(child, {
                        /*
                        ...bemClasses(
                          'slide',
                          { active: !index, current: !index },
                          child.props.className
                        ),
                        */
                        className: child.props.className,
                        key: `orig${child.key || index}`,
                        // 'data-index': index,
                        // tabIndex: '-1',
                        'aria-hidden': !!index,
                        style: { outline: 'none', ...(child.props.style || {}), width: '100%' }
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {dots && <ul className={defaultProps.dotsClass} />}
        </div>
      );
    }

    return (
      <InnerSlider
        sliderRef={ el => this.innerSlider = el }
        {...updatedProps.settings}
      >
        {updatedProps.newChildren}
      </InnerSlider>
    );
  }
}
