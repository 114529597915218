import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Container from 'components/container';
import Row, { Col } from 'components/row';
import Icon, { iconTypes } from 'components/icon';

import imgChoise from '../assets/imgChoiseBlock.jpg';

import translates from './choise-block-i18n.json';

const classesBlockChoise = new Bem('blockChoise');

@I18nHoc(translates)
export default class ChoiseBlock extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  renderItemDescription(iconType, iconSubstrate, text) {
    return (
      <Col {...classesBlockChoise('col')} allColumn allPr={0} allCw={50}>
        <Icon
          {...classesBlockChoise('icon')}
          type={iconType}
          substrate={iconSubstrate}
        />
        <p {...classesBlockChoise('text', 'description')} children={text} />
      </Col>
    );
  }

  render() {
    const { i18n } = this;
    const { className } = this.props;

    return (
      <div {...classesBlockChoise({ extra: className })}>
        <Container body>
          <Row {...classesBlockChoise('row')} mtColumn>
            <Col {...classesBlockChoise('col', 'info')} mdCw={50} mdPl={25} mdPr={50} mtOrder={1}>
              <h2 {...classesBlockChoise('text', 'h2')} children={i18n('h2')} />
              <div {...classesBlockChoise('description', 'top')}>
                <p {...classesBlockChoise('text')} children={i18n('description1')} />
                <p {...classesBlockChoise('text')} children={i18n('description2')} />
                <p {...classesBlockChoise('text')} children={i18n('description3')} />
                <p {...classesBlockChoise('text')} children={i18n('description4')} />
                <p {...classesBlockChoise('text')} children={i18n('description5')} />
              </div>
              <h3 {...classesBlockChoise('text', 'h3')} children={i18n('h3')} />
              <Row allAuto {...classesBlockChoise('ourIndexIncludes')}>
                {this.renderItemDescription(
                  iconTypes.iconMap,
                  `<span class="${classesBlockChoise('img', 'substrate map').className}"></span>`,
                  i18n('descriptionItem1')
                )}
                {this.renderItemDescription(
                  iconTypes.iconWindows,
                  `<span class="${classesBlockChoise('img', 'substrate windows').className}"></span>`,
                  i18n('descriptionItem2')
                )}
                {this.renderItemDescription(
                  iconTypes.iconCar,
                  `<span class="${classesBlockChoise('img', 'substrate car').className}"></span>`,
                  i18n('descriptionItem3')
                )}
                {this.renderItemDescription(
                  iconTypes.iconFlower,
                  `<span class="${classesBlockChoise('img', 'substrate flower').className}"></span>`,
                  i18n('descriptionItem4')
                )}
              </Row>
            </Col>
            <Col {...classesBlockChoise('col', 'slider')} allPr={0} mdCw={50} mp={0}>
              <div {...classesBlockChoise('imgContainer')}>
                <div {...classesBlockChoise('img')}>
                  <div {...classesBlockChoise('imgPic')}>
                    <div {...classesBlockChoise('item')}>
                      <img src={imgChoise} alt={i18n('alt')} loading="lazy" decoding="async" />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
