/* eslint-disable */
import PropTypes from 'prop-types';
import Leaflet from 'leaflet';
import { MapLayer, withLeaflet } from 'react-leaflet';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { I18nHoc } from 'helpers/i18n';
import markerIcon from './assets/marker-icon.png';
import markerIconShadow from './assets/marker-shadow.png';

import translates from './map-i18n.json';

const icon = Leaflet.icon({
  iconUrl: markerIcon,
  iconSize: [40, 44],
  shadowUrl: markerIconShadow,
  shadowSize: [41, 41],
  iconAnchor: [13, 41],
  shadowAnchor: [13, 41]
});

@I18nHoc(translates)
class MapInput extends MapLayer {
  static propTypes = {
    // eslint-disable-next-line
    value: PropTypes.object,
    schema: PropTypes.shape({
      readOnly: PropTypes.bool
    }),
    // Default map zoom for markers
    markerZoom: PropTypes.number
  };

  static defaultProps = {
    markerZoom: 16
  }

  constructor(props, context) {
    super(props, context);
  }

  createLeafletElement(props) {
    this.state = {
      helperCoords: false,
      radius: false
    };
    this._map = props.leaflet.map;
    this.calcGeometry = this.calcGeometry.bind(this);
    this.fireOnChange = this.fireOnChange.bind(this);
    this._finishCircleEdit = this._finishCircleEdit.bind(this);
    this._circleEdit = this._circleEdit.bind(this);
    this.drawing = false;
    this.mounted = true;

    this._map.on('mouseup', this._finishCircleEdit);
    this._map.on('mouseout', this._finishCircleEdit);

    const el = this.updateMarker(props);
    if (el) {
      el.on('add', () => this.setMapBounds(el));
    }
    return el;
  }

  componentDidMount() {
    const {schema={}} = this.props;
    if (!schema.readOnly) this.createControl();

    if (!this.leafletElement) {
      return;
    }
    super.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    //Смена языка на кнопках
    const { i18n } = this;
    const circleLabel = get(this, 'control.options.buttons.circle.label');
    if (circleLabel && circleLabel !== i18n('near')) {
      this.control.options.buttons.circle.label = i18n('near');
      this.control.options.buttons.point.label = i18n('exactly');

      this.control.updateButtons(this.control.options.buttons);
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value) || !isEqual(this.props.schema, nextProps.schema);
  }

  componentWillUnmount() {
    this.mounted=false;
    this._map.off('mouseup', this._finishCircleEdit);
    this._map.off('mouseout', this._finishCircleEdit);
    this._map.off('mousemove', this._circleEdit);
    if (!this.leafletElement) {
      return;
    }
    super.componentWillUnmount();
  }

  performValidation() {
    const value = this.leafletElement.toGeoJSON();
    value.$moved = true;
    if (value.geometry) {
      value.geometry.$moved = true;
    }
    if (typeof this.props.onValidation === 'function') {
      this.props.onValidation(value);
    }
  }

  performDataUpdate(value, item) {
    if (typeof this.props.onDataUpdateNeeded === 'function') {
      this.props.onDataUpdateNeeded(value, item);
    }
  }

  _circleEdit(event) {
    const el = this.leafletElement;
    if (!el || !this.circleEditing || !el._onCircleEditDragStart) {
      return;
    }

    let {lat: circleStartingLat, lng: circleStartingLng} = this.circleLatLng;
    let {lat: mouseStartingLat, lng: mouseStartingLng} = this.eventLatLng;

    let {lat: mouseNewLat, lng: mouseNewLng} = event.latlng;
    let latDifference = mouseStartingLat - mouseNewLat;
    let lngDifference = mouseStartingLng - mouseNewLng;
    let center = [circleStartingLat-latDifference, circleStartingLng-lngDifference];
    el.setLatLng(center);
    el._onCircleEditDrag();
  }

  _finishCircleEdit() {
    this._map.dragging.enable();
    this._map.off('mousemove', this._circleEdit);
    const el = this.leafletElement;
    if (!el) {
      return;
    }

    this.circleEditing = false;
    this.circleLatLng = null;
    this.eventLatLng = null;

    if (el._onCircleEditDragEnd) {
      el._onCircleEditDragEnd();
    }
  }

  _getLatLng(val) {
    if (!val) {
      return null;
    }
    const obj = val.type === 'Feature' ? val.geometry : val;
    const latLng = {
      lat: obj.coordinates[1],
      lng: obj.coordinates[0]
    };
    if (obj.radius) {
      latLng.radius = obj.radius;
    }
    return latLng;
  }

  _toGeoJson(lat, lng, radius) {
    const json = {
      type: 'Point',
      coordinates: [lng, lat]
    };
    if (radius) {
      json.radius = radius;
    }
    return json;
  }

  fireOnChange(value) {
    const newVal = get(value, 'geometry', value);
    if (this.props.value && this.props.value.properties) {
      newVal.properties = this.props.value.properties;
    }
    this.props.onChange(newVal);
  }

  createControl() {
    const { i18n } = this;
    const control = new Leaflet.Control.ButtonSwitchControl({
      onSelect: (val, key) => {
        let { value } = this.props;
        if (!value) {
          value = this._map.getCenter().toGeoJSON();
        }

        const latLng = this._getLatLng(value);

        switch (key) {
          case 'none':
            value = null;
            this.setState({
              radius: false,
              helperCoords: false
            });
            break;
          case 'point':
            value = this._toGeoJson(latLng.lat, latLng.lng );
            this.setState({
              radius: false,
              helperCoords: false
            });
            break;
          case 'circle':
            let radius = 700;
            if (this.state.radius) {
              radius = this.state.radius;
            }
            value = this._toGeoJson(latLng.lat, latLng.lng, radius);
            break;
        }
        this.fireOnChange(value);
      },
      buttons: {
        /* none: {
          id: 'none',
          label: 'Нет'
        }, */
        point: {
          id: 'point',
          label: i18n('exactly')
        },
        circle: {
          id: 'circle',
          label: i18n('near')
        },
      }
    });
    control.addTo(this._map);
    this.control = control;
  }

  updateControl(sel) {
    if (this.control) {
      this.control.setSelected(sel);
    }
  }

  updateMarker(props) {
    if (!this.mounted) return;
    // return;
    if (props.value === null) {
      this.updateControl('none');
      return null;
    }
    const latLng = this._getLatLng(props.value);
    const center = [latLng.lat, latLng.lng];
    if (latLng.radius) {
      const propsRadius = latLng.radius;
      let maxRadius = 1700;
      let minRadius = 100;
      if (props.schema.max) {
        maxRadius = props.schema.max;
      }
      if (this.props.schema.min) {
        minRadius = props.schema.min;
      }

      const circle = new Leaflet.CircleEditor(center, {
        color: "#f00",
        weight: 1,
        fillColor: "#f00",
        fillOpacity: 0.15,
        radius: propsRadius,
        /*draggable: true,*/
        maxRadius: maxRadius,
        minRadius: minRadius,
        helperCoords:
        this.state.helperCoords
      });

      // circle.on('dragstart', circle._onCircleEditDragStart);
      // circle.on('drag', circle._onCircleEditDrag);
      // circle.on('dragend', circle._onCircleEditDragEnd);

      circle.on('edit', () => {
        this.drawing = true;
        this.calcGeometry();
      });
      circle.on('circleDraged', () => {
        this.drawing = true;
        this.calcGeometry();
      });


      circle.on('mousedown', (event) => {
        //L.DomEvent.stop(event);
        this._map.dragging.disable();
        this.circleEditing = true;
        this.circleLatLng = circle._latlng;
        this.eventLatLng = event.latlng;
        this._map.on('mousemove', this._circleEdit);
        circle._onCircleEditDragStart();
      });

      this.updateControl('circle');
      return circle;

    } else if (props.value) {
      let draggable = true;
      const { schema = {} } = this.props;
      if (schema.readOnly) draggable = false;
      const marker = Leaflet.marker(center, { icon, draggable: draggable });
      marker.on('add', this.calcGeometry);
      marker.on('dragend', () => {
        this.drawing = true;
        this.calcGeometry();
        this.performValidation();
      });
      this.updateControl('point');
      return marker;
    }
  }

  setMapBounds(el) {
    if (el && !this.drawing) {
      if (el.getBounds) {
        this._map.fitBounds(el.getBounds());
      } else {
        this._map.setView(el.getLatLng(), this.props.markerZoom);
      }
    }
  }

  updateLeafletElement(prevProps, props) {
    if (this.leafletElement) {
      this.layerContainer.removeLayer(this.leafletElement);
    }
    this.leafletElement = this.updateMarker(props);
    if (this.leafletElement) {
      this.layerContainer.addLayer(this.leafletElement);
      this.setMapBounds(this.leafletElement);
      }
    this.drawing = false;
  }

  calcGeometry() {
    if (this.leafletElement && this.leafletElement._markers && this.leafletElement.getRadius()) {
      this.setState({
        helperCoords: {lat: this.leafletElement._markers[0].getLatLng().lat,
          lng: this.leafletElement._markers[0].getLatLng().lng},
        radius: this.leafletElement.getRadius()
      });
    }
    if (this.drawing) this.fireOnChange(this.leafletElement.toGeoJSON());
  }
}

export default withLeaflet(MapInput);
