/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import scrollToComponent from 'react-scroll-to-component-ssr';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import cacheTerms from 'helpers/cacheTerms';
import preload from 'api/data-preload';
import seoPreload, { seoDecorator } from 'api/seo';
import DataProvider from 'api/data-provider';
import Dict from 'api/data-dict';
import { pageCacheStatic } from 'api/page-cache';
import GeoLandings from 'subsys/geo-landings';
import { marketStatEvents, mstatDecorator } from 'subsys/market-stat';
import contentBlock, { blockPreloader } from 'containers/content-block';
import sensor from 'components/sensor';
import Container from 'components/container';
import { SeoText } from 'components/layouts';
import { OpenGraphImage } from 'components/image';

import SearchBlock from './blocks/search-block';
import ReliabilityBlock from './blocks/reliability-block';
import LinksBlock from './blocks/links-block';
import ChoiseBlock from './blocks/choise-block';
// import CheckDocumentsBlock from './blocks/check-documents-block'; TODO: post-release
import HelpBlock from './blocks/help-block';
import BottomBlockWrapper from '../blocks/bottomBlockWrapper';
import blocksConfig from '../blocks/config';

import 'styles/bild/page-home/_page-home.scss';

const classes = new Bem('pageMain');

@preload({
  ...blockPreloader({
    project: 'bild',
    entity: 'mainPage'
  }, blocksConfig, []),
  pointsLevel: [Dict.preload, { url: 'reliability/points-level' }],
  projectsReliability: {
    $wait: ['pointsLevel'],
    $callback: (data) => {
      const cfg = {};
      const level = get(data, 'pointsLevel.data[2].min');
      cfg.projectsReliability = level ? [DataProvider.preload, {
        url: 'bild/newbuilds-list',
        query: {
          pointsLevel: level,
          order: ['rand', null],
          limit: 10,
          onlyReliability: true,
          notReadyStatus: true
        },
        queryJson: true,
        cache: cacheTerms.longInfoProvider,
      }] : null;
      return cfg;
    }
  },
  livingComplexCondition: [Dict.preload, { url: 'reliability/living-complex' }],
  developerCondition: [Dict.preload, { url: 'reliability/developer' }],
  ...seoPreload({ entity: 'mainPage' }, []),
  ...pageCacheStatic(cacheTerms.longPage, true)
})
@seoDecorator({
  defaultTitle: 'Bild.ua',
  advancedVars: {
    catText: 'Текст возле кота',
    bottomText: 'Текст в самом низу'
  }
})
@mstatDecorator(marketStatEvents.historyChange, () => ({ entity: 'Главная' }))
@sensor
export default class BildHome extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    seoData: PropTypes.shape(),
    mainMenuBase: PropTypes.shape({}),
    projectsReliability: PropTypes.shape({}),
    pointsLevel: PropTypes.shape({}),
    livingComplexCondition: PropTypes.shape({}),
    developerCondition: PropTypes.shape({})
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.onHandleResize = this.onHandleResize.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      device: 'desktop'
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;
    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  scrollTo() {
    scrollToComponent(this.myRef, { align: 'top', duration: 300, ease: 'linear' });
  }

  render() {
    const { device } = this.state;
    const {
      className,
      mainMenuBase,
      seoData,
      projectsReliability,
      livingComplexCondition = {},
      developerCondition = {}
    } = this.props;
    const seoText = get(seoData, 'bottomText', null);
    const dataLC = livingComplexCondition.data || [];
    const dataDeveloper = developerCondition.data || [];
    const countCondition = dataLC.length + dataDeveloper.length;

    return (
      <div {...classes({ extra: className })}>
        <OpenGraphImage />
        <SearchBlock
          seoData={seoData}
          scrollTo={this.scrollTo}
          device={device}
        />
        <LazyHydrate whenIdle>
          <ReliabilityBlock
            ref={ref => this.myRef = ref}
            projectsPreloaded={projectsReliability}
            countCondition={countCondition}
          />
          <GeoLandings base={mainMenuBase} injectPropName="landingLinks" project="bild">
            <GeoLandingsWrapper {...this.props} />
          </GeoLandings>
          <Container {...classes('seoTextContainer')} body>
            <SeoText data={seoText} />
          </Container>
        </LazyHydrate>
      </div>
    );
  }
}

class GeoLandingsWrapper extends PureComponent {
  static propTypes = {
    landingLinks: PropTypes.shape({}),
    geolocation: PropTypes.shape({})
  };

  render() {
    const { landingLinks, geolocation } = this.props;

    return (
      <>
        {contentBlock(blocksConfig, 'stocksSmall', this.props, { geolocation, landingLinks, wrapper: BottomBlockWrapper, withContainer: false, gtmProps: { 'place': 'Main' } })}
        <LinksBlock />
        <ChoiseBlock />
        {/* TODO: post-release
        <CheckDocumentsBlock />
        */}
        {contentBlock(blocksConfig, 'bottom1', this.props, { geolocation, landingLinks, wrapper: BottomBlockWrapper, withContainer: false, gtmProps: { 'place': 'Main' } })}
        {contentBlock(blocksConfig, 'bottom2', this.props, { geolocation, landingLinks, wrapper: BottomBlockWrapper, withContainer: false, gtmProps: { 'place': 'Main' } })}
        {contentBlock(blocksConfig, 'bottom3', this.props, { geolocation, landingLinks, wrapper: BottomBlockWrapper, withContainer: false, gtmProps: { 'place': 'Main' } })}
        {contentBlock(blocksConfig, 'bottom4', this.props, { geolocation, landingLinks, wrapper: BottomBlockWrapper, withContainer: false, gtmProps: { 'place': 'Main' } })}
        {contentBlock(blocksConfig, 'bottom5', this.props, { geolocation, landingLinks, wrapper: BottomBlockWrapper, withContainer: false, gtmProps: { 'place': 'Main' } })}
        <HelpBlock {...classes('helpBlock')} otherProps={{ dataLink: { 'promo': 'false', 'place': 'Main' }}} />
        {contentBlock(blocksConfig, 'bottom6', this.props, { geolocation, landingLinks, wrapper: BottomBlockWrapper, withContainer: false, gtmProps: { 'place': 'Main' } })}
      </>
    );
  }
}
