import { init } from 'components/link/builder';

const linksBackoffice = {
  project: 'backoffice',

  // *** Sections
  // Home
  sectionBackoffice: props => ({
    href: '/',
    title: props.title || props.name
  }),
  // Mortgage
  sectionMortgage: props => ({
    href: '/admin/finance/mortgage/',
    title: props.title || props.name
  }),
  // Installments
  sectionInstallments: props => ({
    href: '/admin/finance/installments/',
    title: props.title || props.name
  }),
  // Add newbuild
  sectionOrderAd: props => ({
    href: '/admin/advert/order-ad/',
    title: props.title || props.name
  }),
  // Add newbuild
  sectionNewbuildAdControl: props => ({
    href: '/admin/advert/newbuild-ad-tariff/',
    title: props.title || props.name
  }),
  // Add region-promotional-links'
  sectionRegionPromotionalLinks: props => ({
    href: '/admin/location/region-promotional-links/',
    title: props.title || props.name
  }),
  // Add region-banners'
  sectionRegionBanners: props => ({
    href: '/admin/location/region-banners/',
    title: props.title || props.name
  }),

  // *** Pages
  // Location structure
  pageLocationStructure: props => ({
    href: '/admin/location/structure.html',
    title: props.title || props.name,
    query: props.query
  }),
  // Photo management
  pagePhotoManagement: props => ({
    href: '/admin/location/photo-manager.html',
    title: props.title || props.name
  }),
  // Gis
  pageGis: props => ({
    href: '/admin/location/gis.html',
    title: props.title || props.name
  }),
  // Geo info
  pageGeoinfo: props => ({
    href: '/admin/location/geoinfo.html',
    title: props.title || props.name
  }),

  // Newbuild Objects
  pageNewbuildObjects: props => ({
    href: '/admin/objects/newbuild-objects.html',
    title: props.title || props.name
  }),

  // Icons
  pageIcons: props => ({
    href: '/admin/tech/existed-icons/',
    title: props.title || props.name
  }),
  // Custom criterias
  pageCustomCriterias: props => ({
    href: '/admin/tech/custom-criterias.html',
    title: props.title || props.name
  }),
  // DataView
  pageDataView: props => ({
    href: '/admin/tech/data-view.html',
    title: props.title || props.name
  }),
  // Dicts
  pageDicts: props => ({
    href: '/admin/tech/dicts.html',
    title: props.title || props.name
  }),
  // Chargeable Services management
  pageChargeable: props => ({
    href: '/admin/tech/chargeable.html',
    title: props.title || props.name
  }),
  // Tech/System utilites
  pageTools: props => ({
    href: '/admin/tech/tools.html',
    title: props.title || props.name
  }),
  // Forum management
  pageForum: props => ({
    href: '/admin/forum/forums/',
    title: props.title || props.name
  }),

  // Roles Editor (System/Company)
  pageSysRolesEditor: props => ({
    href: '/admin/users/sys-roles.html',
    title: props.title || props.name
  }),
  pageCompanyRolesEditor: props => ({
    href: '/admin/users/company-roles.html',
    title: props.title || props.name
  }),

  // Users Editor (System/Company)
  pageAllUsersEditor: props => ({
    href: '/admin/users/all-users.html',
    title: props.title || props.name,
    hash: props.hash || null
  }),
  pageCompanyUsersEditor: props => ({
    href: '/admin/users/company-users.html',
    title: props.title || props.name
  }),

  // *** Panel Admin
  // Announcements
  pagePanelAnnouncements: props => ({
    href: '/admin/forum/panel-admin/announcements',
    title: props.title || props.name
  }),
  // Medals
  pagePanelMedals: props => ({
    href: '/admin/forum/panel-admin/medals',
    title: props.title || props.name
  }),
  // Approvements
  pagePanelApprovements: props => ({
    href: '/admin/forum/panel-admin/approvements',
    title: props.title || props.name
  }),
  // Unread
  pagePanelUnread: props => ({
    href: '/admin/forum/panel-admin/unread',
    title: props.title || props.name
  }),
  // avatar-premoderation
  pageAvatarPremoderation: props => ({
    href: '/admin/forum/panel-admin/avatar-premoderation',
    title: props.title || props.name
  }),
  // Club orders
  pagePanelpagePanelClubOrders: props => ({
    href: '/admin/forum/panel-admin/club-orders',
    title: props.title || props.name
  }),
  // Complaints
  pagePanelComplaints: props => ({
    href: '/admin/forum/panel-admin/complaints',
    title: props.title || props.name
  }),
  // Autofilter
  pagePanelAutofilter: props => ({
    href: '/admin/forum/panel-admin/autofilter',
    title: props.title || props.name
  }),
  // BadWords
  pageBadWords: props => ({
    href: '/admin/forum/panel-admin/badwords',
    title: props.title || props.name
  }),
  // Context blocks.
  pagePanelContext: props => ({
    href: '/admin/forum/panel-admin/context-blocks',
    title: props.title || props.name
  }),
  // Instruments
  pagePanelInstruments: props => ({
    href: '/admin/forum/panel-admin/instruments',
    title: props.title || props.name
  }),
  // Users
  pagePanelUsers: props => ({
    href: '/admin/forum/panel-admin/users',
    title: props.title || props.name
  }),

  // *** CRM
  // Roles company
  crmRolesCompany: props => ({
    href: '/admin/crm/roles-company',
    title: props.title || props.name
  }),
  // Products company
  crmProductsCompany: props => ({
    href: '/admin/crm/products-company',
    title: props.title || props.name
  }),
  // Sales company
  crmSalesCompany: props => ({
    href: '/admin/crm/sales-company',
    title: props.title || props.name
  }),
  // Legal entity
  crmLegalEntity: props => ({
    href: '/admin/crm/legal-entity',
    title: props.title || props.name
  }),
  // Customer type of company
  crmCustomerTypeCompany: props => ({
    href: '/admin/crm/customer-type',
    title: props.title || props.name
  }),
  // Customer type of company
  crmAdPlace: props => ({
    href: '/admin/crm/ad-place',
    title: props.title || props.name
  }),
  // Customers company
  crmCustomersCompany: props => ({
    href: '/admin/crm/customers-company',
    title: props.title || props.name
  }),
  // Company objects
  crmCompanyObjects: props => ({
    href: '/admin/crm/objects',
    title: props.title || props.name
  }),
  // Search objects
  crmSearchObjects: props => ({
    href: '/admin/crm/objects-search',
    title: props.title || props.name
  }),
  // Partners company
  crmPartnersCompany: props => ({
    href: '/admin/crm/partners-company',
    title: props.title || props.name
  }),
  // Partners company
  crmLcAndCottageTowns: props => ({
    href: '/admin/crm/lc-and-cottage-towns',
    title: props.title || props.name
  }),
  // Projects company
  crmProjectsCompany: props => ({
    href: '/admin/crm/projects-company',
    title: props.title || props.name
  }),
  // Tasks company
  crmTasksCompany: props => ({
    href: '/admin/crm/tasks-company',
    title: props.title || props.name
  }),
  // Deal company
  crmDealCompany: props => ({
    href: '/admin/crm/deal-company',
    title: props.title || props.name
  }),

  // *** Publications
  // Publications control
  publicationsControl: props => ({
    href: '/admin/publications/control',
    title: props.title || props.name
  }),

  // *** SEO
  seoLandings: props => ({
    href: '/admin/seo/landings.html',
    title: props.title || props.name
  }),

  translationLists: props => ({
    href: '/admin/seo/translation-lists.html',
    title: props.title || props.name
  }),

  adUnits: props => ({
    href: '/admin/seo/ad-units.html',
    title: props.title || props.name
  }),

  livingComplexAnchor: props => ({
    href: '/admin/seo/living-complex-anchor',
    title: props.title || props.name
  }),

  // *** Reliability
  // Newbild
  reliabilityNewbild: props => ({
    href: '/admin/reliability/newbild.html',
    title: props.title || props.name
  }),
  // Developer
  reliabilityDeveloper: props => ({
    href: '/admin/reliability/developer.html',
    title: props.title || props.name
  }),
  // Orders
  reliabilityOrders: props => ({
    href: '/admin/reliability/orders.html',
    title: props.title || props.name
  }),
  // PartnersBanner
  reliabilityPartnersBanner: props => ({
    href: '/admin/reliability/banner.html',
    title: props.title || props.name
  }),
  // DPI Borg
  reliabilityDPIBorg: props => ({
    href: '/admin/reliability/dpi-borg.html',
    title: props.title || props.name
  }),
  // Config
  reliabilityConfig: props => ({
    href: '/admin/reliability/config.html',
    title: props.title || props.name
  }),
  // our company
  reliabilityOurCompany: props => ({
    href: '/admin/reliability/our-company.html',
    title: props.title || props.name
  }),
  reliabilityLongTermConstruction: props => ({
    href: '/admin/reliability/longTermConstructionOrder.html',
    title: props.title || props.name
  }),
  moderatorCommentLC: props => ({
    href: '/admin/moderator/moderator-comment-lc.html',
    title: props.title || props.name
  }),
  moderatorUserAdvert: props => ({
    href: '/admin/forum/panel-admin/moderator-advert',
    title: props.title || props.name
  }),
  statisticUserAdvert: props => ({
    href: '/admin/forum/panel-admin/statistic-advert',
    title: props.title || props.name
  }),
  statNewbild: props => ({
    href: '/admin/statistic/stat-newbild',
    title: props.title || props.name
  }),
  managementDiscounts: props => ({
    href: '/admin/discounts/management-discounts.html',
    title: props.title || props.name
  }),

  // *** Bild
  bildControl: props => ({
    href: '/admin/bildcontrol/control',
    title: props.title || props.name
  }),

};

export default init(linksBackoffice);
