/**
 * Created by sem on 20.10.16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import uniqueId from 'lodash/uniqueId';
import isBoolean from 'lodash/isBoolean';
import isArray from 'lodash/isArray';
import without from 'lodash/without';
import Icon, { iconTypes } from 'components/icon';

import AbstractInput from './abstractInput';
import ValidationState from './validationState';
import { VALIDATION_VALID } from './form-consts';

import 'styles/base/containers/form/_input-checkbox.scss';

const bemClasses = new Bem('checkbox');

export default class Checkbox extends AbstractInput {
  static propTypes = {
    ...AbstractInput.propTypes,
    children: PropTypes.node,

    // Checkbox name
    name: PropTypes.string,

    // No validation message below Checkbox (renders empty area)
    disableValidationState: PropTypes.bool,

    // No error message below Checkbox (doesn't render empty area)
    noError: PropTypes.bool,

    // No Padding to Checkbox
    noPadding: PropTypes.bool,

    // view Checkbox vertical align center
    verticalACenter: PropTypes.bool,

    // No Margin after icon checkbox and no Label to Checkbox
    noLabel: PropTypes.bool,

    // Use for select values in array
    arrayValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),

    // Disable checkbox
    disabled: PropTypes.bool,

    // for render only button without icon check
    withIcon: PropTypes.bool,

    // for test
    dataTest: PropTypes.string,

    // Color checkbox white
    white: PropTypes.bool,

    schema: PropTypes.shape({
      readOnly: PropTypes.bool,
      label: PropTypes.string
    })
  };

  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.state = {
      // Performance Workaround for "freezed"
      // components with lot of checkboxes
      wChecked: null
    };
    this._idContainer = uniqueId('checkbox_');
  }

  static getDerivedStateFromProps(props, state) {
    if (state.wChecked !== null) {
      return { wChecked: null };
    }

    return null;
  }

  arrayChange(event) {
    const { value, arrayValue } = this.props;
    const chk = event.target.checked;
    const newValue = isArray(value) ? without(value, arrayValue) : [];

    if (chk) {
      newValue.push(arrayValue);
      // Performance workaround
      this.setState(
        { wChecked: true },
        () => this.props.onChange(newValue)
      );
    } else {
      this.setState(
        { wChecked: false },
        () => this.props.onChange(newValue)
      );
    }
  }

  onChange(event) {
    const { schema = {} } = this.props;

    if (schema.readOnly) return;
    if (this.props.arrayValue) return this.arrayChange(event);
    if (isBoolean(event)) return this.props.onChange(event);

    this.props.onChange(event.target.checked);
  }

  render() {
    const {
      value, arrayValue, schema = {}, children, dataTest,
      noError, noPadding, verticalACenter, noLabel, right,
      allCheck, className, disabled, white, withIcon
    } = this.props;
    const validationState =
      this.props.validationState ? this.props.validationState.state : VALIDATION_VALID;

    let checked = !!value;
    if (arrayValue) checked = isArray(value) && value.indexOf(arrayValue) > -1;
    if (this.state.wChecked !== null) checked = this.state.wChecked;

    const mods = {
      right,
      allCheck,
      noPadding,
      verticalACenter,
      noLabel,
      disabled: disabled || schema.readOnly,
      white,
      withIcon,
      checked
    };

    return (
      <div {...bemClasses(null, mods, className)} data-test={dataTest}>
        <label {...bemClasses('label')} htmlFor={`id_${this._idContainer}`} data-test="checkboxLabel">
          <input
            {...bemClasses('input', {
              error: validationState !== VALIDATION_VALID
            })}
            type="checkbox"
            disabled={disabled}
            checked={checked}
            onChange={this.onChange}
            name={this.props.name ? this.props.name : null}
            id={`id_${this._idContainer}`}
            data-test="actionsCheckbox"
          />
          {withIcon &&
            <Icon
              {...bemClasses('icon', { checkbox: true, checked })}
              type={!checked ? iconTypes.checkbox : iconTypes.checkboxChecked}
            />
          }
          {!noLabel &&
            <span {...bemClasses('text', 'checkbox')}>{children || schema.label}</span>
          }
        </label>
        {!noError &&
          <div {...bemClasses('errorWrapper')}>
            {!this.props.disableValidationState &&
              <ValidationState validationState={this.props.validationState} />
            }
          </div>
        }
      </div>
    );
  }
}
