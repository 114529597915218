/**
 * Created by Kotkin on 14.06.2017.
 */
import React, { Component, Children, cloneElement, createElement } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import isObject from 'lodash/isObject';
import Icon, { iconTypes } from 'components/icon';
import Button from 'components/button';

import 'styles/base/components/modal/_modal-header.scss';

import BtnCloseModal from './btnCloseModal';

const classes = new Bem('headerModal');
const classesModal = new Bem('modal');

/**
 * === HeaderModal Component ===
 * Uses for rendering modal header.
 */

export default class HeaderModal extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,

    // Icon before modal header text (left side)
    iconType: PropTypes.string,

    // Says back button should be rendered
    backButton: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({})
    ]),

    // Action for back button
    buttonAction: PropTypes.func,

    // Header has closing button
    closeButton: PropTypes.bool,

    // Header has custom content
    renderer: PropTypes.bool,

    // noLine to Modal Header
    noLine: PropTypes.bool,

    // Color view types (background, text color)
    colorScheme: PropTypes.oneOf(['green', 'gray'])
  };

  setContainerClasses(className) {
    const { colorScheme } = this.props;
    const extraClasses = `modalHeaderContentContainer ${className || ''}`.trim();
    return classes('contentContainer', { [colorScheme]: colorScheme }, extraClasses);
  }

  renderHeaderContent() {
    const {
      children, iconType, closeButton, backButton, buttonAction, renderer, noLine
    } = this.props;
    let child = Children.toArray(children)[0];
    const isString = typeof child === 'string';
    const backBtn = isObject(backButton) ? backButton :
      (
        <Button
          {...classesModal('btn', 'back')}
          onClick={buttonAction}
          iconType={iconTypes.arrowLeft}
          noPadding
        />
      );

    if (renderer) {
      if (!isObject(child)) {
        return createElement('div', {
          ...this.setContainerClasses(),
          children: child
        });
      }
      return cloneElement(child, {
        ...this.setContainerClasses(child.props.className)
      });
    } else if (!isString && !isObject(child)) {
      child = createElement('span', { children: child });
    }

    return (
      <div {...this.setContainerClasses()} data-test="headerModal">
        <div {...classes('content')}>
          <div {...classes('left', { filled: !!backButton })} children={!!backButton && backBtn} />
          <div {...classes('center')}>
            <div {...classes('topic')}>
              <div {...classes('topicLeft')}>
                {iconType && <Icon {...classes('icon')} type={iconType} />}
                <span {...classes('text', 'ellipsis')} children={child} />
              </div>
            </div>
          </div>
          <div {...classes('right')}>
            {closeButton && <BtnCloseModal />}
          </div>
          {!noLine &&
            <div {...classes('contentLine')} />
          }
        </div>
      </div>
    );
  }

  render() {
    return (
      <div {...classes({ extra: this.props.className })}>
        {this.renderHeaderContent()}
      </div>
    );
  }
}
