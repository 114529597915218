import React from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-recaptcha';
import Bem from 'bemHelper';

import AbstractInput from './abstractInput';
import projectConfig from '../../../config';

import 'styles/base/containers/form/_input-recaptcha-button.scss';

const bemClasses = new Bem('gRecaptcha');

export default class RecaptchaButton extends AbstractInput {
  static propTypes = {
    captcha: PropTypes.string
  };

  static defaultProps = {
    disableValidationState: false
  };

  componentDidMount() {
    if (!document.getElementById('recaptcha')) {
      const script = document.createElement('script', { defer: true });
      script.setAttribute('id', 'recaptcha');
      script.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
      script.async = true;
      document.head.appendChild(script);
    }
  }

  verifyCallback = (response) => {
    this.props.onChange(response);
  }

  onLoadCallback = resp => null // eslint-disable-line

  render() {
    return (
      <div {...bemClasses({ extra: this.props.className })}>
        <Recaptcha
          ref={el => this.captcha = el}
          sitekey={projectConfig.apiKeys.googleRecaptcha}
          render="explicit"
          hl="ru"
          verifyCallback={this.verifyCallback}
          onloadCallback={this.onLoadCallback}
        />
      </div>
    );
  }
}
