import { emit } from 'helpers/global-events';

const simpleStore = {
  set: function(key, value) {this[key] = value; },

  get: function(key, shouldEmitted = false) {
    if (!this[key] && shouldEmitted) {
      emit(key);
      return simpleStore.get(key);
    }
    return this[key] || null;
  },

  remove: function(key) {
    delete this[key];
  }
};

export default simpleStore;
