/* eslint-disable import/newline-after-import */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import each from 'lodash/each';
import ResizeDetector from 'react-resize-detector';
import { geolocationConnect } from 'geolocation';

import { Leaflet, Map, ZoomControl, withLeaflet } from './react-leaflet-ssr';
import { propTypeGeoJSON } from './prop-types';

import 'styles/base/containers/maps/_maps.scss';

/**
 * Map container wrapper
 */
class MapsWrapper extends Map {
  constructor(props, ctx) {
    super(props, ctx);
    this._unregisterLayer = this._unregisterLayer.bind(this);
    this._registerLayer = this._registerLayer.bind(this);
    this._orderLayers = this._orderLayers.bind(this);
    this._fireUserTriggeredMove = this._fireUserTriggeredMove.bind(this);
  }

  componentWillUnmount() {
    if (this.leafletElement) {
      this.leafletElement.stop();
    }
    super.componentWillUnmount();
  }

  /**
   * Register in GIS layers repository
   * @param layer
   * @private
   */
  _registerLayer(layer) {
    const el = this.leafletElement;
    if (!el._gisLayers) {
      el._gisLayers = [];
      el._gisLayerAutoOrder=1;
    }
    if (!layer._gisOrder) {
      layer._gisOrder = el._gisLayerAutoOrder;
    }
    el._gisLayerAutoOrder += 1;
    el._gisLayers.push(layer);
  }

  /**
   * Unregister layer from GIS layers repository
   * @param layer
   * @private
   */
  _unregisterLayer(layer) {
    const el = this.leafletElement;
    el._gisLayers = filter(el._gisLayers, (fl) => fl !== layer);
  }

  /**
   * Order GIS layers
   * @private
   */
  _orderLayers() {
    const el = this.leafletElement;
    const ord = sortBy(el._gisLayers, ['_gisOrder']);
    each(ord, (layer) => {
      if(el.hasLayer(layer)) {
        layer.bringToFront();
      }
      if (layer._updateSnapGuides) {
        layer._updateSnapGuides();
      }
    });
  }

  _fireUserTriggeredMove(evt) {
    if (this.userTriggeredEvent) {
      if (this.props.onUserChangeBounds) {
        this.props.onUserChangeBounds(evt);
      }
    }
    this.userTriggeredEvent = false;
  }

  /**
   * Create main map container
   * @param props
   * @return {null}
   */
  createLeafletElement(props) {
    if (__SERVER__) {
      return null;
    }

    this.userTriggeredEvent = false;

    const el = super.createLeafletElement(props);
    el._unregisterGisLayer = this._unregisterLayer;
    el._registerGisLayer = this._registerLayer;
    el._orderGisLayers = this._orderLayers;
    el.on('viewreset', evt => evt.target.invalidateSize());

    Leaflet.DomEvent.on(el._container, 'mousewheel', () => this.userTriggeredEvent = true, this);
    Leaflet.DomEvent.on(el._container, 'touchstart', () => this.userTriggeredEvent = true, this);
    Leaflet.DomEvent.on(el._container, 'mousemove', evt => {
      if (evt.buttons > 0) {
        this.userTriggeredEvent = true, this
      }});

    el.on('zoomend', this._fireUserTriggeredMove);
    el.on('moveend', this._fireUserTriggeredMove);

    return el;
  }
}

@geolocationConnect()
class Maps extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    bbox: propTypeGeoJSON,
    noAutoGeolocate: PropTypes.bool,
    /**
     *
     * when > 0 - mouse scroll zoom turns on after {num} milliseconds
     * after mouseover, and turns off on mouseout
     */
    // TODO: implement, now it's not work because I can't modify scrollWheelZoom option
    adaptiveScrollZoom: PropTypes.number
  };

  static defaultProps = {
    center: [48.92249, 31.50878],
    zoom: 5,
    adaptiveScrollZoom: 0,
    noAutoGeolocate: false
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.state = {
      // ssr workaround
      clientInitialized: false
    };

    this.scrollZoom = this.props.adaptiveScrollZoom === 0;
    this.manualChanged = false;
  }

  /*
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }
  */

  componentDidMount() {
    this.setState({clientInitialized: true}, () => {
      let autoLocate = true;
      if (this.props.bbox) {
        this.setBbox(this.props.bbox);
        autoLocate = false;
        }
      if (this.props.bounds || this.props.noAutoGeolocate) {
        autoLocate = false;
      }
      if (autoLocate) {
        this.autoGeolocate(this.props);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.bbox &&
      !this.props.bounds &&
      !this.props.noAutoGeolocate &&
      !isEqual(this.props.geolocation, prevProps.geolocation)
    ) {
      this.autoGeolocate(this.props);
    }
    if (!isEqual(this.props.bbox, prevProps.bbox)) {
      this.setBbox(this.props.bbox);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.resizeTimeout);
  }

  _resizeDetect = () => {
    if (!this.leafletElement) return;
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(
      () => this.leafletElement.invalidateSize(),
      300
    );
  }

  setBbox(box) {
    if (!box || this.leafletElement._boundsAlreadySet) {
      return;
    }
    const bbox = Leaflet.geoJSON(box);
    this.leafletElement.fitBounds(bbox.getBounds());
  }

  autoGeolocate(nextProps) {
    let loc = nextProps.geolocation;
    if (
      !loc ||
      loc.status !== 'located' ||
      this.leafletElement._boundsAlreadySet
    ) {
      return;
    }

    // *** If location link and bbox exists
    if (loc.location) {
      loc = loc.location;

      if (!loc.geo_bbox) {
        return;
      }

      this.setBbox(loc.geo_bbox);

      return;
    }

    // *** If only point
    if (loc.point) {
      loc = loc.point;

      if (!loc.coordinates) {
        return;
      }

      const c = loc.coordinates;
      this.leafletElement.setView(Leaflet.latLng([c[1], c[0]]), 11);

      return;
    }

  }

  switchScrollZoom(val, msec) {
    if (this.props.adaptiveScrollZoom === 0 ||
      this.scrollZoom === val) {
      return;
    }
    this.scrollZoom = val;
    clearTimeout(this.aszTimeout);
    this.aszTimeout = setTimeout(
      () => {
        if (!map) return;
        const map = this.leafletElement;
        val ?
          map.scrollWheelZoom.enable() :
          map.scrollWheelZoom.disable();
      }, msec);
  }

  handleMouseOver() {
    this.switchScrollZoom(true, this.props.adaptiveScrollZoom);
  }

  handleMouseOut() {
    this.switchScrollZoom(false, 0);
  }

  renderMap() {
    if (!this.state.clientInitialized) {
      return (<div>Loading map...</div>);
    }

    const { width, height, children, className, ...rest } = this.props;

    return (
      <MapsWrapper
        className={className ? `mapsContent ${className}` : 'mapsContent'}
        renderer={Leaflet.svg()}
        zoomControl={false}
        ref={el => this.leafletElement = el && el.leafletElement}
        style={{ width, height }}
        scrollWheelZoom={this.scrollZoom}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        crs={Leaflet.CRS.EPSG3857}
        {...rest}
      >
        <ZoomControl key="zoomcontrol" position="topright" />
        {children}
      </MapsWrapper>
    );

  }

  render() {
    const { width, height, className } = this.props;

    return (
      <div
        className={className ? `mapsContainer ${className}` : 'mapsContainer'}
        data-test="map"
        style={{ width, height }}
      >
        <ResizeDetector
          handleWidth
          onResize={this._resizeDetect}
          skipOnMount
        />
        {this.renderMap()}
      </div>
    );
  }
}
/*
 <ZoomControl position="topleft" />

 */
export default Maps;

/*

 <TileLayer
 // attribution='&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
 url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
 />
 {children}
 <Control position="bottomleft" >

 </Control>

 <LayersControl position="bottomleft">
 <LayersControl.BaseLayer checked name="Mapnik">
 <TileLayer
 url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
 // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
 />
 </LayersControl.BaseLayer>
 <LayersControl.BaseLayer name="BlackAndWhite">
 <TileLayer
 // attribution='&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
 url="http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
 />
 </LayersControl.BaseLayer>
 </LayersControl>
 */
