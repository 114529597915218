import React, { PureComponent } from 'react';
import CSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import isEqual from 'lodash/isEqual';
import toNumber from 'lodash/toNumber';
import sensor from 'components/sensor';

const bemClasses = new Bem('viewListExtended');
const cssTransitionConfig = {
  enter: 'enter',
  leave: 'leave',
  classes: {
    up: 'Up',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    fadeIn: 'FadeIn',
    fadeOut: 'FadeOut'
  }
};

@sensor
export default class CardExtendedView extends PureComponent {
  static propTypes = {
    className: PropTypes.string,

    // Steps config
    viewConfig: PropTypes.shape({
      main: PropTypes.string
    }),

    // Method for rendering content
    renderContent: PropTypes.func,

    // Redefine first step (used on target page)
    stepsConfig: PropTypes.shape({
      view: PropTypes.string,
      extraOption: PropTypes.string
    }),

    // Callback for closing steps in modal window (used on target page)
    closeModal: PropTypes.func
  };

  static setTransitionClass(action, direction) {
    const start = `${action}${direction}`;
    const end = `${start}Active`;

    return {
      [action]: bemClasses('state', start).className,
      [`${action}Active`]: bemClasses('state', end).className
    };
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.nextStep = this.nextStep.bind(this);

    this.state = {
      clientSide: false,
      currentView: props.viewConfig.main,
      transitionEnter: { enter: null, enterActive: null },
      transitionLeave: { leave: null, leaveActive: null }
    };

    this.extraOption = null;
    this.nodeHeight = null;
  }

  UNSAFE_componentWillMount() {
    if (this.props.stepsConfig) {
      const { enter, leave, classes } = cssTransitionConfig;
      const { view, extraOption } = this.props.stepsConfig;
      this.extraOption = extraOption;
      const state = { ...this.state };
      state.currentView = this.props.viewConfig[view];
      state.transitionEnter = CardExtendedView.setTransitionClass(enter, classes.left);
      state.transitionLeave = CardExtendedView.setTransitionClass(leave, classes.left);
      this.setState(state);
    }
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({ clientSide: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps, this.props)) {
      this.extraOption = null;
      const { enter, leave, classes } = cssTransitionConfig;
      const state = { ...this.state };
      state.currentView = this.props.viewConfig.main;
      state.transitionEnter = CardExtendedView.setTransitionClass(enter, classes.fadeIn);
      state.transitionLeave = CardExtendedView.setTransitionClass(leave, classes.fadeOut);
      if (!isEqual(state, this.state)) this.setState(state);
    }
  }

  componentDidUpdate() {
    if (this.state.clientSide && !this.nodeHeight && this.nodeRef) {
      this.nodeHeight = this.nodeRef.offsetHeight;
    }
  }

  nextStep(currentView, extraOption, toMain = false) {
    if (this.props.closeModal &&
      currentView === this.props.viewConfig.main) return this.props.closeModal();

    const { enter, leave, classes } = cssTransitionConfig;
    let state = { ...this.state, currentView };
    if (extraOption) this.extraOption = extraOption; else this.extraOption = null;
    if (toMain) {
      state = {
        ...state,
        transitionEnter: CardExtendedView.setTransitionClass(enter, classes.right),
        transitionLeave: CardExtendedView.setTransitionClass(leave, classes.right)
      };
    } else {
      state = {
        ...state,
        showOnMap: false,
        transitionEnter: CardExtendedView.setTransitionClass(enter, classes.left),
        transitionLeave: CardExtendedView.setTransitionClass(leave, classes.left)
      };
    }
    if (!isEqual(state, this.state)) this.setState(state);
  }

  render() {
    if (!this.state.clientSide) return null;

    const transitionDuration = toNumber(this.sensor.getVariable('timeDuration'));
    const cssDurations = transitionDuration * 2;
    const { className, renderContent, ...restProps } = this.props;
    const {
      currentView, transitionEnter, transitionLeave
    } = this.state;
    const { enter, enterActive } = transitionEnter;
    const { leave, leaveActive } = transitionLeave;

    return (
      <CSSTransitionGroup
        component="div"
        {...bemClasses({
          // modifiers: { action, myObject },
          extra: className
        })}
        transitionName={{
          enter,
          enterActive,
          leave,
          leaveActive
        }}
        transitionEnterTimeout={cssDurations}
        transitionLeaveTimeout={cssDurations}
      >
        <div
          {...bemClasses('state')}
          key={currentView}
          ref={el => this.nodeRef = el}
          style={{ minHeight: this.nodeHeight }}
        >
          {renderContent(
            {
              currentView,
              changeStep: this.nextStep,
              extraOption: this.extraOption,
              restProps
            },
            bemClasses
          )}
        </div>
      </CSSTransitionGroup>
    );
  }
}
