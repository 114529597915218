
/**
 * Retrieve cache/TTL information from response, and set expires headers for
 * web server cache level. Needs for cache server-prerendered pages
 */
export default function (wait) {
  const cfg = {
    $wait: [wait],
    $callback: data => ({
      ttl: [() => Promise.resolve({ value: data[wait].$ttl })]
    })
  };
  return { $cache: cfg };
}

/**
 * Helper for cache pages in static time
 * @param ttl - time (in seconds)
 * @param withQueryString - cache pages ignoring query string.
 *        Needs for force cache in fully static pages
 * @return {{$cache: *[]}}
 */
export function pageCacheStatic(ttl, withQueryString = false) {
  return {
    $cache: [() => Promise.resolve({
      ttl: { value: ttl * 60 },
      withQueryString
    })]
  };
}
