/**
 * Created by Kotkin on 02.08.2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataProvider from 'api/data-provider';
import simpleStore from 'helpers/simpleStore';
import isEqual from 'lodash/isEqual';
import Notification from 'components/notification';

import PaginatorContainer from './paginator-container';

import 'styles/base/components/paginator/_paginator.scss';

/**
 *
 * === Paginator Component ===
 *
 * = Usage Example =
 * <Paginator
 *   url="some/url"
 *   limit={20}
 *   query={query}
 * >
 *   <Control />
 *   <Container injectPropName="propName">
 *     <RenderComponent />
 *   </Container>
 *   <Pages />
 * </Paginator>
 *
 * <Paginator
 *   url="some/url"
 *   limit={20}
 *   query={query}
 *   queryJson
 *   changeUrl
 *   page={params.page}
 *   preloaded={preloaded data}
 *   withTopPages
 * >
 *   <Control />
 *   <Container injectPropName="propName">
 *     <RenderComponent />
 *   </Container>
 *   <Pages
 *     linkBuilder={builder}
 *     linkBuilderProps={{
 *       paramsTitle: params.title,
 *       paramsId: params.id
 *     }}
 *     withPageChange
 *     noArrows
 *     elementsRight={some content}
 *   />
 * </Paginator>
 *
 */

export default class Paginator extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,

    // Preloaded data for DataProvider
    preloaded: PropTypes.any, // eslint-disable-line

    // Not run queries from data providers
    preloadedStatic: PropTypes.bool,

    // Url string for DataProvider
    url: PropTypes.string,

    // Query object for DataProvider
    query: PropTypes.object, //eslint-disable-line

    // Transform query object to JSON (DataProvider)
    queryJson: PropTypes.bool,

    // Max items per page
    limit: PropTypes.number,

    // Смещение скрола при переключении страниц
    scrollOffset: PropTypes.number,

    // Update DataProvider event
    updateEvent: PropTypes.string,

    // Change page in DataProvider query from changing page prop
    changeUrl: PropTypes.bool,

    // Page which is currently active (from router params)
    page: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),

    // Render Pages component also on top of Container
    withTopPages: PropTypes.bool,

    // Doesn't render pages block
    noPages: PropTypes.bool,

    // Add padding-bottom and background-color
    addPdBgBsh: PropTypes.bool,

    // Calls when error happens
    onError: PropTypes.func,

    // Using cache
    lruCache: PropTypes.bool,

    // render block before pages
    blockBeforePages: PropTypes.shape({}),

    // If set - save and restore scroll position. Key will be unique
    scrollRestoreKey: PropTypes.string
  };

  static defaultProps = {
    page: 1,
    scrollOffset: 0,
    lruCache: true,
    preloadedStatic: false
  };

  constructor(...props) {
    super(...props);
    this.changePage = this.changePage.bind(this);
    this.reloadProvider = this.reloadProvider.bind(this);

    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    if (props.changeUrl) {
      const currentPage = props.page >= 1 ? props.page - 1 : 0;
      return {
        currentPage,
        prevPage: currentPage
      };
    }

    if (state.prevPage !== state.currentPage) return {
      prevPage: state.currentPage
    };

    return {
      currentPage: 0,
      prevPage: 0
    };
  }

  changePage(page, length) {
    const { currentPage: statePage } = this.state;
    const setNewState = (currentPage) => {
      if (currentPage !== null && currentPage !== this.state.currentPage) {
        return this.setState({ currentPage });
      }
    };

    // Change to next page
    if (page === null) {
      const nextPage = statePage + 1 <= length - 1 ? statePage + 1 : null;
      return setNewState(nextPage);
    }

    // Change to previous page
    if (page === -1) {
      const prevPage = statePage - 1 >= 0 ? statePage - 1 : null;
      return setNewState(prevPage);
    }

    // Change to specific page
    return setNewState(page);
  }

  reloadProvider() {
    this.provider.reload();
  }

  render() {
    const {
      url, limit, query = {}, queryJson,
      preloaded, preloadedStatic, lruCache,
      onError, blockBeforePages, ...restProps
    } = this.props;
    const { currentPage } = this.state;

    let queryUpdated = { ...query, count: true };
    if (limit) queryUpdated = { ...queryUpdated, limit };
    if (currentPage) queryUpdated = { ...queryUpdated, offset: currentPage * limit };

    return (
      <DataProvider
        ref={el => this.provider = el}
        updateEvent={this.props.updateEvent}
        url={url}
        preloaded={preloaded}
        preloadedStatic={preloadedStatic}
        query={queryUpdated}
        queryJson={!!queryJson}
        injectPropName="paginator"
        lruCache={preloadedStatic && lruCache}
        onLoad={() => {
          // проверка на существование метода, который должен быть вызван после загрузки
          const afterLoadAction = simpleStore.get('afterLoadAction');
          if (afterLoadAction) {
            afterLoadAction();
            simpleStore.remove('afterLoadAction');
          }
        }}
        onError={Notification.error}
      >
        <PaginatorContainer
          {...restProps}
          limit={limit}
          reloadProvider={this.reloadProvider}
          changePage={this.changePage}
          currentPage={currentPage}
          blockBeforePages={blockBeforePages}
        />
      </DataProvider>
    );
  }
}
