import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Data visualizer, that calls custom function for data visualize
 */
class VisFunc extends Component {
  static propTypes = {
    values: PropTypes.shape({}),
    schemas: PropTypes.shape({}),
    props: PropTypes.shape({}),
    func: PropTypes.func
  };

  render() {
    const { func, values, schemas, props } = this.props;
    return func(values, schemas, props);
  }
}
export default VisFunc;
