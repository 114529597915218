/**
 * Created by Kotkin on 08.06.2017.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { subscribe, unsubscribe } from 'helpers/global-events';

import getUnnamedImg from './get-unnamed-img'

import 'styles/base/components/image/_thumb.scss';

const classes = new Bem('thumb');

/**
 * === Thumb Component ===
 * Renders thumbnails.
 * = Example =
 * <Thumb
 *   image={image object}
 * />
 *
 */
class Thumb extends PureComponent {
  static propTypes = {
    className: PropTypes.string,

    // Event name for rerendering component
    updateEvent: PropTypes.string,

    // Image object. Id is required!
    image: PropTypes.shape({
      id: PropTypes.string,
      alt: PropTypes.string,
      title: PropTypes.string
    }).isRequired,

    // Render thumb src=/images/thumb/full/...
    full: PropTypes.bool,

    // Main tag is 'span' instead div
    tagSpan: PropTypes.bool,

    // вместо findDOMNode
    thumbRef: PropTypes.func,

    // клик по тамбу
    onClick: PropTypes.func,

    // Additional methods for picture slider
    onTouchStart: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,

    // seo props for img
    // seoPropsImg: PropTypes.shape({})
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.reload = this.reload.bind(this);
    this.mounted = false;
    this.state = {
      seed: null
    };

    if (props.updateEvent) subscribe(props.updateEvent, this.reload);
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.props.updateEvent) unsubscribe(this.props.updateEvent, this.close);
  }

  getImage(img = {} /* , seoPropsImg = {} */) {
    const image = img || {};
    const { id, alt, title } = image;
    const { full } = this.props;
    const { seed } = this.state;

    const srcMicro = id ? `/images/thumb/micro/${id}.jpg` : getUnnamedImg();
    const srcFull = id ? `/images/thumb/full/${id}.jpg` : getUnnamedImg();
    const sSeed = (seed && id) ? `?seed=${seed}` : '';
    const srcImg = full ? srcFull : srcMicro;

    return (
      <img // eslint-disable-line
        {...classes('imgThumb', null)}
        srcSet={`${srcMicro} 400w 1x, ${srcFull} 800w 2x`}
        sizes="(max-width: 767px) 700px, (max-width: 1250px) 400px, 250px"
        src={srcImg + sSeed}
        // data-src={srcImg + sSeed}
        loading="lazy"
        decoding="async"
        alt={alt || ''}
        title={title && title}
        onClick={this.props.onClick || null}
        ref={el => this.imgTag = el}
        // {...seoPropsImg}
      />
    );
  }

  reload() {
    if (this.mounted) this.setState(() => ({ seed: Math.random() }));
  }

  render() {
    const {
      image = {}, thumbRef,
      onTouchStart, onTouchEnd, onMouseDown, onMouseUp,
      tagSpan, className // , seoPropsImg = {}
    } = this.props;

    const HtmlElement = tagSpan ? 'span' : 'div';
    let propsFromSlider = {};
    if (onTouchStart) {
      propsFromSlider = {
        onTouchStart,
        onTouchEnd,
        onMouseDown,
        onMouseUp
      };
    }

    return (
      <HtmlElement
        {...propsFromSlider}
        {...classes({
          extra: className
        })}
        ref={thumbRef}
        children={this.getImage(image /* , seoPropsImg */)}
      />
    );
  }
}

export default Thumb;
