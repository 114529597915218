/* eslint-disable import/prefer-default-export */
import Leaflet from 'leaflet';

import markerIcon from './assets/marker-icon.png';
import markerIconRed from './assets/marker-icon-red.png';
import markerIconGreen from './assets/marker-icon-green.png';
import markerIconYellow from './assets/marker-icon-yellow.png';
import markerIconShadow from './assets/marker-shadow.png';

const markerIcons = {
  standart: markerIcon,
  yellow: markerIconYellow,
  green: markerIconGreen,
  red: markerIconRed
};

export function marker(icon = 'standart') {
  return Leaflet.icon({
    iconUrl: markerIcons[icon],
    iconSize: [25, 41],
    shadowUrl: markerIconShadow,
    shadowSize: [41, 41],
    iconAnchor: [13, 41],
    shadowAnchor: [13, 41]
  });
}
