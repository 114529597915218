import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import values from 'lodash/values';
import Bem from 'bemHelper';

import initialState from './initial-state';
import {
  getOnDemandLazySlides,
  extractObject,
  initializedState,
  getHeight,
  canGoNext,
  slideHandler,
  changeSlide,
  keyHandler,
  swipeStart,
  swipeMove,
  swipeEnd,
  getPreClones,
  getPostClones,
  getTrackLeft,
  getTrackCSS
} from './utils/innerSliderUtils';

import Track from './track';
import Dots from './dots';
import { PrevArrow, NextArrow } from './arrows';

const bemClasses = new Bem('slickSlider');

export default class InnerSlider extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    initialSlide: PropTypes.number,
    speed: PropTypes.number,
    slidesToShow: PropTypes.number,
    onInit: PropTypes.func,
    serverRendered: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    onLazyLoad: PropTypes.func,
    onReInit: PropTypes.func,
    autoplay: PropTypes.bool,
    infinite: PropTypes.bool,
    pauseOnFocus: PropTypes.bool,
    adaptiveHeight: PropTypes.bool,
    variableWidth: PropTypes.bool,
    centerMode: PropTypes.bool,
    useCSS: PropTypes.bool,
    onLazyLoadError: PropTypes.func,
    asNavFor: PropTypes.shape({}),
    currentSlide: PropTypes.number,
    beforeChange: PropTypes.func,
    afterChange: PropTypes.func,
    accessibility: PropTypes.bool,
    rtl: PropTypes.bool,
    verticalSwiping: PropTypes.bool,
    swipe: PropTypes.bool,
    draggable: PropTypes.bool,
    vertical: PropTypes.bool,
    pauseOnHover: PropTypes.bool,
    focusOnSelect: PropTypes.bool,
    dots: PropTypes.bool,
    unslick: PropTypes.bool,
    pauseOnDotsHover: PropTypes.bool,
    arrows: PropTypes.bool,
    arrowsFill: PropTypes.bool,
    touchMove: PropTypes.bool,
    allowDotsClick: PropTypes.bool,
    slidesToScroll: PropTypes.number,
    autoplaySpeed: PropTypes.number,
    centerPadding: PropTypes.string,
    sliderRef: PropTypes.func,
    sliderCount: PropTypes.shape({})
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.list = null;
    this.track = null;
    this.lazyLoadTimer = null;
    this.animationEndCallback = null;
    this.autoplayTimer = null;
    this.callbackTimers = [];
    this.clickable = true;
    this.debouncedResize = null;

    this.state = {
      ...initialState,
      currentSlide: props.initialSlide,
      slideCount: Children.count(props.children)
    };

    this.onWindowResized = this.onWindowResized.bind(this);
    this.onDotsOver = this.onDotsOver.bind(this);
    this.onDotsLeave = this.onDotsLeave.bind(this);
    this.onTrackOver = this.onTrackOver.bind(this);
    this.onTrackLeave = this.onTrackLeave.bind(this);
    this.onSlideFocus = this.onSlideFocus.bind(this);
    this.onSlideBlur = this.onSlideBlur.bind(this);
    this.progressiveLazyLoad = this.progressiveLazyLoad.bind(this);
    this.slideHandler = this.slideHandler.bind(this);
    this.changeSlide = this.changeSlide.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.swipeStart = this.swipeStart.bind(this);
    this.swipeMove = this.swipeMove.bind(this);
    this.swipeEnd = this.swipeEnd.bind(this);
    this.slickPrev = this.slickPrev.bind(this);
    this.slickNext = this.slickNext.bind(this);
    this.slickGoTo = this.slickGoTo.bind(this);
    this.play = this.play.bind(this);
    this.autoPlay = this.autoPlay.bind(this);
    this.pause = this.pause.bind(this);
    this.keyHandler = this.keyHandler.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.ssrInit();

    const { onInit, lazyLoad, onLazyLoad } = this.props;
    if (onInit) onInit();
    if (lazyLoad) {
      const slidesToLoad = getOnDemandLazySlides({
        ...this.props,
        ...this.state
      });

      if (slidesToLoad.length > 0) {
        this.setState(prevState => ({
          lazyLoadedList: prevState.lazyLoadedList.concat(slidesToLoad)
        }));

        if (onLazyLoad) onLazyLoad(slidesToLoad);
      }
    }
  }

  componentDidMount() {
    const { autoplay, lazyLoad, speed } = this.props;
    const spec = { listRef: this.list, trackRef: this.track, ...this.props };

    this.updateState(spec, true, () => {
      this.adaptHeight();
      if (autoplay) this.autoPlay('update');
    });
    if (lazyLoad === 'progressive') {
      this.lazyLoadTimer = setInterval(this.progressiveLazyLoad, 1000);
    }
    this.ro = new ResizeObserver(() => {
      if (this.state.animating) {
        this.onWindowResized(false); // don't set trackStyle hence don't break animation
        this.callbackTimers.push(
          setTimeout(() => this.onWindowResized(), speed)
        );
      } else {
        this.onWindowResized();
      }
    });
    this.ro.observe(this.list);

    // To support server-side rendering
    if (!__CLIENT__) return;

    // TODO: resize
    if (window.addEventListener) {
      window.addEventListener('resize', this.onWindowResized);
    } else {
      window.attachEvent('onresize', this.onWindowResized);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const spec = {
      listRef: this.list,
      trackRef: this.track,
      ...nextProps,
      ...this.state
    };
    let setTrackStyle = false;

    const propValues = values(this.props);
    const propsLength = propValues.length;
    for (let i = 0; i < propsLength; i += 1) {
      const key = propValues[i];

      if (!Object.prototype.hasOwnProperty.call(nextProps, key)) {
        setTrackStyle = true;
        break;
      }

      if (
        (typeof nextProps[key] === 'object' ||
          typeof nextProps[key] === 'function') &&
        nextProps[key] !== this.props[key]
      ) {
        setTrackStyle = true;
        break;
      }
    }

    // for (let key of Object.keys(this.props)) {
    //   if (!Object.prototype.hasOwnProperty.call(nextProps, key)) {
    //     setTrackStyle = true;
    //     break;
    //   }
    //   if (
    //     typeof nextProps[key] === 'object' ||
    //     typeof nextProps[key] === 'function'
    //   ) {
    //     continue;
    //   }
    //   if (nextProps[key] !== this.props[key]) {
    //     setTrackStyle = true;
    //     break;
    //   }
    // }

    this.updateState(spec, setTrackStyle, () => {
      if (this.state.currentSlide >= Children.count(nextProps.children)) {
        this.changeSlide({
          message: 'index',
          index:
          Children.count(nextProps.children) - nextProps.slidesToShow,
          currentSlide: this.state.currentSlide
        });
      }

      if (nextProps.autoplay) {
        this.autoPlay('update');
      } else {
        this.pause('paused');
      }
    });
  }

  componentDidUpdate() {
    const { onReInit, onLazyLoad } = this.props;

    this.checkImagesLoad();

    if (onReInit) onReInit();

    if (this.props.lazyLoad) {
      const slidesToLoad = getOnDemandLazySlides({
        ...this.props,
        ...this.state
      });

      if (slidesToLoad.length > 0) {
        // eslint-disable-next-line
        this.setState(prevState => ({
          lazyLoadedList: prevState.lazyLoadedList.concat(slidesToLoad)
        }));
        if (onLazyLoad) onLazyLoad(slidesToLoad);
      }
    }

    this.adaptHeight();
  }

  componentWillUnmount() {
    if (this.animationEndCallback) {
      clearTimeout(this.animationEndCallback);
    }

    if (this.lazyLoadTimer) {
      clearInterval(this.lazyLoadTimer);
    }

    if (this.callbackTimers.length) {
      each(this.callbackTimers, timer => clearTimeout(timer));
      this.callbackTimers = [];
    }

    // TODO: resize
    if (window.addEventListener) {
      window.removeEventListener('resize', this.onWindowResized);
    } else {
      window.detachEvent('onresize', this.onWindowResized);
    }

    if (this.autoplayTimer) {
      clearInterval(this.autoplayTimer);
    }
  }

  onWindowResized(setTrackStyle) {
    if (this.debouncedResize) this.debouncedResize.cancel();
    this.debouncedResize = debounce(() => this.resizeWindow(setTrackStyle), 50);
    this.debouncedResize();
  }

  onDotsOver() {
    return this.props.autoplay && this.pause('hovered');
  }

  onDotsLeave() {
    return this.props.autoplay && this.state.autoplaying === 'hovered' && this.autoPlay('leave');
  }

  onTrackOver() {
    return this.props.autoplay && this.pause('hovered');
  }

  onTrackLeave() {
    return this.props.autoplay && this.state.autoplaying === 'hovered' && this.autoPlay('leave');
  }

  onSlideFocus() {
    return this.props.autoplay && this.pause('focused');
  }

  onSlideBlur() {
    return this.props.autoplay && this.state.autoplaying === 'focused' && this.autoPlay('blur');
  }

  getArrowProps() {
    const spec = { ...this.props, ...this.state };
    const arrowProps = extractObject(spec, [
      'infinite',
      'centerMode',
      'currentSlide',
      'slideCount',
      'slidesToShow',
      'prevArrow',
      'nextArrow'
    ]);
    arrowProps.clickHandler = this.changeSlide;

    return arrowProps;
  }

  getTrackProps() {
    const spec = { ...this.props, ...this.state };
    const { pauseOnHover, focusOnSelect } = this.props;
    const trackProps = extractObject(spec, [
      'fade',
      'cssEase',
      'speed',
      'infinite',
      'centerMode',
      'focusOnSelect',
      'currentSlide',
      'lazyLoad',
      'lazyLoadedList',
      'rtl',
      'slideWidth',
      'slideHeight',
      'listHeight',
      'vertical',
      'slidesToShow',
      'slidesToScroll',
      'slideCount',
      'trackStyle',
      'variableWidth',
      'unslick',
      'centerPadding'
    ]);

    return {
      ...trackProps,
      onMouseEnter: pauseOnHover ? this.onTrackOver : null,
      onMouseLeave: pauseOnHover ? this.onTrackLeave : null,
      onMouseOver: pauseOnHover ? this.onTrackOver : null,
      focusOnSelect: focusOnSelect ? this.selectHandler : null
    };
  }

  getListProps() {
    if (this.props.unslick) return { ...bemClasses('groupContainer') };

    const { vertical, centerMode, centerPadding, touchMove, accessibility } = this.props;
    const { dragging, listHeight } = this.state;

    let verticalHeightStyle = null;
    if (vertical) {
      verticalHeightStyle = {
        height: listHeight
      };
    }
    let centerPaddingStyle = null;
    if (vertical === false) {
      if (centerMode === true) {
        centerPaddingStyle = {
          padding: `0px ${centerPadding}`
        };
      }
    } else if (centerMode === true) {
      centerPaddingStyle = {
        padding: `${centerPadding} 0px`
      };
    }
    const listStyle = { ...verticalHeightStyle, ...centerPaddingStyle };

    return {
      ...bemClasses('groupContainer'),
      style: listStyle,
      onClick: this.clickHandler,
      onMouseDown: touchMove ? this.swipeStart : null,
      onMouseMove: dragging && touchMove ? this.swipeMove : null,
      onMouseUp: touchMove ? this.swipeEnd : null,
      onMouseLeave: dragging && touchMove ? this.swipeEnd : null,
      onTouchStart: touchMove ? this.swipeStart : null,
      onTouchMove: dragging && touchMove ? this.swipeMove : null,
      onTouchEnd: touchMove ? this.swipeEnd : null,
      onTouchCancel: dragging && touchMove ? this.swipeEnd : null,
      onKeyDown: accessibility ? this.keyHandler : null
    };
  }

  adaptHeight() {
    if (this.props.adaptiveHeight && this.list) {
      const elem = this.list.querySelector(
        `[data-index='${this.state.currentSlide}']`
      );
      this.list.style.height = `${getHeight(elem)}px`;
    }
  }

  resizeWindow(setTrackStyle = true) {
    if (!this.track) return;

    const spec = {
      listRef: this.list,
      trackRef: this.track,
      ...this.props,
      ...this.state
    };

    this.updateState(spec, setTrackStyle, () => {
      if (this.props.autoplay) this.autoPlay('update');
      else this.pause('paused');
    });

    // animating state should be cleared while resizing, otherwise autoplay stops working
    this.setState({
      animating: false
    });

    clearTimeout(this.animationEndCallback);
    delete this.animationEndCallback;
  }

  updateState(spec, setTrackStyle, callback) {
    const updatedState = initializedState(spec);
    // eslint-disable-next-line
    spec = { ...spec, ...updatedState, slideIndex: updatedState.currentSlide };
    const targetLeft = getTrackLeft(spec);
    // eslint-disable-next-line
    spec = { ...spec, left: targetLeft };

    if (
      setTrackStyle ||
      Children.count(this.props.children) !== Children.count(spec.children)
    ) {
      updatedState.trackStyle = getTrackCSS(spec);
    }
    this.setState(updatedState, callback);
  }

  ssrInit() {
    const { children, centerMode, slidesToShow } = this.props;
    const { currentSlide } = this.state;
    if (this.props.variableWidth) {
      let trackWidth = 0;
      let trackLeft = 0;
      const childrenWidths = [];
      const preClones = getPreClones({
        ...this.props,
        ...this.state,
        slideCount: children.length
      });
      const postClones = getPostClones({
        ...this.props,
        ...this.state,
        slideCount: children.length
      });

      each(children, (child) => {
        childrenWidths.push(child.props.style.width);
        trackWidth += child.props.style.width;
      });

      for (let i = 0; i < preClones; i += 1) {
        trackLeft += childrenWidths[childrenWidths.length - 1 - i];
        trackWidth += childrenWidths[childrenWidths.length - 1 - i];
      }
      for (let i = 0; i < postClones; i += 1) {
        trackWidth += childrenWidths[i];
      }
      for (let i = 0; i < currentSlide; i += 1) {
        trackLeft += childrenWidths[i];
      }

      const trackStyle = {
        width: `${trackWidth}px`,
        left: `-${trackLeft}px`
      };
      if (centerMode) {
        const currentWidth = `${childrenWidths[currentSlide]}px`;
        trackStyle.left = `calc(${
          trackStyle.left
        } + (100% - ${currentWidth}) / 2 ) `;
      }

      return this.setState({
        trackStyle
      });
    }

    const childrenCount = Children.count(children);
    const spec = { ...this.props, ...this.state, slideCount: childrenCount };
    const slideCount = getPreClones(spec) + getPostClones(spec) + childrenCount;
    const trackWidth = (100 / slidesToShow) * slideCount;
    const slideWidth = 100 / slideCount;

    let trackLeft = (
      (-slideWidth) *
      (getPreClones(spec) + currentSlide) *
      trackWidth
    ) / 100;
    if (centerMode) trackLeft += (100 - ((slideWidth * trackWidth) / 100)) / 2;

    this.setState({
      slideWidth: `${slideWidth}%`,
      trackStyle: {
        width: `${trackWidth}%`,
        left: `${trackLeft}%`
      }
    });
  }

  // TODO: change the way images are defined
  checkImagesLoad() {
    const images = this.track.querySelectorAll('.slick-slide img');
    const imagesCount = images.length;
    let loadedCount = 0;

    each(images, (image) => {
      const handler = () =>
        loadedCount += 1 && loadedCount >= imagesCount && this.onWindowResized();

      if (!image.onclick) {
        // eslint-disable-next-line
        image.onclick = () => image.parentNode.focus();
      } else {
        const prevClickHandler = image.onclick;
        // eslint-disable-next-line
        image.onclick = () => {
          prevClickHandler();
          image.parentNode.focus();
        };
      }

      if (!image.onload) {
        if (this.props.lazyLoad) {
          // eslint-disable-next-line
          image.onload = () => {
            this.adaptHeight();
            this.callbackTimers.push(
              setTimeout(this.onWindowResized, this.props.speed)
            );
          };
        } else {
          // eslint-disable-next-line
          image.onload = handler;
          // eslint-disable-next-line
          image.onerror = () => {
            handler();
            if (this.props.onLazyLoadError) this.props.onLazyLoadError();
          };
        }
      }
    });
  }

  progressiveLazyLoad() {
    const slidesToLoad = [];
    const spec = { ...this.props, ...this.state };
    const { currentSlide } = this.state;

    const length1 = this.state.slideCount + getPostClones(spec);
    for (let index = currentSlide; index < length1; index += 1) {
      if (this.state.lazyLoadedList.indexOf(index) < 0) {
        slidesToLoad.push(index);
        break;
      }
    }

    const length2 = -getPreClones(spec);
    for (let index = currentSlide - 1; index >= length2; index -= 1) {
      if (this.state.lazyLoadedList.indexOf(index) < 0) {
        slidesToLoad.push(index);
        break;
      }
    }

    if (slidesToLoad.length > 0) {
      this.setState(state => ({
        lazyLoadedList: state.lazyLoadedList.concat(slidesToLoad)
      }));

      if (this.props.onLazyLoad) {
        this.props.onLazyLoad(slidesToLoad);
      }
    } else if (this.lazyLoadTimer) {
      clearInterval(this.lazyLoadTimer);
      delete this.lazyLoadTimer;
    }
  }

  slideHandler(index, dontAnimate = false) {
    const {
      asNavFor,
      currentSlide,
      beforeChange,
      onLazyLoad,
      speed,
      afterChange,
      useCSS
    } = this.props;

    const { state, nextState } = slideHandler({
      index,
      ...this.props,
      ...this.state,
      trackRef: this.track,
      useCSS: useCSS && !dontAnimate
    });
    if (!state) return;

    if (beforeChange) beforeChange(currentSlide, state.currentSlide);

    const slidesToLoad = state.lazyLoadedList.filter(
      value => this.state.lazyLoadedList.indexOf(value) < 0
    );
    if (onLazyLoad && slidesToLoad.length > 0) onLazyLoad(slidesToLoad);
    this.setState(state, () => {
      if (asNavFor && asNavFor.innerSlider.state.currentSlide !== currentSlide) {
        asNavFor.innerSlider.slideHandler(index);
      }
      if (!nextState) return;

      this.animationEndCallback = setTimeout(() => {
        const { animating, ...firstBatch } = nextState;
        this.setState(firstBatch, () => {
          this.callbackTimers.push(
            setTimeout(() => this.setState({ animating }), 10)
          );
          if (afterChange) afterChange(state.currentSlide);
          delete this.animationEndCallback;
        });
      }, speed);
    });
  }

  changeSlide(options, dontAnimate = false) {
    const spec = { ...this.props, ...this.state };
    const targetSlide = changeSlide(spec, options);

    if (targetSlide !== 0 && !targetSlide) return;

    if (dontAnimate === true) {
      this.slideHandler(targetSlide, dontAnimate);
    } else {
      this.slideHandler(targetSlide);
    }
  }

  clickHandler(e) {
    if (this.clickable === false) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.clickable = true;
  }

  keyHandler(e) {
    const dir = keyHandler(e, this.props.accessibility, this.props.rtl);
    if (dir !== '') this.changeSlide({ message: dir });
  }

  selectHandler(options) {
    this.changeSlide(options);
  }

  // TODO: check this method
  disableBodyScroll() {
    window.ontouchmove = (e) => {
      // eslint-disable-next-line
      e = e || window.event;
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    };
  }

  // TODO: check this method
  enableBodyScroll() {
    window.ontouchmove = null;
  }

  swipeStart(e) {
    if (this.props.verticalSwiping) {
      this.disableBodyScroll();
    }
    const state = swipeStart(e, this.props.swipe, this.props.draggable);
    if (state !== '') this.setState(state);
  }

  swipeMove(e) {
    const state = swipeMove(e, {
      ...this.props,
      ...this.state,
      trackRef: this.track,
      listRef: this.list,
      slideIndex: this.state.currentSlide
    });
    if (!state) return;

    if (state.swiping) {
      this.clickable = false;
    }
    this.setState(state);
  }

  swipeEnd(e) {
    this.clickable = true;
    const state = swipeEnd(e, {
      ...this.props,
      ...this.state,
      trackRef: this.track,
      listRef: this.list,
      slideIndex: this.state.currentSlide
    });
    if (!state) return;

    const triggerSlideHandler = state.triggerSlideHandler;
    delete state.triggerSlideHandler;

    this.setState(state);
    if (triggerSlideHandler === undefined) return;

    this.slideHandler(triggerSlideHandler);
    if (this.props.verticalSwiping) {
      this.enableBodyScroll();
    }
  }

  slickPrev() {
    // this and fellow methods are wrapped in setTimeout
    // to make sure initialize setState has happened before
    // any of such methods are called
    this.callbackTimers.push(
      setTimeout(() => this.changeSlide({ message: 'previous' }), 0)
    );
  }

  slickNext() {
    this.callbackTimers.push(
      setTimeout(() => this.changeSlide({ message: 'next' }), 0)
    );
  }

  slickGoTo(slide, dontAnimate = false) {
    // eslint-disable-next-line
    slide = Number(slide);
    if (isNaN(slide)) return '';

    this.callbackTimers.push(
      setTimeout(() =>
        this.changeSlide(
          {
            message: 'index',
            index: slide,
            currentSlide: this.state.currentSlide
          },
          dontAnimate
        ),
      0)
    );
  }

  play() {
    let nextIndex;
    if (this.props.rtl) {
      nextIndex = this.state.currentSlide - this.props.slidesToScroll;
    } else if (canGoNext({ ...this.props, ...this.state })) {
      nextIndex = this.state.currentSlide + this.props.slidesToScroll;
    } else {
      return false;
    }

    this.slideHandler(nextIndex);
  }

  autoPlay(playType) {
    if (this.autoplayTimer) {
      clearInterval(this.autoplayTimer);
    }

    const autoplaying = this.state.autoplaying;
    if (playType === 'update') {
      if (
        autoplaying === 'hovered' ||
        autoplaying === 'focused' ||
        autoplaying === 'paused'
      ) {
        return;
      }
    } else if (playType === 'leave') {
      if (autoplaying === 'paused' || autoplaying === 'focused') {
        return;
      }
    } else if (playType === 'blur') {
      if (autoplaying === 'paused' || autoplaying === 'hovered') {
        return;
      }
    }
    this.autoplayTimer = setInterval(this.play, this.props.autoplaySpeed + 50);
    this.setState({ autoplaying: 'playing' });
  }

  pause(pauseType) {
    if (this.autoplayTimer) {
      clearInterval(this.autoplayTimer);
      this.autoplayTimer = null;
    }

    const autoplaying = this.state.autoplaying;

    if (pauseType === 'paused') {
      this.setState({ autoplaying: 'paused' });
    } else if (pauseType === 'focused') {
      if (autoplaying === 'hovered' || autoplaying === 'playing') {
        this.setState({ autoplaying: 'focused' });
      }
    // pauseType is 'hovered'
    } else if (autoplaying === 'playing') {
      this.setState({ autoplaying: 'hovered' });
    }
  }

  renderArrow() {
    const props = this.getArrowProps();

    return (
      <React.Fragment>
        <PrevArrow {...props} />
        <NextArrow {...props} />
      </React.Fragment>
    );
  }

  renderDots() {
    if (
      this.props.dots === true &&
      this.state.slideCount >= this.props.slidesToShow
    ) {
      const spec = { ...this.props, ...this.state };
      const { pauseOnDotsHover } = this.props;
      const dotProps = extractObject(spec, [
        'dotsClass',
        'slideCount',
        'slidesToShow',
        'currentSlide',
        'slidesToScroll',
        'clickHandler',
        'children',
        'customPaging',
        'customPagingNoClick',
        'allowDotsClick',
        'infinite'
      ]);

      return (
        <Dots
          {...dotProps}
          clickHandler={this.changeSlide}
          onMouseEnter={pauseOnDotsHover ? this.onDotsLeave : null}
          onMouseOver={pauseOnDotsHover ? this.onDotsOver : null}
          onMouseLeave={pauseOnDotsHover ? this.onDotsLeave : null}
        />
      );
    }

    return null;
  }

  render() {
    const {
      vertical, unslick, arrows, arrowsFill, sliderRef, pauseOnFocus, sliderCount, children = []
    } = this.props;
    const { currentSlide, slideCount } = this.state;

    const { className } = bemClasses(
      null,
      {
        vertical,
        initialized: true,
        arrowsFill
      },
      this.props.className
    );

    const innerSliderProps = {
      className,
      ref: (el) => {
        if (sliderRef) sliderRef(el);
      }
    };
    if (!unslick) {
      innerSliderProps.dir = 'ltr';
    }

    return (
      <div {...innerSliderProps}>
        <div ref={el => this.list = el} {...this.getListProps()}>
          <Track
            trackRef={el => this.track = el}
            {...this.getTrackProps()}
            slideProps={{
              onFocus: pauseOnFocus ? this.onSlideFocus : null,
              onBlur: pauseOnFocus ? this.onSlideBlur : null,
            }}
          >
            {this.props.children}
          </Track>
        </div>
        {!unslick &&
          <>
            {arrows && <div {...bemClasses('arrows', { fill: arrowsFill })} children={this.renderArrow()} />}
            {this.renderDots()}
          </>
        }
        {!!sliderCount &&
          <div {...bemClasses('sliderCount')}>
            <span
              {...bemClasses('text', 'count')}
              children={`${currentSlide + 1}/${slideCount}`}
            />
            {sliderCount}
          </div>
        }
      </div>
    );
  }
}
