import { InfoLayerExtendable } from './info-layer';
import BboxWrapper from './bbox-wrapper';
import { withLeaflet } from 'react-leaflet';

class InfoLayerExtended extends InfoLayerExtendable {
  static defaultProps = {
    ...InfoLayerExtendable.defaultProps,
    noAutoFit: true,
    deferRequestData: 0
  };

  createLeafletElement(props) {
    const el = super.createLeafletElement(props);
    const { deferRequestData } = props;

    el.on('add', () => {
      this._map.on('zoomend', this.handleBboxChange);
      this._map.on('moveend', this.handleBboxChange);
      if (deferRequestData) {
        setTimeout(() => {
          if (this._mounted) {
            this.requestData(this._map.getBounds().toGeoJSON());
          }
        }, deferRequestData);
      } else {
        this.requestData(this._map.getBounds().toGeoJSON());
      }
    });

    el.on('remove', () => {
      this._map.off('zoomend', this.handleBboxChange);
      this._map.off('moveend', this.handleBboxChange);
    });

    return el;
  }

  handleBboxChange = (evt) => {
    const bbox = evt.target.getBounds().toGeoJSON();
    this.requestData(bbox);
  };

  requestData = (bbox) => {
    if (this._map.getZoom() <= this.props.minZoom) {
      return null;
    }
    this._fire('onUpdate', [bbox]);
  }
}

export default class InfoLayerBboxWrapper extends BboxWrapper {
  constructor(...prm) {
    super(...prm);
    this.component = withLeaflet(InfoLayerExtended);
  }
}
