/**
 * Created by Kotkin on 05.05.17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';

import AbstractInput from './abstractInput';

const bemClasses = new Bem('errorContainer');

class ValidationState extends AbstractInput {
  static propTypes = {
    className: PropTypes.string,
    validationState: PropTypes.shape({
      state: PropTypes.string,
      messages: PropTypes.array
    })
  };

  render() {
    const msgList =
      this.props.validationState ?
        (this.props.validationState.messages || []) : [];

    return (
      <div {...bemClasses({ extra: this.props.className })}>
        {!!msgList.length &&
          <span {...bemClasses('text')} data-test="errorContainer">{msgList[0].text}</span>
        }
      </div>
    );
  }
}

export default ValidationState;
