import lastIndexOf from 'lodash/lastIndexOf';
import { init } from 'components/link/builder';

// Identifier for pages in pagination
export const PAGE_ID = 'page-';

const linksBild = {
  project: 'bild',

  // *** Sections
  // Home
  sectionBild: props => ({
    href: `/${props.langPrefix.replace('/', '')}`,
    title: props.title || props.name
  }),
  // Sitemap
  sectionSitemap: props => ({
    href: '/sitemap.xml',
    title: props.title || props.name
  }),
  // Novostroyki
  sectionProjects: props => ({
    href: '/projects/',
    title: props.title || props.name
  }),
  // Stocks TODO ???
  sectionStocks: props => ({
    href: `/${props.langPrefix}akcii`,
    title: props.title || props.name
  }),
  // o-kompanii
  sectionAbout: props => ({
    href: `/${props.langPrefix}o-kompanii`,
    title: props.title || props.name
  }),
  // terms
  sectionTerms: props => ({
    href: `/${props.langPrefix}soglashenie`,
    title: props.title || props.name
  }),
  // registrationCondition
  sectionRegistrationCondition: props => ({
    href: `/${props.langPrefix}confidence`,
    title: props.title || props.name
  }),

  // Landing page
  sectionLanding: props => ({
    href: addPageSlash(props.url, props.page),
    query: props.query,
    title: props.title || props.name
  }),

  // Favorites
  pageFavorites: props => ({
    href: `/${props.langPrefix}auth/favorites/${props.project}`,
    title: props.name || props.title
  }),

  // Order
  /*
  pageOrder: props => ({
    href: `/order-bild-control.html/${props.id}`,
    title: props.name || props.title
  }),
  */
};

function addPageSlash(url = '', page) {
  if (page) {
    return `${url}${lastIndexOf(url, '/') === url.length - 1 ? '' : '/'}${PAGE_ID}${page}`;
  }
  return url;
}

export default init(linksBild);
