/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import numDesc from 'helpers/numDesc';
import cacheTerms from 'helpers/cacheTerms';
import { I18nHoc } from 'helpers/i18n';
import DataProvider from 'api/data-provider';
import Dict from 'api/data-dict';
import { InputLocality, ComboboxInput } from 'containers/form';
import Link from 'components/link';
import Row, { Col } from 'components/row';
import Icon, { iconTypes } from 'components/icon';
import Container from 'components/container';
import Button from 'components/button';
import YoutubeIframe from 'components/youtube-iframe';
import ServerRendering from 'components/server-rendering';

import translates from './search-block-i18n.json';

const classesBlockSearch = new Bem('blockSearch');

@I18nHoc(translates)
export default class SearchBlock extends Component {
  static propTypes = {
    className: PropTypes.string,
    seoData: PropTypes.shape({}),
    scrollTo: PropTypes.func,
    device: PropTypes.oneOf(['desktop', 'tablet', 'phone'])
  };

  constructor(props) {
    super(props);
    this.state = {
      locality: null,
      group: 'flats'
    };

    this.renderBtnScrollTo = this.renderBtnScrollTo.bind(this);
    this.renderFormContent = this.renderFormContent.bind(this);
  }

  handleChangeLocality = (id) => {
    this.setState({ locality: id });
  };

  handleChangeGroup = (id) => {
    this.setState({ group: id });
  };

  renderBtnScrollTo() {
    return (
      <div {...classesBlockSearch('btnContainer', 'toBlockNext')}>
        <span {...classesBlockSearch('img', 'substrate btn')} />
        <Button
          {...classesBlockSearch('btn')}
          onClick={this.props.scrollTo}
          iconType={iconTypes.dropdown}
          iconOnly
        />
      </div>
    );
  }

  renderImgSubstrate(forInfo, isPhone) {
    if (forInfo && !isPhone) {
      return (
        <span {...classesBlockSearch('img', 'substrate blockLeft')} />
      );
    }

    return (
      <>
        <span {...classesBlockSearch('img', 'substrate blockRightPBottom')} />
        <span {...classesBlockSearch('img', 'substrate blockRightPTop')} />
      </>
    );
  }

  renderShowButton = (payload) => {
    const { i18n } = this;
    const { data } = payload;
    const locInfo = data[0] ? data[0] : {};

    return (
      <>
        {locInfo.objects_count > 0 &&
          <Link
            {...classesBlockSearch('link')}
            title={i18n('searchBtnText')}
            href={locInfo.objects_landing}
          >
            <span {...classesBlockSearch('linkContent')}>
              <span {...classesBlockSearch('linkShadow')} />
              <span {...classesBlockSearch('text', 'link')} children={i18n('searchBtnText')} />
            </span>
          </Link>
        }
        <div {...classesBlockSearch('dataCount')}>
          <span
            {...classesBlockSearch('text', 'dataCount')}
            children={i18n(
              'searchCount',
              {
                placeholderRU: `${numDesc(+locInfo.objects_count || 0, 'новостройка', 'новостройки', 'новостроек')}`,
                placeholderUA: `${numDesc(+locInfo.objects_count || 0, 'новобудова', 'новобудови', 'новобудов')}`
              }
            )}
          />
        </div>
      </>
    );
  };

  getClientRender = () => {
    const { group, locality } = this.state;

    return (
      <>
        <Dict
          url="location/living-complex/newbuild-object-search-groups"
          injectPropName="listBoxItems"
        >
          <ComboboxInput
            {...classesBlockSearch('item', 'combobox')}
            value={group}
            onChange={this.handleChangeGroup}
            iconSecondType={iconTypes.carretBild}
            nullValueItem={false}
            modalContentNoPadding
            noLabel
            wAuto
            left
            noError
          />
        </Dict>
        <InputLocality
          {...classesBlockSearch('item', 'locality')}
          project="bild"
          countColumnType="newbuilds"
          estateType={group}
          value={locality}
          onChange={this.handleChangeLocality}
          designMode="modal"
          modalBtnProps={{
            iconType: '',
            iconOnly: false,
            iconSecondType: iconTypes.carretBild
          }}
          confirmLocality
          noError
        />
        {group &&
          <DataProvider
            url="domik/location/administrative"
            query={{
              id: locality || 1,
              columnObjectCount: {
                project: 'bild',
                type: 'newbuilds',
                class: group
              }
            }}
            queryJson
            cache={cacheTerms.loc_administrative}
            lruCache
            renderer={this.renderShowButton}
          />
        }
      </>
    );
  };

  renderFormContent() {
    const clientRender = this.getClientRender();
    /*
    const serverRenderer = (
      <>
        <div className="input blockSearch__item blockSearch__item_combobox">
          <div className="input__inputWrapper">
            <div className="input__inputContainer">
              <div className="dropdown dropdown_inForm dropdown_left dropdown_wAuto input__dropDown input__dropDown_valueSelected">
                <button className="btn btn_contentLeft dropdown__btn input__btn input__btn_dropDown" type="button">
                  <span className="btn__content dropdown__btnContent">
                    <span className="btn__text dropdown__text">
                      <span children="Квартиры в новостройках" />
                    </span>
                    <span className="icon icon_carretBild btn__icon btn__icon_second dropdown__icon dropdown__icon_second"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="inputLocality blockSearch__item blockSearch__item_locality">
          <button className="btn btnModal inputLocality__btn inputLocality__btn_locality" type="button">
            <span className="btn__content btnModal__btnContent">
              <span className="btn__text btnModal__text" children="Украина" />
              <span className="icon icon_carretBild btn__icon btn__icon_second btnModal__icon btnModal__icon_second"></span>
            </span>
          </button>
        </div>
        <a className="link blockSearch__link" href="/novostroyki-ukrainy" title="Показать">
          <span className="blockSearch__linkContent">
            <span className="blockSearch__linkShadow" />
            <span className="blockSearch__text blockSearch__text_link" children="Показать" />
          </span>
        </a>
        <div className="blockSearch__dataCount">
          <span className="blockSearch__text blockSearch__text_dataCount" children="В каталоге 2813 новостроек" />
        </div>
      </>
    );
    */

    return (
      <div {...classesBlockSearch('form', 'pageMain')}>
        <ServerRendering
          serverRenderer={clientRender}
          clientRenderer={clientRender}
        />
      </div>
    );
  }

  render() {
    const { i18n } = this;
    const { className, seoData, device } = this.props;
    const topic = seoData && seoData.header ? seoData.header : i18n('h1');
    // const iframeSrc = 'https://www.youtube.com/embed/5PIcgnewTNY?&autoplay=1&loop=1&rel=0&playlist=KJdwax-oXvY,jBV-JU8h9sc,y6_gA85BxK4';
    const iframeSrcId = '5PIcgnewTNY';
    const isPhone = device === 'phone';
    const noDesktop = device !== 'desktop';

    return (
      <div {...classesBlockSearch({ extra: className })}>
        <Container body>
          <Row {...classesBlockSearch('row')} alignIcenter mtColumn mpColumn>
            <Col {...classesBlockSearch('col', 'form')} allPr={0} mdCw={50} mdPl={26}>
              {this.renderImgSubstrate()}
              <div {...classesBlockSearch('imgContainer')}>
                <div {...classesBlockSearch('img', 'imgCity')} />
              </div>
              <div {...classesBlockSearch('formContainer')}>
                <div {...classesBlockSearch('text', 'h2')} children={i18n('h2')} />
                {this.renderFormContent()}
              </div>
            </Col>
            <Col {...classesBlockSearch('col', 'info')} mdCw={50} mdPl={42} mdPr={42}>
              {this.renderImgSubstrate(true, isPhone)}
              <h1
                {...classesBlockSearch('text', 'h1')}
                children={topic}
              />
              <Row {...classesBlockSearch('description')} mpColumn>
                <Col {...classesBlockSearch('col', 'cat')} allAuto allPr={0} mtPl={16}>
                  <Icon {...classesBlockSearch('icon', 'cat')} type={iconTypes.catOk} />
                </Col>
                <Col {...classesBlockSearch('col', 'description')} allFlex allAlignSEnd allPr={0}>
                  <p
                    {...classesBlockSearch('text', 'description')}
                    children={seoData.catText ? seoData.catText : i18n('description')}
                  />
                </Col>
              </Row>
            </Col>
            <Col {...classesBlockSearch('col', 'video')} mdCw={50} mdPl={42} mdPr={42}>
              <div
                {...classesBlockSearch('description', 'link')}
                children={
                  <YoutubeIframe
                    className={classesBlockSearch}
                    // classNameIframe={classesBlockSearch('lolo').className}
                    videoId={iframeSrcId}
                    otherProps={{
                      toContent: noDesktop
                    }}
                  />
                }
              />
            </Col>
          </Row>
          {this.renderBtnScrollTo()}
        </Container>
      </div>
    );
  }
}
