import React, { Component } from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import marketStatPush, { marketStatEvents } from 'subsys/market-stat';
import { ModalButton, Content } from 'components/modal';
import { iconTypes } from 'components/icon';
import Button from 'components/button';

import translates from './youtube-iframe-i18n.json';

import 'styles/base/components/youtube-iframe/_modal-video.scss';

const classesYoutube = new Bem('iframeYoutube');

@I18nHoc(translates)
export default class YoutubeIframe extends Component {
  static propTypes = {
    className: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({}),
      PropTypes.string
    ]).isRequired,
    // classNameIframe: PropTypes.string,
    videoId: PropTypes.string.isRequired,
    otherProps: PropTypes.shape({
      toContent: PropTypes.bool,
      toContentWithoutBtn: PropTypes.bool,
      btnToVideoProps: PropTypes.shape({})
    })
  };

  constructor(props) {
    super(props);

    this.handleChangeIframeVideo = this.handleChangeIframeVideo.bind(this);
    this.closeModalIframeVideo = this.closeModalIframeVideo.bind(this);
    this.renderIframeVideo = this.renderIframeVideo.bind(this);
    this._onStateChange = this._onStateChange.bind(this);
    this._onEnd = this._onEnd.bind(this);

    this.state = {
      showIframeVideo: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.showIframeVideo !== this.state.showIframeVideo;
  }

  handleChangeIframeVideo() {
    this.setState(
      prevState => ({ showIframeVideo: !prevState.showIframeVideo })
    );
    if (!this.state.showIframeVideo && this.modal) this.modal.closeModal();
  }

  closeModalIframeVideo() {
    this.setState({ showIframeVideo: false });
  }

  _onStateChange(event) {
    const videoId = get(this, 'props.videoId');
    marketStatPush(marketStatEvents.successError, 'video', { ea: event.data, el: `https://www.youtube.com/embed/${videoId}` });
  }

  _onEnd(event) {
    if (event.target.getPlayerState() === 0) {
      this.handleChangeIframeVideo();
    }
  }

  renderIframeVideo(classes, videoId, otherProps) {
    let newVideoId = videoId.replace('/', '');
    if (newVideoId !== videoId) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = typeof get(videoId, 'match') === 'function' ? videoId.match(regExp) : null;
      newVideoId = (match && match[7].length === 11) ? match[7] : false;
    }
    const { i18n } = this;
    const { showIframeVideo } = this.state;
    const propsBtnVideo = {
      label: i18n('text'),
      title: i18n('text'),
      iconType: iconTypes.videoBild,
      contentLeft: true,
      ...otherProps.btnToVideoProps
    };
    const opts = {
      width: '1280',
      height: '720',
      playerVars: {
        autoplay: 0,
        enablejsapi: 1,
        // origin: absoluteUrl()
      }
    };
    const iframeVideo = (
      <YouTube
        videoId={newVideoId}
        host="https://www.youtube.com"
        opts={opts}
        // className={classesYoutube({ extra: this.props.classNameIframe }).className}
        containerClassName={classesYoutube('container', null, classes('iframe', 'video').className).className}
        onStateChange={this._onStateChange}
        onEnd={this._onEnd}
      />
    );

    /*
    window.YTConfig = {
      host: 'https://www.youtube.com'
    };
    */

    if (otherProps.toContent) {
      return (
        <>
          <Button
            {...classes('btn', 'toVideo')}
            onClick={this.handleChangeIframeVideo}
            {...propsBtnVideo}
          />
          {showIframeVideo && iframeVideo}
        </>
      );
    }

    if (otherProps.toContentWithoutBtn) {
      return iframeVideo;
    }

    return (
      <ModalButton
        {...classes('btn', 'toVideo')}
        ref={el => this.modal = el}
        classNameModal="modalVideo"
        buttonProps={{
          dataView: null,
          ...propsBtnVideo
        }}
        size="full"
        onOpenCallback={this.handleChangeIframeVideo}
        onCloseCallback={this.closeModalIframeVideo}
        disableNotification={false}
      >
        <Content closeButton noPadding>
          <div
            {...classes('modalContent')}
            children={showIframeVideo && iframeVideo}
          />
        </Content>
      </ModalButton>
    );
  }

  render() {
    const { className, videoId, otherProps } = this.props;

    if (!videoId || !className) return null;

    return this.renderIframeVideo(className, videoId, otherProps);
  }
}
