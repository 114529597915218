/* eslint-disable */
import React, { Component } from 'react';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Container for data visualizers
 * Implemented for performance optimization
 */
class FormVisContainer extends Component {
  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      values: {},
      schemas: {}
    };
    this.props._form.registerVisIdx(this.props.dataVisualize, this);
  }

  static getDerivedStateFromProps(props) {
    const fields = props.dataVisualize;
    const form = props._form;

    let values = {};
    let schemas = {};

    if (fields === '*') {
      values = form.data;
      schemas = form.state.schema.rules;
    } else {
      forEach(fields, (field) => {
        values[field] = form._getValue(form.data, field);
        schemas[field] = form._getRule(form.state.schema, field);
      });
    }

    return {
      values: cloneDeep(values),
      schemas
    };
  }

  componentWillUnmount() {
    this.props._form.unregisterVisIdx(this.props.dataVisualize, this);
  }

  update() {
    this.setState(
      FormVisContainer.getDerivedStateFromProps(this.props)
    );
  }

  render() {
    if (this.props._form.state.schemaLoading) {
      return null;
    }

    const {
      element,
      ...props
    } = this.props;
    return React.cloneElement(element, {
      ...props,
      values: this.state.values,
      schemas: this.state.schemas
    });
  }
}

export default FormVisContainer;
