import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'react-schemaorg';
import map from 'lodash/map';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import Bem from 'bemHelper';
import numDesc from 'helpers/numDesc';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import DataProvider from 'api/data-provider';
import Container from 'components/container';
import Row, { Col } from 'components/row';
import Image from 'components/image';
import Slick from 'components/slick-slider';
import Icon, { iconTypes } from 'components/icon';
import Link from 'components/link';
import Button from 'components/button';
import YoutubeIframe from 'components/youtube-iframe';

import linksBild from 'src-sites/bild/links';
import { reliabilityIndex } from 'src-sites/bild/section-project/reliability-index';

import translates from './reliability-block-i18n';

const classesBlockReliability = new Bem('blockReliability');

@I18nHoc(translates())
export default class ReliabilityBlock extends Component {
  static propTypes = {
    className: PropTypes.string,
    projectsPreloaded: PropTypes.shape({}),
    countCondition: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.providerRenderer = this.providerRenderer.bind(this);
    this.closeHint = this.closeHint.bind(this);

    this.state = {
      showHint: true
    };
  }

  providerRenderer({ data }) {
    const sliderData = [];

    forEach(data, (val) => {
      const image = get(val, 'title_image.id');
      if (image) {
        sliderData.push({
          image,
          display_name: val.display_name,
          url: val.url,
          points: val.points
        });
      }
    });

    return (
      <div {...classesBlockReliability('imgContainer')}>
        <div {...classesBlockReliability('img')}>
          <Slick
            {...classesBlockReliability('imgPic')}
            lazyLoad
          >
            {map(sliderData, (item, key) => (
              <div {...classesBlockReliability('item')} key={key}>
                <Image
                  image={{
                    id: item.image,
                    renderer: 'orig',
                    alt: item.display_name
                  }}
                  fullScreenSwitch="none"
                />
                <JsonLd
                  item={{
                    '@context': 'https://schema.org',
                    '@type': 'ImageObject',
                    name: item.display_name || null,
                    description: item.display_name || null,
                    contentUrl: item.image ? absoluteUrl(`/images/orig/full/${item.image}`) : null
                  }}
                />
                <div {...classesBlockReliability('onPhoto')}>
                  <div {...classesBlockReliability('onPhotoOverlay', 'bottom')}>
                    <span {...classesBlockReliability('text', 'slider')} children={item.display_name} />
                  </div>
                  {reliabilityIndex.call(this, classesBlockReliability, item.points, null, item.status_ready)}
                </div>
                <Link
                  {...classesBlockReliability('link', 'fill')}
                  builder={linksBild.sectionLanding}
                  builderProps={{
                    title: item.display_name,
                    url: item.url
                  }}
                >
                  <span {...classesBlockReliability('linkContent')} />
                </Link>
              </div>
            ))}
          </Slick>
        </div>
      </div>
    );
  }

  closeHint() {
    if (this.state.showHint) {
      this.setState({
        showHint: false
      });
    }
  }

  renderItemDescription(iconType, iconSubstrate, textTopic, textDescription) {
    return (
      <Col {...classesBlockReliability('col')} allColumn allPr={0} allCw={50}>
        <Icon
          {...classesBlockReliability('icon')}
          type={iconType}
          substrate={iconSubstrate}
        />
        <h4 {...classesBlockReliability('text', 'h4')} children={textTopic} />
        <p {...classesBlockReliability('text', 'description')} children={textDescription} />
      </Col>
    );
  }

  render() {
    const { i18n } = this;
    const { className, projectsPreloaded = {}, countCondition } = this.props;
    const iframeSrcId = 'pnLpiJPnRCE';

    return (
      <div {...classesBlockReliability({ extra: className })}>
        <Container body>
          <Row {...classesBlockReliability('row')} mtColumn mpColumn>
            <Col {...classesBlockReliability('col', 'slider')} mdCw={50} mpFlex allPr={0}>
              <DataProvider
                preloaded={projectsPreloaded.projectsReliability}
                preloadedStatic
                renderer={this.providerRenderer}
              />
              {this.state.showHint &&
                <div {...classesBlockReliability('blockHint')}>
                  <div {...classesBlockReliability('blockHintContent')}>
                    <p {...classesBlockReliability('text', 'topic')} children={i18n('hintTopic')} />
                    <p {...classesBlockReliability('text')} children={i18n('hintDescription')} />
                    <YoutubeIframe
                      className={classesBlockReliability}
                      videoId={iframeSrcId}
                      otherProps={{
                        toContent: false,
                        btnToVideoProps: {
                          label: i18n('labelYoutube'),
                          title: i18n('labelYoutube')
                        }
                      }}
                    />
                    {/*
                    <Link
                      {...classesBlockReliability('link', 'toVideo')}
                      {...linksExternal.pageYoutubeShort}
                      title="Смотреть про индекс надёжности"
                    >
                    <span {...classesBlockReliability('linkContent')}>
    <Icon {...classesBlockReliability('icon', 'video')} type={iconTypes.videoBild} />
    <span {...classesBlockReliability('text', 'link')} children="Смотреть про индекс надёжности" />
                    </span>
                    </Link>
                    */}
                    <span {...classesBlockReliability('img', 'arrow bottom')} />
                    <Button
                      {...classesBlockReliability('btn', 'close')}
                      onClick={this.closeHint}
                      iconType={iconTypes.clear}
                      title={i18n('hintClose')}
                      iconOnly
                    />
                  </div>
                </div>
              }
            </Col>
            <Col {...classesBlockReliability('col', 'info')} mdCw={50} mdPl={55} mdPr={30}>
              <h2 {...classesBlockReliability('text', 'h2')} children={i18n('h2')} />
              <div {...classesBlockReliability('description', 'top')}>
                <p
                  {...classesBlockReliability('text')}
                  children={i18n('description', {
                    placeholderRU: numDesc(countCondition, 'параметру', 'параметрам', 'параметрам'),
                    placeholderUA: numDesc(countCondition, 'параметру', 'параметрам', 'параметрам')
                  })}
                />
              </div>
              <div {...classesBlockReliability('description', 'top')}>
                <Link
                  {...classesBlockReliability('link', 'toExample')}
                  // builder={linksBildControl.sectionBildControl}
                  // builderProps={{
                  //   title: i18n('linkToExampleTitle'),
                  //   url: i18n('urlToPdf')
                  // }}
                  href={i18n('urlToPdf')}
                  title={i18n('linkToExampleTitle')}
                  dataProps={{
                    'target': 'bc',
                    'place': 'Main',
                    'type': 'bc'
                  }}
                  external
                  blank
                >
                  <span {...classesBlockReliability('linkContent')}>
                    <Icon {...classesBlockReliability('icon')} type={iconTypes.logoBildControl} />
                    <span {...classesBlockReliability('text')} children={i18n('linkToExampleLabel')} />
                  </span>
                </Link>
              </div>
              <h3 {...classesBlockReliability('text', 'h3')} children={i18n('h3')} />
              <Row allAuto {...classesBlockReliability('ourIndexIncludes')}>
                {this.renderItemDescription(
                  iconTypes.iconStamp,
                  `<span class="${classesBlockReliability('img', 'substrate stamp').className}"></span>`,
                  i18n('descriptionItemTopic1'),
                  i18n('descriptionItemDescription1')
                )}
                {this.renderItemDescription(
                  iconTypes.iconDocProect,
                  `<span class="${classesBlockReliability('img', 'substrate docProect').className}"></span>`,
                  i18n('descriptionItemTopic2'),
                  i18n('descriptionItemDescription2')
                )}
                {this.renderItemDescription(
                  iconTypes.iconDoc,
                  `<span class="${classesBlockReliability('img', 'substrate doc').className}"></span>`,
                  i18n('descriptionItemTopic3'),
                  i18n('descriptionItemDescription3')
                )}
                {this.renderItemDescription(
                  iconTypes.iconBuilds,
                  `<span class="${classesBlockReliability('img', 'substrate builds').className}"></span>`,
                  i18n('descriptionItemTopic4'),
                  i18n('descriptionItemDescription4')
                )}
              </Row>
            </Col>
          </Row>
          <Row {...classesBlockReliability('row', 'hint')}>
            <Col {...classesBlockReliability('col', 'info')} allPr={0}>
              <div {...classesBlockReliability('description')}>
                <p {...classesBlockReliability('text')} children={i18n('hintDescriptionBottom1')} />
                <p {...classesBlockReliability('text')} children={i18n('hintDescriptionBottom2')} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
