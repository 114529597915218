/**
 * Created by Kotkin on 02.08.17.
 */

import module from './paginator';
import Control from './control';
import Container from './container';
import Pages from './pages';
import InnerLinks from './inner-links';
import StaticPaginator from './static-paginator';

function offsetHelper(page, perPage) {
  const intPage = parseInt(page, 10);
  return intPage === 0 ? 0 : (intPage - 1) * perPage;
}

export {
  module as default,
  Control,
  Container,
  Pages,
  InnerLinks,
  StaticPaginator,
  offsetHelper
};
