/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react';
import { JsonLd } from 'react-schemaorg';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import split from 'lodash/split';
import findIndex from 'lodash/findIndex';
import beautifyNumber from 'helpers/beautifyNumber';
import formatDate from 'helpers/formatDate';
import numDesc, { numDescOnlyText } from 'helpers/numDesc';
import urlShortener from 'helpers/urlShortener';
import absoluteUrl from 'helpers/absoluteUrl';
import Counter from 'subsys/counter';
import Icon, { iconTypes } from 'components/icon';
import Link from 'components/link';
import { Thumb } from 'components/image';
import { HideTelephoneSeo } from 'components/hide-telephone';
import Row, { Col } from 'components/row';

import linksBild from 'src-sites/bild/links';

import { checkSold } from './cards-object/list-card';
import ContactToDeveloper from './contact-to-developer';

const currentDate = new Date().getTime();

export function renderSimpleItem(bemClasses, label, key, mods = {}, noTextSpan = false) {
  if (!label) return null;

  return (
    <div
      key={key}
      {...bemClasses('item', mods)}
    >
      {noTextSpan
        ? label
        : <span {...bemClasses('text')}>{label}</span>
      }
    </div>
  );
}

export function renderPrice(
  bemClasses,
  { label, mainPrice, symbol, className, desc },
  key
) {
  if (!mainPrice) return null;

  const priceFullText = (
    <div {...bemClasses('price')}>
      <span {...bemClasses('text')}>
        {`${label || symbol} ${mainPrice} ${className}`}
      </span>
    </div>
  );

  return (
    <Fragment key={key}>
      {desc && renderSimpleItem(bemClasses, desc, `${key}desc`, { priceDesc: true })}
      {renderSimpleItem(bemClasses, priceFullText, key, { price: priceFullText }, true)}
    </Fragment>
  );
}

export function renderCommonItem(bemClasses, item, key) {
  if (!item || !item.val) return null;

  let label = null;
  if (typeof item === 'string') {
    label = <span {...bemClasses('text')}>{item}</span>;
  } else {
    label = (
      <>
        <span {...bemClasses('text', 'label')}>{item.label}:&ensp;</span>
        <span {...bemClasses('text')}>{item.val}</span>
      </>
    );
  }

  return renderSimpleItem(bemClasses, label, key, {}, true);
}

export function renderSimpleLink(
  bemClasses, { url = '', text, builder, builderProps, blank, noRedirector, dataProps = {} },
  textShortener = false, mods = {}, withShadow = false, counterProps
) {
  if (!url && !builder) return null;
  let hrfShortener;
  if (textShortener) hrfShortener = urlShortener(url, true);
  const linkProps = {};
  linkProps.blank = blank;
  linkProps.noRedirector = noRedirector;
  linkProps.dataProps = dataProps;

  if (url) {
    linkProps.href = url;
    linkProps.title = text || hrfShortener || url;
    linkProps.external = true;
    linkProps.rel = noRedirector ? '' : 'nofollow';
    linkProps.blank = true;
  }
  if (builder) {
    linkProps.builder = builder;
    linkProps.builderProps = builderProps;
  }
  const simpleLink = (
    <Link
      {...bemClasses('link', mods)}
      {...linkProps}
    >
      <span {...bemClasses('linkContent')}>
        {withShadow && <span {...bemClasses('linkShadow')} />}
        <span {...bemClasses('text')}>{text || hrfShortener || url}</span>
      </span>
    </Link>
  );
  if (counterProps) {
    return (
      <Counter
        mode="click"
        injectClickName="onClick"
        {...counterProps}
      >
        {simpleLink}
      </Counter>
    );
  }

  return simpleLink;
}

export function renderCommonIconAndItem(bemClasses, iconType = false, item, key, mods, dataProps) {
  if (!item) return null;
  let label = item;
  const idProject = get(this, 'props.project.data[0].id');
  const displayName = get(this, 'props.project.data[0].display_name');
  if (key && key[0] === 'complex_sites') label = renderSimpleLink(bemClasses, { url: item.val ? item.val[0] : item[0], dataProps }, true, { site: true }, false, { entity: { project_id: idProject, project_title: displayName }, action: 'siteProjectClick' });
  if (key && key[0] === 'complex_forums') label = renderSimpleLink(bemClasses, { url: item.val ? item.val[0] : item[0], text: item.label, noRedirector: true }, false, {}, false, { entity: { project_id: idProject, project_title: displayName, url_forum: item.val ? item.val[0] : item[0] }, action: 'forumClick' });
  if (key && key[0] === 'phone') {
    if (!item.phones || !item.phones[0]) return null;

    label = (
      renderClickPhone.call(
        this,
        bemClasses,
        item.phones[0],
        { entity: { project_id: idProject, project_title: displayName } }
      )
    );
  }

  return renderSimpleItem(
    bemClasses,
    <>
      <span {...bemClasses('text', 'icon')}>
        {iconType && <Icon {...bemClasses('icon')} type={iconType} />}
      </span>
      <span {...bemClasses('text', 'colRight')}>
        {label}
      </span>
    </>,
    key,
    { row: true, ...mods },
    true
  );
}

export function renderCreators(bemClasses, item, key) {
  const label = (
    <>
      <span {...bemClasses('text', 'label')}>{item.label}:&ensp;</span>
      <span {...bemClasses('text')}>
        {creatorsList.call(this, bemClasses, item.val)}
      </span>
    </>
  );

  return renderSimpleItem(bemClasses, label, key, { creators: true }, true);
}

function creatorsList(bemClasses, val) {
  return map(val, (creator, index) => {
    const name = creator.name || creator.name_short;

    return (
      <Fragment key={index}>
        {name && creator.url &&
          renderSimpleLink(
            bemClasses,
            { builder: linksBild.sectionLanding, builderProps: creator, text: name }
          )
        }
      </Fragment>
    );
  });
}

// рендер списка Застройщиков в горизонтальном виде
export function renderCreatorsListHorizontal(bemClasses, valCreator) {
  const { i18n } = this;

  return map(valCreator, (creator, index) => {
    const idProject = get(this, 'props.project.id');
    const displayName = get(this, 'props.project.display_name');
    const { company_id, company_name, avatar, living_complex_counters } = creator;
    const image = (typeof avatar === 'string' || !avatar) ? { id: avatar } : avatar;
    const housesCounter = getHousesCounter.call(this, living_complex_counters);
    const minBuiltYear = housesCounter.ready_house_from_year ? i18n('developerYear', { placeholder: housesCounter.ready_house_from_year }) : '';
    const name = creator.name || creator.name_short;
    const avatarDeveloper = { ...image, alt: name, title: name };
    const foundingDate = creator.work_start_date || '';
    const dataJsonLdImg = {
      name: get(avatarDeveloper, 'alt', null),
      description: get(avatarDeveloper, 'title', null),
      contentUrl: get(image, 'id') ? absoluteUrl(`/images/thumb/full/${image.id}`) : null
    };
    const salers = get(this, 'props.project.salers', []);
    const indexPhone = findIndex(salers, (saler) => saler.parent === creator.id);
    const salerPhones = get(salers, `[${indexPhone}].phones`);
    const dataJsonLd = {
      name,
      url: absoluteUrl(creator.url),
      logo: absoluteUrl(`/images/thumb/full/${image.id}`),
      image: absoluteUrl(`/images/thumb/full/${image.id}`),
      telephone: salerPhones,
      foundingDate,
      // sameAs: creator.website
    };

    return (
      <Fragment key={index}>
        {name && creator.url &&
          <div {...bemClasses('creator')}>
            <Row {...bemClasses('content')} alignIcenter>
              <Col {...bemClasses('col', 'img')} allAuto allPr={0}>
                <div {...bemClasses('imgContainer')}>
                  <div {...bemClasses('img')}>
                    <Counter
                      entity={{
                        developer_id: company_id,
                        developer_title: company_name,
                        project_id: idProject,
                        project_title: displayName
                      }}
                      mode="click"
                      action="developerPhotoClick"
                      injectClickName="onClick"
                    >
                      <Link
                        {...bemClasses('link', 'fill')}
                        builder={linksBild.sectionLanding}
                        builderProps={creator}
                      >
                        <span />
                      </Link>
                    </Counter>
                    <Thumb
                      {...bemClasses('imgPic')}
                      image={avatarDeveloper}
                    />
                    {renderJsonLd('ImageObject', dataJsonLdImg)}
                  </div>
                </div>
              </Col>
              <Col {...bemClasses('col', 'topicPhonesBuildBuilt')} allPr={0}>
                <Row {...bemClasses('row', 'topicPhonesBuildBuilt')} alignIcenter>
                  <Col {...bemClasses('col', 'topicPhones')} allPr={0}>
                    <div {...bemClasses('topicContainer')}>
                      {renderSimpleLink(
                        bemClasses,
                        { builder: linksBild.sectionLanding, builderProps: creator, text: name },
                        false,
                        { topic: true },
                        false,
                        { entity: { developer_id: company_id, project_id: idProject }, action: 'developerClick' }
                      )}
                    </div>
                    <div {...bemClasses('phonesContainer')}>
                      {salerPhones && renderPhones.call(this, bemClasses, salerPhones, true, { entity: { developer_id: company_id, developer_title: company_name, project_id: idProject, project_title: displayName }, action: 'phoneDeveloperClick' })}
                    </div>
                  </Col>
                  <Col {...bemClasses('col', 'buildBuilt')} allPr={0}>
                    {!!housesCounter.in_progress_house_number &&
                      <div {...bemClasses('buildContainer')}>
                        <span {...bemClasses('text')}>
                          <span {...bemClasses('text', 'bold')} children={beautifyNumber(housesCounter.in_progress_house_number || 0)} />
                          {`\u00a0${
                            numDescOnlyText(
                              housesCounter.in_progress_house_number || 0,
                              i18n('developerHouse'),
                              i18n('developerHouseDeclination'),
                              i18n('developerHouseMultiple')
                            )
                          } ${
                            i18n('developerInCount', { placeholder: housesCounter.in_progress_house_in_lc_number || 0 })
                          } ${i18n('developerBuild')}`}
                        </span>
                      </div>
                    }
                    {!!housesCounter.ready_house_number &&
                      <div {...bemClasses('builtContainer')}>
                        <span {...bemClasses('text')}>
                          <span
                            {...bemClasses('text', 'bold')}
                            children={beautifyNumber(housesCounter.ready_house_number || 0)}
                          />
                          {`\u00a0${
                            numDescOnlyText(
                              housesCounter.ready_house_number || 0,
                              i18n('developerHouseReady'),
                              i18n('developerHouseReadyDeclination'),
                              i18n('developerHouseReadyMultiple')
                            )
                          } в\u00a0${housesCounter.ready_house_in_lc_number || 0}\u00a0ЖК${minBuiltYear}`}
                        </span>
                      </div>
                    }
                    {+creator.rel_finished_cottage_towns > 0 &&
                      <div {...bemClasses('builtContainer')}>
                        <span {...bemClasses('text')}>
                          <span
                            {...bemClasses('text', 'bold')}
                            children={beautifyNumber(+creator.rel_finished_cottage_towns || 0)}
                          />
                          {`\u00a0${
                            numDescOnlyText(
                              +creator.rel_finished_cottage_towns || 0,
                              i18n('developerHouseReadyCT'),
                              i18n('developerHouseReadyCTDeclination'),
                              i18n('developerHouseReadyCTMultiple')
                            )
                          }`}
                        </span>
                      </div>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            {renderJsonLd('HomeAndConstructionBusiness', dataJsonLd)}
          </div>
        }
      </Fragment>
    );
  });
}

function renderClickPhone(bemClasses, val, counterProps) {
  if (!val) return null;

  const telephone = (
    <HideTelephoneSeo
      {...bemClasses('link', 'tel')}
      phone={val}
      slicedNumber={6}
      btnLabel={`\u00a0xx xx ${this.i18n('call')}`}
      // seoProps={{ itemProp: 'telephone', content: val }}
      classMods={{
        telBild: true
      }}
    />
  );

  if (counterProps) {
    return (
      <Counter
        action="phoneClick"
        {...counterProps}
        entity={{
          ...counterProps.entity,
          phone: val
        }}
        mode="click"
        injectClickName="onClick"
      >
        {telephone}
      </Counter>
    );
  }

  return telephone;
}

function renderPhones(bemClasses, phones, forCreators, counterProps) {
  const length = phones.length - 1;

  return map(phones, (tel, index) => (
    <span key={index} {...bemClasses('text', 'phones')}>
      {renderClickPhone.call(this, bemClasses, tel, counterProps)}
      {!forCreators && index !== length && ', '}
    </span>
  ));
}

// рендер акций
export function renderSignOfAvailabilityStock(bemClasses, currentStock) {
  if (!currentStock) return null;
  const presentAndDiscount = { present: null, discount: null, finflash: null };

  const checkedStocks = checkStocks(currentStock);
  if (!checkedStocks) return null;

  if (currentStock.present) presentAndDiscount.present = currentStock.present;
  if (currentStock.discount) presentAndDiscount.discount = currentStock.discount;
  if (currentStock.finflash && !presentAndDiscount.finflash) presentAndDiscount.finflash = currentStock.finflash;

  if (
    !presentAndDiscount.present &&
    !presentAndDiscount.discount &&
    !presentAndDiscount.finflash
  ) return null;

  return (
    <div {...bemClasses('onPhoto', 'stocks')}>
      <div {...bemClasses('stocks')}>
        <span {...bemClasses('stock')}>
          {presentAndDiscount.discount &&
            <span {...bemClasses('text', 'stock')} children={presentAndDiscount.discount} />
          }
          {presentAndDiscount.present &&
            <Icon {...bemClasses('icon', 'stock')} type={iconTypes.gift} />
          }
          {presentAndDiscount.finflash &&
            <Icon {...bemClasses('icon', 'finflash')} type={iconTypes.finflash} />
          }
        </span>
      </div>
    </div>
  );
}

// рендер данных о продажах и о статусах
export function renderSalesTitleAndStatuses(
  bemClasses,
  data = {},
  viewTile = false
) {
  const { i18n } = this;
  const { living_complex_build_status = {} } = data;
  const salesCfg = [
    { val: data.start_of_sales, label: i18n('startOfSales') },
    { val: data.start_of_sales_soon, label: i18n('startOfSalesSoon') },
    { val: data.start_of_new_turn_sales, label: i18n('startOfNewTurnSales') },
    { val: data.last_flats, label: i18n('lastFlats') },
    { val: data.last_commercial, label: i18n('lastCommercial') },
    { val: data.last_house_and_area, label: i18n('lastHouseAndArea') }
  ].filter(cfg => cfg.val === true);

  forEach(living_complex_build_status, (status) => {
    if (
      status.status !== 'in_progress' ||
      status.status !== 'with_problem' ||
      status.status !== 'ready'
    ) {
      salesCfg.push({ label: status.value });
    }
  });

  if (!salesCfg.length) return null;

  const salesTitle = (
    <div {...bemClasses('salesTitle')} data-test="salesTitle">
      {map(salesCfg, val => (
        <div key={val.label}><span {...bemClasses('text')} children={val.label} data-test="viewTitleText" /></div>
      ))}
    </div>
  );

  if (viewTile) {
    return (
      <div {...bemClasses('onPhoto', 'sales')}>
        <div {...bemClasses('onPhotoOverlay', 'bottom')} data-test="onPhotoOverlay">
          {!!salesCfg.length && salesTitle}
        </div>
      </div>
    );
  }

  return (
    <div {...bemClasses('onPhoto', 'sales')}>
      {!!salesCfg.length && salesTitle}
    </div>
  );
}

export function locType(type) {
  const { i18n } = this;

  return type === 'cottage_town' ? ` ${i18n('textCT')}` : ` ${i18n('textLC')}`;
}

// рендер Характеристик комплекса
export function renderComplexProperties(bemClasses) {
  const { project = {} } = this.props;
  const { advanced_info = {}, ...rest } = project;
  const propsGroup = { ...advanced_info, ...rest };
  const { start_year, built_year, number_of_storeys } = propsGroup;
  const propertiesMain = [
    'complex_class',
    'house_count',
    'section_count',
    'all_parking_count',
    'flats_count',
    'flats_by_building',
    'land_area',
    'percentage_of_greenery',
    'ceil_height',
    'has_storeyrooms',
    'has_wheelchairs',
    'has_concierge_service',
    'has_showrooms',
    'has_comm_compartment',
    'comm_compartment_ceil_height',
  ];
  const propertiesConstruction = [
    'construction_technology',
    'wall_material',
    'wall_warming',
    'facade_facing',
    'window_types',
    'balcony_loggia_terrace',
    'renovate',
    'heating',
    'water',
    'central_air_conditioning_system',
    'solar_panels',
    'house_counters',
    'flat_counters',
    'ground_parking_count',
    'underground_parking_count',
    'garage_count'
  ];
  const propertiesInfrastructure = [
    'complex_concept',
    'complex_infrastruct',
    'access_to_complex',
    'security',
    'planting_of_greenery',
    'complex_beautification',
    'tenants_parking_types',
    'parking_infrastructure',
    'guest_parking_types',
    'playgrounds_count',
    'playgrounds_types',
    'sports_grounds_count',
    'sports_grounds_type',
  ];
  const allNeededProperties = [
    'living_complex_build_status',
    'start_year',
    'built_year',
    'number_of_storeys',
    ...propertiesMain,
    ...propertiesConstruction,
    ...propertiesInfrastructure
  ];
  const allPropertiesIsExist = checkForEmptyProperties.call(null, allNeededProperties, propsGroup);
  if (!allPropertiesIsExist) return null;
  const propertiesConstructionIsExist = checkForEmptyProperties.call(
    null, propertiesConstruction, propsGroup
  );
  const propertiesMainIsExist = checkForEmptyProperties.call(null, ['living_complex_build_status', ...propertiesMain], propsGroup);
  const typeTxt = locType.call(this, get(this, 'props.project.data[0].type') || get(this, 'props.project.type'));

  return (
    <div {...bemClasses('blockProperties')}>
      <Row {...bemClasses('rowTopic')}>
        <Col allPr={0}><div {...bemClasses('text', 'h2')}><h2 children={this.i18n('propertiesTopic', { placeholder: typeTxt })} /></div></Col>
      </Row>
      <div {...bemClasses('content')}>
        <table {...bemClasses('table')}>
          <tbody>
            {(start_year || built_year || number_of_storeys || propertiesMainIsExist) &&
              <tr>
                <td>
                  <table {...bemClasses('table')}>
                    <tbody {...bemClasses('tableBody', 'rowCol')}>
                      {renterComplexItemTable.call(this, bemClasses, ['living_complex_build_status'])}
                      {getStartAndBuiltYearsTable.call(this, bemClasses, start_year, built_year)}
                      {getNumberOfStoreys.call(this, bemClasses, number_of_storeys, true)}
                      {renterComplexItemTable.call(this, bemClasses, propertiesMain)}
                    </tbody>
                  </table>
                </td>
              </tr>
            }
            {propertiesConstructionIsExist &&
              <tr>
                <td>
                  <table {...bemClasses('table')}>
                    <tbody {...bemClasses('tableBody')}>
                      {renterComplexItemTable.call(this, bemClasses, propertiesConstruction)}
                    </tbody>
                  </table>
                </td>
              </tr>
            }
            <tr>
              <td>
                <table {...bemClasses('table')}>
                  <tbody {...bemClasses('tableBody')}>
                    {renterComplexItemTable.call(this, bemClasses, propertiesInfrastructure)}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function renderJsonLd(type, dataJsonLd) {
  return (
    <JsonLd
      item={{
        '@context': 'https://schema.org',
        '@type': type,
        ...dataJsonLd
      }}
    />
  );
}

function getTxt(advanced_info, item) {
  const { i18n } = this;
  const labelSeo = i18n(item) !== item ? i18n(item) : get(advanced_info, `[${item}].label`);

  let txt = advanced_info[item].val;
  let txtSeo = txt;

  if (txt === true || txt === i18n('propertyYes')) {
    txt = i18n('propertyThereIs');
    txtSeo = 'True';
  }
  if (txt === false || txt === i18n('propertyNo')) {
    txt = i18n('propertyNo');
    txtSeo = 'False';
  }
  if (
    item === 'comm_compartment_ceil_height' ||
    item === 'ceil_height'
  ) {
    txt += ' м';
  }
  if (item === 'percentage_of_greenery') {
    txt += ' %';
  }
  if (item === 'land_area') {
    const dict = get(advanced_info, 'land_area.dict');
    txt = dict ? numDesc(+txt, dict.F1, dict.F2, dict.F3) : txt;
  }

  return { txt, txtSeo, labelSeo };
}

// рендер одного свойства комплекса
export function renterComplexItem(bemClasses, order) {
  const advanced_info =
    get(this, 'props.project.advanced_info') ||
    get(this, 'props.project.data[0].advanced_info', {});

  return map(order, (item, key) => {
    if (!get(advanced_info, `[${item}].label`) || !get(advanced_info, `[${item}].val`)) return null;

    const { txt, txtSeo, labelSeo } = getTxt.call(this, advanced_info, item);
    const dataJsonLd = {
      name: labelSeo,
      value: split(txtSeo, ', '),
      description: txt
    };
    const label = `${labelSeo}:`;

    return (
      <span {...bemClasses('item')} key={key}>
        <span {...bemClasses('text', 'label')} children={label} />
        <span {...bemClasses('text', 'info')} children={txt} />
        {renderJsonLd('PropertyValue', dataJsonLd)}
      </span>
    );
  });
}

// рендер одного свойства комплекса в Таблице
function renterComplexItemTable(bemClasses, order) {
  const advanced_info =
    get(this, 'props.project.advanced_info') ||
    get(this, 'props.project.data[0].advanced_info', {});

  return map(order, (item, key) => {
    if (!get(advanced_info, `[${item}].label`) || !get(advanced_info, `[${item}].val`)) return null;

    const { txt, txtSeo, labelSeo } = getTxt.call(this, advanced_info, item);
    const dataJsonLd = {
      name: labelSeo,
      value: split(txtSeo, ', '),
      description: txt
    };

    return (
      <tr {...bemClasses('item')} key={key}>
        <td {...bemClasses('text', 'label')}>
          <span children={labelSeo} />
        </td>
        <td {...bemClasses('text', 'info')}>
          <span children={txt} />
          {renderJsonLd('PropertyValue', dataJsonLd)}
        </td>
      </tr>
    );
  });
}

export function getReferencePoints(bemClasses, referencePoints, type) {
  if (!referencePoints || referencePoints.length === 0) return null;

  const txt = this.i18n(type);
  let array = sortBy(referencePoints, item => get(item, 'distance'));

  if (type === 'subways') {
    // array = filter(array, val => val.pedestrain <= 20);
    array = array.slice(0, 3);
  } else {
    array = array.slice(0, 5);
  }
  if (!array || array.length === 0) return null;

  return (
    <div {...bemClasses('item')}>
      <span {...bemClasses('text', 'label')} children={txt} />
      <span {...bemClasses('text', 'info')}>
        {map(array, (item, key) => {
          if (item) return itemReferencePoint.call(this, bemClasses, item, key, type);
        })}
      </span>
    </div>
  );
}

function itemReferencePoint(bemClasses, item, key, type) {
  if (!item.display_name && !item.name) return null;

  const { i18n } = this;
  const name = item.display_name || item.name;
  const children = type === 'subways' ?
    (
      <Fragment key={key}>
        <Icon {...bemClasses('icon', 'metro')} type={iconTypes.metro} />
        <span {...bemClasses('text')} children={name} />
      </Fragment>
    ) :
    <span {...bemClasses('text')} key={key} children={name} />;
  const linkOrSpan = item.url ?
    (
      <Fragment key={key}>
        {key >= 1 && ', '}
        <Link
          {...bemClasses('link', 'subway')}
          href={item.url}
        >
          <span {...bemClasses('linkContent')} children={children} />
        </Link>
        {item.pedestrain ?
          <span {...bemClasses('text', 'pedestrain')}>&ensp;&ndash;&ensp;{item.pedestrain}&nbsp;{i18n('textMinutes')}&nbsp;
            <Icon {...bemClasses('icon', 'walk')} type={iconTypes.walk} />
          </span> : null
        }
      </Fragment>
    ) : (
      <Fragment key={key}>
        {key >= 1 && ', '}
        <span {...bemClasses('text')} children={children} />
      </Fragment>
    );

  return linkOrSpan;
}

function getNumberOfStoreys(bemClasses, number_of_storeys, isTable) {
  const txt = get(number_of_storeys, 'val');
  if (!txt) return null;

  const label = this.i18n('number_of_storeys');
  const txtSeo = txt.split(',');
  const dataJsonLd = {
    name: label,
    value: txtSeo,
    description: txt
  };

  if (isTable) {
    return (
      <tr {...bemClasses('item')}>
        <td {...bemClasses('text', 'label')}>
          <span children={label} />
        </td>
        <td {...bemClasses('text', 'info')}>
          <span children={txt} />
          {renderJsonLd('PropertyValue', dataJsonLd)}
        </td>
      </tr>
    );
  }

  return (
    <div {...bemClasses('item')}>
      <span {...bemClasses('text', 'label')} children={label} />
      <span {...bemClasses('text', 'info')} children={txt} />
      {renderJsonLd('PropertyValue', dataJsonLd)}
    </div>
  );
}

function yearData(start_year, built_year) {
  const { i18n } = this;
  const typeTxt = locType.call(this, get(this, 'props.project.data[0].type'));
  const labelSeo = i18n('propertyReady', { placeholder: typeTxt });
  const txtYear = (start_year && built_year && start_year !== built_year) ?
    `${start_year} - ${built_year}` :
    start_year || built_year;
  const txt = i18n('propertyReadyYear', { placeholder: txtYear });
  const dataJsonLd = {
    name: labelSeo,
    value: txtYear,
    description: txt
  };

  return { labelSeo, txt, dataJsonLd };
}

export function getStartAndBuiltYears(bemClasses, start_year, built_year) {
  if (!start_year && !built_year) return null;

  const { labelSeo, txt, dataJsonLd } = yearData.call(this, start_year, built_year);

  return (
    <div {...bemClasses('item')}>
      <span {...bemClasses('text', 'label')} children={`${labelSeo}:`} />
      <span {...bemClasses('text', 'info')} children={txt} />
      {renderJsonLd('PropertyValue', dataJsonLd)}
    </div>
  );
}

export function getStartAndBuiltYearsTable(bemClasses, start_year, built_year) {
  if (!start_year && !built_year) return null;

  const { labelSeo, txt, dataJsonLd } = yearData.call(this, start_year, built_year);

  return (
    <tr {...bemClasses('item')}>
      <td {...bemClasses('text', 'label')}>
        <span children={labelSeo} />
      </td>
      <td {...bemClasses('text', 'info')}>
        <span children={txt} />
        {renderJsonLd('PropertyValue', dataJsonLd)}
      </td>
    </tr>
  );
}

export function getHousesCounter(living_complex_counters) {
  if (!living_complex_counters || living_complex_counters.length === 0) return {};

  const in_progress_house_number = get(living_complex_counters, 'in_progress_house.number');
  const in_progress_house_in_lc_number = get(living_complex_counters, 'in_progress_house.in_lc_number');
  const ready_house_number = get(living_complex_counters, 'ready_house.number');
  const ready_house_in_lc_number = get(living_complex_counters, 'ready_house.in_lc_number');
  const ready_house_from_year = get(living_complex_counters, 'ready_house.from_year');

  return {
    in_progress_house_number,
    in_progress_house_in_lc_number,
    ready_house_number,
    ready_house_in_lc_number,
    ready_house_from_year
  };
}

export function dateLifeInfo(bemClasses, dateLife, groupName) {
  const { project = {} } = this.props;
  const { advanced_info = {}, ...rest } = project;
  const propsGroup = { ...advanced_info, ...rest };

  if (checkIsHideJson.call(this, propsGroup)) return null;
  if (!dateLife) return null;

  const name = groupName ? ` ${groupName.toLowerCase()}` : null;
  if (!name) return null;
  const dateLifeHuman = formatDate({ date: dateLife, format: 'dd.mm.yyyy' });

  return <span {...bemClasses('text', 'dateLife')} children={`${this.i18n('dateLifeInfo', { placeholder: name })} ${dateLifeHuman}`} />;
}

export function checkIsHideJson(data) {
  const {
    $entity,
    all_flats_sold, all_commercial_sold, all_house_and_area_sold,
    sales_suspended_until, living_complex_build_status = {}
  } = data;

  let salesSuspendedUntil = sales_suspended_until;

  if (typeof salesSuspendedUntil !== 'boolean') salesSuspendedUntil = false;

  let needToHide = false;
  forEach(living_complex_build_status, status => {
    if (
      status.status === 'frozen' ||
      status.status === 'project_canceled' ||
      status.status === 'construction_suspended'
    ) {
      return needToHide = true;
    }
  });
  needToHide = !!salesSuspendedUntil || needToHide;
  const soldData = { all_flats_sold, all_commercial_sold, all_house_and_area_sold };
  const sold = checkSold.call(this, needToHide, soldData, $entity);

  return sold && sold.sold;
}

function checkForEmptyProperties(allNeededProperties, objWithProperties) {
  let propertiesIsExist = false;

  map(allNeededProperties, (item) => {
    if (!propertiesIsExist && objWithProperties[item]) {
      propertiesIsExist = objWithProperties[item].label ? objWithProperties[item].val : true;
    }
  });

  return propertiesIsExist;
}

export function mergePrice(
  { all_flats_sold, sales_suspended_until, price, min_price },
  noFlatsCheck = true
) {
  const priceToCheck = min_price || price || {};
  // проверяет проданы ли все квартиры
  if (!noFlatsCheck && (all_flats_sold || sales_suspended_until)) return {};

  // if (priceToCheck.value) return mergeMinPrice.call(priceToCheck, noFlatsCheck);

  if (priceToCheck.mainPrice) {
    const modPrice = {
      ...priceToCheck,
      mainPrice: beautifyNumber(priceToCheck.mainPrice)
    };

    return !noFlatsCheck ? { price: modPrice } : modPrice;
  }

  return {};
}

// проверка акций
export function checkStocks(stock) {
  if (!stock || !stock.link || (!stock.period_date_from && !stock.period_date_to)) return null;

  const stockStart = new Date(stock.period_date_from);
  const stockEnd = new Date(stock.period_date_to);

  if (
    (stockStart.getTime() <= currentDate && stockEnd.getTime() >= currentDate) ||
    (stockStart.getTime() <= currentDate && !stock.period_date_to) ||
    (!stock.period_date_from && stockEnd.getTime() >= currentDate)
  ) {
    return stock;
  }
}

export function getDeveloperLink(bemClasses, developer) {
  const mainDeveloperUrl = get(developer, 'url');
  const mainDeveloperName = get(developer, 'name') || get(developer, 'name_short');
  const mainDeveloperAvatar = get(developer, 'avatar');
  const image = typeof mainDeveloperAvatar === 'string' ?
    { id: mainDeveloperAvatar } : mainDeveloperAvatar;

  return (
    <>
      <div {...bemClasses('itemInfoDeveloper', 'linkDeveloper')}>
        <Link
          {...bemClasses('link', 'toDeveloper')}
          builder={linksBild.sectionLanding}
          builderProps={{ url: mainDeveloperUrl, title: mainDeveloperName }}
          external
        >
          <span {...bemClasses('linkContent')}>
            <span {...bemClasses('text', 'link')} children={mainDeveloperName} />
          </span>
        </Link>
      </div>
      <div {...bemClasses('itemInfoDeveloper', 'imgDeveloper')}>
        <Link
          {...bemClasses('link', 'imgDeveloper')}
          builder={linksBild.sectionLanding}
          builderProps={{ url: mainDeveloperUrl, title: mainDeveloperName }}
          rel="nofollow"
          external
        >
          <span {...bemClasses('linkContent')}>
            <Thumb
              {...bemClasses('avatar')}
              image={image}
              tagSpan
            />
            <JsonLd
              item={{
                '@context': 'https://schema.org',
                '@type': 'ImageObject',
                name: mainDeveloperName,
                description: mainDeveloperName,
                contentUrl: get(image, 'id') ? absoluteUrl(`/images/thumb/full/${image.id}`) : null
              }}
            />
          </span>
        </Link>
      </div>
    </>
  );
}

export function getAskDevHeader(bemClasses, project) {
  const { i18n } = this;
  const {
    advanced_info = {}, type, id, url, title_image, display_name, min_price, free_taxi, ...rest
  } = project;
  const propsGroup = { ...advanced_info, ...rest };
  const { start_year, built_year } = propsGroup;
  const { labelSeo, txt } = yearData.call(this, start_year, built_year);
  const priceCurrencyName = get(min_price, 'currency.id') === 'uah' ?
    get(min_price, 'currency.name_short') :
    get(min_price, 'currency.names_long');
  const priceClassDisplayName = get(min_price, 'class.displayName', '');
  const priceCurrency = `${priceCurrencyName}${priceClassDisplayName}`;
  const priceBeginText = i18n('priceBeginText');
  const priceMain = beautifyNumber(get(min_price, 'value'));
  const priceFullText = `${priceBeginText} ${priceMain} ${priceCurrency}`;

  return (
    <Row {...bemClasses('row', 'modalInfoDeveloper')}>
      <Col {...bemClasses('col', 'imgDeveloper')} allAuto allPr={0}>
        <div {...bemClasses('imgContainer')}>
          <div {...bemClasses('img')}>
            <Thumb
              {...bemClasses('imgPic')}
              image={title_image}
            />
          </div>
        </div>
      </Col>
      <Col {...bemClasses('col', 'infoDeveloper')} allPr={0}>
        <div {...bemClasses('item')}>
          <span {...bemClasses('text', 'topic')} children={display_name} />
        </div>
        {(start_year || built_year) &&
          <div {...bemClasses('item')}>
            <span {...bemClasses('text', 'label')} children={`${labelSeo}\u00a0`} />
            <span {...bemClasses('text', 'info')} children={txt} />
          </div>
        }
        {min_price && priceMain &&
          <div {...bemClasses('item')}>
            <span {...bemClasses('text', 'price')} children={priceFullText} />
          </div>
        }
      </Col>
    </Row>
  );
}

export function renderContactToDeveloper(bemClasses, btnLabel = null) {
  const { i18n } = this;
  const project = get(this, 'props.project.data[0]') || this.props.project;
  const { id, url, salers = [], free_taxi } = project;
  const developer = get(this, 'props.mainDeveloperData') || get(this, 'props.project.main_developer[0]') || {};
  const indexPhone = findIndex(salers, (saler) => saler.parent === developer.id);
  const salerPhone = get(salers, `[${indexPhone}].phones[0]`);
  const askDevHeader = getAskDevHeader.call(this, bemClasses, project);
  const salerEmail = get(salers, `[${indexPhone}].contact_email`);

  return (
    <ContactToDeveloper
      free_taxi={free_taxi}
      developer={developer}
      salerEmail={salerEmail}
      salerPhone={salerPhone}
      projectId={id}
      projectUrl={url}
      askDevHeader={askDevHeader}
      otherPropsDD={{
        btnText: btnLabel || i18n('connectConsult'),
        btnIconSecond: iconTypes.gift
      }}
    />
  );
}
