/**
 * Created by DryyMoon on 14.11.2016.
 */
import isBrower from './isBrowser';

// const queue = [];

// let transportReady = false;

/* if (isBrower && window.postMessage && window.originalPostMessage) transportReady = true;

 if (isBrower && !transportReady) {
 window.originalPostMessage = window.postMessage;
 window.postMessage = function store(message) {
 queue.push(message);
 };
 }

 let currentPostMessage;

 if (__APP__ && isBrower) {
 currentPostMessage = window.postMessage;
 Object.defineProperty(window, 'postMessage', {
 configurable: true,
 enumerable: true,
 get() {
 return currentPostMessage;
 },
 set(fn) {
 currentPostMessage = fn;
 transportReady = true;
 setTimeout(runQueue, 0);
 }
 });
 } */

if (__APP__ && isBrower) awaitPostMessage();

export default function send(message) {
  if (__APP__ && isBrower && window.postMessage) {
    return window.postMessage(JSON.stringify(message), '*');
  }
}

function awaitPostMessage() {
  let isReactNativePostMessageReady = !!window.originalPostMessage;
  const queue = [];
  let currentPostMessageFn = function store(message) {
    if (queue.length > 100) queue.shift();
    queue.push(message);
  };
  if (!isReactNativePostMessageReady) {
    // const originalPostMessage = window.postMessage;
    Object.defineProperty(window, 'postMessage', {
      configurable: true,
      enumerable: true,
      get() {
        return currentPostMessageFn;
      },
      set(fn) {
        currentPostMessageFn = fn;
        isReactNativePostMessageReady = true;
        setTimeout(sendQueue, 0);
      }
    });
  }

  function sendQueue() {
    while (queue.length > 0) window.postMessage(queue.shift());
  }
}
