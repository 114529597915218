// import formatDateTranslates from 'helpers/format-date-i18n.json';
import numDescTranslates from 'helpers/num-desc-i18n.json';
import reliabilityIndexTranslates from './reliability-index-i18n.json';
import developerTranslates from '../section-developer/developer-i18n';
import configLabelsTranslates from './config-labels-i18n';

export default function () {
  return {
    // ...formatDateTranslates,
    ...numDescTranslates,
    ...reliabilityIndexTranslates,
    ...developerTranslates(),
    ...configLabelsTranslates,

    more: {
      ru: 'Eщe',
      ua: 'Ще'
    },
    moreDetails: {
      ru: 'Подробнее',
      ua: 'Детальніше'
    },

    overflight: {
      ru: 'Аэрооблёт',
      ua: 'Аерообліт'
    },

    call: {
      ru: 'Позвонить',
      ua: 'Дзвонити'
    },

    year: {
      ru: 'год',
      ua: 'рік'
    },

    textLC: {
      ru: 'ЖК',
      ua: 'ЖК'
    },
    textCT: {
      ru: 'КП',
      ua: 'КМ'
    },

    // linkToBackText: {
    //   ru: 'К результатам поиска',
    //   ua: 'До результатів пошуку'
    // },

    tabMain: {
      ru: 'Общее',
      ua: 'Загальне'
    },

    btnToLocation: {
      ru: 'К размещению',
      ua: 'До розміщення'
    },

    reliability: {
      ru: 'Надёжность',
      ua: 'Надійність'
    },
    report: {
      ru: 'Отчёт',
      ua: 'Звіт'
    },
    question: {
      ru: 'Вопрос',
      ua: 'Питання'
    },
    newBuildingReliabilityIndex: {
      ru: 'Индекс надёжности новостройки',
      ua: 'Індекс надійності новобудови'
    },
    verified: {
      ru: 'Проверено',
      ua: 'Перевірено'
    },
    get: {
      ru: 'Получить ',
      ua: 'Отримати '
    },
    toOrder: {
      ru: 'Заказать ',
      ua: 'Замовити '
    },
    actualReport: {
      ru: 'актуальный отчёт{{placeholder}}',
      ua: 'актуальний звіт{{placeholder}}'
    },
    linkToSiteText: {
      ru: 'На сайт{{placeholder}}',
      ua: 'На сайт{{placeholder}}'
    },
    linkToSiteInPanelText: {
      ru: 'Сайт{{placeholder}}',
      ua: 'Сайт{{placeholder}}'
    },
    reportCreated: {
      ru: 'Отчёт создан',
      ua: 'Звіт створено'
    },
    reportUpdated: {
      ru: 'Отчёт актуализирован',
      ua: 'Звіт актуалізовано'
    },
    btnToVideoText: {
      ru: 'Подробнее про оценку',
      ua: 'Детальніше про оцінку'
    },
    btnToAskQuestionText: {
      ru: 'Задать вопрос',
      ua: 'Задати питання'
    },
    btnComplaintText: {
      ru: 'Сообщить о неточности',
      ua: 'Повідомити про неточність'
    },

    levelTextNA: {
      ru: 'Мы ещё не провели оценку этого ЖК, но вы можете её заказать',
      ua: 'Ми ще не провели оцінку цього ЖК, але ви можете її замовити'
    },
    levelTextLow: {
      ru: 'Осторожно! Очень низкая оценка надёжности, увы',
      ua: 'Обережно! Дуже низька оцінка надійності, на жаль'
    },
    levelTextMedium: {
      ru: 'Оценка надёжности средняя - нужно смотреть детали отчёта',
      ua: 'Оцінка надійності середня - потрібно дивитися деталі звіту'
    },
    levelTextHigh: {
      ru: 'Хм… судя по оценке надёжности, вариант очень интересный',
      ua: 'Хм… судячи з оцінки надійності, варіант дуже цікавий'
    },

    advantagesAndDisadvantages: {
      ru: 'Преимущества и недостатки',
      ua: 'Переваги і недоліки'
    },

    linkIdentification: {
      ru: 'на Bildcontrol.ua',
      ua: 'до Bildcontrol.ua'
    },

    developerSale: {
      ru: 'Продажа от застройщика',
      ua: 'Продаж від забудовника'
    },
    linkShowAllPlansText: {
      ru: 'Смотреть все планировки',
      ua: 'Переглянути усі планування'
    },
    dateLifeInfo: {
      ru: 'Цены на{{placeholder}} от застройщика актуальны на',
      ua: 'Ціни на{{placeholder}} від забудовника актуальні на'
    },

    purchaseOptionsTopic: {
      ru: 'Варианты покупки',
      ua: 'Варіанти придбання'
    },
    installment: {
      ru: 'Рассрочка',
      ua: 'Розстрочка'
    },
    credit: {
      ru: 'Кредит',
      ua: 'Кредит'
    },

    mapTopic: {
      ru: 'Расположение',
      ua: 'Розташування'
    },
    mapBtnPanorama: {
      ru: 'Панорама',
      ua: 'Панорама'
    },
    textMinutes: {
      ru: 'мин.',
      ua: 'хв.'
    },
    getRoute: {
      ru: 'Проложить маршрут',
      ua: 'Прокласти маршрут'
    },

    complexYoutubeTopic: {
      ru: 'Видео о{{placeholder}}',
      ua: 'Відео про{{placeholder}}'
    },

    propertiesTopic: {
      ru: 'Характеристики{{placeholder}}',
      ua: 'Характеристики{{placeholder}}'
    },
    propertyReady: {
      ru: 'Сдача{{placeholder}}',
      ua: 'Здача{{placeholder}}'
    },
    propertyReadyYear: {
      ru: '{{placeholder}}\u00a0г.',
      ua: '{{placeholder}}\u00a0р.'
    },
    propertyYes: {
      ru: 'Да',
      ua: 'Так'
    },
    propertyThereIs: {
      ru: 'Есть',
      ua: 'Є'
    },
    propertyNo: {
      ru: 'Нет',
      ua: 'Немає'
    },

    constructionProgressTopic: {
      ru: 'Ход строительства',
      ua: 'Хід будівництва'
    },
    constructionProgressLinkText: {
      ru: 'Ход стройки у застройщика',
      ua: 'Хід будівництва у забудовника'
    },

    askQuestionMessage: {
      ru: 'Спасибо, ваш запрос отправлен.',
      ua: 'Дякуємо, ваше запит відправлено.'
    },

    descriptionTopic: {
      ru: 'Описание',
      ua: 'Інформація'
    },

    publicationsTopic: {
      ru: 'С этим{{placeholder}} читают',
      ua: 'З цим{{placeholder}} читають'
    },

    nearestLivingComplexLCTopic: {
      ru: 'Новостройки рядом',
      ua: 'Новобудови поруч'
    },
    nearestLivingComplexCTTopic: {
      ru: 'Коттеджные посёлки рядом',
      ua: 'Котеджні містечка поблизу'
    },

    commentsTopic: {
      ru: 'А что вы думаете об{{placeholder}}?',
      ua: 'А що ви думаєте про{{placeholder}}?'
    },
    commentsAddToHeaderCTText: {
      ru: ' этом коттеджном посёлке',
      ua: ' це котеджне містечко'
    },
    commentsAddToHeaderLCText: {
      ru: ' этом комплексе',
      ua: ' цей комплекс'
    },

    rooms: {
      ru: 'Количество комнат',
      ua: 'Кількість кімнат'
    },
    type: {
      ru: 'Тип',
      ua: 'Тип'
    },
    commissioning: {
      ru: 'Ввод в эксплуатацию',
      ua: 'Введення в експлуатацію'
    },
    btnAllText: {
      ru: 'Все',
      ua: 'Всі'
    },
    btnAnyTimeText: {
      ru: 'Любое время',
      ua: 'Будь-який час'
    },

    floors: {
      ru: 'Этажи',
      ua: 'Поверхи'
    },
    areaAll: {
      ru: 'Общая площадь',
      ua: 'Загальна площа'
    },
    areaLiv: {
      ru: 'Жилая площадь',
      ua: 'Житлова площа'
    },
    areaKit: {
      ru: 'Площадь кухни',
      ua: 'Площа кухні'
    },
    areaLand: {
      ru: 'Площадь участка',
      ua: 'Площа ділянки'
    },
    bedroomsCount: {
      ru: 'Спален',
      ua: 'Спалень'
    },
    bathroomsCount: {
      ru: 'Санузлов',
      ua: 'Санвузлів'
    },
    penthouse: {
      ru: 'Пентхаус',
      ua: 'Пентхаус'
    },
    appartments: {
      ru: 'Аппартаменты',
      ua: 'Апартаменти'
    },
    privatePatio: {
      ru: 'Личный дворик',
      ua: 'Особисте подвір\'я'
    },
    terrace: {
      ru: 'Терраса',
      ua: 'Тераса'
    },
    linkToAllObjects: {
      ru: 'Показать все планировки',
      ua: 'Показати всі планування'
    },

    tableDeadlineTopic1: {
      ru: 'Название элемента ЖК',
      ua: 'Назва елемента ЖК'
    },
    tableDeadlineTopic2: {
      ru: 'Обещанный квартал',
      ua: 'Обіцяний квартал'
    },
    tableDeadlineTopic3: {
      ru: 'Обещанный год',
      ua: 'Обіцяний рік'
    },
    tableDeadlineTopic4: {
      ru: 'Задержка сдачи',
      ua: 'Затримка здачі'
    },
    tableDeadlineTopic5: {
      ru: 'Сдали квартал',
      ua: 'Здали квартал'
    },
    tableDeadlineTopic6: {
      ru: 'Сдали год',
      ua: 'Здали рік'
    },
    tableDeadlineTopic7: {
      ru: 'Дата последней записи',
      ua: 'Дата останнього запису'
    },
    tableColDelayedDeliveryText: {
      ru: 'Задержка {{placeholderRU}}',
      ua: 'Затримка {{placeholderUA}}'
    },
    readyOnTimeText: {
      ru: 'Сдан в срок',
      ua: 'Зданий вчасно'
    },
    deadlineHasNotComeText: {
      ru: 'Срок сдачи не наступил',
      ua: 'Термін здачі не настав'
    },
    tableDeadlineAfterText: {
      ru: 'В таблице указаны сроки сдачи, которые были объявлены застройщиком первоначально.\nМы не изменяем первоначально объявленные сроки сдачи в этой таблице и показываем их для отслеживания задержек строительства.',
      ua: 'У таблиці вказані терміни здачі, які були первісно оголошені забудовником.\nМи не змінюємо первісно оголошені терміни здачі в цій таблиці і показуємо їх для відстеження затримок будівництва.'
    },

    expertText: {
      ru: 'Эксперт',
      ua: 'Експерт'
    },
    expertH2: {
      ru: 'Комментарии экспертов',
      ua: 'Коментарі експертів'
    },

    certificateOfReadinessText: {
      ru: 'Сертификат о готовности {{placeholder}}',
      ua: 'Сертифікат про готовність {{placeholder}}'
    },
    certificateOfReadinessH2: {
      ru: 'Сертификаты о сдаче',
      ua: 'Сертифікати про здачу'
    },
    discussLCinForum: {
      ru: 'Отзывы о {{displayName}} на форуме Domik.ua',
      ua: 'Відгуки про {{displayName}} на форумі Domik.ua'
    },
    altNames: {
      ru: 'Этот ЖК часто ищут по таким названиям: ',
      ua: 'Цей ЖК часто шукають за такими назвами: '
    },
    connect: {
      ru: 'Связаться',
      ua: 'Зв\'язатися'
    },
    connectConsult: {
      ru: 'Отдел продаж',
      ua: 'Відділ продажу'
    },
    questionMeeting: {
      ru: 'Вопрос, встреча',
      ua: 'Питання, зустріч'
    },
    questionMeetingTaxi: {
      ru: 'Вопрос, встреча, бесплатное такси от Bild.ua',
      ua: 'Питання, зустріч, безкоштовне таксі від Bild.ua'
    },
    backCall: {
      ru: 'Обратный звонок',
      ua: 'Зворотний дзвінок'
    },
    orderCallBack: {
      ru: 'Заказать обратный звонок',
      ua: 'Замовити зворотній дзвінок'
    },
    consultantContacts: {
      ru: 'Контакты консультанта',
      ua: 'Контакти консультанта'
    },
    contactToSeller: {
      ru: 'Связаться с отделом продаж',
      ua: 'Зв\'язатися з відділом продажів'
    },
    byPhone: {
      ru: 'вы можете по телефону:',
      ua: 'ви можете за телефоном:'
    },
    freeTaxiBildUa: {
      ru: 'Бесплатное такси от Bild.ua',
      ua: 'Безкоштовне таксі від Bild.ua'
    },

    btnCardSoldHide: {
      ru: 'Скрыть проданные планировки',
      ua: 'Сховати продані планування'
    },
    btnCardSoldShow: {
      ru: 'Показать проданные планировки',
      ua: 'Показати продані планування'
    },
    wantDiscount: {
      ru: 'Хочу скидку!',
      ua: 'Хочу знижку!'
    }
  };
}
