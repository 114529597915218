import Leaflet from 'leaflet';

export default function (layer, options) {
  if (layer instanceof Leaflet.Polygon) {
    return Leaflet.polygon(layer.getLatLngs(), options);
  }

  if (layer instanceof Leaflet.Polyline) {
    return Leaflet.polyline(layer.getLatLngs(), options);
  }
}
