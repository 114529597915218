import loadable from '@loadable/component';
import { ordersLC } from '../section-projects/orders';

const HorizontalCard = loadable(() =>
  import(/* webpackChunkName: "block-nblist-horCard" */ './newbuildsList/horizontalCards'));
const StocksView = loadable(() =>
  import(/* webpackChunkName: "block-nblist-stockView" */ './newbuildsList/stocksView'));
const StocksViewTile = loadable(() =>
  import(/* webpackChunkName: "block-nblist-stockViewTile" */ './newbuildsList/stocksViewTile'));
const StocksViewSlider = loadable(() =>
  import(/* webpackChunkName: "block-nblist-stockViewSlider" */ './newbuildsList/stocksViewSlider'));
const StocksViewTileSlider = loadable(() =>
  import(/* webpackChunkName: "block-nblist-stockViewTileSlider" */ './newbuildsList/stocksViewTileSlider'));
const StocksViewTileSliderWithConversions = loadable(() =>
  import(/* webpackChunkName: "block-nblist-stockViewTileSlider-WithConversions" */ './newbuildsList/stocksViewTileSliderWithConversions'));
const StocksViewSliderMediaAll = loadable(() =>
  import(/* webpackChunkName: "block-nblist-stockViewSliderMediaAll" */ './newbuildsList/stocksViewSliderMediaAll'));

const BannerBlockRenderer = loadable(() =>
  import(/* webpackChunkName: "block-banner" */ './ablock/ablock-renderer'));
const BView1 = loadable(() =>
  import(/* webpackChunkName: "block-banner-view1" */ './ablock/view1'));
const BView2 = loadable(() =>
  import(/* webpackChunkName: "block-banner-view2" */ './ablock/view2'));
const BView3 = loadable(() =>
  import(/* webpackChunkName: "block-banner-view3" */ './ablock/view3'));

const StaticTelegram = loadable(() =>
  import(/* webpackChunkName: "block-static-telegram" */ './static/banner-telegram'));

const StatisticSeo = loadable(() =>
  import(/* webpackChunkName: "block-statistics-seo" */ './seo/statistic-seo'));

const ContentBanner = loadable(() =>
  import(/* webpackChunkName: "content-banner" */ 'containers/content-block/blocks/content-banner'));

const UserAdvertList = loadable(() =>
  import(/* webpackChunkName: "block-userAdvert-topicView" */ 'containers/content-block/blocks/userAdvert/advertList'));

const nbOrders = [...ordersLC(), { id: ordersLC().length, value: 'Рандом', param: [['rand', 'asc']] }];

/**
 * Overrides and extends default block config from containers/content-block/config.js
 */
const cfg = {
  landingLink: {
  },
  newbuildsList: {
    provider: 'bild/newbuilds-list',
    controller: 'admin/content-blocks/bild/newbuilds-list',
    orders: nbOrders,
    views: {
      horizontalCards: {
        name: 'Горизонтальный список в виде карточек',
        component: HorizontalCard
      }
    }
  },
  nbStocksList: {
    provider: 'bild/newbuilds-stocks-list',
    controller: 'admin/content-blocks/bild/newbuilds-stocks-list',
    orders: nbOrders,
    views: {
      stocksView: {
        name: 'Вид "Акции"',
        component: StocksView
      },
      stocksViewT: {
        name: 'Вид "Акции плиткой"',
        component: StocksViewTile
      },
      stocksViewSlid: {
        name: 'Вид "Акции в слайдере"',
        component: StocksViewSlider
      },
      stocksViewTSlid: {
        name: 'Вид "Акции плиткой в слайдере"',
        component: StocksViewTileSlider
      },
      stocksViewTSlidWC: {
        name: 'Вид "Акции плиткой в слайдере с конверсией"',
        component: StocksViewTileSliderWithConversions
      },
      stocksViewSlidMA: {
        name: 'Вид "Акции плиткой в слайдере (для Старого Домика)"',
        component: StocksViewSliderMediaAll
      }
    }
  },
  bannerLcBlock: {
    provider: 'core/a-block/living-complex',
    controller: 'admin/content-blocks/bild/lc-block',
    orders: [],
    views: {
      view1Random: {
        name: 'Вид "Dark" - рандом',
        component: BannerBlockRenderer,
        props: { type: 'rand', view: 'dark', Block: BView1 }
      },
      view1Slider: {
        name: 'Вид "Dark" - Слайдер',
        component: BannerBlockRenderer,
        props: { type: 'slider', view: 'dark', Block: BView1 }
      },
      view2Random: {
        name: 'Вид "Yellow" - рандом',
        component: BannerBlockRenderer,
        props: { type: 'rand', view: 'yellow', Block: BView2 }
      },
      view2Slider: {
        name: 'Вид "Yellow" - Слайдер',
        component: BannerBlockRenderer,
        props: { type: 'slider', view: 'yellow', Block: BView2 }
      },
      view3Random: {
        name: 'Вид "Light" - рандом',
        component: BannerBlockRenderer,
        props: { type: 'rand', view: 'light', Block: BView3 }
      },
      view3Slider: {
        name: 'Вид "Light" - Слайдер',
        component: BannerBlockRenderer,
        props: { type: 'slider', view: 'light', Block: BView3 }
      },
    }
  },
  bannerList: {
    provider: 'common/region-banners/banner-list',
    controller: 'admin/content-blocks/banners/banner-list',
    orders: [],
    views: {
      default: {
        name: 'По умолчанию',
        component: ContentBanner
      }
    }
  },
  userAdvertList: {
    provider: 'user-advert/content-advert-list',
    controller: 'admin/content-blocks/user-advert/user-advert-list',
    orders: [],
    views: {
      topicView: {
        name: 'Вид "В темах"',
        component: UserAdvertList,
        props: { view: 'topic' }
      },
      postsView: {
        name: 'Вид "В постах"',
        component: UserAdvertList,
        props: { view: 'post' }
      }
    }
  },
  staticBlock: {
    views: {
      telegram: {
        name: 'Баннер Telegram',
        component: StaticTelegram
      },
      statisticSeo: {
        name: 'SEO',
        component: StatisticSeo
      }
    }
  }
};

export default cfg;
