import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import absoluteUrl from 'helpers/absoluteUrl';

import defaultLogoDomik from 'src-sites/domik/assets/logo-domik.jpg';
import defaultLogoForum from 'src-sites/forum/assets/logo-forum.jpg';
import defaultLogoBild from 'src-sites/bild/assets/logo-bild.jpg';
import defaultLogoBildcontrol from 'src-sites/bildcontrol/assets/logo-bildcontrol.jpg';
import defaultLogoA2 from 'src-sites/a2/assets/logo-a2.jpg';

const logoProject = {
  domik: defaultLogoDomik,
  forum: defaultLogoForum,
  bild: defaultLogoBild,
  bildcontrol: defaultLogoBildcontrol,
  a2: defaultLogoA2
};

/**
 * Render OpenGraph tag for image thumbnail
 */

class OpenGraph extends PureComponent {
  static propTypes = {
    imageId: PropTypes.string
  };

  render() {
    const { imageId } = this.props;
    const proj = __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__;
    const defaultImg = logoProject[proj];

    if (!defaultImg && !imageId) return null;

    const imageUrl = imageId ? `/images/thumb/full/${imageId}.jpg` : defaultImg;

    return (
      <Helmet>
        <meta property="og:image" content={absoluteUrl(imageUrl)} />
      </Helmet>
    );
  }
}

export default OpenGraph;
