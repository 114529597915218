import classNames from 'classnames';

export default {
  carousel: (isSlider, isThumbs) => classNames({
    carousel: true,
    'carousel-slider': isSlider,
    'carousel-thumbs': isThumbs
  }),

  wrapper: (isSlider, axis) => classNames({
    'thumbs-wrapper': !isSlider,
    'slider-wrapper': isSlider,
    'axis-horizontal': axis === 'horizontal',
    'axis-vertical': axis !== 'horizontal'
  }),

  slider: (isSlider, isFullScreen, isSwiping, isRow) => classNames({
    thumbs: !isSlider,
    slider: isSlider,
    fullScreen: isFullScreen,
    sliderRow: isRow,
    animated: !isSwiping
  }),

  item: (isSlider, selected) => classNames({
    thumbnail: !isSlider,
    slide: isSlider,
    selected
  }),

  arrowPrev: (disabled, fullScreen) => classNames({
    'control-arrow control-prev': true,
    'control-disabled': disabled,
    'control-fullScreen': fullScreen
  }),

  arrowNext: (disabled, fullScreen) => classNames({
    'control-arrow control-next': true,
    'control-disabled': disabled,
    'control-fullScreen': fullScreen
  }),

  dot: selected => classNames({
    dot: true,
    selected
  })
};
