import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';

const classes = new Bem('seoText');

export default class SeoText extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.string
  };

  render() {
    const { className, data } = this.props;
    if (!data) return null;

    return (
      <div {...classes({ extra: className })}>
        <span {...classes('text')} children={data} />
      </div>
    );
  }
}
