// import { Parser } from 'expr-eval';
import jexl from 'jexl';
import merge from 'lodash/merge';
import get from 'lodash/get';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import { getCurrentMonth } from 'helpers/formatDate';

/**
 * Async parse template use jexl expression parser
 *
 * @see https://www.npmjs.com/package/jexl
 *
 * @param template
 * @param data
 * @return {Promise<any>}
 */
export default function parseTemplate(template, data) {
  let parsedTemplate = (`${template || ''}`)
    .replace(/\{\{/g, '__CURLY_O__').replace(/\}\}/g, '__CURLY_C__');
  const regexp = /(\{(.*?)\})/g;
  const compilers = [];
  let res;

  while (res = regexp.exec(parsedTemplate)) { // eslint-disable-line
    const expr = res[2]
      .replace(/__CURLY_O__/g, '{').replace(/__CURLY_C__/g, '}');
    const placeholder = res[1];
    compilers.push(
      jexl.eval(expr, data)
        .then(expRes => ({ placeholder, expRes }))
        .catch((err) => {
          console.error('EXPR PARSER ERROR', err, expr, data); // eslint-disable-line
          return { placeholder, expRes: '' };
        }));
  }
  return Promise.all(compilers).then(
    (expRes) => {
      expRes.forEach(val => parsedTemplate = parsedTemplate.replace(val.placeholder, val.expRes));
      return parsedTemplate;
    });
}

export function parseTemplateSync(template, data, singleRaw = false) {
  const parsedTemplate = (`${template || ''}`)
    .replace(/\{\{/g, '__CURLY_O__').replace(/\}\}/g, '__CURLY_C__');

  let processedTemplate = parsedTemplate;
  const regexp = /(\{(.*?)\})/g;
  let res;

  while (res = regexp.exec(parsedTemplate)) { // eslint-disable-line
    const expr = res[2]
      .replace(/__CURLY_O__/g, '{').replace(/__CURLY_C__/g, '}');
    const placeholder = res[1];
    const exprParsed = cloneDeep(jexl.evalSync(expr, data));
    if (singleRaw) {
      return exprParsed;
    }
    processedTemplate = processedTemplate.replace(placeholder, exprParsed);
  }
  return processedTemplate;
}

export function rawEvaluate(expr, data) {
  if (!expr) return null;
  return jexl.evalSync(expr, data);
}

/**
 *
 * Template helper functions (transforms)
 *
 */

/**
 * String to lower case
 * Usage: valiable|lower
 */
jexl.addTransform('lower', val => String(val).toLocaleLowerCase());

jexl.addTransform('ucFirst', val => String(val)[0].toLocaleUpperCase()+String(val).substring(1));

/**
 * CASE transform. Usage:
 * variable|case('case1', 'value1', ..., 'caseN', 'valueN', 'defaultValue')
 *
 * Returns 'Value' when variable is equal to 'caseN' or
 * 'defaultValue' when variable not equal any case
 */
jexl.addTransform('case', (val, ...args) => {
  for (let i = 0; i < args.length; i += 2) {
    if (val === args[i]) {
      return args[i + 1];
    }
  }
  return args.pop();
});

/**
 * JSON encode transform
 */
jexl.addTransform('jsonEncode', val => JSON.stringify(val));

/**
 * Recursive merge objects
 */
jexl.addTransform('merge', (obj1, obj2) => merge(obj1 || {}, obj2 || {}));

/**
 * Transform returns current year
 */
jexl.addTransform('currentYear', (() => (new Date()).getFullYear()));

/**
 * Transform returns current month in human's format
 */
jexl.addTransform('currentMonth', ((val, format = 'f1', lang) => (getCurrentMonth(format, lang))));

/**
 * Format numbers with spaces
 */
jexl.addTransform('numberWithSpaces', (val => (String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ' '))));

/**
 * Safe get value from potentially undefined variable
 */
jexl.addTransform('get', ((val, path) => get(val, path)));

/**
 * Lodash find() binding
 */
jexl.addTransform('find', ((arr, criteria) => find(arr, criteria)));

/**
 * Number by value description
 */
jexl.addTransform('numDesc', ((val, sVal, mVal1, mVal2) => {
  if (Math.round(val) != val) {
    return `${val} ${mVal1}`;
  }
  const n = Math.abs(val) % 100;
  const n1 = val % 10;
  if (n > 10 && n < 20) { return `${val} ${mVal2}`; }
  if (n1 > 1 && n1 < 5) { return `${val} ${mVal1}`; }
  if (n1 === 1) { return `${val} ${sVal}`; }
  return `${val} ${mVal2}`;
}));


// const parser = new Parser();

/**
 * Function returns a lowercase of the string
 *
 * @param arg
 * @return {*}
 */
/* parser.functions.lower = function (arg) {
  if (typeof arg === 'string') {
    return arg.toLocaleLowerCase();
  }
  return arg;
}; */

/**
 * Returns first non-empty argument
 * @param arg
 * @return any
 */
/* parser.functions.coalesce = function (...arg) {
  for (let i = 0; i < arg.length; i += 1) {
    if (arg[i]) {
      return arg[i];
    }
  }
};

parser.functions._arrayValue = function (arr, idx) {
  console.log(arr, idx, arr[idx]);
  return { a: 10 };// arr[idx];
};
*/

/**
 * DEPRECATED
 * Parse template string with variables (expressions)
 * Template string is similar of:
 * 'the string with the {data.var1} and '{coalesce(data.var2, data.var3)}'
 * For expressions evaluation was used this:
 *   @see https://github.com/silentmatt/expr-eval
 *
 * @param template
 * @param data
 */
/* function _parseTemplate(template, data) {
  let parsedTemplate = template;
  const regexp = /(\{(.*?)\})/g;
  let res;

  while (res = regexp.exec(parsedTemplate)) {
    const expr = res[2];
    const placeholder = res[1];
    const expRes = parser.evaluate(expr, data);
    parsedTemplate = parsedTemplate.replace(placeholder, expRes);
  }

  return parsedTemplate;
} */
