import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TileLayer, LayersControl, LayerGroup } from 'react-leaflet';
import map from 'lodash/map';
import { I18nHoc } from 'helpers/i18n';
import LayerSwitch from './leaflet-layerswitch';

import translates from './map-i18n.json';

export function OSMLayer(props = {}) {
  return (
    <TileLayer
      attribution='&copy; <a href="http://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>'
      geocoder="nominatim"
      url="http://c.tile.openstreetmap.org/{z}/{x}/{y}.png"
      {...props}
    />
  );
}

export function GoogleLayer(props = {}) {
  const mapTypes = {
    streets: 'm',
    satellite: 's',
    hybrid: 's,h',
    terrain: 'p'
  };
  const { maptype = 'streets' } = props; // eslint-disable-line
  return (
    <TileLayer
      attribution='&copy; <a href="http://www.google.com/" target="_blank">Google</a>'
      url={`//{s}.google.com/vt/lyrs=${mapTypes[maptype]}&x={x}&y={y}&z={z}`}
      geocoder="google"
      subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
      {...props}
    />
  );
}

export function VisicomLayer(props = {}) {
  return (
    <TileLayer
      attribution='&copy; <a href="http://visicom.ua/" target="_blank">Visicom</a>'
      url={`//{s}.visicom.ua/2.0.0/planet3/base_ru/{z}/{x}/{y}.png`} // eslint-disable-line
      subdomains={['tms0', 'tms1', 'tms2', 'tms3']}
      geocoder="visicom"
      tms={true} // eslint-disable-line
      {...props}
    />);
}

/**
 * Base layers for user/frontoffice functionality
 * @param props
 * @constructor
 */
@I18nHoc(translates)
export class BaseLayersUser extends Component {
  static propTypes = {
    positionLayerSwitch: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    advancedButtons: PropTypes.shape({})
  };

  render() {
    const { i18n } = this;
    const { positionLayerSwitch, advancedButtons } = this.props;

    if (!positionLayerSwitch) return <GoogleLayer key="gs" maptype="streets" />;

    return (
      <LayerSwitch position={positionLayerSwitch} advancedButtons={advancedButtons}>
        <LayersControl.BaseLayer name={i18n('map')} checked>
          <GoogleLayer
            key="gs"
            maptype="streets"
            maxNativeZoom={22}
            maxZoom={23}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name={i18n('mapSatellite')}>
          <GoogleLayer
            key="gsat"
            maptype="hybrid"
            maxNativeZoom={20}
            maxZoom={23}
          />
        </LayersControl.BaseLayer>
      </LayerSwitch>
    );
  }
}

/**
 * Base layers for administrative/GIS functionality
 * @param props
 * @return {XML}
 * @constructor
 */
export function BaseLayers(props = {}) {
  const { children, defLayer = 'gstreet', overlays = [], ...rest } = props; // eslint-disable-line

  return (
    <LayersControl key="blCtl" position="topright">
      <LayersControl.BaseLayer name="Google Streets" checked={defLayer === 'gstreet' && true}>
        <GoogleLayer
          key="gst"
          maptype="streets"
          maxNativeZoom={22}
          {...rest}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Google Sat" checked={defLayer === 'gsat' && true}>
        <GoogleLayer
          key="gsat"
          maptype="satellite"
          maxNativeZoom={20}
          {...rest}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Google Hybrid" checked={defLayer === 'gh' && true}>
        <GoogleLayer
          key="gh"
          maptype="hybrid"
          maxNativeZoom={20}
          {...rest}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Google Terrain" checked={defLayer === 'gt' && true}>
        <GoogleLayer
          key="gt"
          maptype="terrain"
          maxNativeZoom={22}
          {...rest}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="OpenStreetMap" checked={defLayer === 'osm' && true}>
        <OSMLayer
          key="osm"
          maxNativeZoom={19}
          {...rest}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Visicom Ukraine" checked={defLayer === 'visicom' && true}>
        <VisicomLayer
          key="visicom"
          maxNativeZoom={19}
          {...rest}
        />
      </LayersControl.BaseLayer>
      {map(overlays, (val, key) => (
        <LayersControl.Overlay
          key={key}
          name={val.props.name}
          checked={typeof val.props.checked !== 'undefined' ? val.props.checked : true}
        >
          <LayerGroup>
            {val}
          </LayerGroup>
        </LayersControl.Overlay>
      ))}
      {children}
    </LayersControl>
  );
}

/*
<LayersControl.BaseLayer checked name="Yandex Карта">
  <YandexLayer maptype={YANDEX_MAP} />
</LayersControl.BaseLayer>
<LayersControl.BaseLayer name="Yandex Спутник">
  <YandexLayer maptype={YANDEX_SAT} />
  </LayersControl.BaseLayer>
  <LayersControl.BaseLayer name="Yandex Гибрид">
  <LayerGroup>
  <YandexLayer maptype={YANDEX_SAT} />
  <YandexLayer maptype={YANDEX_HYBRID} />
  </LayerGroup>
  </LayersControl.BaseLayer>
*/
