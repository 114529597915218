/* eslint-disable */
import React, { cloneElement, Children } from "react";
import PropTypes from 'prop-types';
import Leaflet, {Layer} from 'leaflet';
import { ExtendableLayersControl } from 'react-leaflet-extendable';
import { withLeaflet } from 'react-leaflet';
import each from 'lodash/each';
import './leaflet-switch';

/**
 * For more information and available options list
 * @see https://github.com/ppete2/Leaflet.PolylineMeasure
 */
class LayerSwitch extends ExtendableLayersControl {
  static propTypes = {
    position: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['topleft', 'topright', 'bottomleft', 'bottomright'])
    ]),
    advancedButtons: PropTypes.shape({})
  };

  static defaultProps = {
    position: 'topright',
  };

  createLeafletElement(props) {
    this.buttons = {};
    this.maxId = 1;
    this.selectedLayer = null;

    const control = new Leaflet.Control.ButtonSwitchControl({
      position: props.position,
      onSelect: (val, key) => {
        if (val.onClick) {
          val.onClick(val, key);
          return;
        }
        if (this.selectedLayer) {
          props.leaflet.map.removeLayer(this.selectedLayer);
        }
        props.leaflet.map.addLayer(val.layer);
      },
      buttons: {}
    });
    return control;
  }

  updateLeafletElement(fromProps, toProps) {
    this.leafletElement.updateButtons({ ...this.buttons, ...this.props.advancedButtons });
    super.updateLeafletElement(fromProps, toProps);
  }

  addBaseLayer(layer, name, checked = false) {
    this.buttons[`layer${this.maxId}`] = {
      id: `layer${this.maxId}`,
      label: name,
      checked,
      layer
    };
    this.maxId += 1;

    if (checked) {
      this.props.leaflet.map.addLayer(layer);
      this.selectedLayer = layer;
    }

    this.leafletElement.updateButtons({ ...this.buttons, ...this.props.advancedButtons });
  }

  addOverlay(layer, name, checked = false) {
    if (checked) {
      this.props.leaflet.map.addLayer(layer);
    }
    /* this.leafletElement.addOverlay(layer, name) */
  }

  removeLayer(layer) {
    this.props.leaflet.map.removeLayer(layer);
  }

  removeLayerControl(layer) {
    each(this.buttons, (val, key) => {
      if (val.layer === layer) {
        delete this.buttons[key];
      }
    });
  }
}

export default withLeaflet(LayerSwitch);
