import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Dots extends PureComponent {
  static propTypes = {
    clickHandler: PropTypes.func,
    customPaging: PropTypes.func,
    dotsClass: PropTypes.string,
    currentSlide: PropTypes.number,
    slideCount: PropTypes.number,
    slidesToScroll: PropTypes.number,
    slidesToShow: PropTypes.number,
    infinite: PropTypes.bool,
    allowDotsClick: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func
  };

  static getDotCount(spec) {
    if (spec.infinite) return Math.ceil(spec.slideCount / spec.slidesToScroll);
    return Math.ceil((spec.slideCount - spec.slidesToShow) / spec.slidesToScroll) + 1;
  }

  constructor(...args) {
    super(...args);

    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(options, e) {
    // In Autoplay the focus stays on clicked button even after transition
    // to next slide. That only goes away by click somewhere outside
    e.preventDefault();
    this.props.clickHandler(options);
  }

  renderDots() {
    const {
      slideCount, slidesToScroll, slidesToShow, infinite, allowDotsClick,
      currentSlide, customPaging
    } = this.props;
    const dotCount = Dots.getDotCount({
      slideCount,
      slidesToScroll,
      slidesToShow,
      infinite
    });

    // Apply join & split to Array to pre-fill it for IE8
    //
    // Credit: http://stackoverflow.com/a/13735425/1849458
    return [
      ...Array(dotCount + 1)
        .join('0')
        .split('')
    ].map((x, i) => {
      const leftBound = i * slidesToScroll;
      const rightBound = (i * slidesToScroll) + (slidesToScroll - 1);
      const className = classnames({
        ' slick-active': currentSlide >= leftBound && currentSlide <= rightBound
      });

      const dotOptions = {
        message: 'dots',
        index: i,
        slidesToScroll,
        currentSlide
      };

      // TODO: implement unclickable dots
      return (
        <li key={i} className={`slick-dot${className}` || 'slick-dot'}>
          {allowDotsClick &&
            customPaging(e => this.clickHandler(dotOptions, e), i)
          }
        </li>
      );
    });
  }

  render() {
    const { dotsClass, onMouseEnter, onMouseOver, onMouseLeave } = this.props;

    return (
      <ul
        className={dotsClass}
        onMouseEnter={onMouseEnter}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        children={this.renderDots()}
      />
    );
  }
}
