import get from 'lodash/get';

import srcDefault from './data/unnamed.jpg';
import srcDefaultForum from './data/unnamed-forum.jpg';

const defaultProject = {
  bild: srcDefault,
  forum: srcDefaultForum
};

export default function getUnnamedImg() {
  const proj = __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__;
  return get(defaultProject, `[${proj}]`, srcDefault);
}
