import notificationTranslates from 'components/notification/notification-i18n.json';

export default function () {
  return {
    ...notificationTranslates,

    errorSave: {
      ru: 'Ошибка сохранения',
      ua: 'Помилка збереження'
    },
    errorDataLoading: {
      ru: 'Ошибка загрузки данных',
      ua: 'Помилка завантаження даних'
    },
    saved: {
      ru: 'Сохранено',
      ua: 'Збережено'
    },
    deletedData: {
      ru: 'Данные удалены',
      ua: 'Дані видалені'
    },
    deleting: {
      ru: 'Удаление',
      ua: 'Видалення'
    },
    deleteAreYouSure: {
      ru: 'Вы уверены, что хотите удалить запись?',
      ua: 'Ви впевнені, що хочете видалити запис?'
    },
    deleteAreYouExactlySure: {
      ru: 'Вы ТОЧНО уверены? Это действие необратимо!!! Проверьте, что вы собираетесь удалить, прежде чем делать эту операцию.',
      ua: 'Ви ТОЧНО упевнені? Ця дія необоротна! Перевірте, що ви збираєтеся видалити, перш ніж робити цю операцію.'
    }
  };
}
