import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Button from 'components/button';
import Link from 'components/link';

const btnClasses = new Bem('tabJSButton');

class TabJSButton extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    active: PropTypes.bool,
    onFocus: PropTypes.func,
    onTouchStart: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    linkMode: PropTypes.bool
  };

  render() {
    const {
      active, className, children, linkMode,
      onFocus, onTouchStart, onTouchEnd, onMouseDown, onMouseUp
    } = this.props;

    if (linkMode) {
      const linkFullProps = {
        ...btnClasses(null, { active }, className),
        ...children.linkProps,
        tabIndex: 0,
        addEvents: {
          onFocus,
          onTouchStart,
          onTouchEnd,
          onMouseDown,
          onMouseUp
        }
      };

      return (
        <Link {...linkFullProps}>
          <span {...btnClasses('content')}>
            <span {...btnClasses('text')}>
              <span children={children.title} />
            </span>
          </span>
        </Link>
      );
    }

    if (typeof children !== 'string') {
      return (
        <div
          {...btnClasses(null, { active }, className)}
          role="button"
          tabIndex="0"
          onFocus={onFocus}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        >
          {children.type === 'span' ?
            <span {...btnClasses('content')}>
              <span {...btnClasses('text')} children={children} />
            </span> :
            children
          }
        </div>
      );
    }

    return (
      <Button
        {...btnClasses(null, { active }, className)}
        tabIndex="0"
        onFocus={onFocus}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        label={children}
      />
    );
  }
}

export default TabJSButton;
