import React, { PureComponent } from 'react';
import { authConnect } from 'auth';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import localStorage from 'helpers/local-storage';
import { subscribe, unsubscribe, emit } from 'helpers/global-events';
import apiDecorator from 'api/api-decorator';
import { reportError } from 'api/api-errors';
import Button, { btnViews } from 'components/button';
import { iconTypes } from 'components/icon';
import InteractiveComponent from 'components/interactive-fly-component';
import Notification from 'components/notification';
import { counterDecorator } from 'subsys/counter';

import translates from './favorites-i18n.json';

const bemClasses = new Bem('clubBlock');
const bemClassesMessage = new Bem('favoritesMessage');

/**
 *
 * === Favorites button ===
 *
 * = Example =

 import { FavoritesButton, FavoriteWrapper } from 'subsys/favorites';

   <FavoriteWrapper
     project="forum"
     entity="publication"
   >
     <FavoritesButton
       project={this.props.project}
       entity={this.props.entity}
       entityId={this.props.params.id}
       subgroup={this.props.subgroup}
       inFavorites={!!dataObj.favorites}
     />
   </FavoriteWrapper>
 */

@authConnect()
@counterDecorator()
@apiDecorator
@I18nHoc(translates)
export default class FavoritesButton extends PureComponent {
  static propTypes = {
    project: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    entityId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired,
    subgroup: PropTypes.string.isRequired,
    inFavorites: PropTypes.bool,
    hideLabel: PropTypes.bool,
    updateSimilar: PropTypes.bool,
    auth: PropTypes.shape({
      authenticated: PropTypes.bool
    }),
    actionDoLogin: PropTypes.func,
    className: PropTypes.string,
    classMods: PropTypes.shape({}),
    renderNotification: PropTypes.shape({})
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.favoritesClick = this.favoritesClick.bind(this);
    this.updateState = this.updateState.bind(this);

    this.state = {};

    if (props.updateSimilar) {
      this.updateEvent = `favorites_${props.subgroup}`;
      subscribe(this.updateEvent, this.updateState);
    }
  }

  static getDerivedStateFromProps(props) {
    return { inFavorites: !!props.inFavorites }
  }

  componentWillUnmount() {
    if (this.props.updateSimilar) {
      unsubscribe(this.updateEvent, this.updateState);
    }
  }

  updateState() {
    this.setState(state => ({ inFavorites: !state.inFavorites }));
  }

  favoriteData(pushObj) {
    const favStorage = localStorage.get('favorites') || [];
    const notificationFavorite = localStorage.get('notificationFavorite');

    const index = findIndex(favStorage, pushObj);

    return { favStorage, index, notificationFavorite };
  }

  favoritesClick() {
    const { i18n } = this;
    const { auth = {}, project, entity, entityId, subgroup, counterProps = {}, countAction } = this.props;
    const { inFavorites } = this.state;
    const pushObj = { project, entity, entity_id: entityId, subgroup };

    if (
      !inFavorites &&
      counterProps.action &&
      counterProps.entity &&
      typeof countAction === 'function'
    ) {
      countAction(
        counterProps.entity,
        counterProps.action
      );
    }

    if (auth.authenticated) {
      return this.api.post('action/domik/actions-favorites/add', {
        postData: pushObj
      })
        .then((data) => {
          const message = data.message === 'add' ? i18n('favouritesAdded') : i18n('favouritesRemoved');
          Notification.success(message);
          // emit(this.updateEvent);
          // emit('updateFavorites');
          emit('countFavoritesProvider');
          if (!this.props.updateSimilar) this.updateState();
        })
        .catch(error => reportError(error));
    }

    const { favStorage, index, notificationFavorite } = this.favoriteData(pushObj);

    if (index !== -1) {
      favStorage.splice(index, 1);
    } else {
      favStorage.push(pushObj);
    }

    localStorage.emit('favorites', favStorage);

    if (!this.props.updateSimilar) this.updateState();
    if (!notificationFavorite) {
      Notification.success(this.renderNotification(), null, 7);
      localStorage.emit('notificationFavorite', true);
    }
    emit('favoriteCount');
  }

  renderNotification() {
    if (this.props.renderNotification) return this.props.renderNotification;

    const { i18n } = this;

    return (
      <div {...bemClassesMessage()}>
        <div {...bemClassesMessage('header')}>
          <span {...bemClassesMessage('text')} children={i18n('notificationHeader')} />
        </div>
        <div {...bemClassesMessage('content')}>
          <p {...bemClassesMessage('text')} children={i18n('notificationText1')} />
          <p {...bemClassesMessage('text')}>
            {i18n('notificationText2')}
            <Button
              {...bemClassesMessage('btn', 'auth')}
              onClick={this.props.actionDoLogin}
              label={i18n('auth')}
            />
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { i18n } = this;
    const { inFavorites } = this.state;
    const { className, classMods, subgroup, hideLabel } = this.props;
    let label = inFavorites ? i18n('favouritesRemove') : i18n('favouritesAdd');
    let title = label;
    if (subgroup === 'forum_forum') {
      title = inFavorites ? 'Удалить форум из избранного' : 'Добавить форум в избранное';
      label = inFavorites ? i18n('favouritesRemoveLight') : i18n('favouritesAddLight');
    }
    if (subgroup === 'forum_topic') {
      title = inFavorites ? 'Удалить тему из избранного' : 'Добавить тему в избранное';
      label = inFavorites ? i18n('favouritesRemoveLight') : i18n('favouritesAddLight');
    }

    return (
      <InteractiveComponent
        {...bemClasses('btn', { favorites: true, ...classMods }, className)}
        iconType={iconTypes.favoritesBorder}
        newIconType={iconTypes.favorites}
        destinationPoint="favoritesIcon"
        active={inFavorites}
      >
        <Button
          label={hideLabel ? null : label}
          title={title}
          iconType={inFavorites ? iconTypes.favorites : iconTypes.favoritesBorder}
          dataView={btnViews.favorites}
          onClick={this.favoritesClick}
        />
      </InteractiveComponent>
    );
  }
}
