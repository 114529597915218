/* eslint-disable */
import React, { Component } from 'react';

/**
 * Container for form inputs
 * Implemented for performance optimization
 */
class FormInputContainer extends Component {
  constructor(props, ctx) {
    super(props, ctx);
    const { _form, dataBind } = props;
    this.state = {
      value: _form._getValue(_form.data, dataBind),
      validationState: props._form.validationStates[props.dataBind]
    };
    props._form.registerInputIdx(props.dataBind, this);
  }

  static getDerivedStateFromProps(props) {
    const { _form, dataBind } = props;
    return {
      value: _form._getValue(_form.data, dataBind),
      validationState: _form.validationStates[dataBind] || {}
    };
  }

  componentWillUnmount() {
    this.props._form.unregisterInputIdx(this.props.dataBind, this);
  }

  update(value, validationState) {
    this.setState({
      value,
      validationState
    });
  }

  render() {
    const {
      element,
      children,
      ...props
    } = this.props;

    const form = this.props._form;

    const ce = React.cloneElement(element, {
      ...props,
      value: this.state.value,
      validationState: this.state.validationState,
    }, form.renderChildren(
      children,
      form.data || {},
      form.state.schema,
      form.validationStates
    ));
    return ce;
  }
}

export default FormInputContainer;
