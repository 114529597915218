import React, { PureComponent } from 'react';

/**
 * Simple helper for dynamically component load
 * Works only in browser, server rendering doesn't support
 *
 * @param loader
 * @return {{
 * EVENT_VISIBLE_CHANGED: string,
 * new(template?: string): Loadable,
 * EVENT_DESTROYED: string,
 * addEventListener: {
 * (eventType: string, listener: Function, async?: boolean): void,
 * (eventType: string, listener: Function, async?: boolean): void
 * },
 * removeEventListener: {
 * (eventType: string, listener: Function, async?: boolean): void,
 * (eventType: string, listener: Function, async?: boolean): void
 * },
 * getGui: {(): HTMLElement, (): HTMLElement},
 * destroy?: {(): void, (): void},
 * afterGuiAttached?: {
 * (params?: IAfterGuiAttachedParams): void,
 * (params?: IAfterGuiAttachedParams): void
 * },
 * init?: {(params: any): (Promise<void> | void), (params: any): (Promise<void> | void)},
 * new(template?: string): Loadable,
 * new(jodit?: (IViewBased | Jodit)): Loadable
 * }}
 */
export default function loadable(loader, config = {}) {
  return class Loadable extends PureComponent {
    constructor(props, ctx) {
      super(props, ctx);
      this.component = null;
    }

    componentDidMount() {
      loader().then((component) => {
        this.component = component.default || component;
        this.forceUpdate();
      });
    }

    render() {
      if (!this.component) {
        if (config.noDisplayLoading) {
          return null;
        }
        return 'Loading...';
      }
      return <this.component {...this.props} />;
    }
  };
}
