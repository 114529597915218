import React, { PureComponent, Children } from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-addons-css-transition-group';
import join from 'lodash/join';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import size from 'lodash/size';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import { I18nHoc } from 'helpers/i18n';
import { emit, subscribe, unsubscribe } from 'helpers/global-events';
import { Maps, BaseLayersUser, InfoLayer } from 'containers/maps';
import sensor from 'components/sensor';
import Image, { Thumb } from 'components/image';
import Slick from 'components/slick-slider';
import Icon, { iconTypes } from 'components/icon';
import Button from 'components/button';

import { mergePrice } from 'src-sites/bild/section-project/render-common';

import translates from './layouts-i18n.json';

const subwayStation = 'subway_station';

@sensor
@I18nHoc(translates)
export default class AbstractObjectTile extends PureComponent {
  static propTypes = {
    object: PropTypes.shape({
      $entity: PropTypes.string,
      geometry: PropTypes.any, // eslint-disable-line
      date_add: PropTypes.string,
      header: PropTypes.string,
      id: PropTypes.number,
      location_path: PropTypes.string,
      owner: PropTypes.shape({
        image: PropTypes.shape({
          id: PropTypes.string
        }),
        name: PropTypes.string,
        phone: PropTypes.string
      }),
      paramsShortText: PropTypes.string,
      price: PropTypes.shape({
        altClass: PropTypes.string,
        altClassName: PropTypes.string,
        className: PropTypes.string,
        commission: PropTypes.any, // eslint-disable-line
        currency: PropTypes.string,
        mainPrice: PropTypes.number,
        symbol: PropTypes.string
      }),
      title_image: PropTypes.shape({
        id: PropTypes.string
      }),
      url: PropTypes.string,
      action: PropTypes.bool,
      toponym: PropTypes.shape({
        name: PropTypes.string,
        pedestrain: PropTypes.number,
        metatype: PropTypes.string,
        type: PropTypes.string
      }),
      imagesCount: PropTypes.number,
      images: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string
          })
        )
      ])
    }),
    onHover: PropTypes.func,
    onUnhover: PropTypes.func,
    drawMarkerOnPhone: PropTypes.func,
    device: PropTypes.oneOf(['desktop', 'tablet', 'phone']),
    noHover: PropTypes.bool,
    checkForEmptyData: PropTypes.func
  };

  static getImages(images, otherPropsImg = {}) {
    const singleString = typeof images === 'string';
    const imagesIsArray = isArray(images);
    const imageObj = imagesIsArray ? images[0] && !!images[0].id : images && !!images.id;

    let titleImage = imagesIsArray && images[0] ? images[0] : { id: null };
    if (singleString) titleImage = { id: images || null };
    if (imageObj) titleImage = imagesIsArray ? images[0] : images;
    titleImage = {
      ...titleImage,
      alt: otherPropsImg.alt || '',
      title: otherPropsImg.title || null,
      renderer: titleImage.renderer || 'orig'
    };

    return {
      titleImage,
      singleString,
      imageObj,
      imagesIsArray
    };
  }

  static renderSingleChild(props) {
    const children = Children.toArray(props.children);
    return children[0] || null;
  }

  static getDerivedStateFromProps(props, state) {
    if ( !state.noHover &&  state.showOnMap && props.device === 'desktop') {
      if (props.onUnhover) {
        return {
          objectToShowOnMap: null,
          showOnMap: false
        };
      }
      return {objectToShowOnMap: null};
    }
    return null;
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.renderImage = this.renderImage.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderSideBlock = this.renderSideBlock.bind(this);
    this.renderUnderneath = this.renderUnderneath.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleUnhover = this.handleUnhover.bind(this);
    this.disableShowOnMap = this.disableShowOnMap.bind(this);
    this.toggleShowOnMap = this.toggleShowOnMap.bind(this);
    this.toggleShowOnMapFromMap = this.toggleShowOnMapFromMap.bind(this);
    this.openImageModal = this.openImageModal.bind(this);

    this.state = {
      clientSide: false,
      phone: false,
      showOnMap: false,
      imageModal: false,
      noHover: !!props.noHover
    };

    this.hoverLayerRef = null;
    // this.tileRef = null;
    this.tileHeight = 0;
    this.imagesKey = null;
    this.notObjectKey = null;

    if (!this.state.noHover) {
      subscribe('disableShowOnMap', this.disableShowOnMap);
      subscribe('toggleTileShowOnMap', this.toggleShowOnMap);
      subscribe('toggleTileShowOnMapFromMap', this.toggleShowOnMapFromMap);
    }
  }

  componentDidMount() {
    this.setState({ clientSide: true }); // eslint-disable-line
  }

  componentDidUpdate({ device }) {
    if (
      !this.state.noHover &&
      this.state.showOnMap &&
      (
        // переход с телефона на планшет
        (this.props.device === 'tablet' && device === 'phone') ||
        // переход с планшета на телефон
        (this.props.device === 'phone' && device === 'tablet')
      )
    ) {
      setTimeout(() => this.handleHover(null, true), 0);
    }
  }

  componentWillUnmount() {
    if (!this.state.noHover) {
      unsubscribe('disableShowOnMap', this.disableShowOnMap);
      unsubscribe('toggleTileShowOnMap', this.toggleShowOnMap);
      unsubscribe('toggleTileShowOnMapFromMap', this.toggleShowOnMapFromMap);
    }
  }

  // Subways
  getSubways(subways, bemClasses) {
    let text = subways;
    const createTitle = array => (
      join(map(array, metro => metro.name), ', ')
    );

    if (isArray(text)) {
      text = createTitle(subways);
    }

    return <span {...bemClasses('text')} children={text} />;
  }

  // Prices
  getPrices(priceData, bemClasses, addContent = null) {
    const price = mergePrice({ price: priceData });

    if (!price.mainPrice) return null;

    return (
      <div {...bemClasses('price')}>
        <span {...bemClasses('text')}>
          {`${price.label || price.symbol} ${price.mainPrice} ${price.className}`}
        </span>
        {addContent}
      </div>
    );
  }

  defineObject({ $entity }) {
    return $entity !== this.notObjectKey;
  }

  checkForEmptyData(data) {
    if (this.props.checkForEmptyData) return this.props.checkForEmptyData(data);
    return false;
  }

  disableShowOnMap() {
    const { clientSide } = this.state;
    if (clientSide && this.props.device !== 'desktop' && this.state.showOnMap) {
      // if (clientSide && this.state.showOnMap) {
      this.setState({ showOnMap: false, objectToShowOnMap: null });
    }
  }

  toggleShowOnMapFromMap(obj) {
    if (!obj) return;
    const sameObj = obj.id === this.props.object.id;
    this.setState({ showOnMap: sameObj, objectToShowOnMap: sameObj ? obj : null });
  }

  toggleShowOnMap(obj) {
    const { clientSide } = this.state;
    this.setState({ objectToShowOnMap: obj });
    if (clientSide && this.props.device !== 'desktop' && obj && obj.id) {
      if (obj.id === this.props.object.id) {
        if (!this.state.showOnMap) {
          this.setState({ showOnMap: true });
        }
      } else if (this.state.showOnMap) {
        this.setState({ showOnMap: false });
      }
    }
  }

  handleHover(e, doAction = false) {
    if (this.state.noHover) return false;

    let obj = this.props.object;
    const price = mergePrice({ price: obj.min_price || obj.price });
    obj = { ...obj, price };

    if (this.props.device === 'phone') {
      setTimeout(() => {
        if (this.hoverLayerRef) {
          this.hoverLayerRef.setData({
            type: 'Feature',
            properties: {
              price: `${price.symbol} ${price.mainPriceK}`
            },
            geometry: obj.geometry || obj.geo
          });
        }
      }, 0);
      return;
    }

    if ((this.props.device === 'desktop' || doAction) && this.props.onHover) {
      this.props.onHover(obj);
    }
  }

  handleUnhover(e, doAction = false) {
    if (this.state.noHover) return false;

    if ((this.props.device === 'desktop' || doAction) && this.props.onUnhover) {
      this.props.onUnhover();
      this.setState({ showOnMap: false });
    }
  }

  openImageModal() {
    this.setState(prevState => ({ imageModal: !prevState.imageModal }));
  }

  // Show on map button
  showOnMapBtn(bemClasses) {
    const { object = {} } = this.props;
    if (
      this.state.noHover ||
      this.props.device === 'desktop' ||
      (object && object.id && !(object.geometry || object.geo))
    ) return null;

    const notPhone = this.props.device !== 'phone';
    const showOnMapButton =  this.props.object.id === get(this, 'state.objectToShowOnMap.id');

    return (
      <Button
        data-test="map3"
        {...bemClasses('btn', { location: true, active: showOnMapButton })}
        iconType={iconTypes.locationFixed}
        onClick={() => {
          if (!this.state.showOnMap) {
            emit('toggleTileShowOnMap', this.props.object, true);
            // if (this.tileRef) this.tileHeight = this.tileRef.clientHeight;
            this.handleHover(null, notPhone);
          } else {
            emit('toggleTileShowOnMap');
            this.handleUnhover(null, true);
          }
        }}
        iconOnly
      />
    );
  }

  renderImageModal() {
    return null;
  }

  renderContentBottom(data = {}, bemClasses) {
    const { paramsShortText, bed_places: bedPlaces } = data;

    return (
      <div {...bemClasses('dataBottom')}>
        {paramsShortText &&
          <div {...bemClasses('paramsText')}>
            <span
              {...bemClasses('text')}
              dangerouslySetInnerHTML={{ __html: `${paramsShortText}` }} // eslint-disable-line
            />
            {bedPlaces && <span {...bemClasses('text')}>,&ensp;</span>}
          </div>
        }
        {bedPlaces &&
          <span {...bemClasses('bed')}>
            <span {...bemClasses('text')}><Icon {...bemClasses('icon', 'bed')} type={iconTypes.bed} />{`\u00a0${bedPlaces}`}</span>
          </span>
        }
      </div>
    );
  }

  // TileView Image
  renderImage(
    data = {},
    bemClasses,
    fullScreenMod = false,
    fullImage = false,
    singlePhoto = false,
    restPropsImg = {}
  ) {
    let ImgTag = Image;
    let modal = null;
    let images = data.images;
    const alt = restPropsImg.alt || data.display_name || data.location_path;
    const title = restPropsImg.title || data.name || '';
    if (images && this.imagesKey && !isArray(images)) images = images[this.imagesKey];
    if (singlePhoto) images = data.title_image;
    const imagesLength = size(images);
    const restProps = {};
    const restSliderProps = {};
    if (restPropsImg.sliderCount) restSliderProps.sliderCount = restPropsImg.sliderCount
    // const restProps = { ...restPropsImg };
    const classNames = {};
    if (fullScreenMod === 'modal') {
      restProps.onClick = this.openImageModal;
      modal = this.renderImageModal(data, bemClasses);
    }
    if (!fullImage) {
      classNames.className = bemClasses('imgPic').className;
      ImgTag = Thumb;
    }

    const {
      titleImage,
      singleString,
      imageObj,
      imagesIsArray
    } = AbstractObjectTile.getImages(images, { alt, title });

    if (
      // server side
      !this.state.clientSide ||
      // render template when no images passed
      !images ||
      // contains single id as string
      singleString ||
      (imagesLength <= 1 &&
        // one image in array
        (imagesIsArray ||
        // one image object
        imageObj)
      )
    ) {
      return (
        <>
          <ImgTag
            image={titleImage}
            imgOrigin={fullImage}
            {...classNames}
            {...restProps}
          />
          {modal}
        </>
      );
    }

    return (
      <>
        <Slick
          {...classNames}
          arrowsFill={fullImage}
          lazyLoad
          {...restSliderProps}
        >
          {map(images, (img, index) => (
            <ImgTag
              key={index}
              image={AbstractObjectTile.getImages(img, { alt, title }).titleImage}
              imgOrigin={fullImage}
              {...restProps}
            />
          ))}
        </Slick>
        {modal}
      </>
    );
  }

  // TileView Content
  renderContent(data = {}, bemClasses) {
    const { display_locality, toponym, geometry, geo } = data;
    const price = data.min_price || data.price;
    const toponyms = getToponyms.call(this, display_locality, toponym, geometry || geo, bemClasses);

    if (this.checkForEmptyData(data)) {
      if (!toponyms) return <span {...bemClasses('text', 'empty')} children={this.i18n('noDataText')} />;
      return toponyms;
    }

    return (
      <>
        {toponyms}
        {this.getPrices(price, bemClasses)}
        {this.renderContentBottom(data, bemClasses)}
      </>
    );
  }

  // TileView SideBlock
  renderSideBlock(data, bemClasses) {
    if (!this.state.clientSide) return null;

    return this.showOnMapBtn(bemClasses);
  }

  // Render Map on phones
  renderUnderneath(data, bemClasses) {
    const transitionDuration = toNumber(this.sensor.getVariable('timeDuration'));

    if (!this.state.noHover && this.props.device === 'phone') {
      return (
        <CSSTransitionGroup
          component={AbstractObjectTile.renderSingleChild}
          // className="mapContainer"
          transitionName={{
            enter: 'viewTile__mapContainer_enterUp',
            enterActive: 'viewTile__mapContainer_enterUpActive',
            leave: 'viewTile__mapContainer_leaveDown',
            leaveActive: 'viewTile__mapContainer_leaveDownActive'
          }}
          transitionEnterTimeout={transitionDuration * 2}
          transitionLeaveTimeout={transitionDuration}
        >
          {this.state.showOnMap ?
            <div {...bemClasses('mapContainer')}>
              <Maps
                editable
                zoom={12}
                adaptiveScrollZoom={1000}
                // noAutoGeolocate
                // bbox={locality.geo_bbox}
                height={`${this.tileHeight * 0.7}px`}
              >
                <BaseLayersUser />
                <InfoLayer
                  pointToLayer={this.props.drawMarkerOnPhone}
                  noAutoFit
                  ref={el => this.hoverLayerRef = el}
                />
              </Maps>
            </div> :
            null
          }
        </CSSTransitionGroup>
      );
    }

    return null;
  }
}

// Toponym
export function getToponyms(displayLocality, toponym, geometry, bemClasses, inline = true) {
  if (geometry && !displayLocality && (!toponym || !toponym.name)) return null;

  const { i18n } = this;

  return (
    <div {...bemClasses('toponym')} key="toponyms">
      <span {...bemClasses('item')}>
        <span {...bemClasses('text')}>
          {displayLocality}
          {toponym && toponym.name &&
            <>
              {displayLocality && <>,&ensp;</>}
              {toponym.type === subwayStation &&
                <><Icon {...bemClasses('icon', 'metro')} type={iconTypes.metro} />{'\u00a0'}</>
              }
              {toponym.name}
              {toponym.pedestrain > 0 &&
                <>
                  {inline ?
                    <>{`\u2002\u2013\u2002${toponym.pedestrain}\u00a0${i18n('textMinutes')}\u00a0`}<Icon {...bemClasses('icon', 'walk')} type={iconTypes.walk} /></> :
                    <span {...bemClasses('pedestrain')}><Icon {...bemClasses('icon', 'walk')} type={iconTypes.walk} />{`\u00a0${toponym.pedestrain}\u00a0${i18n('textMinutes')}`}</span>
                  }
                </>
              }
              {!geometry && <>,&ensp;</>}
            </>
          }
          {displayLocality && !(toponym && toponym.name) && !geometry &&
            <>,&ensp;</>
          }
          {!geometry && i18n('notOnMapText')}
        </span>
      </span>
    </div>
  );
}
