/**
 * Created by sem on 20.10.16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import beautifyNumber from 'helpers/beautifyNumber';
import toString from 'lodash/toString';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import AbstractInput from './abstractInput';
import ValidationState from './validationState';
import { FIELD_INT, FIELD_FLOAT, VALIDATION_VALID } from './form-consts';

import 'styles/base/containers/form/_input.scss';

const bemClasses = new Bem('input');

export default class Input extends AbstractInput {
  static propTypes = {
    ...AbstractInput.propTypes,

    // Input type
    // type: PropTypes.string,

    // Input name
    name: PropTypes.string,

    labelSuffix: PropTypes.string,

    labelSuffixMax: PropTypes.bool,

    // No validation message below input (renders empty area)
    disableValidationState: PropTypes.bool,

    // Render input for password entry
    password: PropTypes.bool,

    // No error message below input (doesn't render empty area)
    noError: PropTypes.bool,

    // передает автофокус инпуту
    autoFocus: PropTypes.bool,

    // Delay for input data validation in ms
    timeValidation: PropTypes.number,

    // Callback on focus
    onFocusCallback: PropTypes.func,

    // Callback on blur
    onBlurCallback: PropTypes.func,

    // KeyDown event
    onKeyDown: PropTypes.func,

    // onKeyPress
    onKeyPress: PropTypes.func,

    // Callback on clearing input field
    clearInputCallBack: PropTypes.func,

    // Custom inline styles (admin pages)
    style: PropTypes.shape({}),

    // Min value for input of type number
    // min: PropTypes.number,

    // Max value for input of type number
    // max: PropTypes.number

    advancedChecker: PropTypes.func,

    doNotBeatify: PropTypes.bool,

    // for test
    dataTest: PropTypes.string,

    noLabel: PropTypes.bool,

    tabIndex: PropTypes.number
  };

  static defaultProps = {
    timeValidation: 0
  };

  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.clearVTimeouts = this.clearVTimeouts.bind(this);
    this.cancelValidation = this.cancelValidation.bind(this);
    this.allowValidation = this.allowValidation.bind(this);
    this.setCurrentSelection = this.setCurrentSelection.bind(this);
    this.getCaretPosition = this.getCaretPosition.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.setFocusState = this.setFocusState.bind(this);

    this.changed = false;
    this.state = { showPassword: false };
    this.delay = null;
    this.blurDelay = null;
    this.noValidation = false;

    this.inputMode = {
      RuleInt: 'numeric',
      RuleFloat: 'numeric',
    };

    this.pattern = {
      RuleInt: '[0-9]*',
      RuleFloat: '[0-9]*'
    };

    this.schemaType = {
      RuleEmail: 'email',
      RuleDate: 'text',
      // TODO number type conflicts with number beautify implementation
      // RuleFloat: 'number',
      // RuleInt: 'number'
    };

    this.state = {
      cursorPosition: 0,
      focused: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.doNotBeatify && this.props.schema && (this.props.schema.class === FIELD_INT ||
        this.props.schema.class === FIELD_FLOAT)

    ) {
      this.setCurrentSelection(this.state.cursorPosition);
    }

    if (
      this.props.onFocusCallback &&
      this.props.value !== prevProps.value &&
      this.props.value
    ) this.changed = true;
  }

  setCurrentSelection(cursorPosition) {
    const elem = this.input;
    const isBrowser =
      !!(typeof window !== 'undefined' && window.document && window.document.createElement);
    if (isBrowser && elem === document.activeElement) {
      if (elem.createTextRange) {
        const range = elem.createTextRange();
        range.move('character', cursorPosition);
        range.select();
      } else {
        elem.setSelectionRange(cursorPosition, cursorPosition);
      }
    }
    /* this.setState({
      updateCursorPosition: false
    }); */
  }

  checkInt(value) {
    if ((this.props.schema && this.props.schema.class !== FIELD_INT)
      || (value === '-' && this.props.schema.min && this.props.schema.min < 0)) return value;
    if (value.match(/\-[^0-9]+/)) return '-'; //eslint-disable-line
    const newVal = parseInt(value.replace(/[^0-9\-]/g, ''), 10); //eslint-disable-line
    return !isNaN(newVal) ? newVal : null;
  }

  checkFloat(value) {
    if ((this.props.schema && this.props.schema.class !== FIELD_FLOAT)
      || value.match(/^0\.[0]+$/) !== null
      || (value === '-' && this.props.schema.min && this.props.schema.min < 0)) return value;
    if (value === '--') return '-';
    let newValue = value
      .replace(',', '.')
      .replace(/\.+/, '.')
      .replace(/[^0-9\-\.]/g, ''); //eslint-disable-line

    if (newValue.endsWith('.') || newValue.endsWith('0')) return newValue; // TODO ??
    newValue = parseFloat(newValue);

    return !isNaN(parseFloat(newValue)) ? parseFloat(newValue) : null;
  }

  checkIntAndFloatRestrictions(value) {
    if (this.props.schema && (this.props.schema.class !== FIELD_INT &&
        this.props.schema.class !== FIELD_FLOAT)

    ) return value;

    if (this.props.schema.min && value < this.props.schema.min) {
      return this.props.schema.min;
    }

    if (this.props.schema.max && value > this.props.schema.max) {
      return this.props.schema.max;
    }
    return value;
  }

  checkOther(value) {
    if (this.props.schema && (this.props.schema.class === FIELD_INT ||
        this.props.schema.class === FIELD_FLOAT)

    ) return value;

    const maxLen = this.props.schema.max;
    if (maxLen && value.length > maxLen) {
      return value.substr(0, maxLen);
    }

    if (this.props.schema.allowedChars && value !== null) {
      return value.replace(new RegExp(this.props.schema.allowedChars, 'g'), '');
    }

    if (value === '') return null;

    return value;
  }

  getValueFromEvent(event) {
    let value = event.target.value;
    if (this.props.schema) {
      value = this.checkInt(value);
      value = this.checkFloat(value);

      value = this.checkIntAndFloatRestrictions(value);
      value = this.checkOther(value);
    }
    if (this.props.advancedChecker) {
      value = this.props.advancedChecker(value);
    }

    return value;
  }

  getCaretPosition(event) {
    this.selectionStart = this.input.selectionStart;
    this.selectionEnd = this.input.selectionEnd;
    this.selectionRange = Math.abs(this.selectionEnd - this.selectionStart);
    this.closestToStart = this.selectionStart;
    this.closestToEnd = this.selectionEnd;
    if (this.selectionStart > this.selectionEnd) {
      this.closestToStart = this.selectionEnd;
      this.closestToEnd = this.selectionStart;
    }
    const valueFromEvent = beautifyNumber(event.target.value);
    this.indexOfPointInValue = valueFromEvent.indexOf('.');
    this.deltaToEnd = valueFromEvent.length - this.closestToEnd;
    // lets find a point
    if (this.props.schema &&
      (this.props.schema.class === FIELD_FLOAT || this.props.schema.class === FIELD_INT)) {
      const selection = valueFromEvent.substring(this.closestToStart, this.closestToEnd);
      this.afterSelection = valueFromEvent.substring(this.closestToEnd, valueFromEvent.length);
      this.indexOfPointInRange = selection.indexOf('.');
    }
  }

  getChar(eventFromKeyPress) {
    if (eventFromKeyPress.which == null) {
      if (eventFromKeyPress.keyCode < 32) return null;
      return String.fromCharCode(eventFromKeyPress.keyCode);
    }
    if (eventFromKeyPress.which !== 0 && eventFromKeyPress.charCode !== 0) {
      if (eventFromKeyPress.which < 32) return null;
      return String.fromCharCode(eventFromKeyPress.which);
    }
    return null;
  }

  onKeyPress(event) {
    if (!this.props.doNotBeatify && this.props.schema && (this.props.schema.class === FIELD_INT ||
        this.props.schema.class === FIELD_FLOAT
    )) {
      const onChangeValue = this.getValueFromEvent(event);
      const beautifyValue = beautifyNumber(onChangeValue);
      // const charCode = event.which || event.keyCode;
      const charCode = this.getChar(event); // Cross-Browser
      if (charCode === ',' ||
        charCode === '.') {
        if (this.indexOfPointInValue > -1) {
          event.preventDefault();
        } else {
          const stringAfterCaret = beautifyValue.substring(this.closestToEnd);
          const cleanedStringAfterCaret = stringAfterCaret.replace(/ /g, '');
          const cleanedStringAfterCaretLen = cleanedStringAfterCaret.length;
          this.deltaToEnd = cleanedStringAfterCaretLen;
        }
      }
    }
    if (this.props.onKeyPress) this.props.onKeyPress(event);
  }
  onKeyDown(event) {
    if (!this.props.doNotBeatify && this.props.schema && (this.props.schema.class === FIELD_INT ||
        this.props.schema.class === FIELD_FLOAT
    )) {
      const onChangeValue = this.getValueFromEvent(event);
      const beautifyValue = beautifyNumber(onChangeValue);
      const beautifyValueLength = beautifyValue.length;
      const charCode = event.which || event.keyCode;
      this.getCaretPosition(event);
      switch (charCode) {
        case 8: { // backspace
          const deletionIndex = this.closestToStart - 1;
          // const nextByDeletion = this.closestToEnd + 1;

          if (deletionIndex < 0) return;
          const deletionElement = beautifyValue[deletionIndex];

          if ((this.selectionStart === 0 || this.selectionEnd === 0) && this.selectionRange > 0) {
            this.setCaretToStart = true;
          }
          if (deletionIndex === 0 && this.selectionRange < 1) {
            this.setCaretToStart = true;
          }
          if (beautifyValue[deletionIndex] === '.' && deletionIndex === 1 && beautifyValue[0] === '0') {
            this.setCaretToStart = true;
          } else if (this.selectionRange !== 0) {
            this.deltaToEnd = beautifyValueLength - this.closestToEnd;
          }
          if (deletionElement === ' ' && this.selectionRange === 0) {
            this.deltaToEnd += 1;
          }
          if ((deletionElement === '.' && this.selectionRange === 0) ||
            (this.selectionRange !== 0 && this.indexOfPointInRange > -1)) {
            const additionalSpaces = Math.trunc(this.afterSelection.length / 3);
            this.deltaToEnd = beautifyValueLength + (-this.closestToEnd) + additionalSpaces;
          }
          break;
        }
        case 46: { // delete
          if (this.selectionRange === 0) {
            if (beautifyValue[this.closestToEnd] === '.' && this.closestToEnd === 1 && beautifyValue[0] === '0') {
              this.setCaretToStart = true;
            }
            if (beautifyValue[this.closestToEnd] === '.' && (this.closestToEnd !== 1 || beautifyValue[0] !== '0')) {
              const additionalSpaces = Math.trunc((this.afterSelection.length - 1) / 3);
              this.deltaToEnd = beautifyValueLength + (-this.closestToEnd - 1) + additionalSpaces;
            } else {
              this.deltaToEnd = beautifyValueLength - this.closestToEnd - 1;
            }
          }
          break;
        }
        case 37: { // left arrow
          this.selectionEnd = this.input.selectionEnd - 1;
          if (this.selectionEnd < 0) {
            this.selectionEnd = 0;
          }
          this.deltaToEnd = beautifyValueLength - this.selectionEnd;
          break;
        }
        case 39: { // right arrow
          this.selectionEnd = this.input.selectionEnd + 1;
          if (this.selectionEnd > beautifyValueLength) this.selectionEnd = beautifyValueLength;
          this.deltaToEnd = beautifyValueLength - this.selectionEnd;
          break;
        }
        default:
        // do nothing
      }

      if (this.indexOfPointInRange > -1) {
        const additionalSpaces = Math.trunc(this.afterSelection.length / 3);
        this.deltaToEnd = beautifyValueLength + (-this.closestToEnd) + additionalSpaces;
      }
    }
    if (this.props.onKeyDown) this.props.onKeyDown(event);
  }

  onPaste(event) {
    if (!this.props.doNotBeatify && this.props.schema && (this.props.schema.class === FIELD_INT ||
        this.props.schema.class === FIELD_FLOAT
    )) {
      this.getCaretPosition(event);
      const clipboardData = event.clipboardData || window.clipboardData;
      let pastedData = clipboardData.getData('Text');
      pastedData = pastedData.replace(/,/g, '.');
      const index = pastedData.indexOf('.');
      if (this.indexOfPointInValue > -1 && index > -1) {
        event.stopPropagation(); // TODO
        this.deltaToEnd = this.closestToEnd;
      } else if (index > -1) {
        this.cleanedAfterSelection = this.afterSelection.replace(/ /g, '');
        this.deltaToEnd = this.cleanedAfterSelection.length;
      }
    }
  }

  onMouseUp(event) {
    this.getCaretPosition(event);
  }

  onTouchEnd(event) {
    this.getCaretPosition(event);
  }

  countZeros(string) {
    const stringWithZero = typeof string === 'string' ? string : toString(string);
    let count = 0;
    let i = 1;
    for (i; i <= stringWithZero.length; i += 1) {
      if (stringWithZero[stringWithZero.length - i] === '0') {
        count += 1;
      } else {
        return count;
      }
    }
    return count;
  }

  onChange(event) {
    let onChangeValue = this.getValueFromEvent(event);
    if (!this.props.doNotBeatify && this.props.schema && (this.props.schema.class === FIELD_INT ||
        this.props.schema.class === FIELD_FLOAT)

    ) {
      const beautifyValue = beautifyNumber(onChangeValue);
      this.beautifyValue = beautifyValue;


      let cursorIndex = beautifyValue.length;
      if (this.setCaretToStart) {
        cursorIndex = 0;
        this.deltaToEnd = beautifyValue.length;
        this.setCaretToStart = false;
      } else if (this.deltaToEnd > -1 && beautifyValue.length > 0) {
        cursorIndex = beautifyValue.length - this.deltaToEnd;
      }
      if (cursorIndex > -1) {
        this.setState({ cursorPosition: cursorIndex });
      }
    }

    this.clearVTimeouts();
    this.changed = true;
    if (this.props.schema &&
      this.props.schema.class === FIELD_FLOAT
    ) {
      if (onChangeValue && onChangeValue.toString().indexOf('.') > -1) {
        const stringAfterPoint =
          onChangeValue.toString().substring(onChangeValue.toString().indexOf('.') + 1,
            onChangeValue.length);
        const zerosCount = this.countZeros(stringAfterPoint);
        if (this.input.selectionEnd >= onChangeValue.toString().length && zerosCount > 0) {
          this.input.selectionEnd = onChangeValue.toString().length;

          this.setState({
            value: onChangeValue
          });
          return;
        } else if (zerosCount > 0) {
          this.deltaToEnd -= zerosCount;
          this.setState({
            value: null });
          onChangeValue = onChangeValue.substring(0, onChangeValue.length - zerosCount);
        }
      }

      if (onChangeValue && onChangeValue.toString().endsWith('.')) {
        if (this.input.selectionEnd === this.beautifyValue.length) {
          this.setState({
            value: onChangeValue
          });
        } else {
          onChangeValue = onChangeValue.substring(0, onChangeValue.length - 1);
          this.deltaToEnd -= 1;
          this.props.onChange(parseFloat(onChangeValue));
          this.setState({
            value: null
          });
        }
      } else {
        this.props.onChange(parseFloat(onChangeValue), event);
        this.setState({
          value: null
        });
      }
    } else {
      this.props.onChange(onChangeValue, event);
    }


    // TODO: event.persist() is needed to access the event properties in an asynchronous way
    if (event.persist) event.persist();
    this.delayValidation(event);
  }

  delayValidation(event) {
    if (this.props.timeValidation === 0) {
      return;
    }

    this.delay = setTimeout(() => {
      this.performValidation(this.getValueFromEvent(event));
    }, this.props.timeValidation);
  }

  setFocusState(state) {
    this.focused = state;
  }

  setFocus = () => {
    this.setFocusState(true);
    this.setState({ focused: true });
    this.input.focus();
  }

  blur = () => {
    this.input.blur();
  };

  handleBlur(event) {
    this.setFocusState(false);
    if (this.props.onBlurCallback) {
      setTimeout(() =>
        this.props.onBlurCallback(event, this, this.props.dataBind),
      300);
    }

    this.clearVTimeouts();
    if (this.noValidation) {
      this.noValidation = false;
      return;
    }
    event.persist();
    this.blurDelay = setTimeout(() =>
      this.performValidation(this.getValueFromEvent(event)),
    30);
    this.setState({
      focused: false
    });
  }

  handleFocus(evt) {
    this.allowValidation();
    if (this.props.onFocusCallback) this.props.onFocusCallback(evt, this, this.props.dataBind);
    this.clearVTimeouts();
    this.setState({
      focused: true
    });
  }

  clearInput() {
    this.props.onChange(null);
    this.changed = false;
    if (this.props.clearInputCallBack) this.props.clearInputCallBack(true);
    this.input.focus();
    if (this.state.value) {
      this.setState({ value: null });
    }
  }

  clearVTimeouts() {
    clearTimeout(this.blurDelay);
    clearTimeout(this.delay);
  }

  cancelValidation() {
    this.noValidation = true;
  }

  allowValidation() {
    this.noValidation = false;
  }

  insertAtCursor(text) {
    const start = this.input.selectionStart;
    const end = this.input.selectionEnd;
    const { value } = this.props;
    if (!value) {
      this.props.onChange(text);
    }
    const newValue = value ? value.substring(0, start)
      + text
      + value.substring(end, value.length) : text;
    this.props.onChange(newValue);
  }

  render() {
    if (!this.props.doNotBeatify && this.props.schema && (this.props.schema.class === FIELD_INT ||
        this.props.schema.class === FIELD_FLOAT)
    ) {
      this.value = this.state.value ?
        beautifyNumber(this.state.value) : beautifyNumber(this.props.value);
    } else {
      this.value = this.state.value ? this.state.value : this.props.value;
      if (this.value === null) this.value = '';
    }
    if (
      this.props.tipSuffix &&
      this.value &&
      this.value.toString() &&
      this.value.toString().length > 0
    ) {
      this.value = `${this.value} ${this.props.tipSuffix}`;
    }
    const {
      schema = {}, password, noError, autoFocus, /* min, */ max, labelSuffixMax,
      dataBind, style, className, dataTest, noLabel, disabled
    } = this.props;
    const { showPassword } = this.state;
    const required = schema.required;
    const empty = this.props.disableValidationState;
    const mods = { required, empty, disabled: schema.readOnly };
    const validationState =
      this.props.validationState ? this.props.validationState.state : VALIDATION_VALID;

    return (
      <div {...bemClasses(null, mods, className)} style={style} data-test={dataTest}>
        <div {...bemClasses('inputWrapper')} data-test="inputWrapper">
          <div {...bemClasses('inputContainer')} data-test="inputTextContainer">
            <input
              // key={this.value ? 'isNew' : 'MaybeOld'}
              {...bemClasses('input', {
                error: validationState !== VALIDATION_VALID
              })}
              // min={min}
              max={max}
              ref={component => this.input = component}
              type={password && !showPassword ? 'password' : this.schemaType[schema.class] || 'text'}
              inputMode={this.inputMode[schema.class] || null}
              pattern={this.pattern[schema.class] || null}
              value={this.value !== null ? this.value : ''}
              onChange={this.onChange}
              onPaste={this.onPaste}
              onBlur={this.handleBlur}
              autoFocus={autoFocus}
              onFocus={this.handleFocus}
              readOnly={schema.readOnly}
              // onKeyUp={onKeyUp || null}
              onKeyDown={this.onKeyDown}
              onKeyPress={this.onKeyPress}
              onMouseUp={this.onMouseUp}
              onTouchEnd={this.onTouchEnd}
              name={this.props.name ? this.props.name : null}
              placeholder={this.props.placeholder || schema.placeholder || ''}
              autoCapitalize={(dataBind === 'email' || password) ? 'none' : 'sentences'}
              tabIndex={this.props.tabIndex || '-1'}
              disabled={disabled}
            />
            {password &&
              <Button
                {...bemClasses('btn', 'show')}
                onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                onMouseDown={this.cancelValidation}
                onTouchEnd={this.cancelValidation}
                tabIndex="-1"
                iconType={showPassword ? iconTypes.visibility : iconTypes.visibilityOff}
                noPadding
              />
            }
            {!password && (this.value === 0 || this.value) && !schema.readOnly && !disabled &&
              <Button
                {...bemClasses('btn', 'clear')}
                onClick={this.clearInput}
                onMouseDown={this.cancelValidation}
                onTouchEnd={this.cancelValidation}
                tabIndex="-1"
                iconType={iconTypes.clear}
                noPadding
                data-test="clearInput"
              />
            }
            {schema && schema.label && !noLabel && (
              <div {...bemClasses('labelContainer')}>
                <span {...bemClasses('text', 'label')}>
                  {schema.label}
                  {this.props.labelSuffix &&
                  !this.state.focused &&
                  (!this.value && this.value.toString().length < 1) &&
                    <span>&nbsp;{this.props.labelSuffix}</span>
                  }
                  {!!labelSuffixMax && !!schema.max &&
                    <span>&nbsp;(до {schema.max} символов)</span>
                  }
                </span>
                {required && <React.Fragment>&nbsp;<span {...bemClasses('text', 'asterisk')}>*</span></React.Fragment>}
              </div>
            )}
            <span {...bemClasses('bottomLine')} />
          </div>
          {!noError &&
            <div {...bemClasses('errorWrapper')} data-test="errorContainer">
              {!this.props.disableValidationState &&
                <ValidationState
                  validationState={this.props.validationState}
                />
              }
            </div>
          }
        </div>
      </div>
    );
  }
}
