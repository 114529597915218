/* eslint-disable */
/* eslint-disable class-methods-use-this */
import Leaflet from 'leaflet';
import PropTypes from 'prop-types';
import { ExtendableFeatureGroup } from 'react-leaflet-extendable';
import { withLeaflet } from 'react-leaflet';
import FreeDrawBase, { CREATE, NONE } from 'leaflet-freedraw';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import isObjectLike from 'lodash/isObjectLike';
import each from 'lodash/each';
import concat from 'lodash/concat';
import apiDecorator from 'api/api-decorator';
import md5 from 'md5';
import cloneLayer from './clone-layer';
import { I18nHoc } from 'helpers/i18n';
import { metersToDegrees } from './helpers';

import standartMarkerIcon from './standartMarkerIcon';
import { marker } from './marker-icons';

import './vendor/snap';
import './vendor/point-in-polygon';

import translates from './map-i18n.json';

const firstMarker = Leaflet.divIcon({
  className: 'leaflet-div-icon leaflet-vertex-icon editor-poly-first-marker',
  iconSize: [22, 22]
});

/**
 * Patch for fix bug with app crashes when remove
 */
// TODO monitor new versions for probably fix this bug
FreeDrawBase.prototype.remove = function() {};

@apiDecorator
@I18nHoc(translates)
class InputGeometry extends ExtendableFeatureGroup {
  static propTypes = {
    buttonLabel: PropTypes.string,
    displayAreaNumber: PropTypes.bool,
    contextMenu: PropTypes.bool,
    // Layer order (upper - higher)
    order: PropTypes.number,
    // Geometry editor style
    style: PropTypes.shape({}),
    // Snap polygon to enabled GIS layers
    snap: PropTypes.bool,
    buttons: PropTypes.arrayOf(
      PropTypes.oneOf([
        'point',
        'line',
        'polygon',
        'circle',
        'freedraw',
        'house',
        'hole',
        'drag',
        'and',
        'or',
        'xor',
        'simplify'
      ])
    )
  };

  static defaultProps = {
    buttons: [],
    buttonLabel: '',
    displayAreaNumber: false,
    contextMenu: false
  };

  componentDidMount() {
    this.createControl();
    // Register layer to map's GIS layers repository
    this._map._registerGisLayer(this.leafletElement);

    // Add layers to map
    this._map.addLayer(this.freeDraw);
    this._map.addLayer(this.labelsGroup);
    this._map.addLayer(this.errorMarkers);

    // Reset geoprocessing
    this._geoprocessingEnd();

    this.updatePolygons(this.props);
    super.componentDidMount();
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value) ||
      !isEqual(this.props.validationState, nextProps.validationState);
  }

  componentWillUnmount() {
    this._map.removeLayer(this.freeDraw);
    this._map.removeLayer(this.labelsGroup);
    this._map.removeLayer(this.errorMarkers);
    this._map._unregisterGisLayer(this.leafletElement);
    this._map.off('click', this._geoProcessing);
    return super.componentWillUnmount();
  }

  /**
   * Create editor
   * @param props
   * @return {LeafletElement}
   */
  createLeafletElement(props) {
    this._geoProcessing = this._geoProcessing.bind(this);
    // Init
    this._map = props.leaflet.map;
    // Workaround for fitBounds and componentWllReceiveProps after drawing
    this.drawing = false;
    // Workaround for layerremove event when props received
    this.clearing = false;
    this.dragging = false;

    // Geoprocessing (AND/OR/XOR/etc) mode
    this.geoProcessing = null;

    // Create vertex snap helper
    this.snap = new Leaflet.Handler.MarkerSnap(this._map);

    // Create main geometry editor element
    const el = super.createLeafletElement({
      props
    });
    this._map.editTools.featuresLayer = el;
    el._updateSnapGuides = this._updateSnapGuides;


    this._map.on('click', this._geoProcessing);

    // Layer for labels
    this.labelsGroup = Leaflet.layerGroup();

    // Layer for error markers
    this.errorMarkers = Leaflet.layerGroup();

    // Drawing handlers and helpers
    el.on('layeradd', (event) =>
      event.layer
        .on('editable:dragend', () => this.endDrawing())
        .on('editable:vertex:deleted', () => this.endDrawing())
        .on('editable:vertex:dragend', () => this.endDrawing())
        .on('editable:shape:deleted', () => this.endDrawing())
        .enableEdit(this._map)
    ).on('layerremove', () => !this.clearing && this.endDrawing());

    // Custom draw geometry editor
    this.freeDraw = new FreeDrawBase({
      mode: NONE,
      simplifyFactor: 1.3,
      leaveModeAfterCreate: true,

    });
    this.freeDraw.on('markers', (event) => {
      if (event.latLngs.length === 0) {
        return;
      }
      this.leafletElement.addLayer(
        Leaflet.polygon(event.latLngs)
      );
      setTimeout(() => {
        this.freeDraw.clear();
        this.freeDraw.mode(NONE);
      }, 0);
      this.endDrawing();
    });

    // Layers order helper
    if (props.order) {
      el._gisOrder = props.order;
    }
    el._type='inputGeometry';

    return el;
  }

  /**
   * Update editor
   * @param prevProps
   * @param props
   */
  updateLeafletElement(prevProps, props) {
    this.updatePolygons(props);
    if (!isEqual(this.currentButtons, props.buttons)) {
      this.createControl();
    }
  }

  /**
   * Update editor's control
   * @param sel
   */
  updateControl(sel) {
    if (this.control) {
      this.control.setSelected(sel);
    }
  }

  /**
   * Create editor's control
   */
  createControl() {
    const { i18n } = this;

    if (this.control) {
      this.control.remove();
    }

    const allButtons = {
      point: {
        id: 'point',
        label: '\ue99b',
        iconClass: 'mapPlace',
        hint: i18n('mapDot')
      },
      line: {
        id: 'line',
        label: i18n('line'),
        iconClass: 'mapLine',
        hint: i18n('mapLine')
      },
      polygon: {
        id: 'polygon',
        icon: '\ue985',
        iconClass: 'mapPoly',
        hint: i18n('mapPolygon')
      },
      freedraw: {
        id: 'freedraw',
        icon: '\ue98a',
        iconClass: 'mapFreedraw',
        hint: i18n('mapFreedraw')
      },
      circle: {
        id: 'circle',
        icon: '\ue98b',
        iconClass: 'mapCircle',
        hint: i18n('mapCircle')
      },
      house: {
        id: 'house',
        icon: '\ue930',
        iconClass: 'checkbox',
        hint: i18n('mapHouse')
      },
      hole: {
        id: 'hole',
        icon: i18n('mapHoleShort'),
        iconClass: 'checkbox',
        hint: i18n('mapHole')
      },
      drag: {
        id: 'drag',
        icon: '\ue99f',
        iconClass: 'dragging',
        hint: i18n('mapDrag')
      },
      and: {
        id: 'and',
        icon: 'Сум.',
        iconClass: '',
        hint: i18n('mapAnd')
      },
      or: {
        id: 'or',
        icon: 'Пер.',
        iconClass: '',
        hint: i18n('mapOr')
      },
      xor: {
        id: 'xor',
        icon: i18n('mapXorShort'),
        iconClass: '',
        hint: i18n('mapXor')
      },
      simplify: {
        id: 'simplify',
        icon: i18n('mapSimplifyShort'),
        iconClass: '',
        hint: i18n('mapSimplify')
      }
    };

    const enabledButtons = {};
    each(this.props.buttons, (val) =>
      enabledButtons[val] = allButtons[val]
    );

    // Workaround. Because strange behavior with props...
    this.currentButtons = this.props.buttons;

    const control = new Leaflet.Control.ButtonSwitchControl({
      title: this.props.buttonLabel,
      switchMode: true,
      onSelect: (val, key) => {
        switch (key) {
          case 'point':
            this.draw('Point');
            break;
          case 'line':
            this.draw('Line');
            break;
          case 'polygon':
            this.draw('Polygon');
            break;
          case 'freedraw':
            this.draw('FreePoly');
            break;
          case 'circle':
            this.draw('Circle');
            break;
          case 'hole':
            this.draw('Hole');
            break;
          case 'house':
            this._map.on('click', this._startDrawHouse)
            break;
          case 'drag':
            this.toggleDrag();
            break;
          case 'and':
            this._geoprocessingStart('and');
            break;
          case 'or':
            this._geoprocessingStart('or');
            break;
          case 'xor':
            this._geoprocessingStart('xor');
            break;
          case 'simplify':
            this._geoSimplify();
            break;
          default:
            this.draw(null);
            break;
        }
      },
      buttons: enabledButtons
    });
    control.addTo(this._map);
    this.control = control;
  }

  /**
   * Start snap helper
   * @private
   */
  _snapStart() {
    if (!this.props.snap) {
      return;
    }
    this._updateSnapGuides();
    this._map.on('editable:vertex:dragstart', this._snapWatch);
    this._map.on('editable:vertex:dragend', this._snapUnwatch);
  }

  /**
   * Finish snap helper
   * @private
   */
  _snapEnd() {
    if (!this.props.snap) {
      return;
    }
    this._map.off('editable:vertex:dragstart', this._snapWatch);
    this._map.off('editable:vertex:dragend', this._snapUnwatch);
    this.snap.clearGuideLayers();
  }

  _geoprocessingStart(method) {
    this.geoProcessing = method;
  }

  _geoprocessingEnd() {
    this.geoProcessing = null;
    this.updateControl(null);
  }

  /**
   * Apply geoprocessing if needed
   * @param evt
   * @private
   */
  _geoProcessing(evt) {
    if (!this.geoProcessing) {
      return;
    }

    each(this._map._gisLayers, (gl) => {
      if (gl !== this.leafletElement) {
        each(gl.getLayers(), (layer) => {
          if (layer.contains(evt.latlng)) {
            this.api.post('action/location/common/geoprocess', {
              postData: {
                op: this.geoProcessing,
                geo1: this._getResultJson(),
                geo2: layer.toGeoJSON()
              }
            }).then(payload => this.props.onChange(payload));
          }
        })
      }
    });
    this._geoprocessingEnd();
  }

  /**
   * Simplify geometry with requested treshold
   * @param evt
   * @private
   */
  _geoSimplify() {
    this.api.post('action/location/common/geo-simplify', {
      postData: {
        treshold: prompt('Укажите порог упрощения в метрах'),
        geo: this._getResultJson()
      }
    }).then(payload => this.props.onChange(payload));
  }

  /**
   * Update helper layers for snapping
   * @private
   */
  _updateSnapGuides = () => {
    this.snap.clearGuideLayers();
    if (this._map._gisLayers) {
      each(this._map._gisLayers, (layer) => {
        if (layer !== this.leafletElement) {
          this.snap.addGuideLayer(layer);
        }
      });
    }
  }

  /**
   * Watch marker for snapping
   * @param e
   * @private
   */
  _snapWatch = (e) => {
    this.snap.watchMarker(e.vertex);
  }

  /**
   * Unsatch marker for snapping
   * @param e
   * @private
   */
  _snapUnwatch = (e) => {
    this.snap.unwatchMarker(e.vertex);
  }

  /**
   * Set feature style if needed
   * @param layer
   * @param feature
   * @private
   */
  _setFeatureStyle(layer, feature) {
    if (layer.setStyle) {
      let { style = { } } = this.props;
      if (feature.properties && feature.properties.highlight) {
        style = {
          color: '#800',
          fillColor: '#800'
        };
      }
      layer.setStyle(style);
    }
  }

  /**
   * Bind context menu to feature layer
   * @param layer
   * @private
   */
  _bindContextMenu(layer) {
    if (this.props.contextMenu) {
      layer.bindContextMenu({
        contextmenu: true,
        contextmenuItems: [{
          text: 'Удалить',
          callback: (evt) => {
            const rm = layer.editor.deleteShapeAt;
            if (rm) {
              layer.editor.deleteShapeAt(evt.latlng);
            } else {
              this.leafletElement.removeLayer(layer);
            }
          }
        }]
      });
    }
  }

  _bindLayerAreaNumber(layer, number) {
    if (this.props.displayAreaNumber) {
      const icon = Leaflet.divIcon({
        className: 'leaflet-label',
        html: `<div style="margin-top: 1.5rem">Область ${number}</div>`,
        iconSize: [300, 80]
      });
      this.labelsGroup.addLayer(
        Leaflet.marker(
          layer.getCenter ? layer.getCenter() : layer.getLatLng(),
          {icon})
      );
    }
  }

  _getFeatureGeometry(feature) {
    return feature.geometry ?
      feature.geometry :
      feature;
  }

  _defineFeatureLayer(feature, layer) {
    let el = layer;
    const geo = this._getFeatureGeometry(feature);
    const c = geo.coordinates;
    if (geo.radius) {
      el = Leaflet.circle(
        [c[1], c[0]],
        geo.radius
      );
    }
    return el;
  }

  _removeInvalidCoords(json, isCoords = false) {
    if (isObjectLike(json)) {
      each(json, (val, key) => {
        if (isCoords && isObjectLike(val) && (typeof val[0] === 'undefined')) {
          json[key] = [];
        }
        if (key === 'coordinates') {
          this._removeInvalidCoords(json[key], true);
        } else if (isObjectLike(val)) {
          this._removeInvalidCoords(json[key], isCoords);
        }
      });
    }
  }

  /**
   * Get valid GeoJSON representation of this input geoetry
   * @private
   */
  _getResultJson() {
    let json = this.leafletElement.toGeoJSON();
    this._removeInvalidCoords(json);
    if (json) {
      json.features = filter(json.features, (val) =>
        val.geometry.type === 'Point' || (
          val.geometry.coordinates[0] &&
          val.geometry.coordinates[0][0] !== null &&
          typeof val.geometry.coordinates[0][0] !== 'undefined'
        )
      );
    }
    if (json.features.length === 0) {
      json = null;
    }
    return json;
  }

  /**
   * Add a polygons from external source
   * @param polygons
   */
  addPolygons(polygons) {
    each(polygons, poly =>
      this.leafletElement.addLayer(cloneLayer(poly))
    );
    this.endDrawing();
  }

  /**
   * Check validation state for error markers,
   * and draw this markers if they are exists
   * @param props
   */
  updateValidationState(props) {
    this.errorMarkers.clearLayers();
    if (!props.validationState) {
      return;
    }
    if (!props.validationState.messages) {
      return;
    }

    let points = [];
    // Markers draw
    each(props.validationState.messages, (msg) => {
      if (!msg.params) {
        return;
      }
      if (!msg.params.points) {
        return;
      }
      points = concat(points, msg.params.points);
    });

    Leaflet.geoJSON(points, {
      interactive: false,
      pointToLayer: (point, latLng) =>
        this.errorMarkers.addLayer(Leaflet.marker(latLng, {
          icon: marker('red')
        }))
    });
  }

  /**
   * Update polygons (calls, when props received)
   * @param props
   */
  updatePolygons(props) {
    this._snapEnd();
    this.clearing = true;
    this.leafletElement.clearLayers();
    this.labelsGroup.clearLayers();
    this.clearing = false;

    if (props.value === null) {
      this.drawing = false;
      this.prevCoordHash = null;
      return;
    }
    let count = 0;
    let coordHash = [];
    const json = Leaflet.geoJSON(props.value, {
      interactive: true,
      draggable: this.dragging,
      pointToLayer: (point, latLng) => {
        return Leaflet.marker(latLng, {
          icon: standartMarkerIcon,
          contextmenu: this.props.contextMenu,
          contextmenuItems: this.featureContextMenu
        });
      },

      onEachFeature: (feature, layer) => {
        count += 1;
        const newLayer = this._defineFeatureLayer(feature, layer);
        coordHash.push(this._getFeatureGeometry(feature));
        this._setFeatureStyle(newLayer, feature);
        this._bindContextMenu(newLayer);
        newLayer.on('add', (evt) => {
          this._bindLayerAreaNumber(evt.target, count);
        });
        this.leafletElement.addLayer(newLayer);
      }});

    // Update error markers from validation state
    this.updateValidationState(props);

    // Check, is geometry was changed from outside
    coordHash = md5(JSON.stringify(coordHash));
    if (!this.drawing && count > 0 && this.prevCoordHash !== coordHash) {
      this._map.fitBounds(json.getBounds(), { maxZoom: 18 });
      this._map._boundsAlreadySet = true;
    }
    this.drawing = false;
    this.prevCoordHash = coordHash;
    this._map._orderGisLayers();
    this._snapStart();
  }

  endDrawing = () => {
    this.dragging = false;
    let json = this._getResultJson();

    this.drawing = true;
    this.props.onChange(json);
    this.updateControl(null);
    Leaflet.DomUtil.removeClass(this._map._container, 'crosshair-cursor-enabled');
  }

  clear() {
    this.props.onChange(null);
    this.updateControl(null);
  }

  stopDrawing() {
    this._map.editTools.stopDrawing();
    this.freeDraw.mode(NONE);
    Leaflet.DomUtil.removeClass(this._map._container,'crosshair-cursor-enabled');
  }

  draw(what, latLng) {
    this.stopDrawing();
    if (what === null) {
      Leaflet.DomUtil.removeClass(this._map._container,'crosshair-cursor-enabled');
      return;
    }
    Leaflet.DomUtil.addClass(this._map._container,'crosshair-cursor-enabled');
    this[`draw${what}`](latLng);
  }

  drawPolygon(latLng = null) {
    this._map.editTools
      .startPolygon(latLng, { })
      .on('editable:vertex:new', (marker) => {
        if (marker.vertex.getIndex() === 0 ) {
          marker.vertex.setIcon(firstMarker);
        }
      })
      .on('editable:drawing:end', this.endDrawing);
  }

  drawFreePoly() {
    this.freeDraw.mode(CREATE);
    Leaflet.DomUtil.addClass(this._map._container,'crosshair-cursor-enabled');
  }

  drawCircle(latLng = null) {
    this._map.editTools
      .startCircle(latLng)
      .on('editable:drawing:end', this.endDrawing);
  }

  drawLine(latLng = null) {
    this._map.editTools
      .startPolyline(latLng)
      .on('editable:drawing:end', this.endDrawing);
  }

  drawPoint(latLng = null) {
    this._map.editTools
      .startMarker(latLng)
      .on('editable:drawing:end', this.endDrawing);
  }

  _startDrawHouse = (evt) => {
    this._map.off('click', this._startDrawHouse);
    this.draw('House', evt.latlng);
  }

  _startDrawHole = (evt) => {
    this.leafletElement.off('click', this._startDrawHole);
    if (evt.layer.editor) {
      evt.layer.editor.newHole(evt.latlng);
      evt.layer.on('editable:drawing:end', this.endDrawing);
    }
  }

  drawHouse(latLng) {
    const { lat, lng } = latLng;
    const m1 = metersToDegrees(10);
    const m2 = metersToDegrees(15);
    const poly = Leaflet.polygon([
      [lat-m1, lng-m2], [lat-m1, lng+m2],
      [lat+m1, lng+m2], [lat+m1, lng-m2]
    ]);
    this.leafletElement.addLayer(poly);
    this.endDrawing();
  }

  drawHole(latLng = null) {
    this.leafletElement.on('click', this._startDrawHole);
  }


  toggleDrag() {
    this.dragging = !this.dragging;
    this.updatePolygons(this.props);
  }

}

export default withLeaflet(InputGeometry);
