import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Row, { Col } from 'components/row';

const classesTwoColumnView = new Bem('twoColumnView');
const columnRenderCfg = {
  leftColumn: 'renderLeftColumn',
  rightColumn: 'renderRightColumn'
};

export default class TwoColumnView extends Component {
  static propTypes = {
    renderLeftColumn: PropTypes.func,
    renderRightColumn: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    pagesProps: PropTypes.shape({
      pagesAmount: PropTypes.number,
      changePage: PropTypes.func,
      currentPage: PropTypes.number
    })
  };

  renderColumn(method) {
    const { data = [], pagesProps = {} } = this.props;
    const columnMethod = this.props[method];
    if (!columnMethod) return null;

    let addProp = {
      mdCw: 562,
      mp: 12
    };
    if (method === columnRenderCfg.rightColumn) {
      addProp = {
        mdAdjust: 562,
        mp: 0
      };
    }
    return (
      <Col
        {...classesTwoColumnView('col')}
        {...addProp}
        allPr={0}
        allAlignSStretch
        mt={6}
      >
        {columnMethod(data, pagesProps)}
      </Col>
    );
  }

  render() {
    return (
      <Row noMaxW {...classesTwoColumnView()}>
        {this.renderColumn(columnRenderCfg.leftColumn)}
        {this.renderColumn(columnRenderCfg.rightColumn)}
      </Row>
    );
  }
}
