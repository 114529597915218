import queryString from 'query-string';
import isObject from 'lodash/isObject';

import config from '../../config';

// Returns absolute URL (with domain and protocol)
export default function absoluteUrl(path, query = null, projectAlternate = null) {
  const qEncoded = isObject(query) ? queryString.stringify(query) : query;
  const qs = qEncoded || '';
  const q = (qs && qs.charAt(0) !== '?') ? `?${qs}` : qs;
  const project = projectAlternate || __PROJECT__; // eslint-disable-line

  return `${config.protos[project]}://${config.domains[project]}${path}${q}`;
}
