import PropTypes from 'prop-types';

export const propTypePoint = PropTypes.shape({
  type: PropTypes.oneOf(['Point']),
  coordinates: PropTypes.array
});

export const propTypeGeoJSON = PropTypes.shape({
  type: PropTypes.oneOf([
    'Point',
    'Polygon',
    'LineString',
    'MultiLineString',
    'MultiPolygon'
  ]),
  coordinates: PropTypes.array
});
