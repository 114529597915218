import React, { Component } from 'react';
import { routerShape, locationShape } from 'react-router';
import LazyHydrate from 'react-lazy-hydration';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import filter from 'lodash/filter';
import each from 'lodash/each';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
// import { setBackUrl } from 'helpers/back-url-store';
import apiDecorator from 'api/api-decorator';
import { FavoriteWrapper } from 'subsys/favorites';
import Container from 'components/container';
import Breadcrumbs from 'components/breadcrumbs';
import { OpenGraphImage } from 'components/image';
import TabJSControl from 'components/tab-js-control';
import Paginator, { Control, Container as ContainerPaginator, Pages } from 'components/paginator';

import config from './config';

import translates from './favorites-i18n.json';

import 'styles/base/subsys/favorites/_page-favorites.scss';

const bemClasses = new Bem('pageFavorites');

@apiDecorator
@I18nHoc(translates)
export default class PageFavorites extends Component {
  static propTypes = {
    className: PropTypes.string,
    params: PropTypes.shape({
      project: PropTypes.string
    }),
    router: routerShape,
    location: locationShape,
    dictLocation: PropTypes.array, // eslint-disable-line
    dictObject: PropTypes.array,// eslint-disable-line
    seoData: PropTypes.shape({}),
  };

  constructor(...props) {
    super(...props);

    this.renderFavoritesTabs = this.renderFavoritesTabs.bind(this);
    this.rendererFavoritesPage = this.rendererFavoritesPage.bind(this);

    this.state = {
      tabSelected: 0
    };
  }

  // componentDidMount() {
  //   this._clearBackUrl();
  // }
  //
  // componentDidUpdate() {
  //   this._clearBackUrl();
  // }

  // _clearBackUrl() {
  //   setBackUrl();
  // }

  renderFavoritesTabs({ data = {} }, config) {
    if (data.length === 0) {
      return (
        <Container body>
          <div {...bemClasses('noData')}>
            <span {...bemClasses('text')} children={this.i18n('noDataText')} />
          </div>
        </Container>
      );
    }

    const subgroup = {};
    const { tabSelected } = this.state;

    each(data, (val) => {
      subgroup[val.subgroup] = {
        ids: map(filter(data, ['subgroup', val.subgroup]), 'entity_id'),
        entity: val.entity
      };
    });
    const type = Object.keys(subgroup)[tabSelected] || Object.keys(subgroup)[0];
    const currentPage = subgroup[type];

    if (!currentPage) this.setState({ tabSelected: 0 });

    const itemType = get(config, `[${currentPage.entity}][${type}]`);
    const project = get(this, 'props.params.project');
    const dataProviderUrl =
      get(config, `[${currentPage.entity}][${type}].dataProviderUrl[${project}]`) ||
      get(config, `[${currentPage.entity}][${type}].dataProviderUrl`);
    const favComponent = get(itemType, `component[${project}]`) || get(itemType, `component`);
    const customQuery = get(itemType, 'customQuery', {});

    return (
      <>
        <div {...bemClasses('tabContainer')}>
          <Container body>
            <TabJSControl
              {...bemClasses('tab')}
              onChange={(tab, index) => this.setState({ tabSelected: index })}
              selectedTab={tabSelected}
            >
              {map(subgroup, (item, key) => {
                return (
                  <tab
                    key={key}
                    id={key}
                    title={`${get(config, `[${item.entity}][${key}].name`)} ${get(item, 'ids.length')}`}
                  />
                );
              })}
            </TabJSControl>
          </Container>
        </div>
        <Container body {...bemClasses('tabContent')}>
          {currentPage && dataProviderUrl && itemType &&
            <Paginator
              url={dataProviderUrl}
              query={{ ids: currentPage.ids, priceCurrency: 'uah', ...customQuery }}
              queryJson
              limit={20}
              lruCache={false}
            >
              <Control />
              <ContainerPaginator
                {...bemClasses('projects')}
                injectPropName="object"
              >
                {favComponent({ favData: data, project, router: this.props.router })}
              </ContainerPaginator>
              <Pages />
            </Paginator>
          }
        </Container>
      </>
    );
  }

  rendererFavoritesPage(config) {
    const { className, params: { project = null, landing = {} } = {} } = this.props;

    return (
      <div {...bemClasses({ extra: className })}>
        <LazyHydrate ssrOnly>
          <OpenGraphImage />
        </LazyHydrate>
        <Container body {...bemClasses('title')}>
          <div {...bemClasses('text')}><h1 children={this.i18n('favourites')} /></div>
        </Container>
        <FavoriteWrapper
          project={project}
          children={data => this.renderFavoritesTabs(data, config)}
        />
        <Container body>
          <Breadcrumbs data={landing.breadcrumbs} />
        </Container>
      </div>
    );
  }

  render() {
    return this.rendererFavoritesPage(config.call(this));
  }
}
