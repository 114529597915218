/**
 * Created by Kotkin on 28.08.2017.
 */
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataProvider from 'api/data-provider';
import Bem from 'bemHelper';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import cloneDeep from 'lodash/cloneDeep';
import without from 'lodash/without';
import map from 'lodash/map';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import AbstractInput from './abstractInput';

import 'styles/base/containers/form/_chosen-item.scss';

const bemClasses = new Bem('inputShowSelected');

class InputShowSelected extends AbstractInput {
  static propTypes = {
    ...AbstractInput.props,

    // Url for dataProvider
    url: PropTypes.string,
    idsCriteria: PropTypes.string,
    query: PropTypes.object, //eslint-disable-line
    nameField: PropTypes.string,
    noLabel: PropTypes.bool,
    setSearchFormTopPosition: PropTypes.func
  };

  constructor(...props) {
    super(...props);
    this.onChange = this.onChange.bind(this);
    this.highlight = this.highlight.bind(this);
    this.unhighlight = this.unhighlight.bind(this);
  }

  onChange(id) {
    const { value } = this.props;
    let newValue = null;
    // For geoJSON features group
    if (isObject(value) && isArray(value.features)) {
      const f = value.features.slice();
      f.splice(id, 1);
      newValue = {
        ...value,
        features: f
      };
    } else {
      // For simple array
      newValue = without(value, id);
    }
    if (this.props.onChange) this.props.onChange(newValue);
  }

  setFeatureProp(feature, prop, val) {
    const { value } = this.props;
    if (isObject(value) && isArray(value.features)) {
      const newValue = cloneDeep(value);
      newValue.features[feature].properties[prop] = val;
      this.props.onChange(newValue);
    }
  }

  highlight(id) {
    this.setFeatureProp(id, 'highlight', true);
  }

  unhighlight(id) {
    this.setFeatureProp(id, 'highlight', false);
  }

  renderDataProvider() {
    const {
      url, idsCriteria, query = {}, nameField, value
    } = this.props;

    return (
      <DataProvider
        url={url}
        query={{
          ...query,
          [idsCriteria]: value.features || value
        }}
        queryJson
        renderMethod="list"
      >
        <ChosenItemsRenderer
          nameField={nameField}
          onChange={this.onChange}
          setSearchFormTopPosition={this.props.setSearchFormTopPosition}
        />
      </DataProvider>
    );
  }

  renderMapFeatures() {
    const list = this.props.value.features;

    return map(list, (item, key) => (
      <ChosenItemsRenderer
        key={key}
        nameField="name"
        data={{ id: key, name: `Область ${key + 1}` }}
        onMouseOver={this.highlight}
        onMouseOut={this.unhighlight}
        onChange={this.onChange}
        setSearchFormTopPosition={this.props.setSearchFormTopPosition}
      />
    ));
  }

  render() {
    const { schema = {}, value, noLabel, className } = this.props;

    let length = 0;
    let renderer = 'dp';
    if (isObject(value) && isArray(value.features)) {
      length = value.features.length;
      renderer = 'feature';
    }
    if (isArray(value)) length = value.length;

    if (noLabel && !length) return null;
    return (
      <div {...bemClasses({ extra: className })}>
        {!length &&
          <span {...bemClasses('text')}>{schema.label}</span>
        }
        {!!length && renderer === 'dp' &&
          this.renderDataProvider()
        }
        {!!length && renderer === 'feature' &&
          this.renderMapFeatures()
        }
      </div>
    );
  }
}

const bemClassesItem = new Bem('chosenItem');

class ChosenItemsRenderer extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.object, //eslint-disable-line
    nameField: PropTypes.string,
    onChange: PropTypes.func,
    setSearchFormTopPosition: PropTypes.func
  };

  static contextTypes = {
    calcChildOffset: PropTypes.func
  };

  componentDidMount() {
    if (this.props.setSearchFormTopPosition) this.props.setSearchFormTopPosition();
    if (this.context.calcChildOffset) this.context.calcChildOffset();
  }

  componentWillUnmount() {
    if (this.props.setSearchFormTopPosition) this.props.setSearchFormTopPosition();
  }

  emit(evt, prm) {
    if (this.props[evt]) {
      this.props[evt](prm);
    }
  }

  render() {
    const { className, data, nameField } = this.props;
    const { id } = data;

    return (
      <div
        {...bemClassesItem({ extra: className })}
        onMouseOver={() => this.emit('onMouseOver', id)}
        onMouseOut={() => this.emit('onMouseOut', id)}
      >
        <span {...bemClassesItem('text')}>{data[nameField]}</span>
        <Button
          {...bemClassesItem('btn')}
          onClick={() => this.props.onChange(id)}
          iconType={iconTypes.clearCircle}
          noPadding
        />
      </div>
    );
  }
}

export default InputShowSelected;
