/**
 * Created by SLK on 21.03.2017.
 */
import React, { Component, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import forEach from 'lodash/forEach';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import sensor from 'components/sensor';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import 'styles/base/components/accordion/_accordion.scss';

const classes = new Bem('accordion');

/**
 * === Accordion Component ===
 *
 * = Example =
 * <Accordion>
 *   <div panelTopic={accordion btn title}>
 *     <div>
 *       {accordion content}
 *     </div>
 *   </div>
 * </Accordion>
 *
 * <Accordion>
 *   <div
 *     panelTopic={accordion btn title - required}
 *     panelIconType={additional accordion btn left icon}
 *     panelTopicRight={additional accordion btn right notification text}
 *   >
 *     <div {...classes('accordionContent')>
 *       {accordion content}
 *       <Accordion>
 *         <div panelTopic={accordion btn title}>
 *           <div {...classes('accordionContent')>
 *             {accordion content}
 *           </div>
 *         </div>
 *       </Accordion>
 *     </div>
 *   </div>
 * </Accordion>
 *
 */

@sensor
export default class Accordion extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,

    // render accordion without button.
    // Only show/hide content (made for Maps:
    // not rendering them in DOM until user wants to see them)
    noHeaderButton: PropTypes.bool,

    // Fired right after accordion is opened (optional)
    openCallBack: PropTypes.func,

    // Fired before accordion is closed (optional)
    beforeCloseCallBack: PropTypes.func,

    // Different views
    dataView: PropTypes.PropTypes.oneOf([
      'searchForm',
      'viewMenuMobile',
      'viewBlockMain',
      'viewBlockOnlyBtn',
      'viewBlockForum',
      'viewBlockForumLink'
    ]),

    // Accordion is opened by default
    firstlyOpened: PropTypes.bool,

    // убирает боковые padding
    noPaddingLR: PropTypes.bool,

    // убирает нижний margin
    noMarginB: PropTypes.bool,

    // убирает тень
    noShadow: PropTypes.bool,

    // пересчитывает высоту чилдов на апдейте компонента
    recalcOnUpdate: PropTypes.bool,

    panelTopic: PropTypes.any, // eslint-disable-line
    panelIconType: PropTypes.string,
    panelBefore: PropTypes.node,
    panelBlock: PropTypes.bool,
    withPanelBefore: PropTypes.bool,
    disabledToggle: PropTypes.bool,
    panelTopicRight: PropTypes.any, // eslint-disable-line
    onPanelClick: PropTypes.func,

    // Удваивает время открытия/закрытия аккордеона
    doubleTime: PropTypes.bool
  };

  static defaultProps = {
    panelTopic: 'Accordion Title',
    panelBlock: false
  };

  static childContextTypes = {
    calcChildOffset: PropTypes.func
  };

  static getDerivedStateFromProps(props, state) {
    if(state.prevPropsFirstlyOpened === props.firstlyOpened) return null;

    return {
      opened: !!props.firstlyOpened,
      prevPropsFirstlyOpened: props.firstlyOpened
    }
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.openContent = this.openContent.bind(this);
    this.closeContent = this.closeContent.bind(this);
    this.toggle = this.toggle.bind(this);
    this.calcChildOffset = this.calcChildOffset.bind(this);

    this.stateChanged = false;
    this.delay = null;
  }

  /**
   * Pass func into context to recalc max height for dynamic Accordion content
   * @returns {{calcChildOffset: func}}
   */
  getChildContext() {
    return {
      calcChildOffset: this.calcChildOffset
    };
  }

  componentDidUpdate() {
    if (this.props.recalcOnUpdate && this.state.opened) this.calcChildOffset();
  }

  calcChildOffset(content = null) {
    const panel = content || get(this, 'element.children[0]') || {};
    // calculating height of all accordion enclosures
    let height = 0;
    const elements = this.element ? this.element.querySelectorAll('.accordion__content') : [];
    forEach(elements, item => height += item.scrollHeight);
    if (panel.style) panel.style.maxHeight = `${height / 10}rem`;
  }

  toggle() {
    clearTimeout(this.delay);

    this.toggleVisibility();
    if (this.state.opened && this.props.beforeCloseCallBack) this.props.beforeCloseCallBack();

    this.setState({ opened: !this.state.opened }, () => {
      if (this.state.opened && this.props.openCallBack) this.props.openCallBack();
    });
    const transitionDuration = toNumber(this.sensor.getVariable('timeDuration'));
    const timeDelay = this.props.doubleTime ? transitionDuration * 2 : transitionDuration;
    this.delay = setTimeout(() => this.toggleVisibility('visible', this.state.opened), timeDelay);

    const panel = get(this, 'element.children[0]') || {};
    if (panel.style.maxHeight ||
      (this.props.firstlyOpened && !this.stateChanged)) {
      if (panel.style) panel.style.maxHeight = null;
    } else {
      this.calcChildOffset(panel);
    }

    if (!this.stateChanged) this.stateChanged = true;
  }

  toggleVisibility(visibility = '') {
    if (this.state.opened) this.element.style.overflow = visibility;
  }

  // opens Accordion content via ref
  openContent() {
    if (!this.state.opened) return this.toggle();
  }

  closeContent() {
    if (this.state.opened) return this.toggle();
  }

  renderNoHeaderAccordion(children, bindedPanelBefore = null) {
    const {
      panelTopic, panelIconType, panelTopicRight, panelBlock, onPanelClick
    } = this.props;
    const panelBefore = bindedPanelBefore || this.props.panelBefore;

    return (
      <div {...classes('panel', { row: panelBefore })}>
        {panelBefore &&
          <div
            {...classes('btn', 'before', children.props.className)}
            children={panelBefore}
            data-test="btnBefore"
          />
        }
        <Button
          {...classes('btn', 'panel', children.props.className)}
          onClick={this.onPanelClick}
          iconType={!panelBefore && panelIconType}
          label={panelTopic}
          labelSecond={!!panelTopicRight && panelTopicRight}
          iconSecondType={iconTypes.dropdown}
          contentBlock={panelBlock}
        />
      </div>
    );
  }

  onPanelClick = () => {
    const { onPanelClick, disabledToggle } = this.props;

    if (!disabledToggle) this.toggle();
    if (onPanelClick) onPanelClick();
  }

  render() {
    const {
      className, noHeaderButton, dataView,
      noPaddingLR, noMarginB, noShadow,
      doubleTime, withPanelBefore, firstlyOpened
    } = this.props;
    const children = Children.toArray(this.props.children)[0];
    const { opened } = this.state;
    const mods = {
      opened, [dataView]: dataView, noPaddingLR, noMarginB, noShadow, doubleTime
    };

    // Accordion items content
    let contentEl = null;
    let panelBefore = null;

    if (noHeaderButton) {
      contentEl = cloneElement(children, {
        ...classes('content', {
          preOpened: firstlyOpened && !this.stateChanged
        }, children.props.className)
      });
    } else {
      if (withPanelBefore) {
        panelBefore = children.props.children[0];
        contentEl = children.props.children[1];
      } else {
        contentEl = children.props.children[0] || children.props.children;
      }

      contentEl = cloneElement(contentEl, {
        ...classes('content', {
          preOpened: firstlyOpened && !this.stateChanged
        }, contentEl.props.className)
      });
    }

    return (
      <div {...classes(null, mods, className)}>
        {!noHeaderButton && this.renderNoHeaderAccordion(children, panelBefore)}
        <div
          {...classes('contentContainer')}
          style={(opened && firstlyOpened && !this.stateChanged) ? { overflow: 'visible' } : null}
          ref={ref => this.element = ref}
          children={contentEl}
          data-test="contentContainerAccordion"
        />
      </div>
    );
  }
}
