import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import replace from 'lodash/replace';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import simpleStore from 'helpers/simpleStore';
import Link from 'components/link';

import translates from './hide-telephone-i18n.json';

const bemClasses = new Bem('hideTelephone');

const initialState = {
  firstRender: true,
  openedPhone: true,
  clickedPhone: false,
  prevPhone: null
};

@I18nHoc(translates)
export default class HideTelephoneSeo extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    phone: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    classMods: PropTypes.shape({}),
    active: PropTypes.bool,
    btnLabel: PropTypes.string,
    btnBeforeText: PropTypes.shape({}),
    slicedNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    // seoProps: PropTypes.shape({}) // props for SEO
  };

  static getDerivedStateFromProps(props, state) {
    if (props.phone !== state.prevPhone) {
      return {
        ...initialState,
        openedPhone: state.firstRender ? true : false,
        firstRender: false,
        prevPhone: props.phone
      }
    }
    return null;
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.openThisPhone = this.openThisPhone.bind(this);
    this.closeThisPhone = this.closeThisPhone.bind(this);
    this.state = initialState;

    this.openedPhones = simpleStore.get('openedPagePhones');
    if (props.active) this.addToStore(props.phone);
  }

  componentDidMount() {
    this.closeThisPhone();
  }

  openThisPhone() {
    if (this.state.openedPhone) return;
    if (this.props.onClick) this.props.onClick();
    this.addToStore(this.props.phone);
    setTimeout(
      () => this.setState({
        openedPhone: true,
        clickedPhone: true
      }),
      300
    );
  }

  closeThisPhone() {
    this.setState({ openedPhone: false });
  }

  addToStore(phone) {
    if (!this.openedPhones) return null;

    const index = this.openedPhones.indexOf(phone);
    if (index === -1) {
      this.openedPhones.push(phone);
      simpleStore.set('openedPagePhones', this.openedPhones);
    }
  }

  render() {
    const { openedPhone, clickedPhone } = this.state;
    const {
      className, phone, classMods = {}, btnLabel, btnBeforeText = null, slicedNumber /* , seoProps = {} */
    } = this.props;
    const hidedPhone = slicedNumber ? phone.substr(0, (phone.length - slicedNumber)) : '';
    const linkTxt = openedPhone ? phone : hidedPhone;

    return (
      <Link
        {...bemClasses(
          null, { opened: openedPhone, clicked: clickedPhone, ...classMods }, className
        )}
        // {...seoProps}
        title={openedPhone ? phone : this.i18n('phoneShow')}
        href={openedPhone ? `tel:${replace(phone, /[\s\(\)]/g, '')}` : ''}
        onClick={this.openThisPhone}
        rel="nofollow"
        external
      >
        <span {...bemClasses('btnContent')}>
          {btnBeforeText}
          <span {...bemClasses('text', 'tel')} children={linkTxt} />
          <span {...bemClasses('text', 'instead')} children={btnLabel} />
        </span>
      </Link>
    );
  }
}
