import React, { PureComponent, createElement, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import map from 'lodash/map';
import Carousel from 'components/carousel';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import Image from './image';

import 'styles/base/containers/form/_input-image.scss';

const bemClassesInput = new Bem('inputImage');

export default class FullScreenSlider extends PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({})),
    sliderProps: PropTypes.shape({}),
    selectedImage: PropTypes.number,
    onCloseCallback: PropTypes.func
  };

  static defaultProps = {
    sliderProps: {
      infiniteLoop: true,
      showIndicators: false,
      showThumbs: true
    }
  };

  static getImageSrc(img) {
    // TODO: implement
    if (img.src) return img.src;
    const fullImg = Image.getFullImageLink(img);
    if (fullImg) return fullImg;
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.escTrigger = this.escTrigger.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escTrigger, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escTrigger, false);
  }

  escTrigger(e) {
    if (e.keyCode === 27 && this.props.onCloseCallback) {
      this.props.onCloseCallback();
    }
  }

  render() {
    if (!this.props.images) return null;

    const {
      images = [], selectedImage = 0, sliderProps = {}, onCloseCallback
    } = this.props;

    return ReactDOM.createPortal(
      <div {...bemClassesInput('fullScreen')}>
        <Carousel
          {...bemClassesInput('carousel')}
          {...sliderProps}
          selectedItem={selectedImage}
        >
          {map(images, (img, key) => {
            return (
              <Fragment key={key}>
                {
                  createElement('img', {
                    ...bemClassesInput('img'),
                    src: FullScreenSlider.getImageSrc(img),
                    alt: img.alt || '',
                    title: img.title || null
                  })
                }
                {img.topic}
              </Fragment>
            );
          })}
        </Carousel>
        <div {...bemClassesInput('iconsContainer', 'top right')}>
          <Button
            {...bemClassesInput('btn', 'close')}
            title="Закрыть"
            onClick={() => onCloseCallback && onCloseCallback()}
            iconType={iconTypes.clear}
            noPadding
          />
        </div>
      </div>,
      document.getElementById('content')
    );
  }
}
