/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import isNaN from 'lodash/isNaN';
import filter from 'lodash/filter';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import apiDecorator from 'api/api-decorator';
import { getErrorMessage } from 'api/api-errors';
import Notification from 'components/notification';

import FormInputContainer from './form-input-container';
import FormVisContainer from './form-vis-container';
import { VALIDATION_VALID } from './form-consts';

/**
 * Abstract Form Component
 */
@apiDecorator
class AbstractForm extends PureComponent {
  static propTypes = {
    // *** Props
    // Form controller URL
    controller: PropTypes.string,

    // Default values, which has a priority above schema's default values
    defaultValues: PropTypes.shape({}),

    // Function-checker, that determines element's visibility
    visibilityChecker: PropTypes.func,

    // Inject this from input-form props to inputs
    // Needed for input-form and input-array
    injectFromProps: PropTypes.array, // eslint-disable-line

    // *** Events
    // Fires when form mounted and schema loaded
    onMount: PropTypes.func, // eslint-disable-line

    // *** System props
    children: PropTypes.node, // eslint-disable-line
  };

  static defaultProps = {
    defaultValues: {},
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      schema: {},
      error: {}
    };
    this.validationStates = {};
    this.dataDirty = false;
    this._mounted = true;
    this.inputIdx = {};
    this.visIdx = { '*': [] };

    this.handleDataVisChangeRequest = this.handleDataVisChangeRequest.bind(this);
  }

  componentDidMount() {
    return null;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  /**
   * Overriding for checking component unmounted
   * @param newState
   * @param callback
   */
  setState(newState, callback) {
    if (!this._mounted) {
      return;
    }
    super.setState(newState, callback);
  }

  forceUpdate() {
    if (!this._mounted) {
      return;
    }
    super.forceUpdate();
  }

  /** @private methods */

  /**
   * Get rule for corresponding field
   * @param schema
   * @param field
   * @return {{}}
   * @private
   */
  _getRule(schema, field) {
    return (schema
    && schema.rules
    && schema.rules[field])
      ? schema.rules[field]
      : {};
  }

  /**
   * Get value of corresponding field
   * @param values
   * @param field
   * @return {*}
   * @private
   */
  _getValue(values, field) {
    if (!values) {
      return null;
    }
    let fieldValue = values[field];
    if (isNaN(fieldValue)) fieldValue = null;
    if (isUndefined(fieldValue)) fieldValue = null;
    return fieldValue;
  }

  /**
   * Get form data
   * @protected
   * @abstract
   */
  _getData() {}

  /**
   * Fires form event
   * @param event
   * @param args
   * @protected
   */
  _fire(event, args) {
    if (this.props[event]) {
      this.props[event](...args);
    }
  }

  /**
   * Query API
   * @param method
   * @param suffix
   * @param params
   * @protected
   */
  _query(method, suffix, params, controller) {
    const queryString = method === 'get' ? params : undefined;
    const postData = ['post', 'put', 'delete'].indexOf(method) >= 0 ? params : undefined;
    const cUrl = controller || this.props.controller;
    return this.api[method](
      `form/${cUrl}/${suffix}`,
      { queryString, postData }
      );
  }

  /**
   * Display error message
   * @param error
   * @param header
   * @protected
   */
  _error(error, header) {
    const msg = getErrorMessage(error);
    console.error(error); // eslint-disable-line
    Notification.error(header, msg);
  }

  /**
   * Set default values from schema.
   * insertValues prop has a priority, if it exists
   * @param schema
   * @param force
   * @protected
   */
  _setDefaultValuesFromSchema(schema, data) {
    const newData = cloneDeep(data);
    const { defaultValues = {} } = this.props;
    forEach(schema.rules, (val, key) => {
      if (typeof data[key] === 'undefined') {
        newData[key] = cloneDeep(
          defaultValues[key] || (typeof val.defaultValue !== 'undefined' ? val.defaultValue : null)
        );
      } else {
        newData[key] = cloneDeep(data[key]);
      }
    });
    return newData;
  }

  /**
   * Set validation states from schema
   * @param schema
   * @protected
   */
  _setValidationStatesFromSchema(schema) {
    this.validationStates = {};
    forEach(schema.rules, (val, key) => {
      this.validationStates[key] = { state: VALIDATION_VALID, message: null };
    });
  }

  /**
   * Set validation states from validator (after submit or dynamic validator)
   * @param states
   * @param setDefaults
   * @return {{}}
   * @protected
   */
  _setValidationStatesFromValidator(states, setDefaults = false) {
    if (setDefaults) {
      this._setValidationStatesFromSchema(this.state.schema);
    }
    forEach(states, (val, key) => {
      this.validationStates[key] = val;
      this._updateInputContainer(key);
    });
  }

  /**
   *
   * @param schema
   * @protected
   */
  _setSchema(schema, other, callback) {
    this._setValidationStatesFromSchema(schema);
    this.setState({
      schemaLoading: false,
      schemaLoadFail: false,
      schemaLoaded: true,
      schema,
      ...other
    }, () => callback && callback());
  }

  _validate(toSend) {
    this._query('post', 'validate', toSend)
      .then((payload) => {
        this._setValidationStatesFromValidator(payload.validatorMessages, false);
        this._updateData(payload.updateData);
      });
  }

  _updateData(data) {
    forEach(data, (val, key) => {
      this.data[key] = val;
      this._updateInputContainer(key);
    });
  }

  _cloneElement(
    element,
    props,
    values,
    schema,
    validationStates,
    noBind = false
  ) {
    return React.cloneElement(element, props,

      this.renderChildren(
        element.props.children,
        values,
        schema,
        validationStates,
        element.type.noDataBindChildren || noBind
      ));
  }

  _updateInputContainer(field) {
    // Input containers
    forEach(this.inputIdx[field], (item) => {
      if (item) {
        item.update(
          this._getValue(this.data, field),
          this.validationStates[field]
        );
      }
    });

    // Vis containers
    if (this.visIdx[field]) {
      forEach(this.visIdx[field], (item) => {
        if (item) {
          item.update();
        }
      });
    }
    forEach(this.visIdx['*'], (item) => {
      if (item) {
        item.update();
      }
    });
  }

  /**
   * Handle field change event
   * @abstract
   */
  _changeField(field, value, markAsDirty, scope) {
    // Update related fields
    this._updateInputContainer(field);
  }

  /**
   * Handle any form field change
   * and update form state
   * @private
   * @scope Input Control
   * */
  handleFieldChange(value) {
    this._form._changeField(
      this.dataBind,
      value,
      true,
      this
    );
  }

  /**
   * Non-standart case, that changes more than one field
   * Uses in some non-standart inputs, such as search form wrapper in
   * landing builder
   * @param fields
   */
  handleFieldMultiChange(fields) {
    forEach(fields, (val, key) => this._form._changeField(
      key,
      val,
      true,
      this
    ));
  }

  /**
   * Handle data visualizer fields change
   * @param field
   * @param value
   */
  handleDataVisChangeRequest(field, value) {
    this._changeField(field, value, true, this);
  }

  /**
   * Internal changes, such as initializations,
   * that not mark data as dirty
   * @private
   * @scope  Input Control
   * @scope Input Control
   */
  handleFieldInternalChange(value) {
    this._form._changeField(
      this.dataBind,
      value,
      false,
      this
    );
  }

  /**
   * Handle dynamic validation, when field
   * synamic validation needed
   * @private
   * @scope Input Control
   */
  handleValidation(value) {
    const form = this._form;
    const field = this.dataBind;
    const deps = form.state.schema.constraintFieldDeps[field];
    const data = form._getData();
    const pk = form.state.schema.primaryKeyField;
    const toSend = {
      [pk]: data[pk],
      [field]: value
    };
    if (deps) {
      deps.forEach(val => toSend[val] = data[val]);
    }
    form._validate(toSend);
  }

  /**
   * @scope Input Control
   */
  handleDataUpdateNeeded(value, item) {
    const form = this._form;
    const du = this.dataUpdater;
    if (du) {
      du(value, item, form.state.schema)
        .then(upd => form._updateData(upd));
    }
  }

  registerInputIdx(field, el) {
    if (!this.inputIdx[field]) {
      this.inputIdx[field] = [];
    }
    this.inputIdx[field].push(el);
  }

  unregisterInputIdx(field, el) {
    this.inputIdx[field] = filter(this.inputIdx[field], item =>
      item !== el
    );
  }

  registerVisIdx(fields, el) {
    if (fields === '*') {
      this.visIdx['*'].push(el);
      return null;
    }

    forEach(fields, (field) => {
      if (!this.visIdx[field]) {
        this.visIdx[field] = [];
      }
      this.visIdx[field].push(el);
    });
  }

  unregisterVisIdx(fields, el) {
    forEach(fields, (field) => {
      this.visIdx[field] = filter(this.visIdx[field], item =>
        item !== el
      );
    });
  }

  updateData(data, emitOnChange = false) {
    this._updateData(data);
    if (emitOnChange) {
      this._fire('onChange', [this.data, this.state, this.dataDirty]);
    }
  }

  /**
   * Inject props to Data Input components
   * @private
   */
  injectInputProperties(element, values, schema, validationStates) {
    const field = element.props.dataBind;
    const props = {
      ...element.props,
      schema: this._getRule(schema, field),
      onChange: this.handleFieldChange,
      onMultiChange: this.handleFieldMultiChange,
      onInternalChange: this.handleFieldInternalChange,
      onValidation: this.handleValidation,
      onDataUpdateNeeded: this.handleDataUpdateNeeded,
      _form: this,
      _inheritOnChange: element.props.onChange
    };

    if (this.props.injectFromProps) {
      forEach(this.props.injectFromProps, val =>
        props[val] = this.props[val]
      );
    }

    return React.createElement(
      FormInputContainer, { key: `inp_${field}`, element, ...props }
    );
  }

  /**
   * Insect props to dataVisualizer components
   * @private
   */
  injectVisualizerProperties(element) {
    return React.createElement(
      FormVisContainer, {
        key: `vis_${md5(JSON.stringify(element.props.dataVisualize))}`,
        _form: this,
        onChange: this.handleDataVisChangeRequest,
        element,
        ...element.props
      });

  }

  /**
   * Inject other props if needed. Can be override
   */
  injectOthers(element, values, schema, validationState) {

  }

  /**
   * Render form children, and inject
   * corresponding props if needed
   * @protected
   */
  renderChildren(list, values, schema, validationStates, noBind = false) {
    // Check for internal child mapping (such as ArrayInput)
    if (list && !Array.isArray(list) /* && list.type && list.type._internalChildMapping */) {
      // return React.cloneElement(list);
    }

    const rv = React.Children.map(list, (element) => {
      if (!element) return null;
      if (!element.props) return element;

      if (this.props.visibilityChecker) {
        if (!this.props.visibilityChecker(element, this.state.schema)) {
          return null;
        }
      }

      const others = this.injectOthers(
        element,
        values,
        schema,
        validationStates
      );
      if (others) {
        return others;
      }

      if (element.props.dataBind && element.props.dataVisualize && !noBind) {
        return this.injectVisualizerProperties(
          this.injectInputProperties(
            element,
            values,
            schema,
            validationStates,
          ),
          values,
          schema,
          validationStates
        );
      }

      if (element.props.dataVisualize && !noBind) {
        return this.injectVisualizerProperties(
          element,
          values,
          schema,
          validationStates
        );
      }

      if (element.props.dataBind && !noBind) {
        return this.injectInputProperties(
          element,
          values,
          schema,
          validationStates,
        );
      }

      return this._cloneElement(
        element,
        { ...element.props },
        values,
        schema,
        validationStates,
        noBind
      );
    });

    return rv;
  }

  render() {
    return null;
  }
}

export default AbstractForm;
